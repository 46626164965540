import Review, { ReviewInput } from 'services/reviews/models/Review'
import Location, { LocationInput } from 'services/shared/models/Location'
import Availability, { AvailabilityInput } from 'services/shared/models/Availability'
import Activity, { ActivityInput } from 'services/shared/models/Activity'
import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'
import Image, { ImageInput } from 'services/shared/models/Image'
import { getCapacityString, getDurationString, formatDuration } from 'utils/datesHelpers'
import Property, { PropertyInput } from 'services/shared/models/Property'
import Section, { EXPERIENCE_SECTIONS, SectionInput, SectionOutput } from 'services/shared/models/Section'
import Pricing, { PricingInput, PricingOutput } from 'services/shared/models/Pricing'

export enum ExperienceStatus {
  Published = 'published',
  Draft = 'draft',
  Canelled = 'cancelled',
}

export const EXPERIENCE_CREATE = {
  id: 0,
  guests: 1,
  base_price: 0,
  variable_price: 0,
  capacity: 1,
  minimum_capacity: 1,
  maximum_capacity: 1,
  days_capacity: 0,
  hours_capacity: 0,
  duration: 0,
  rating: 0,
  image_order: [],
  title: '',
  slug: '',
  activities: [],
  description: '',
  pre_trip_information: '',
  sections: [],
  properties: [],
  locations: [],
  images: [],
  status: 'draft',
  pricing: null,
  hosts: [],
  reviews: [],
  availability: [],
  parent: null,
} as ExperienceInput

export interface ExperienceOutput {
  id: number
  title: string
  activities: number[]
  description: string
  pre_trip_information: string
  sections: SectionOutput[]
  properties: Property[]
  locations: Location[]
  images: Array<number | string>
  status: string
  pricing: PricingOutput
  parent: number | null
}

export interface ExperienceInput {
  id: number
  slug: string
  guests: number
  base_price: number
  variable_price: number
  capacity: number
  minimum_capacity: number
  maximum_capacity: number
  days_capacity: number
  hours_capacity: number
  title: string
  duration: number
  description: string
  pre_trip_information: string
  properties: PropertyInput[] | null
  rating: number
  images: ImageInput[]
  image_order: ImageInput['name'][]
  sections: SectionInput[]
  status: string
  hosts: UserPublicInput[]
  reviews: ReviewInput[]
  locations: LocationInput[]
  activities: ActivityInput[]
  availability: AvailabilityInput[]
  pricing: PricingInput | null
  parent: number | null
}
export interface ExperienceElasticInput {
  id: string
  _source: ExperienceInput
}
class Experience {
  id: number
  slug: string
  guests: number
  basePrice: number
  variablePrice: number
  minimum_capacity: number
  maximum_capacity: number
  days_capacity: number
  hours_capacity: number
  title: string
  duration: number
  description: string
  preTripInformation: string
  properties: Property[]
  rating: number | null
  images: Image[]
  image?: Image
  imageUrl?: Image['url']
  sections: Section[]
  status: string
  hosts: UserPublic[]
  host: UserPublic | null
  reviews: Review[]
  locations: Location[]
  location: Location | null
  activities: Activity[]
  availability: Availability[]
  capacityString: string
  durationString: string
  pricing: Pricing
  parent: number | null

  constructor(marketingPage: ExperienceInput) {
    this.id = marketingPage.id
    this.slug = marketingPage.slug
    this.guests = marketingPage.capacity
    this.basePrice = marketingPage.base_price / 100
    this.parent = marketingPage.parent

    if (marketingPage.minimum_capacity > 0) {
      this.minimum_capacity = marketingPage?.minimum_capacity
      this.maximum_capacity = marketingPage?.maximum_capacity
      this.variablePrice = marketingPage.variable_price / 100
    } else {
      this.minimum_capacity = 1
      this.maximum_capacity = marketingPage.capacity
      this.variablePrice = marketingPage.base_price / 100
    }

    this.days_capacity = marketingPage?.days_capacity
    this.hours_capacity = marketingPage?.hours_capacity

    this.title = marketingPage.title
    this.duration = marketingPage.duration
    this.preTripInformation = marketingPage.pre_trip_information
    this.description = marketingPage.description
    this.properties = Property.fromArray(marketingPage.properties).filter((p) => ![`What's included`].includes(p.name))
    this.rating = marketingPage.rating && marketingPage.rating > 0 ? marketingPage.rating : null
    this.images = Image.fromArray(marketingPage.images)

    this.image = this.images[0] || undefined
    this.imageUrl = this.image?.getLargeSize() || undefined

    const currentSections = Section.fromArray(marketingPage.sections)

    this.sections = EXPERIENCE_SECTIONS.map((section) => {
      const existingSection = currentSections.find((s) => s.title === section.title && s.__component === section.type)
      return existingSection
        ? existingSection
        : new Section({
            id: 0,
            title: section.title,
            __component: section.type,
          })
    })

    this.status = marketingPage.status
    this.hosts = UserPublic.fromArray(marketingPage.hosts)
    this.host = this.hosts.length ? this.hosts[0] : null
    this.reviews = Review.fromArray(marketingPage.reviews)
    this.locations = Location.fromArray(marketingPage.locations)
    this.location = this.locations.length ? this.locations[0] : null
    this.activities = Activity.fromArray(marketingPage.activities)
    this.availability = Availability.fromArray(marketingPage.availability)
    this.pricing = new Pricing(marketingPage.pricing || {})

    this.capacityString = Experience.getCapacityString(this)
    this.durationString = Experience.getDurationString(this)
  }
  static getCapacityString(experience: Experience) {
    return getCapacityString(
      experience.pricing
        ? {
            min: experience.pricing.minimumCapacity,
            max: experience.pricing.maximumCapacity,
          }
        : {
            min: experience.minimum_capacity,
            max: experience.maximum_capacity,
          }
    )
  }

  static getDurationString(experience: Experience) {
    return getDurationString(formatDuration(experience.pricing ? experience.pricing.duration : experience.duration))
  }

  static fromArray(input: ExperienceInput[] | string | null): Experience[] {
    return input && Array.isArray(input) ? input.map((i) => new Experience(i)) : []
  }

  static toOutput(experience: Experience): ExperienceOutput {
    // do we need it stored as properties?
    // probably used only to make elastic search query with whats included values
    const additionalProperties = new Property({
      type: 'multiselect',
      name: `What's included`,
      value: experience.sections.find((s) => s.title === `What's included`)?.icons?.map((icon) => icon.title) || [],
    } as PropertyInput)

    return {
      id: experience.id,
      title: experience.title,
      activities: experience.activities?.map((a) => a.id),
      description: experience.description,
      pre_trip_information: experience.preTripInformation,
      sections: experience.sections ? experience.sections.map(Section.toOutput) : [],
      properties: (experience.properties || []).concat([additionalProperties]),
      locations: experience.locations,
      images: experience.images.map((i) => i.id),
      status: experience.status,
      pricing: Pricing.toOutput(experience.pricing),
      parent: experience.parent,
    }
  }

  static fromObject(input: Experience): Experience {
    input.images = Image.fromArray(input.images as ImageInput[])
    input.locations = Location.fromArray(input.locations)
    input.location = input.locations.length ? input.locations[0] : null
    return input
  }
}

export default Experience
