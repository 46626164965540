import React from 'react'
import { Link } from 'react-router-dom'
import iconsPath from 'assets/icons/sprite.svg'
import classNames from 'classnames'
import styles from './HomeButton.module.scss'

interface IHomeButton {
  className?: string
  size?: string
}

export const HomeButton: React.FC<IHomeButton> = ({ className, size = 'normal' }) => (
  <div className={classNames(className, styles['home-button'])}>
    <Link to="/">
      {size === 'normal' ? (
        <svg
          className={styles['home-button__logo']}
          viewBox="0 0 138 31"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlinkHref={`${iconsPath}#icon_brand_logo`} />
        </svg>
      ) : (
        <svg
          className={styles['home-button__logo-small']}
          viewBox="0 0 31 31"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlinkHref={`${iconsPath}#icon_brand_logo_small`} />
        </svg>
      )}
    </Link>
  </div>
)
