import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import classNames from 'classnames'
import styles from './Partners.module.scss'

export const Partners: React.FC<{ className?: string }> = ({ className }) => (
  <>
    <Wrapper containerClasses={classNames({ [className!]: !!className }, styles.partners, styles['partners--upper'])}>
      <div className={styles.partners__content}>
        <h2 className={styles.partners__header}>2% for Conservation</h2>
        <p className={styles.partners__description}>
          We are proud to support organizations that work to preserve our wild spaces.
          <br />2 percent of our revenue is donated to Conservation NGOs.
        </p>
      </div>
    </Wrapper>
    {/* <Wrapper containerClasses={classNames(styles.partners, styles['partners--lower'])} paddingType="none">
      <div className={styles['partners__images-container']}>
        <img loading="lazy" src={PartnerImage1} alt="Partners" />
        <img loading="lazy" src={PartnerImage2} alt="Partners" />
        <img loading="lazy" src={PartnerImage3} alt="Partners" />
        <img loading="lazy" src={PartnerImage4} alt="Partners" />
        <img loading="lazy" src={PartnerImage5} alt="Partners" />
        <img loading="lazy" src={PartnerImage6} alt="Partners" />
        <img loading="lazy" src={PartnerImage7} alt="Partners" />
      </div>
    </Wrapper> */}
  </>
)
