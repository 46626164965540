import React from 'react'
import './index.scss'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { hydrate, render } from 'react-dom'
import TagManager from 'react-gtm-module'
import { store } from './store'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

// const tagManagerArgs = {
//   gtmId: 'GTM-WSHD4ZP',
// }
//
// TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById('root')
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  )
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  )
}

serviceWorker.unregister()
