import { Input } from 'components-new/forms/Input/Input'
import { Button } from 'components-new/common/Button/Button'
import React, { useMemo, useState } from 'react'
import { Select } from 'components-new/forms/Select/Select'
import { useDispatch } from 'react-redux'
import { PhoneInput } from 'components-new/forms/PhoneInput/PhoneInput'
import { countryOptions, stateOptions } from 'utils/optionHelpers'
import User from 'services/shared/models/User'
import { DeleteUser, UpdateUser } from 'services-new/auth/auth-api'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { useUser } from 'hooks/useUser'
import { PhotoUpload } from 'components-new/common/PhotoUpload/PhotoUpload'
import Image from 'services/shared/models/Image'
import { FormLabel } from 'components-new/forms/FormLabel/FormLabel'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { ZipCodeOptions, zipCodeOptions } from 'utils/formsHelper'
import { FloatingComponent } from 'components-new/common/FloatingComponent/FloatingComponent'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import styles from './AccountDetails.module.scss'

export const AccountDetails: React.FC = () => {
  const dispatch = useDispatch()
  const [formDisabledFlag, setFormDisabledFlag] = useState<boolean>(false)
  const { user } = useUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm<User>({
    defaultValues: useMemo(() => user!, [user!.id]),
  })

  const values = formMethods.watch()

  const updateDetails = async (data: any) => {
    setIsLoading(true)
    await dispatch(UpdateUser(User.toOutput({ ...data })))
    setIsLoading(false)
  }

  const handleAccountDeactivate = async () => {
    setIsLoading(true)
    await dispatch(DeleteUser({ id: user!.id }))
  }

  const isDisabled = Object.keys(formMethods.formState.errors || {}).length > 0

  const zipCodePattern = zipCodeOptions[values.address?.country as keyof ZipCodeOptions]
    ? zipCodeOptions[values.address?.country as keyof ZipCodeOptions]
    : zipCodeOptions['Other']

  return (
    <form className={styles['account-details__wrapper']} onSubmit={formMethods.handleSubmit(updateDetails)}>
      <FormProvider {...formMethods}>
        <Wrapper className={styles['account-details-container__wrapper']}>
          <div className={styles['account-details__profile']}>
            <div className={styles['account-details__image']}>
              <FormLabel>Profile Image</FormLabel>
              <Controller
                name="image"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <PhotoUpload
                      id="profile_image"
                      ref={field.ref}
                      onUpload={() => setFormDisabledFlag(true)}
                      onUploadFinish={() => setFormDisabledFlag(false)}
                      value={field.value ? [new Image(field.value)] : []}
                      onChange={(images) => field.onChange(images[0] ?? null)}
                      max={1}
                      error={(error as any)?.message}
                    />
                  )
                }}
              />
            </div>
            <div className={styles['account-details__name']}>
              <Controller
                name="firstName"
                rules={{ required: 'This field is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} placeholder="First Name" label="First Name" />
                )}
              />
              <Controller
                name="lastName"
                rules={{ required: 'This field is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} placeholder="Last Name" label="Last Name" />
                )}
              />
            </div>
          </div>
          <Controller
            name="gender"
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                error={error?.message}
                placeholder="Gender"
                label="Gender"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                defaultValue={'male'}
              />
            )}
          />
          <Controller
            name="dateOfBirth"
            render={({ field, fieldState: { error } }) => (
              <Input {...field} error={error?.message} placeholder="Date of Birth" label="Date of Birth" type="date" />
            )}
          />
          <Controller
            name="address.address"
            render={({ field, fieldState: { error } }) => (
              <Input {...field} error={error?.message} placeholder="Street" label="Street" />
            )}
          />
          <Controller
            name="address.city"
            render={({ field, fieldState: { error } }) => (
              <Input {...field} error={error?.message} placeholder="City" label="City" />
            )}
          />
          <div className={styles['account-details__inputs']}>
            <Controller
              name="address.country"
              rules={{
                deps: ['address.zip'],
              }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  error={error?.message}
                  name="name"
                  label="Country"
                  placeholder="Country"
                  options={countryOptions}
                  allowEmpty
                  onChange={(value) => {
                    field.onChange(value)
                  }}
                  value={field.value || null}
                />
              )}
            />
            {values.address?.country === 'United States of America' && (
              <Controller
                name="address.state"
                rules={{
                  required: 'This field is required',
                }}
                shouldUnregister={true}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    error={error?.message}
                    name="name"
                    label="State"
                    placeholder="State"
                    options={stateOptions}
                    allowEmpty
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                    value={field.value || null}
                  />
                )}
              />
            )}
            <Controller
              name="address.zip"
              rules={{
                validate: {
                  zipCodeValidation: (value) => !value || zipCodePattern.test(value) || 'Invalid Zip Code',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} error={error?.message} placeholder="Zip Code" label="Zip Code" />
              )}
            />
          </div>
          <Controller
            name="phone"
            rules={{
              required: 'This field is required',
              validate: (value) => !value || isValidPhoneNumber(value) || 'Invalid phone number',
            }}
            render={({ field, fieldState: { error } }) => (
              <PhoneInput
                {...field}
                error={error?.message}
                name="phone"
                label="Phone"
                className={styles['account-details__phone-input']}
              />
            )}
          />
          <Input
            type="text"
            label="email"
            value={user!.email}
            disabled={true}
            inputClassName={styles['account-details__email-input']}
          />
        </Wrapper>
        <FloatingComponent stickyOnMobile>
          <Wrapper contentClasses={styles['account-details__buttons']}>
            <Button
              theme="plain-no-border"
              onClick={() => {
                if (window.confirm('Are you sure you want to remove account?')) {
                  handleAccountDeactivate()
                }
              }}
              className={styles['account-details__deactivate-button']}
              loading={isLoading}
            >
              Deactivate Account
            </Button>
            <Button
              theme="primary"
              type="submit"
              size="small"
              loading={isLoading || formDisabledFlag}
              disabled={isDisabled}
            >
              Save Changes
            </Button>
          </Wrapper>
        </FloatingComponent>
      </FormProvider>
    </form>
  )
}
