import { createAxiosAsyncThunk, getRequest$ } from 'utils/api'
import { IPostInput } from './post.model'

export interface QueryParams {
  _limit?: number
  _start?: number
  _sort?: string
  id_nin?: Array<number>
}

// POSTS
export const GetPosts = createAxiosAsyncThunk<IPostInput[], QueryParams | undefined>(
  'posts/getPosts',
  async (args) => await getRequest$('posts', { params: args })
)

// RELATED POSTS
export const GetPostsRelated = createAxiosAsyncThunk<IPostInput[], number>(
  'posts/getPostsRelated',
  async (id) => await getRequest$(`posts/related/${id}`)
)

// POST
export const GetPost = createAxiosAsyncThunk<IPostInput, string>(
  'posts/getPost',
  async (slug) => await getRequest$(`posts/${slug}`)
)
