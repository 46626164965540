import { UserPublicInput } from 'services/shared/models/UserPublic'

export const GlobalActions = {
  SET_FEATURED_GUIDES: '@global/setFeaturedGuides',
}

export function setFeaturedGuides(payload: UserPublicInput[]) {
  return {
    type: GlobalActions.SET_FEATURED_GUIDES,
    payload,
  }
}
