export interface ActivityIncludeInput {
  id: number
  name: string
  type: 'boolean' | 'multiselect'
}

class ActivitiesInclude {
  id: number
  name: string
  type: 'boolean' | 'multiselect' | 'text'

  constructor(property: ActivityIncludeInput) {
    this.id = property.id
    this.name = property.name
    this.type = property.type
  }

  static fromArray(input: ActivityIncludeInput[] | null): ActivitiesInclude[] {
    return input ? input.map((i) => new ActivitiesInclude(i)) : []
  }
}

export default ActivitiesInclude
