// TODO: REWRITE ME
import React from 'react'
import Helmet from 'react-helmet'

const SEO_DEFAULTS = {
  title: 'Guided Outdoor Adventures | Find your wild with Outguided',
  title_short: 'Outguided',
  description: `The greatest guided outdoor adventures and trips, all in one place. 
    From hunting to heli-skiing, the world outside is at your fingertips.`,
  image: `meta-images/home-share.jpg`,
}

const lTrim = (char = ' ', string) => (string.charAt(0) === char ? string.substring(1) : string)
const rTrim = (char = ' ', string) =>
  string.charAt(string.length - 1) === char ? string.substring(0, string.length - 1) : string

function Seo(props) {
  const {
    title = '',
    description = null,
    lang = 'en',
    meta = [],
    image = null,
    canonical = null,
    statusCode = 200,
    redirect = null,
    noIndex = false,
  } = props
  let metaDescription = description
  if (metaDescription) {
    metaDescription = metaDescription
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/(\r\n|\n|\r)/gm, ' ')
      .replace(/\s\s+/g, ' ')
      .slice(0, 160)
      .trim()
  }
  metaDescription = metaDescription || SEO_DEFAULTS.description

  // try to cut title to 60 chars in total
  let metaTitle = SEO_DEFAULTS.title
  if (title && title !== SEO_DEFAULTS.title) {
    metaTitle = `${title} | ${SEO_DEFAULTS.title_short}`
  }

  const domain = `${window.location.protocol}//${window.location.host}/`

  let pathname = canonical || window.location.pathname.split('?')[0]

  pathname = lTrim('/', pathname)
  pathname = rTrim('/', pathname)

  const canonicalUrl = `${domain}${pathname}`

  const metaImage = image
    ? image.startsWith('http')
      ? image
      : `${domain}${lTrim('/', image)}`
    : `${domain}${SEO_DEFAULTS.image}`

  const redirectUrl = redirect ? `${domain}${lTrim('/', redirect)}` : null

  if (noIndex) {
    meta.push({
      name: 'robots',
      content: 'noindex, nofollow, noarchive',
    })
  }
  if (statusCode !== 200) {
    meta.push({
      name: 'prerender-status-code',
      content: statusCode,
    })
  }

  if (redirectUrl) {
    meta.push({
      name: 'prerender-header',
      content: `Location: ${redirectUrl}`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={
        !redirectUrl
          ? [
              {
                rel: 'canonical',
                href: canonicalUrl,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'charset',
          content: 'utf-8',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:url',
          content: canonicalUrl || window.location.href,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default Seo
