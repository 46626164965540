import { forkJoin, ReplaySubject } from 'rxjs'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { getRequest$ } from 'utils/http'

export function prepareAutocompleteFetchURL({ text, features = 'region,place,country', country = '' }) {
  // eslint-disable-next-line max-len
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&types=${features}&country=${country}`
}

function prepareAddressFetchURL(longitude, latitude) {
  // eslint-disable-next-line max-len
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
}

function prepareTimeZoneFetchURL(longitude, latitude) {
  // eslint-disable-next-line max-len
  return `https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
}

export function getLocationDetails$({ longitude, latitude }) {
  const forkedRequests$ = forkJoin([
    getRequest$(prepareAddressFetchURL(longitude, latitude)),
    getRequest$(prepareTimeZoneFetchURL(longitude, latitude)),
    [{ longitude, latitude }],
  ])
  return forkedRequests$
}

const AutocompleteLocationDetails = (function () {
  let _subject

  function _getObservable$() {
    if (!_subject) {
      _subject = new ReplaySubject()
    }
    return _subject.asObservable().pipe(
      debounceTime(500),
      switchMap((value) => getRequest$(prepareAutocompleteFetchURL(value)))
    )
  }

  function _getAutocompleteLocationDetails$(value) {
    _subject.next(value)
  }

  return {
    getObservable$: _getObservable$,
    requestAutocompleteLocationDetails: _getAutocompleteLocationDetails$,
    autocompleteRequest: (data) => getRequest$(prepareAutocompleteFetchURL(data)),
  }
})()

const MapBoxLocationDetails = (function () {
  let _subject

  function _getObservable$() {
    if (!_subject) {
      _subject = new ReplaySubject()
    }
    return _subject.asObservable().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value) => getLocationDetails$(value))
    )
  }

  function _getLocationDetails(value) {
    _subject.next(value)
  }

  return {
    getObservable$: _getObservable$,
    requestNewLocationDetails: _getLocationDetails,
  }
})()

export { AutocompleteLocationDetails, MapBoxLocationDetails }
