import React from 'react'
import classNames from 'classnames'
import gear from '../../../../assets/icons/icon_gear.svg'
import food from '../../../../assets/icons/icon_food.svg'
import fishCleaning from '../../../../assets/icons/icon_fish_cleaning.svg'
import lodging from '../../../../assets/icons/icon_lodging.svg'
import plus from '../../../../assets/icons/icon_plus.svg'
import styles from './IncludedIcon.module.scss'
import { IIncludedIcon } from './IncludedIcon.types'

export enum IconImages {
  Gear = gear as any,
  Food = food as any,
  'Fish Cleaning' = fishCleaning as any,
  Lodging = lodging as any,
  Gratuity = plus as any,
}

export const IncludedIcon: React.FC<IIncludedIcon> = ({ title, className, description }) => {
  const image = IconImages[title as any]

  return (
    <div className={classNames([className, styles['included-icon']])}>
      <div className={styles['included-icon__image']}>{image && <img src={image} alt={title} />}</div>
      <div className={styles['included-icon__content']}>
        {title && <span className={styles['included-icon__title']}>{title}</span>}
        {description && <span className={styles['included-icon__description']}>({description})</span>}
      </div>
    </div>
  )
}
