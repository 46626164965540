// TODO: REWRITE ME
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutocompleteLocationDetails } from 'services/mapbox/mapbox-api'
import { setLocation, setLocations } from 'redux/SearchForm/actions'
import AutocompleteSearchInput from 'components/SerachInput/Autocomplete'
import { parseMapBoxLocation } from 'utils/marketing-page-helpers'

const AutocompleteLocationFilter = ({ onChange, value, ...other }) => {
  const dispatch = useDispatch()
  const { locations, countries } = useSelector((state) => state.searchForm)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const subscription = AutocompleteLocationDetails.getObservable$().subscribe((location) => {
      setIsLoading(false)
      const locationFeatures = location.features
      if (locationFeatures && locationFeatures.length > 0) {
        dispatch(setLocations(locationFeatures.map(parseMapBoxLocation)))
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const onInputChange = (value) => {
    if (value) {
      setIsLoading(true)
      AutocompleteLocationDetails.requestAutocompleteLocationDetails({
        text: value,
        country: countries.join(',').toLowerCase(),
      })
    }
  }

  const onFilterChange = (option) => {
    dispatch(setLocation(option?.location || null))
    onChange(option ? { label: option.label, value: option?.value } : option)
  }

  return (
    <AutocompleteSearchInput
      label="location"
      placeholder="Try “Key West”"
      isLoading={isLoading}
      autocomplete
      onInputChange={onInputChange}
      value={value}
      options={locations}
      onSelect={onFilterChange}
    />
  )
}

export default AutocompleteLocationFilter
