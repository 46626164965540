import Booking from 'services-new/booking/booking.model'
import { AccountBookingCard } from 'components-new/common/AccountBookingCard/AccountBookingCard'
import { AppDate } from 'services-new/shared/date'
import React, { Children, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Calendar, momentLocalizer, Event, DateLocalizer, SlotInfo } from 'react-big-calendar'
import moment from 'moment'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { Input } from 'components-new/forms/Input/Input'
import { useForm } from 'react-hook-form'
import { postRequest$ } from 'utils/api'
import { useRequestAsync } from 'hooks/useRequestAsync'
import { useUser } from 'hooks/useUser'
import { Loader } from 'components-new/common/Loader/Loader'
import { useDispatch } from 'react-redux'
import { setNotAvailableHostDays } from 'features/user/UserSlice'
import { TAvailability } from 'services/shared/models/User'
import styles from './AccountCalendar.module.scss'

const mLocalizer = momentLocalizer(moment)

function CustomEvent({ event }: any) {
  return (
    <span>
      {event.booking.status === 'pending' && 'Pending: '}
      {event.title}
    </span>
  )
}
const currentDate = new AppDate().getStartOfDay().removeTimezoneOffset()

export const AccountCalendar: React.FC<{
  bookings: Booking[]
  showDemoLink?: boolean
  localizer?: DateLocalizer
}> = ({ bookings, localizer = mLocalizer }) => {
  const [selectedDay, setSelectedDay] = useState<AppDate[]>([currentDate])
  const [selectedDayBookingsRequests, setSelectedDayBookingsRequests] = useState<Booking[]>([])
  const [selectedDayBookingsScheduled, setSelectedDayBookingsScheduled] = useState<Booking[]>([])
  const [selectedDayBookingsCancelled, setSelectedDayBookingsCancelled] = useState<Booking[]>([])
  const [selectedDayBookingsPast, setSelectedDayBookingsPast] = useState<Booking[]>([])
  const { isMobile } = useBreakpoints()
  const { user } = useUser()
  const clickRef = useRef<number | undefined>()
  const resultsRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  const { register, watch, setValue } = useForm()

  useEffect(() => {
    const subscription = watch((value) => {
      if (selectedDay.length === 1) {
        const tmpDate = selectedDay[0].getISODateString()
        if (value.isNotAvailable) {
          addNotAvailableDay({ days: [tmpDate] })
        } else {
          removeNotAvailableDay({ days: [tmpDate] })
        }
      }
      if (selectedDay.length > 1) {
        const tmpDates = selectedDay.map((item) => item.getISODateString())
        if (value.isNotAvailable) {
          addNotAvailableDay({ days: [...tmpDates] })
        } else {
          removeNotAvailableDay({ days: [...tmpDates] })
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, selectedDay])

  useEffect(() => {
    /**
     * What Is This?
     * This is to prevent a memory leak, in the off chance that you
     * teardown your interface prior to the timed method being called.
     */
    return () => {
      window.clearTimeout(clickRef?.current)
    }
  }, [])

  const events = React.useMemo<Event[]>(
    () =>
      bookings
        .filter((item: Booking) => item.status !== 'rejected')
        .map((item: Booking) => {
          return {
            id: item.id,
            title: item.experience.title,
            start: item.startDate.clone().removeTimezoneOffset(),
            end: item.endDate.clone().removeTimezoneOffset(),
            booking: item,
          }
        }),
    [bookings]
  )

  const dayPropGetter = useCallback(
    (date) => {
      const classes = []
      const tmpDay = new AppDate(date)
      if (user!.notAvailableHostDays.includes(tmpDay.getISODateString())) {
        classes.push('rbc-day-bg--inactive')
      }
      selectedDay.forEach((item) => {
        if (item.getISODateString() === tmpDay.getISODateString()) {
          classes.push('rbc-day-bg--selected')
        }
      })
      return {
        className: classes.join(' '),
      }
    },
    [selectedDay]
  )

  const eventPropGetter = useCallback(
    (event) => ({
      ...(event.booking.status === 'pending' && {
        className: 'rbc-event--pending',
      }),
    }),
    []
  )

  useEffect(() => {
    const tmpBookings = bookings.filter(
      (item) => item.startDate.clone().removeTimezoneOffset().getISODateString() === selectedDay[0].getISODateString()
    )

    const bookingsRequests: Booking[] = []
    const bookingsScheduled: Booking[] = []
    const bookingsCancelled: Booking[] = []
    const bookingsPast: Booking[] = []

    tmpBookings.forEach((item) => {
      switch (item.status) {
        case 'pending':
          bookingsRequests.push(item)
          break
        case 'accepted':
          bookingsScheduled.push(item)
          break
        case 'cancelled':
          bookingsCancelled.push(item)
          break
        case 'past':
          bookingsPast.push(item)
          break
      }
    })

    setSelectedDayBookingsRequests(bookingsRequests)
    setSelectedDayBookingsScheduled(bookingsScheduled)
    setSelectedDayBookingsCancelled(bookingsCancelled)
    setSelectedDayBookingsPast(bookingsPast)

    if (isMobile && resultsRef.current) {
      window.scroll({
        top: resultsRef.current?.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [selectedDay, bookings])

  const { isLoading: isLoadingAddNotAvailableDay, fetch: addNotAvailableDay } = useRequestAsync<
    { days: string[] },
    TAvailability[]
  >(
    (args) => postRequest$('availability/add', args),
    (results) => {
      dispatch(setNotAvailableHostDays(results.data.map((a) => a.date)))
    }
  )

  const { isLoading: isLoadingRemoveNotAvailableDay, fetch: removeNotAvailableDay } = useRequestAsync<
    { days: string[] },
    TAvailability[]
  >(
    (args) => postRequest$('availability/remove', args),
    (results) => {
      dispatch(setNotAvailableHostDays(results.data.map((a) => a.date)))
    }
  )

  const TouchCellWrapper = ({ children, value, onSelectSlot }: any) =>
    cloneElement(Children.only(children), {
      onTouchEnd: () => onSelectSlot({ action: 'click', slots: [value] }),
    })

  const onSelectSlot = useCallback(({ slots }) => {
    /**
     * Here we are waiting 250 milliseconds prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */

    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      if (slots.length > 1) {
        setSelectedDay(slots.map((item: Date) => new AppDate(item)))
      } else {
        setSelectedDay([new AppDate(slots[0])])
      }
    }, 250)
  }, [])

  const onSelectEvent = useCallback((event: any) => {
    // Dont ask WHY!
    setSelectedDay([event.start.clone().addTimezoneOffset().getStartOfDay().removeTimezoneOffset()])
  }, [])

  const isLoading = isLoadingRemoveNotAvailableDay || isLoadingAddNotAvailableDay

  const isFirstSelectedDayNotAvailable = user!.notAvailableHostDays.includes(selectedDay[0].getISODateString())
  const hasSelectedRangeSameValues = selectedDay.every(
    (item) => !!user!.notAvailableHostDays.includes(item.getISODateString()) === isFirstSelectedDayNotAvailable
  )

  const selectedDayWithoutOffset = selectedDay[0].clone().addTimezoneOffset()
  const selectedRangeWithoutOffset =
    selectedDay.length > 1
      ? `${selectedDay[0].clone().addTimezoneOffset().getLocalDateString()} - ${selectedDay[selectedDay.length - 1]
          .clone()
          .addTimezoneOffset()
          .getLocalDateString()}`
      : null
  return (
    <div className={styles['calendar__wrapper']}>
      <Calendar
        className={styles['calendar__calendar']}
        components={{
          dateCellWrapper: (props) => {
            return <TouchCellWrapper {...props} onSelectSlot={onSelectSlot} />
          },
        }}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        defaultDate={currentDate}
        events={events}
        localizer={localizer}
        showMultiDayTimes
        selectable={true}
        getNow={() => currentDate}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        views={['month']}
      />
      <div className={styles['calendar__card-wrapper']}>
        <div className={styles['calendar__day-wrapper']} ref={resultsRef}>
          <h2>{selectedDay.length > 1 ? selectedRangeWithoutOffset : selectedDayWithoutOffset.getLocalDateString()}</h2>
          {selectedDay.length > 0 && selectedDay[0] >= currentDate && (
            <>
              <h3>Still available for a new bookings?</h3>
              <p>
                You can change your availability so you don't receive any further booking requests for this day. Any
                bookings you’ve already confirmed for this date will not be affected.
              </p>
              {isLoading ? (
                <Loader fillHeight />
              ) : (
                <form>
                  <Input
                    {...register('isNotAvailable', { required: true })}
                    theme="vertical"
                    type="radio"
                    label="Available"
                    name="isNotAvailable"
                    value="true"
                    onChange={() => {
                      setValue('isNotAvailable', false)
                    }}
                    checked={isFirstSelectedDayNotAvailable === false && hasSelectedRangeSameValues}
                  />
                  <Input
                    {...register('isNotAvailable', { required: true })}
                    theme="vertical"
                    type="radio"
                    label="Not Available"
                    name="isNotAvailable"
                    value="false"
                    onChange={() => {
                      setValue('isNotAvailable', true)
                    }}
                    checked={isFirstSelectedDayNotAvailable === true && hasSelectedRangeSameValues}
                  />
                </form>
              )}
            </>
          )}
        </div>
        {selectedDayBookingsRequests.length > 0 ||
        selectedDayBookingsScheduled.length > 0 ||
        selectedDayBookingsCancelled.length > 0 ||
        selectedDayBookingsPast.length > 0 ? (
          <div className={styles['calendar__day-wrapper']} ref={resultsRef}>
            {selectedDayBookingsRequests.length > 0 && (
              <div className={styles['calendar__cards-block']}>
                <h3>Booking requests on {selectedDayWithoutOffset.getLocalDateString()}</h3>
                {selectedDayBookingsRequests.map((item) => (
                  <AccountBookingCard className={styles['calendar__card']} booking={item} key={item.id} />
                ))}
              </div>
            )}
            {selectedDayBookingsScheduled.length > 0 && (
              <div className={styles['calendar__cards-block']}>
                <h3>Scheduled bookings on {selectedDayWithoutOffset.getLocalDateString()}</h3>
                {selectedDayBookingsScheduled.map((item) => (
                  <AccountBookingCard className={styles['calendar__card']} booking={item} key={item.id} />
                ))}
              </div>
            )}
            {selectedDayBookingsCancelled.length > 0 && (
              <div className={styles['calendar__cards-block']}>
                <h3>Cancelled bookings on {selectedDayWithoutOffset.getLocalDateString()}</h3>
                {selectedDayBookingsCancelled.map((item) => (
                  <AccountBookingCard className={styles['calendar__card']} booking={item} key={item.id} />
                ))}
              </div>
            )}
            {selectedDayBookingsPast.length > 0 && (
              <div className={styles['calendar__cards-block']}>
                <h3>Past bookings on {selectedDayWithoutOffset.getLocalDateString()}</h3>
                {selectedDayBookingsPast.map((item) => (
                  <AccountBookingCard className={styles['calendar__card']} booking={item} key={item.id} />
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className={styles['calendar__info']}>
            {selectedDay ? 'There are no trips on this day' : 'Select a day to see scheduled or requested trips'}
          </div>
        )}
      </div>
    </div>
  )
}
