import React, { FC, useMemo } from 'react'
import AutocompleteSearchInput from 'components/SerachInput/Autocomplete'
import { useCurrentRefs } from 'hooks/useCurrentRefs'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import { ActivityFilterProps, Option } from './ActivityFilter.types'

const ActivityFilter: FC<ActivityFilterProps> = ({ onChange = () => {}, value = null }) => {
  const { activities } = useSettingsContext()

  const refs = useCurrentRefs({ onChange })

  const parsedActivities: Array<Option> = useMemo(() => {
    return activities.map((item) => ({
      label: item.name,
      value: item.slug,
    }))
  }, [activities])

  const options: Array<Option> = useMemo(() => {
    return [{ label: 'Browse All Pursuits', value: '' } as Option].concat(parsedActivities)
  }, [parsedActivities])

  return (
    // @ts-ignore
    <AutocompleteSearchInput
      label="PURSUIT"
      placeholder="All"
      options={options}
      onSelect={refs.current?.onChange}
      value={value}
    />
  )
}

export default ActivityFilter
