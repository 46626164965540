import ActivitiesProperty, { ActivityPropertyInput } from 'services/shared/models/ActivitiesProperty'
import ActivitiesInclude, { ActivityIncludeInput } from 'services/shared/models/ActivitiesInclude'

export interface ActivityInput {
  created_at: string
  updated_at: string
  id: number
  include_options: ActivityIncludeInput[]
  name: string
  properties: ActivityPropertyInput[]
}

export interface ActivityOutput {
  name: string
}

class Activity {
  id: number
  value: string
  key: string
  label: string
  name: string
  slug: string
  properties: ActivitiesProperty[]
  includeOptions: ActivitiesInclude[]

  constructor(activity: ActivityInput) {
    this.id = activity.id
    this.value = '' + activity.id || ''
    this.key = '' + activity.id || ''
    this.name = activity.name || ''

    // we should stop using labels - use name instead
    this.label = activity.name || ''
    // for now we could just use lowercased name
    this.slug = activity.name.toLowerCase() || ''

    this.properties = ActivitiesProperty.fromArray(activity.properties)
    this.includeOptions = ActivitiesInclude.fromArray(activity.include_options)
  }

  static fromArray(input: ActivityInput[] | null): Activity[] {
    return input ? input.map((i) => new Activity(i)) : []
  }
  static toOutput(input: Activity): ActivityOutput {
    return {
      name: input.name,
    }
  }
}

export default Activity
