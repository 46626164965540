import { AxiosResponse } from 'axios'
import { useState } from 'react'

export function useRequestAsync<K, T>(
  request: (params?: K) => Promise<AxiosResponse<T>>,
  callback?: (result: AxiosResponse<T>) => void
): {
  isLoading: isLoading
  fetch: fetch<K>
  results: AxiosResponse<T> | undefined
  responseError: responseError
} {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [results, setResults] = useState<AxiosResponse<T> | undefined>()
  const [responseError, setResponseError] = useState<any>(null)

  const fetch = async (params?: K) => {
    setIsLoading(true)
    setResponseError(null)
    try {
      const result = await request(params)
      setResults(result)
      callback?.(result)
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      setResponseError(e)
    }
  }

  return {
    isLoading,
    fetch,
    results,
    responseError,
  }
}

export type fetch<T> = (params?: T) => void
export type responseError = any
export type isLoading = boolean
