import React, { FC } from 'react'
import classNames from 'classnames'
import { Avatar } from 'components-new/common/Avatar/Avatar'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { useUser } from 'hooks/useUser'
import { BookingStateText } from '../atoms/BookingStateText/BookingStateText'
import { IConversationPreview } from './ConversationPreview.types'
import styles from './ConversationPreview.module.scss'

export const ConversationPreview: FC<IConversationPreview> = (props: IConversationPreview) => {
  const { conversation, onClick, selected, className } = props
  const { user } = useUser()
  const recipient = conversation.getFirstRecipient(user!.id)!
  const { isMobile } = useBreakpoints()

  const messageClasses = classNames({
    [styles['conversation-preview__message']]: true,
  })

  const containerClasses = classNames({
    [styles['conversation-preview']]: true,
    [className!]: !!className,
    [styles['conversation-preview--unreaded']]: conversation.unreaded,
    [styles['conversation-preview--selected']]: selected && !isMobile,
  })

  return (
    <div onClick={onClick} className={containerClasses}>
      <Avatar
        image={recipient.image}
        text={recipient.initials}
        size="huge"
        className={styles['conversation-preview__image']}
      />
      <div className={styles['conversation-preview__text-section']}>
        <div className={styles['conversation-header']}>
          <div className={styles['conversation-header__from']}>{recipient.displayName}</div>
          <div className={styles['conversation-header__time']}>{conversation.updatedAt.getLocalDateTimeString()}</div>
        </div>
        {conversation.experience && (
          <div className={styles['conversation-preview__title']}>{conversation.experience.title}</div>
        )}
        {conversation.lastMessage && <div className={messageClasses}>{conversation.lastMessage.body}</div>}
        {conversation.booking && <BookingStateText status={conversation.booking.status} />}
      </div>
    </div>
  )
}
