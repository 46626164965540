import React from 'react'
import classNames from 'classnames'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import styles from './WhatProvide.module.scss'
import { WhatProvideCard } from './WhatProvideCard/WhatProvideCard'
import { WhatProvideProps } from './WhatProvide.types'

export const WhatProvide: React.FC<WhatProvideProps> = ({ cards, title }) => {
  return (
    <Wrapper containerClasses={styles['what-provide']}>
      <h2 className={styles['what-provide__header']}>{title}</h2>
      <div className={styles['what-provide__cards']}>
        {cards.map((item) => (
          <WhatProvideCard
            icon={item.icon ? item.icon : ''}
            name={item.name}
            description={item.description}
            key={item.name}
            className={classNames(styles['what-provide__card'], styles[`what-provide__card_${cards.length}`])}
          />
        ))}
      </div>
    </Wrapper>
  )
}
