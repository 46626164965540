export const BookingActivities = {
  SET_GUESTS: '@booking/setGuests',
  SET_EVENT_DETAILS: '@booking/setEventDetails',
  SET_MARKETING_DETAILS: '@booking/setMarketingDetails',
  SET_NOTE: '@booking/setNote',
  SET_DISCOUNT: '@booking/setDiscount',
}

export function setBookingGuests(value) {
  return {
    type: BookingActivities.SET_GUESTS,
    payload: value,
  }
}

export function setEventDetails(value) {
  return {
    type: BookingActivities.SET_EVENT_DETAILS,
    payload: value,
  }
}

export function setMarketingDetails(value) {
  return {
    type: BookingActivities.SET_MARKETING_DETAILS,
    payload: value,
  }
}

export function setNote(value) {
  return {
    type: BookingActivities.SET_NOTE,
    payload: value,
  }
}

export function setDiscount(value) {
  return {
    type: BookingActivities.SET_DISCOUNT,
    payload: value,
  }
}
