// TODO: REWRITE ME
import React, { FC } from 'react'
import { snakeCase } from 'lodash'
import classNames from 'classnames'
import iconsPath from 'assets/icons/sprite.svg'
import styles from './Icon.module.scss'
import { IconProps } from './Icon.types'

export const Icon: FC<IconProps> = (props) => {
  const { icon, className = '', style = {}, onClick, inline = false, containerClassName = '', ...other } = props

  const iconClass = classNames({
    [styles.icon]: true,
    [containerClassName]: !!containerClassName,
    [styles.iconAsButton]: !!onClick,
  })

  const Component = typeof onClick === 'function' ? 'button' : 'div'

  return (
    <Component className={iconClass} onClick={onClick} {...other}>
      <svg
        className={`${inline ? 'd-inline-block' : 'd-block'} ${className}`}
        viewBox="0 0 16 16"
        style={{ ...style }}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use xlinkHref={`${iconsPath}#icon_${snakeCase(icon)}`} />
      </svg>
    </Component>
  )
}

export default React.memo(Icon)
