import Address, { AddressInput } from 'services/shared/models/Address'
import { getLatLonFromLocation } from 'utils/marketing-page-helpers'

export interface LocationInput {
  id?: number
  coordinates: string | null
  label: string | null
  longitude: number
  latitude: number
  address: AddressInput | null
  timezone: string
}

export type LocationOutput = Partial<Location>
class Location {
  id?: number
  coordinates: string | null
  label: string | null
  longitude: number
  latitude: number
  address: Address | null
  timezone: string

  constructor(location: LocationInput) {
    this.id = location.id
    this.coordinates = null
    this.longitude = 0
    this.latitude = 0

    if (location.coordinates) {
      this.coordinates = location.coordinates
      const latLon = getLatLonFromLocation(location)
      this.latitude = latLon.latitude
      this.longitude = latLon.longitude
    } else if (location.latitude && location.longitude) {
      this.latitude = location.latitude
      this.longitude = location.longitude
      this.coordinates = `${this.latitude},${this.longitude}`
    }

    this.label = location.label
    this.address = location.address && new Address(location.address)
    this.timezone = location.timezone
  }

  static fromArray(input: LocationInput[] | null): Location[] {
    return input ? input.map((i) => new Location(i)) : []
  }

  static toOutput(data: Partial<Location>) {
    return {
      ...data,
    }
  }
}

export default Location
