import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import logoImage from 'assets/img/logo-big.png'
import { ILogoStory } from './LogoStory.types'
import styles from './LogoStory.module.scss'

export const LogoStory: React.FC<ILogoStory> = () => {
  return (
    <Wrapper>
      <h2 className={styles['our-story__header']}>The Story Behind Our Logo</h2>
      <div className={styles['our-story__content']}>
        <img src={logoImage} alt="Outguided logo story" className={styles['our-story__logo']} />
        <p className={styles['our-story__description']}>
          The red clay brown color comes straight from our homebase in Madison, Georgia, where the red clay sits below
          the Georgia Pines. <br />
          <br />
          Outguided started with hunting adventures, mankind’s oldest outdoor pursuit. The arrow calls back to our
          hunting roots and pushes us to keep working towards our vision. <br />
          <br />
          It also is kind of an O, kind of a G.
        </p>
      </div>
    </Wrapper>
  )
}
