import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import React, { FC } from 'react'
import { BankAccountFormProps, BankAccountFormValues } from './BankAccountForm.types'
import { BankAccountFormInputs } from './BankAccountFormInputs/BankAccountFormInputs'

const FORM_DEFAULTS = {
  token: '',
  country: 'US',
  account_holder_type: 'company',
  account_holder_name: '',
  routing_number: '',
  account_number: '',
}

export const BankAccountForm: FC<BankAccountFormProps> = (props) => {
  const { onSubmit = async () => {} } = props
  return (
    <FormWrapper<BankAccountFormValues>
      {...props}
      defaultValues={FORM_DEFAULTS}
      formOptions={{ mode: 'onChange', reValidateMode: 'onChange' }}
      onSubmit={async (data, formMethods) => {
        await onSubmit(data, formMethods)
        formMethods.reset(FORM_DEFAULTS)
      }}
    >
      {(methods) => <BankAccountFormInputs currency="USD" />}
    </FormWrapper>
  )
}
