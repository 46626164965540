import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import arrowIcon from 'assets/icons/icon_chevrons-right.svg'
import React from 'react'
import classNames from 'classnames'
import styles from './HowItWorks.module.scss'
import { HowItWorksProps } from './HowItWorks.types'

export const HowItWorks: React.FC<HowItWorksProps> = ({ items, title, description }) => (
  <>
    <Wrapper className={styles['how-it-works']}>
      <h2 className={styles['how-it-works__header']}>{title}</h2>
      <div className={styles['how-it-works__points-wrapper']}>
        {items.map((item, index) => (
          <div className={styles['how-it-works__point']} key={item.name}>
            {index === items.length - 1 ? null : (
              <img className={styles['how-it-works__point-arrow']} src={arrowIcon} alt="" />
            )}
            <div>
              <div className={styles['how-it-works__point-number']}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
              <div className={styles['how-it-works__point-name']}>{item.name}</div>
            </div>
            <p className={styles['how-it-works__description']}>{item.description}</p>
          </div>
        ))}
      </div>
      {description && <p className={styles['how-it-works__description_sc']}>{description}</p>}
    </Wrapper>
  </>
)
