const popupOptions =
  'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no'

export function shareFacebook() {
  console.log('fb')
  window.open(
    'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href),
    '',
    popupOptions
  )
}

export function shareTwitter() {
  console.log('tt')
  window.open(
    'https://twitter.com/intent/tweet?url=' +
      encodeURIComponent(window.location.href) +
      '&text=' +
      encodeURIComponent(document.title),
    '',
    popupOptions
  )
}
