import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AuthenticationCallback,
  BecomeGuide,
  ForgotPassword,
  LoginUser,
  Me,
  RegisterUser,
  ResetPassword,
  UpdateUser,
} from 'services-new/auth/auth-api'
import User, { UserInput } from 'services/shared/models/User'
import { initialState, InitialState } from 'utils/api'
import LocalStorageService from 'utils/LocalStorageService'
import { Mixpanel } from 'utils/mixpanel'

type UserInitialState = InitialState & { user: User | null }

export const userSlice = createSlice({
  name: 'user',
  initialState: Object.assign(initialState, { user: null }) as UserInitialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState, { user: null, loaded: true }),
    setLoaded(state, action: PayloadAction<boolean>) {
      state.loaded = action.payload
    },
    updateUser(state, action: PayloadAction<UserInput>) {
      state.user = new User(action.payload)
    },
    setNotAvailableHostDays(state, action: PayloadAction<string[]>) {
      if (state.user) {
        state.user.notAvailableHostDays = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ResetPassword.pending, (state) => {
      state.loading = true
      state.error = null
      state.user = null
    })
    builder.addCase(ResetPassword.fulfilled, (state, action) => {
      state.loading = false
      const { user: userInput, jwt: token } = action.payload.data

      const user = new User(userInput)

      if (token) {
        LocalStorageService.getService().setToken(token)
      }
      state.user = user
    })
    builder.addCase(ResetPassword.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.user = null
    })
    builder.addCase(LoginUser.pending, (state) => {
      state.loading = true
      state.error = null
      state.user = null
    })
    builder.addCase(LoginUser.fulfilled, (state, action) => {
      state.loading = false
      const { user: userInput, jwt: token } = action.payload.data

      const user = new User(userInput)
      Mixpanel.identify(user.id.toString())
      Mixpanel.people.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.email,
      })
      Mixpanel.track('Successful login')
      if (token) {
        LocalStorageService.getService().setToken(token)
      }
      state.user = user
    })
    builder.addCase(LoginUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.user = null
    })
    builder.addCase(AuthenticationCallback.pending, (state) => {
      state.loading = true
      state.error = null
      state.user = null
    })
    builder.addCase(AuthenticationCallback.fulfilled, (state, action) => {
      state.loading = false
      const { user: userInput, jwt: token } = action.payload.data

      const user = new User(userInput)
      Mixpanel.identify(user.id.toString())
      Mixpanel.people.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.email,
      })
      Mixpanel.track('Successful login')
      if (token) {
        LocalStorageService.getService().setToken(token)
      }
      state.user = user
    })
    builder.addCase(AuthenticationCallback.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.user = null
    })
    builder.addCase(RegisterUser.pending, (state) => {
      state.loading = true
      state.error = null
      state.user = null
    })
    builder.addCase(RegisterUser.fulfilled, (state, action) => {
      state.loading = false
      const { user: userInput, jwt: token } = action.payload.data
      if (token) {
        LocalStorageService.getService().setToken(token)
      }
      const user = new User(userInput)
      Mixpanel.identify(user.id.toString())
      Mixpanel.people.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.email,
      })
      Mixpanel.track('Successful register')
      state.user = user
    })
    builder.addCase(RegisterUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.user = null
    })
    builder.addCase(BecomeGuide.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(BecomeGuide.fulfilled, (state, action) => {
      state.loading = false
      state.user = new User(action.payload.data)
    })
    builder.addCase(BecomeGuide.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    builder.addCase(Me.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(Me.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      state.user = new User(action.payload.data)
    })
    builder.addCase(Me.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
      state.user = null
    })
    builder.addCase(ForgotPassword.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(ForgotPassword.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(ForgotPassword.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.user = null
    })
    builder.addCase(UpdateUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(UpdateUser.fulfilled, (state, action) => {
      state.loading = false
      state.user = new User(action.payload.data)
    })
    builder.addCase(UpdateUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.user = null
    })
  },
})

export const { reset, setLoaded, setNotAvailableHostDays, updateUser } = userSlice.actions

export default userSlice.reducer
