import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'
import MarketingDetails, { MarketingDetailsInput } from 'services/marketing-page/models/MarketingDetails'

export interface ReviewInput {
  id: number
  content: string
  rating: number
  created_at: string
  user: UserPublicInput
  marketing_page: MarketingDetailsInput
}
class Review {
  id: number
  content: string
  rating: number
  created: string
  user: UserPublic
  marketingPage: MarketingDetails

  constructor(review: ReviewInput) {
    this.id = review.id
    this.content = review.content
    this.rating = review.rating
    this.created = review.created_at
    this.user = new UserPublic(review.user)
    this.marketingPage = new MarketingDetails(review.marketing_page)
  }

  static fromArray(input: ReviewInput[] | null): Review[] {
    return input ? input.map((i) => new Review(i)) : []
  }
}

export default Review
