import React, { FC } from 'react'
import { SkeletonLoader } from 'components-new/common/SkeletonLoader/SkeletonLoader'
import { IEmptyConversation } from './EmptyConversation.types'

export const EmptyConversation: FC<IEmptyConversation> = (props: IEmptyConversation) => {
  const { containerClasses, loading } = props

  return (
    <div className={containerClasses}>
      <SkeletonLoader loading={loading} width={304} height={120} title="Loading messages">
        <circle cx="32" cy="40" r="24" />
        <rect x="64" y="16" rx="2" ry="2" width="224" height="12" />
        <rect x="64" y="40" rx="2" ry="2" width="224" height="12" />
        <rect x="64" y="64" rx="2" ry="2" width="224" height="12" />
        <rect x="64" y="92" rx="2" ry="2" width="76" height="12" />
        <rect x="212" y="92" rx="2" ry="2" width="76" height="12" />
      </SkeletonLoader>
    </div>
  )
}
