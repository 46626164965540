import { Input } from 'components-new/forms/Input/Input'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { LoginFormInputsProps } from './LoginFormInputs.types'

export const LoginFormInputs: FC<LoginFormInputsProps> = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <Input
        {...register('email', {
          required: 'This field is required',
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Email is invalid',
          },
        })}
        placeholder="Your email"
        label="E-mail"
        error={errors?.email?.message}
      ></Input>
      <Input
        {...register('password', {
          required: 'This field is required',
        })}
        type="password"
        placeholder="Password"
        label="Password"
        error={errors?.password?.message}
      ></Input>
    </>
  )
}
