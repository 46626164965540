import { headerReducer } from 'redux/Header/reducer'
import { searchPageReducer } from 'redux/SearchPage/reducer'
import { searchFormReducer } from 'redux/SearchForm/reducer'
import { eventCreationContainerReducer } from 'redux/EventCreationContainer/reducer'
import { activityReducer } from 'redux/General/activities/reducer'
import { bookingReducer } from 'redux/Booking/reducer'
import { checkoutReducer } from 'redux/Checkout/reducer'
import { globalReducer } from 'redux/Global/reducer'
import experiencesSlice from 'features/experience/ExperiencesSlice'
import postsSlice from 'features/post/PostsSlice'
import postsRelatedSlice from 'features/post/PostsRelatedSlice'
import uploadSlice from 'features/upload/UploadSlice'
import userSlice from 'features/user/UserSlice'
import conversationsSlice from 'features/conversation/ConversationsSlice'
import bookingSlice from 'features/booking/BookingSlice'
import { combineReducers } from 'redux'
import paymentsSlice from 'features/stripe/PaymentsSlice'

export const rootReducer = combineReducers({
  header: headerReducer,
  searchPage: searchPageReducer,
  searchForm: searchFormReducer,
  checkout: checkoutReducer,
  eventCreationContainer: eventCreationContainerReducer,
  booking: bookingReducer,
  global: globalReducer,
  general: combineReducers({
    activities: activityReducer,
  }),
  experiences: experiencesSlice,
  posts: postsSlice,
  postsRelated: postsRelatedSlice,
  upload: uploadSlice,
  user: userSlice,
  conversations: conversationsSlice,
  bookings: bookingSlice,
  payments: paymentsSlice,
})
