import { deleteRequest$, getRequest$, postRequest$, putRequest$ } from 'utils/http'
import { BACKEND_API, SEARCH_BACKEND_API } from 'global/environment'
import MarketingPageFilters from 'services/marketing-page/models/MarketingPageFilters'

const BASE_URL = `${BACKEND_API}/marketing-pages`
const SEARCH_BASE_URL = `${SEARCH_BACKEND_API}/marketing-pages`

export function searchMarketingPages$(filters) {
  const url = `${BASE_URL}/search`
  const preparedFilters = new MarketingPageFilters(filters)
  return postRequest$(url, preparedFilters)
}

export function searchMarketingPagesRaw$(filters) {
  const url = `${SEARCH_BASE_URL}/search`
  return postRequest$(url, filters)
}

export function fetchMarketingPageDetails$(id) {
  const url = `${BASE_URL}/${id}`
  return getRequest$(url)
}
export function fetchMarketingPageDetailsBySlug$(slug) {
  const url = `${BASE_URL}/slug/${slug}`
  return getRequest$(url)
}

export function fetchMarketingPageSchedule$(data) {
  const { id, ...rest } = data
  const url = `${BACKEND_API}/experiences/${id}/schedule/`
  return postRequest$(url, rest)
}

export function fetchMarketingPageDetailsByUuid$(uuid) {
  const url = `${BASE_URL}/uuid/${uuid}`
  return getRequest$(url)
}

export function saveEvent$(data, options) {
  const url = `${BASE_URL}/`
  return postRequest$(url, data, options)
}

export function updateEvent$(eventId, data, options) {
  const url = `${BASE_URL}/${eventId}`
  return putRequest$(url, data, options)
}

export function deleteEvent$(id) {
  const url = `${BASE_URL}/${id}`
  return deleteRequest$(url)
}

export function contactEventHost$({ id, data }, options) {
  const url = `${BACKEND_API}/events/${id}/contact-guide`
  return postRequest$(url, data, options, true)
}

export function contactMarketingPageHost$({ id, data }, options) {
  const url = `${BACKEND_API}/marketing-pages/${id}/contact-guide`
  return postRequest$(url, data, options, true)
}
