import { Image as ImageComponent } from 'components-new/common/Image/Image'
import { Button } from 'components-new/common/Button/Button'
import Image from 'services/shared/models/Image'
import classNames from 'classnames'
import React from 'react'
import { postLink } from 'global/routes'
import styles from './BlogCardFeatured.module.scss'

interface IBlogCardFeatured {
  className?: string
  publishedAt: string
  slug: string
  image: Image | null
  title: string
  excerpt: string
}

export const BlogCardFeatured: React.FC<IBlogCardFeatured> = ({
  className,
  publishedAt,
  slug,
  image,
  title,
  excerpt,
}) => {
  return (
    <div className={classNames(className, styles['blog-card-featured'])}>
      {image && (
        <ImageComponent
          loading="lazy"
          src={image.getSmallSize()}
          alt={image.alternativeText}
          wrapperClassName={styles['blog-card-featured__image-wrapper']}
          className={styles['blog-card-featured__image']}
          srcSet={image.getSrcSet()}
          sizes={['100vw', '(min-width: 585px) 640px', '(min-width: 1265px) 960px']}
        />
      )}
      <div className={styles['blog-card-featured__content']}>
        <span className={styles['blog-card-featured__date']}>{publishedAt}</span>
        <h3 className={styles['blog-card-featured__title']}>{title}</h3>
        <p className={styles['blog-card-featured__description']}>{excerpt}</p>
        <Button theme="plain" size="small" to={postLink(slug)} className={styles['blog-card-featured__link']}>
          Read
        </Button>
      </div>
    </div>
  )
}
