import { getBoundingBox } from 'geolocation-utils'
import { useCallback, useState } from 'react'

export type UseGeolocationReturn = {
  isLocating: boolean
  locate: (callback: (coordinates: GeolocationCoordinates | null) => void) => void
}

export const useGeolocation = (): UseGeolocationReturn => {
  const [isLocating, setIsLocating] = useState<boolean>(false)

  const locate = useCallback(() => {
    return new Promise<GeolocationCoordinates | null>((resolve, reject) => {
      const updateCoordinates: PositionCallback = ({ coords, timestamp }: GeolocationPosition) => {
        const bbox = getBoundingBox([[coords?.longitude, coords?.latitude]], 1000 * 300)
        // @ts-ignore
        resolve(`${bbox.topLeft[1]},${bbox.topLeft[0]}:${bbox.bottomRight[1]},${bbox.bottomRight[0]}`)
      }

      const onError: PositionErrorCallback = (err) => {
        resolve(null)
      }

      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(updateCoordinates, onError)
        } catch (e) {
          resolve(null)
        }
      } else {
        resolve(null)
      }
    })
  }, [])

  return {
    isLocating,
    locate: useCallback(async (callback) => {
      setIsLocating(true)
      const coordinates = await locate()
      callback?.(coordinates)
      setIsLocating(false)
    }, []),
  }
}
