import { AppDate } from 'services-new/shared/date'
import { RegisterFormValues } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs.types'
import Booking from 'services-new/booking/booking.model'
import MarketingDetails from 'services/marketing-page/models/MarketingDetails'

export const Checkout = {
  SET_GUESTS: '@checkout/setGuests',
  SET_DISCOUNT_CODE: '@checkout/setDiscountCode',
  SET_DATE: '@checkout/setDate',
  SET_TIME: '@checkout/setTime',
  SET_MARKETING_PAGE: '@checkout/setMarketingPage',
  SET_BOOKING: '@checkout/setBooking',
  SET_NOTE: '@checkout/setNote',
  SET_USER_DETAILS: '@checkout/setUserDetails',
  RESET: '@checkout/reset',
}

export function setGuests(value: number) {
  return {
    type: Checkout.SET_GUESTS,
    payload: value,
  }
}
export function setDiscountCode(value: string) {
  return {
    type: Checkout.SET_DISCOUNT_CODE,
    payload: value,
  }
}
export function setDate(value: AppDate | null) {
  return {
    type: Checkout.SET_DATE,
    payload: value,
  }
}
export function setTime(value: number | null) {
  return {
    type: Checkout.SET_TIME,
    payload: value,
  }
}
export function setMarketingPage(value: MarketingDetails | null) {
  return {
    type: Checkout.SET_MARKETING_PAGE,
    payload: value,
  }
}
export function setBooking(value: Booking | null) {
  return {
    type: Checkout.SET_BOOKING,
    payload: value,
  }
}
export function setNote(value: string) {
  return {
    type: Checkout.SET_NOTE,
    payload: value,
  }
}
export function setUserDetails(value: RegisterFormValues) {
  return {
    type: Checkout.SET_USER_DETAILS,
    payload: value,
  }
}
export function resetState() {
  return {
    type: Checkout.RESET,
  }
}
