export type ActivityPropertyValueInput = { id: number; value: string }

export interface ActivityPropertyInput {
  id: number
  name: string
  required?: boolean
  type?: 'boolean' | 'multiselect' | 'dropdown'
  values: ActivityPropertyValueInput[]
}

class ActivitiesProperty {
  id: number
  name: string
  required: boolean
  type: 'boolean' | 'multiselect' | 'dropdown'
  values: ActivityPropertyValueInput[]

  constructor(property: ActivityPropertyInput) {
    this.id = property.id
    this.name = property.name
    this.values = Array.isArray(property.values) ? property.values : [property.values]
    this.type = property.type || 'boolean'
    this.required = !!property.required
  }

  static fromArray(input: ActivityPropertyInput[] | null): ActivitiesProperty[] {
    return input ? input.map((i) => new ActivitiesProperty(i)) : []
  }
}

export default ActivitiesProperty
