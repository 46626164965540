import { Icon } from 'components/Icon/Icon'
import { useUser } from 'hooks/useUser'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { accountLink } from 'global/routes'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Avatar } from '../Avatar/Avatar'
import { Button } from '../Button/Button'
import { MessagesButton } from '../MessageButton/MessagesButton'
import { AccountActionsProps } from './AccountActions.types'
import styles from './AccountActions.module.scss'

export const AccountActions: FC<AccountActionsProps> = ({ onClick }) => {
  const { user, isHost, isLoggedIn } = useUser()
  const { isMobile } = useBreakpoints()
  const history = useHistory()

  const onMessagesLinkClick = () => {
    history.push(accountLink())
  }

  return (
    <div className={styles['account-actions']}>
      {isLoggedIn && (
        <div className={classNames({ [styles['account-actions__buttons-wrapper']]: isHost })}>
          {isHost && (
            <button className={styles['account-actions__host-button']} onClick={() => onMessagesLinkClick()}>
              {!isMobile && 'Guide'} Dashboard
            </button>
          )}
          <MessagesButton className={styles['account-actions__button']} />
        </div>
      )}
      <Button
        theme="light"
        radius="full"
        aria-label="open drawer"
        onClick={() => onClick?.()}
        className={styles['account-actions__button']}
      >
        <>
          <Icon icon="hamburger_partial" className="icon-2" containerClassName={styles['account-actions__icon']} />
          {user?.image ? (
            <Avatar
              image={user.image}
              text={user.initials}
              size="compact"
              className={styles['account-actions__icon']}
            />
          ) : (
            <Icon icon="user" className="icon-2" containerClassName={styles['account-actions__icon']} />
          )}
        </>
      </Button>
    </div>
  )
}
