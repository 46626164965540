import classNames from 'classnames'
import React from 'react'
import s from './ButtonGroup.module.scss'
import { TButtonGroupItem, TButtonGroupProps } from './ButtonGroup.types'

export function ButtonGroup<T>({
  activeItemId,
  items,
  onItemChange,
  theme = 'default',
  className,
}: TButtonGroupProps<T>) {
  const onButtonClick = (item: TButtonGroupItem<T>) => () => {
    onItemChange(item)
  }

  const itemsList = items.map((item, i) => (
    <React.Fragment key={`${item.id}`}>
      <button
        type="button"
        className={classNames(s.button, {
          [s.active]: activeItemId === item.id,
        })}
        onClick={onButtonClick(item)}
      >
        {item.title}
      </button>
      {i !== items.length - 1 && (
        <div
          className={classNames({
            [s.active]: activeItemId === item.id || activeItemId === items[i + 1]?.id,
          })}
        />
      )}
    </React.Fragment>
  ))

  return (
    <div
      className={classNames(s.container, {
        [className!]: !!className,
        [s['theme-' + theme]]: !!theme,
      })}
    >
      {itemsList}
    </div>
  )
}
