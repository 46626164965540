import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { MapGL } from 'components-new/common/MapGL/MapGL'
import { MapGLPin } from 'components-new/common/MapGLPin/MapGLPin'
import { MapGLProps } from 'components-new/common/MapGL/MapGL.types'
import styles from './HostLocation.module.scss'

type IHostLocation = Pick<MapGLProps, 'latitude' | 'longitude'> & { zoom?: MapGLProps['zoom'] }

export const HostLocation: React.FC<IHostLocation> = (props) => {
  const { zoom = 7, latitude, longitude } = props
  return (
    <Wrapper containerClasses={styles['host-location']}>
      <h2 className={styles['host-location__header']}>Location</h2>
      <div className={styles['host-location__map']}>
        <MapGL disabled controls zoom={zoom} latitude={latitude} longitude={longitude}>
          <MapGLPin latitude={latitude} longitude={longitude} theme="pin" />
        </MapGL>
      </div>
    </Wrapper>
  )
}
