import { LOGIN_PAGE } from 'global/routes'
import { ComponentType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import User from 'services/shared/models/User'

export const AuthRoute = ({
  component: Component,
  user,
  ...rest
}: RouteProps & { component: ComponentType<any>; user: User | null }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to={`${LOGIN_PAGE}?redirect=${props.location.pathname}`} />
      }
    />
  )
}
