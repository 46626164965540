import { UploadImage } from 'services-new/upload/upload-api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Image from 'services/shared/models/Image'

export type StateUploadItem = { file: File; uploaderId?: string; result: Image | null }

const InitialState = {
  files: [],
} as { files: StateUploadItem[] }

export const uploadSlice = createSlice({
  name: 'upload',
  initialState: InitialState,
  reducers: {
    removeFile(state, action: PayloadAction<Image>) {
      state.files.splice(
        state.files.findIndex((f) => f.result === action.payload),
        1
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UploadImage.pending, (state, action) => {
      state.files.push({ ...action.meta.arg, result: null })
    })
    builder.addCase(UploadImage.fulfilled, (state, action) => {
      const image = new Image(action.payload.data[0])
      state.files.splice(
        state.files.findIndex((f) => f.file === action.meta.arg.file),
        1,
        { ...action.meta.arg, result: image }
      )
    })
    builder.addCase(UploadImage.rejected, (state, action) => {
      state.files.splice(
        state.files.findIndex((f) => f.file === action.meta.arg.file),
        1
      )
    })
  },
})

export const { removeFile } = uploadSlice.actions

export default uploadSlice.reducer
