import React, { FC } from 'react'
import { EventCard } from 'components-new/common/EventCard/EventCard'
import classNames from 'classnames'
import styles from './ExperienceGrid.module.scss'
import { ExperienceGridProps } from './ExperienceGrid.types'

export const ExperienceGrid: FC<ExperienceGridProps> = ({ title, experiences, className }) => {
  const eventCards = experiences.map((el, i) => (
    <div key={el.id}>
      <EventCard layout="card" event={el} />
    </div>
  ))
  const classes = classNames({
    [className!]: !!className,
    [styles['experiences-grid']]: !!styles['experiences-grid'],
  })
  return (
    <div className={classes}>
      <h3 className={styles['experiences-grid__header']}>{title || 'Similar Experiences'}</h3>
      <div className={styles['experiences-grid__wrapper']}>{eventCards}</div>
    </div>
  )
}
