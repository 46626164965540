import { useCheckoutContext } from 'containers-new/CheckoutContainer/CheckoutContext'
import React, { FC, useRef, useState } from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { ImageGallery } from 'components-new/common/ImageGallery/ImageGallery'
import { Sizes } from 'services/shared/models/Image'
import { CustomModal } from 'components-new/modals/CustomModal/CustomModal'
import Experience from 'services-new/experience/experience.model'
import styles from './ExperienceHeader.module.scss'
import { ExperienceHeaderProps } from './ExperienceHeader.types'

export const ExperienceHeader: FC<ExperienceHeaderProps> = ({ experience }) => {
  const { formattedSubAmount } = useCheckoutContext()
  const [galleryModalOpened, setGalleryModalOpened] = useState(false)
  const { title, location, images, description } = experience

  const capacityString = Experience.getCapacityString(experience as Experience)
  const durationString = Experience.getDurationString(experience as Experience)

  return (
    <>
      <div className={styles['experience-header']}>
        <>
          <div className={styles['experience-header__top']}>
            <div>
              <span className={styles['experience-header__address']}>{location?.address?.toString()}</span>
              <h1 className={styles['experience-header__title']}>{title}</h1>
            </div>
            <div className={styles['experience-header__price']}>{formattedSubAmount}</div>
          </div>
          <ImageGallery
            className={styles['experience-header__gallery']}
            images={images}
            maxImages={5}
            alt={title}
            onSeeMoreClick={() => setGalleryModalOpened(true)}
          />
          {description && <p className={styles['experience-header__description']}>{description}</p>}
          {(capacityString || durationString) && (
            <div className={styles['experience-header__details']}>
              {capacityString && <span>{capacityString}</span>}
              {durationString && <span>{durationString}</span>}
            </div>
          )}
        </>
      </div>
      <CustomModal
        open={galleryModalOpened}
        theme="full-screen"
        showClose
        handleClose={() => setGalleryModalOpened(false)}
        onBackgroundClick={() => setGalleryModalOpened(false)}
      >
        <ImageGallery layout="list" images={images} size={Sizes.Large} alt={title} />
      </CustomModal>
    </>
  )
}
