import React, { FC, createContext, useContext, useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react'
import { DEFAULT_SEARCH_PARAMS, ParsedSearchParams, SearchParams, useSearch } from 'hooks/useSearch'
import { useHistory, useLocation } from 'react-router'
import { keys, isPlainObject, isArray } from 'lodash'
import { getUrlParams, searchLink, stringifyUrlParams } from 'global/routes'
import { AppDate } from 'services-new/shared/date'
import { ParamsObject, SearchContext, UpdateHistoryArgs } from './SearchContext.types'

const SearchContextInstance = createContext<SearchContext>({} as SearchContext)

export const SearchContextProvider: FC = (props) => {
  const { children } = props
  const { search } = useLocation()
  const searchObject = useSearch()
  const [searchParams, setSearchParams] = useState(DEFAULT_SEARCH_PARAMS)
  const [updateHistory, setUpdateHistoryFlag] = useState<boolean>(false)

  const history = useHistory()

  const params = getUrlParams(search)

  const getValidParams = useCallback((args: ParamsObject) => {
    let parsedParams: SearchParams = {}
    for (const param in args) {
      if (keys(DEFAULT_SEARCH_PARAMS).includes(param)) {
        parsedParams[param] = args[param]
      }
    }
    return parsedParams
  }, [])

  const parsedQueryParams = useMemo(() => {
    const validParams = getValidParams(params)
    for (const param in validParams) {
      if (keys(DEFAULT_SEARCH_PARAMS).includes(param)) {
        try {
          validParams[param] = JSON.parse(validParams[param])
        } catch (e) {}
      }
    }
    return validParams
  }, [])

  useLayoutEffect(() => {
    setGlobalSearchParams(parsedQueryParams)
  }, [parsedQueryParams])

  const setGlobalSearchParams = useCallback(
    (args?: ParamsObject | null) => {
      if (args?.minDate || args?.maxDate) {
        args = {
          ...args,
          minDate: typeof args.minDate === 'string' ? new AppDate(args.minDate).addTimezoneOffset() : args.minDate,
          maxDate: typeof args.minDate === 'string' ? new AppDate(args.maxDate).addTimezoneOffset() : args.maxDate,
        }
      }
      setSearchParams((prev) => {
        return args ? Object.assign({}, prev, getValidParams(args)) : DEFAULT_SEARCH_PARAMS
      })
    },
    [setSearchParams]
  )

  const queryString = useMemo(() => {
    let parsedParams: ParsedSearchParams = {}
    for (const param in searchParams) {
      if ({}.hasOwnProperty.call(searchParams, param)) {
        if (isPlainObject(searchParams[param])) {
          parsedParams[param] = JSON.stringify(searchParams[param])
        } else if (isArray(searchParams[param])) {
          if (searchParams[param].length !== 0) {
            parsedParams[param] = JSON.stringify(searchParams[param])
          }
        } else {
          parsedParams[param] = searchParams[param]
        }
        // console.log(searchParams)
        if (param === 'minDate' || param === 'maxDate') {
          parsedParams[param] = !!searchParams[param] ? new AppDate(searchParams[param]!).getISODateString() : undefined
        }
      }
    }
    return stringifyUrlParams(parsedParams)
  }, [searchParams])

  const runSearch = (args: UpdateHistoryArgs = {}) => {
    const { withSearchReset = false, searchParams: searchArgs } = args
    if (withSearchReset) {
      searchObject.reset()
      setGlobalSearchParams()
    }

    setGlobalSearchParams(searchArgs)
    setUpdateHistoryFlag(true)
  }

  useEffect(() => {
    if (updateHistory) {
      searchObject.run(searchParams)
      history.push(searchLink({ query: queryString }))
      setUpdateHistoryFlag(false)
    }
  }, [updateHistory, queryString])

  return (
    <SearchContextInstance.Provider
      value={{
        ...searchObject,
        runSearch,
        globalSearchParams: searchParams,
        setGlobalSearchParams,
        queryString,
      }}
    >
      {children}
    </SearchContextInstance.Provider>
  )
}

export function useSearchContext(): SearchContext {
  const context = useContext(SearchContextInstance)

  if (!context) {
    throw new Error('useSearchContext used without provider!')
  }

  return context
}
