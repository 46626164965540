import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'

import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import { exploreLink } from 'global/routes'
import { ExplorePageCard } from '../ExplorePageCard/ExplorePageCard'
import styles from './QuickTripCards.module.scss'

export const QuickTripCards = () => {
  const { featuredExplorePages } = useSettingsContext()
  return (
    <Wrapper className={styles.quick_trip_cards__wrapper}>
      <div className={styles.quick_trip_cards}>
        {featuredExplorePages.slice(0, 2).map((explorePage) => (
          <ExplorePageCard
            key={explorePage.id}
            src={explorePage.image?.getMediumSize()}
            link={exploreLink({ slug: explorePage.slug })}
            text={explorePage.title}
          />
        ))}
      </div>
    </Wrapper>
  )
}
