// from node_modules
import React, { FC } from 'react'
import { registerLink, forgotPasswordLink } from 'global/routes'
import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import { LoginFormInputs } from 'components-new/forms/LoginFormInputs/LoginFormInputs'
import { LoginFormValues } from 'components-new/forms/LoginFormInputs/LoginFormInputs.types'
import { Button } from 'components-new/common/Button/Button'
import { Loader } from 'components-new/common/Loader/Loader'
import { useUser } from 'hooks/useUser'
import { UtilityPage } from 'pages-new/UtilityPage/UtilityPage'
import { FormWrapperProps } from 'components-new/forms/FormWrapper/FormWrapper.types'
import { useDispatch } from 'react-redux'
import { LoginUser } from 'services-new/auth/auth-api'
import { SocialConnect } from 'components-new/common/SocialConnect/SocialConnect'
import { useLoginRedirect } from 'hooks/useLoginRedirect'
import s from './LoginPage.module.scss'
import { LoginPageProps } from './LoginPage.types'

export const LoginPage: FC<LoginPageProps> = () => {
  const dispatch = useDispatch()
  const { isLoaded, isLoading } = useUser()
  const { redirectUrl } = useLoginRedirect()

  const onLogin: FormWrapperProps<LoginFormValues>['onSubmit'] = async (data) => {
    await dispatch(LoginUser(data))
  }

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <UtilityPage title="Login">
      <FormWrapper<LoginFormValues>
        onSubmit={onLogin}
        afterForm={({ formState: errors }) => (
          <>
            <div className={s.buttons}>
              <div className={s.links}>
                <Button to={forgotPasswordLink(redirectUrl)} theme="link-small">
                  Forgot password?
                </Button>
                <Button to={registerLink(redirectUrl)} theme="link-small">
                  Don't have account?
                </Button>
              </div>
              <Button loading={isLoading} disabled={Object.keys(errors || {}).length > 0} type="submit" size="small">
                Login
              </Button>
            </div>
          </>
        )}
      >
        {(methods) => <LoginFormInputs />}
      </FormWrapper>
      <SocialConnect.Separator>or</SocialConnect.Separator>
      <SocialConnect type="google" />
    </UtilityPage>
  )
}
