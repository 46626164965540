import Activity, { ActivityInput } from 'services/shared/models/Activity'
import MarketingDetails, { MarketingDetailsInput } from 'services/marketing-page/models/MarketingDetails'
import Aggregations, { AggregationsInput } from 'services/shared/models/Aggregations'
import Image, { ImageInput } from 'services/shared/models/Image'
import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'
import Post, { IPostInput } from 'services-new/post/post.model'
import ExplorePage, { ExplorePageInput } from 'services/explore-page/models/ExplorePage'

export interface HomeSettings {
  title: string
  subtitle: string
  description: string
  images: Image[]
}
export interface SettingsInput {
  home_title: string
  home_subtitle: string
  home_description: string
  home_hero_images: ImageInput[]
  guide_default_background: ImageInput
  featured_explore_pages: ExplorePageInput[]
  featured_marketing_pages: MarketingDetailsInput[]
  recent_marketing_pages: MarketingDetailsInput[]
  featured_users: UserPublicInput[]
  featured_post: IPostInput | null
  support_email: string | null
  support_phone: string | null
  activities: ActivityInput[]
  aggregations: AggregationsInput
}

class Settings {
  homepage: HomeSettings
  guideDefaultBackground: Image
  featuredMarketingPages: MarketingDetails[]
  recentMarketingPages: MarketingDetails[]
  featuredExplorePages: ExplorePage[]
  featuredUsers: UserPublic[]
  featuredPost: Post | null
  supportEmail: string
  supportPhone: string
  supportPhoneClean: string
  activities: Activity[]
  generalActivity: Activity
  aggregations: Aggregations

  constructor(settings: SettingsInput) {
    this.homepage = {
      title: settings.home_title,
      subtitle: settings.home_subtitle,
      description: settings.home_description,
      images: Image.fromArray(settings.home_hero_images),
    }

    this.featuredPost = settings.featured_post ? new Post(settings.featured_post) : null
    this.guideDefaultBackground = new Image(settings.guide_default_background)
    this.featuredMarketingPages = MarketingDetails.fromArray(settings.featured_marketing_pages)
    this.recentMarketingPages = MarketingDetails.fromArray(settings.recent_marketing_pages)
    this.featuredExplorePages = ExplorePage.fromArray(settings.featured_explore_pages)
    this.featuredUsers = UserPublic.fromArray(settings.featured_users)
    this.activities = Activity.fromArray(settings.activities.filter((a) => a.name !== 'General'))
    this.generalActivity = new Activity(settings.activities.find((a) => a.name === 'General')!)
    this.supportEmail = settings.support_email || ''
    this.supportPhone = settings.support_phone || ''
    this.supportPhoneClean = this.supportPhone.replace(/[^0-9]+/g, '')
    this.aggregations = new Aggregations(settings.aggregations)
  }
}

export default Settings
