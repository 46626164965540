import React, { FormEventHandler, ReactElement } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormWrapperProps } from './FormWrapper.types'

export function FormWrapper<TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit = async () => {},
  afterForm,
  beforeForm,
  children,
  defaultValues,
  className,
  formOptions = {},
}: FormWrapperProps<TFormValues>): ReactElement {
  const formMethods = useForm<TFormValues>({
    defaultValues,
    ...formOptions,
  })

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    formMethods.handleSubmit(async (data) => {
      await onSubmit(data, formMethods)
    })()
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <FormProvider {...formMethods}>
        {beforeForm?.({ ...formMethods })}
        {children(formMethods)}
        {afterForm?.({ ...formMethods })}
      </FormProvider>
    </form>
  )
}
