import { Button } from 'components-new/common/Button/Button'
import React from 'react'
import classNames from 'classnames'
import {
  TGuideDashboardHelperProps,
  TGuideDashboardHelperSection,
  TGuideDashboardHelperSectionActions,
} from './GuideDashboardHelper.types'
import s from './GuideDashboardHelper.module.scss'

const getSectionStatusText = (section: TGuideDashboardHelperSection) => {
  switch (section.status) {
    case 'complete':
      return 'Complete'
    case 'incomplete':
      return 'Incomplete'
    default:
      return ''
  }
}

const getSectionTitleElement = (section: TGuideDashboardHelperSection, index: number) => (
  <>
    <span>{index}.</span> {section.title}
  </>
)

const renderActions = (action: TGuideDashboardHelperSectionActions) => (
  <div className={s.action}>
    {action.type === 'button' && (
      <Button className={s.actionButton} theme="secondary" size="full" {...action.buttonProps}>
        {action.title}
      </Button>
    )}
    {action.type === 'custom' && action.element}
  </div>
)

export const GuideDashboardHelper: React.FC<TGuideDashboardHelperProps> = ({
  className,
  style,
  sections,
  appendBefore = null,
  appendAfter = null,
}) => {
  const isIncompleted = sections.find((section) => section.status === 'incomplete')

  return (
    <div
      className={classNames(s.container, className, {
        [s.incompleted]: isIncompleted,
      })}
      style={style}
    >
      {appendBefore}
      {sections.map((section, i) => {
        const hasActions = !!section.actions.length
        const isCompleted = section.status === 'complete'
        return (
          <section
            key={i}
            className={classNames(s.section, {
              [s.hasActions]: hasActions,
              [s.sectionCompleted]: isCompleted,
            })}
          >
            <h5 className={s.heading}>{getSectionTitleElement(section, i + 1)}</h5>
            <p className={s.status}>{getSectionStatusText(section)}</p>
            <p className={s.description}>{section.description}</p>
            {hasActions && <div className={s.actions}>{section.actions.map(renderActions)}</div>}
          </section>
        )
      })}
      {appendAfter}
    </div>
  )
}
