import { Button } from 'components-new/common/Button/Button'
import { GuideDashboardHelper } from 'components-new/GuideDashboardHelper/GuideDashboardHelper'
import { Input } from 'components-new/forms/Input/Input'
import { useUser } from 'hooks/useUser'
import { accountLink, experienceEditLink, getGuideEmbedCode, guideLink } from 'global/routes'
import { useRequestAsync } from 'hooks/useRequestAsync'
import { UserInput } from 'services/shared/models/User'
import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import { TGuideDashboardHelperProps } from 'components-new/GuideDashboardHelper/GuideDashboardHelper.types'
import { experiencesAdapter } from 'features/experience/ExperiencesSlice'
import { RootState } from 'store'
import { GetExperiences } from 'services-new/experience/experience-api'
import { useBreakpoints } from 'hooks/useBreakpoints'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import slugify from 'slugify'
import { useDispatch, useSelector } from 'react-redux'
import { postRequest$ } from 'utils/api'
import { updateUser } from 'features/user/UserSlice'
import { Controller } from 'react-hook-form'
import { isNumericString } from 'utils/numberhelper'
import { EmbedModal } from 'components-new/modals/EmbedModal/EmbedModal'
import { Wrapper } from '../Wrapper/Wrapper'
import s from './AccountpublicProfileContainer.module.scss'
import { HostDetailsForm } from './components/HostDetails/HostDetailsForm'

const DETAILS_FORM_ID = 'your-public-profile-details'

type TEditPublicProfileLinkFormValues = {
  slug: string
}

const EditPublicProfileLink: React.FC<{}> = () => {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [showEmbedModal, setShowEmbedModal] = useState(false)
  const { user } = useUser()
  const defaultValue = user!.slug

  const dispatch = useDispatch()

  const { isLoading, fetch: updateSlug } = useRequestAsync<string, UserInput>(
    (slug) => postRequest$('update-slug', { slug: slugify(slug!, { lower: true }) }),
    (response) => {
      dispatch(updateUser(response.data))
      toast('Custom URL updated')
    }
  )

  const onInputBlur = () => {
    setIsInputFocused(false)
  }

  const onInputFocus = () => {
    setIsInputFocused(true)
  }

  const onCopyClick = () => {
    const text = window.location.origin + guideLink(defaultValue)
    navigator.clipboard.writeText(text).then(
      () => toast('URL Copied!'),
      () => toast('Cannot copy URL!')
    )
  }

  const domain = `${window.location.host + guideLink('')}`

  return (
    <FormWrapper<TEditPublicProfileLinkFormValues>
      defaultValues={{ slug: defaultValue }}
      className={s.editPublicProfileLink}
      onSubmit={async ({ slug }: TEditPublicProfileLinkFormValues, formMethods) => {
        await updateSlug(slug)
        formMethods.reset({ slug })
      }}
    >
      {({ formState: { errors, isDirty } }) => (
        <>
          <Controller
            name="slug"
            rules={{
              required: 'This field is required',
              minLength: {
                value: 5,
                message: 'Minimum 5 characters required',
              },
              validate: {
                numericTitle: (value) => !isNumericString(value) || 'This field can`t contain only numbers',
              },
            }}
            render={({ field, fieldState: { error, isDirty: dirty } }) => (
              <>
                <Input
                  {...field}
                  className={classNames(s.customUrlInput, {
                    [s.focused]: isInputFocused,
                  })}
                  label="Custom URL"
                  onBlur={onInputBlur}
                  onFocus={onInputFocus}
                  error={error?.message}
                  prepend={<p className={s.staticInputUrl}>{domain}</p>}
                  append={
                    !error &&
                    !dirty && (
                      <button type="button" className={s.copyButton} onClick={onCopyClick}>
                        Copy
                      </button>
                    )
                  }
                />
              </>
            )}
          />
          <p className={s.description}>
            Use this link to share your profile. Post it on your Instagram or send it to clients.
          </p>

          <Button
            type={'submit'}
            disabled={!isDirty || Object.keys(errors || {}).length > 0}
            loading={isLoading}
            className={s.editProfileButton}
            size="small"
            theme="outline"
          >
            Edit Public Profile URL
          </Button>
          <Button to={guideLink(defaultValue)} size="small" className={s.editProfileButton}>
            View Public Profile
          </Button>
          <Button onClick={() => setShowEmbedModal(true)} size="small" theme="secondary">
            Embed
          </Button>
          {user && (
            <EmbedModal
              code={getGuideEmbedCode(user.id)}
              open={showEmbedModal}
              onClose={() => setShowEmbedModal(false)}
            />
          )}
        </>
      )}
    </FormWrapper>
  )
}

const getSections = ({
  isMobile,
  hasTrips,
  isStripeSetupCompleted,
  scrollToYourPublicProfile,
  profileDetailsCompleted,
}: {
  isMobile: boolean
  hasTrips: boolean
  isStripeSetupCompleted: boolean
  profileDetailsCompleted: boolean
  scrollToYourPublicProfile(): void
}): TGuideDashboardHelperProps['sections'] => [
  {
    title: 'Your profile details',
    description: 'In order to post your first trip you need to setup your public profile first. ',
    status: profileDetailsCompleted ? 'complete' : 'incomplete',
    actions: isMobile
      ? [
          {
            type: 'button',
            title: 'Edit Your Public Profile',
            buttonProps: {
              size: 'small',
              onClick: scrollToYourPublicProfile,
            },
          },
        ]
      : [],
  },
  {
    title: 'Post your first trip',
    description:
      'Post your first trip using our easy trip building form. You can post as many trips as you want. We can’t wait to start sending you bookings!',
    status: hasTrips ? 'complete' : 'incomplete',
    actions: [
      {
        type: 'button',
        title: 'Create Your First Trip',
        buttonProps: {
          theme: 'secondary',
          size: 'extra-small',
          to: experienceEditLink('new'),
        },
      },
    ],
  },
  {
    title: 'Setup payment',
    description:
      'Visit the Payments Panel and set up your Outguided payment account. After your payment account is verified you will need to add a preferred payment method for us to transfer funds to. Once added, you can get paid with one click to your preferred method.',
    status: isStripeSetupCompleted ? 'complete' : 'incomplete',
    actions: [
      {
        type: 'button',
        title: 'Configure Payment',
        buttonProps: {
          theme: 'secondary',
          size: 'extra-small',
          to: accountLink('payments'),
        },
      },
    ],
  },
  {
    title: 'Your public profile link',
    description:
      'Your public profile has a custom URL with your business name. Links to your trips will also appear on your profile.',
    status: 'complete',
    actions: [
      {
        type: 'custom',
        element: <EditPublicProfileLink />,
      },
    ],
  },
]

export const AccountPublicProfileContainer: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const { user } = useUser()
  const { isMobile } = useBreakpoints()
  const [slug, setSlug] = useState(user?.slug || '')

  const state = useSelector((s: RootState) => s.experiences)
  const { selectAll } = experiencesAdapter.getSelectors()

  useEffect(() => {
    dispatch(GetExperiences())
  }, [])

  const trips = useMemo(() => {
    return selectAll(state).filter((ex) => ex.status === 'published')
  }, [state])

  const profileDetailsCompleted = useMemo(() => {
    if (!user) return false
    const keysToBeCompleted = ['displayName', 'languages', 'description', 'guidingSince', 'location', 'images']
    return keysToBeCompleted.every((key) => (key === 'location' ? !!(user as any)[key] : !!(user as any).hostInfo[key]))
  }, [user])

  const scrollToYourPublicProfile = () => {
    const elem = document.getElementById(DETAILS_FORM_ID)
    if (elem) {
      window.scroll({
        top: elem.offsetTop + 30,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Wrapper contentClasses={s.container}>
      <div className={s.content}>
        <div className={s.block}>
          <h2>Public Profile Details</h2>
          <HostDetailsForm id={DETAILS_FORM_ID} />
        </div>
        {/* <div className={s.block}>
          <h2>Photos</h2>
          <HostPhotos />
        </div> */}
      </div>
      <aside className={s.sidebar}>
        <GuideDashboardHelper
          className={s.guide}
          sections={getSections({
            isMobile,
            scrollToYourPublicProfile,
            hasTrips: trips.length > 0,
            isStripeSetupCompleted: user!.hostInfo!.status === 'active',
            profileDetailsCompleted,
          })}
        />
      </aside>
    </Wrapper>
  )
}
