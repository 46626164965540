export interface PropertyInput {
  id: number
  name: string
  value: string[]
  type: 'boolean' | 'multiselect' | 'dropdown'
}

class Property {
  name: string
  value: string[]
  type: 'boolean' | 'multiselect' | 'dropdown'

  constructor(property: PropertyInput) {
    this.type = property.type
    this.name = property.name
    this.value = Array.isArray(property.value) ? property.value : [property.value]
  }

  static fromArray(input: PropertyInput[] | null): Property[] {
    return input ? input.map((i) => new Property(i)) : []
  }
}

export default Property
