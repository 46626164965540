import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { Avatar } from 'components-new/common/Avatar/Avatar'
import { useUser } from 'hooks/useUser'
import { IMessage } from './Message.types'
import styles from './Message.module.scss'

export const Message: FC<IMessage> = (props: IMessage) => {
  const { message, conversation } = props
  const { user } = useUser()
  const direction = user?.id === message.authorId ? 'outbound' : 'inbound'
  const author = conversation.getMessageAuthor(message)!
  const [selected, setSelected] = useState(false)
  const classes = classNames({
    [styles[`message--${direction}`]]: true,
    [styles['message']]: true,
  })

  const messageClasses = classNames({
    [styles[`message-text--${direction}`]]: true,
    [styles['message-text']]: true,
  })

  return (
    <div className={classes}>
      {selected && <div className={styles['message__time']}>{message.createdAt.getLocalDateTimeString()}</div>}
      <div className={styles['message__wrapper']}>
        {direction === 'inbound' && author && (
          <Avatar className={styles['message__avatar']} size={'compact'} image={author.image} text={author.initials} />
        )}
        <div className={messageClasses} onClick={() => setSelected(!selected)}>
          {/* <div className={styles['message-text__header']}> */}
          {/* {/* <div className={styles['message-text__from']}>{author.displayName}</div> */}
          {/* </div> */}
          <div className={styles['message-text__text']}>{message.body}</div>
        </div>
      </div>
    </div>
  )
}
