import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import selectn from 'selectn'
import LocalStorageService from 'utils/LocalStorageService'
import { BACKEND_API } from 'global/environment'
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'

const Axios = axios.create({
  baseURL: BACKEND_API,
})

Axios.interceptors.request.use(
  function (config) {
    const localStorageService = LocalStorageService.getService()

    const token = localStorageService.getJWTToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    const err = selectn('response.data', error)

    let message =
      selectn('message[0].messages[0].message', err) || selectn('data[0].message', err) || err?.message || err || error

    const details: string[] = []
    const errorDetails = selectn('data.errors', err) || {}
    Object.keys(errorDetails).forEach((key) => {
      if (errorDetails[key].length) {
        details.push(errorDetails[key][0])
      }
    })

    if (message === 'Forbidden') {
      message = 'Oops! Something went wrong! Please try again later'
    }

    toast(`${message}${details.length ? ' - ' + details.join(', ') : ''}`)

    if (err?.status === 403) {
      // TODO: add handling for not-auth requests
      // console.error('Not authorized - 403');
    } else if (err?.status === 404) {
      // TODO: add handling for not found requests
      // console.error('Not found- 404');
    } else {
      // TODO: add handling for other requests
      // console.error('Other error from http');
    }
    return Promise.reject(error)
  }
)

export const HEADER_FORM_DATA = {
  headers: { 'Content-Type': 'multipart/form-data' },
}

export function getRequest$<K>(path: string, config?: AxiosRequestConfig) {
  return Axios.get<K>(path, config)
}

export function postRequest$<K>(path: string, data?: any, config?: AxiosRequestConfig) {
  return Axios.post<K>(path, data, config)
}

export function putRequest$<K>(path: string, data?: any, config?: AxiosRequestConfig) {
  return Axios.put<K>(path, data, config)
}

export function deleteRequest$<K>(path: string, config?: AxiosRequestConfig) {
  return Axios.delete<K>(path, config)
}

export function getCancelTokenSource() {
  return axios.CancelToken.source()
}

export const createAxiosAsyncThunk = <Returned, ThunkArg = any, ThunkApiConfig = {}>(
  name: string,
  callback: AsyncThunkPayloadCreator<AxiosResponse<Returned>, ThunkArg, ThunkApiConfig>
) => {
  // @ts-ignore
  return createAsyncThunk<AxiosResponse<Returned>, ThunkArg, ThunkApiConfig>(name, callback, {
    serializeError: (error: AxiosError) => ({ status: error.response?.status, message: error.response?.statusText }),
  })
}

export interface InitialState {
  loading: boolean
  loaded: boolean
  error: any
  loaders: { [key: string]: { loading: boolean; error: any } }
}

export const initialState: InitialState = {
  loading: false,
  loaded: false,
  error: null,
  loaders: {},
}
