import React from 'react'
import img_u_guide from 'assets/img/guide_are_you.png'
import { Image } from 'components-new/common/Image/Image'
import { Button } from 'components-new/common/Button/Button'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import styles from './AreYouAGuide.module.scss'

export const AreYouAGuide = () => (
  <Wrapper>
    <div className={styles['are-you-a-guide__overlay-content']}>
      <div className={styles['are-you-a-guide__title']}>Are you a Guide?</div>
      <div className={styles['are-you-a-guide__sub-title']}>List your trips. For free.</div>
      <Button theme="light" className={styles['are-you-a-guide__button']} to="/forguides" clickOverlay={true}>
        Get Started
      </Button>
    </div>
  </Wrapper>
)
