import { useState } from 'react'
import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'
import { getOtherUserDetails$, getOtherUserDetailsBySlug$ } from 'services/user/user-api'
import { isNumericString } from 'utils/numberhelper'
import { useRequest } from './useRequest'

export const useHostDetailsBySlugOrId = () => {
  const [result, setResult] = useState<UserPublic | null>(null)

  const {
    isLoading,
    responseError,
    fetch: fetchRequest,
  } = useRequest<string, UserPublicInput>(
    (params?: string) =>
      isNumericString(params as string) ? getOtherUserDetails$(params) : getOtherUserDetailsBySlug$(params),
    (response) => {
      setResult(new UserPublic(response))
    }
  )

  const fetch = (slug: string): void => {
    fetchRequest(slug)
  }

  return {
    isLoading,
    responseError,
    result,
    fetch,
  }
}
