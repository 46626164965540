import { setFade } from 'redux/Header/actions'

export const SearchPageActions = {
  SET_ADVANCED_FILTERS: '@searchPage/setAdvancedFiltersVisible',
  RUN_SEARCH: '@searchPage/runSearch',
  ZOOM_ON_RESULTS: '@searchPage/setZoomOnResults',
}

export function setAdvancedFilters(value) {
  return {
    type: SearchPageActions.SET_ADVANCED_FILTERS,
    payload: value,
  }
}

export function toggleFiltersWithFade() {
  return (dispatch, getState) => {
    const { advancedFiltersVisible } = getState().searchPage
    dispatch(setAdvancedFilters(!advancedFiltersVisible))
    dispatch(setFade(!advancedFiltersVisible))
  }
}

export function runSearch(value = null) {
  return {
    type: SearchPageActions.RUN_SEARCH,
    payload: value,
  }
}
export function setZoomOnResults(value) {
  return {
    type: SearchPageActions.ZOOM_ON_RESULTS,
    payload: value,
  }
}
