import { LoginFormValues } from 'components-new/forms/LoginFormInputs/LoginFormInputs.types'
import { RegisterFormValues } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs.types'
import { ForgotPasswordFormValues } from 'components-new/forms/ForgotPasswordFormInputs/ForgotPasswordFormInputs.types'
import { AuthUserInput, UserInput } from 'services/shared/models/User'
import { createAxiosAsyncThunk, postRequest$, getRequest$, putRequest$, deleteRequest$ } from 'utils/api'
import { PasswordChangeFormInputsValues } from 'components-new/forms/PasswordChangeFormInputs/PasswordChangeFormInputs.types'
import { AxiosResponse } from 'axios'
import { BecomeGuideFormInputsValues } from 'components-new/forms/BecomeGuideFormInputs/BecomeGuideFormInputs.types'

export const LoginUser = createAxiosAsyncThunk<AuthUserInput, LoginFormValues & { asHost?: boolean }>(
  'user/loginUser',
  async ({ email, password }) => await postRequest$(`auth/local`, { identifier: email, password })
)

export const AuthenticationCallback = createAxiosAsyncThunk<AuthUserInput, { query: string; type: string }>(
  'user/auth/callback',
  async ({ query, type }) => await getRequest$(`auth/${type}/callback${query}`)
)

export const RegisterUser = createAxiosAsyncThunk<AuthUserInput, RegisterFormValues>(
  'user/registerUser',
  async (data) =>
    await postRequest$(`auth/local/register`, {
      first_name: data.firstName,
      last_name: data.lastName,
      password: data.password,
      email: data.email,
      username: data.email,
      phone: data.phone,
    })
)

export const ResetPassword = createAxiosAsyncThunk<AuthUserInput, PasswordChangeFormInputsValues & { code: string }>(
  'user/resetPassword',
  async ({ password, confirmPassword, code }) =>
    await postRequest$(`auth/reset-password`, { password, passwordConfirmation: confirmPassword, code })
)

export const ForgotPassword = createAxiosAsyncThunk<void, ForgotPasswordFormValues>(
  'user/forgotPassword',
  async ({ email }) => await postRequest$(`auth/forgot-password`, { email })
)

export const BecomeGuide = createAxiosAsyncThunk<UserInput, BecomeGuideFormInputsValues>(
  'user/becomeHost',
  async ({ displayName }) => await postRequest$(`become-host`, { display_name: displayName })
)

export const UpdateUser = createAxiosAsyncThunk<UserInput, any>(
  'user/update',
  async ({ id, ...data }) => await putRequest$(`users/${id}`, data)
)
export const DeleteUser = createAxiosAsyncThunk<void, any>(
  'user/delete',
  async ({ id }) => await deleteRequest$(`users/${id}`)
)

export const Me = createAxiosAsyncThunk<UserInput, void>('user/me', async () => await getRequest$(`me`))

export const LogoutUser = createAxiosAsyncThunk<null, void>('user/logout', async () => {
  return { data: null } as AxiosResponse<null>
})
