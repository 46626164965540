import React, { FC } from 'react'
import { EmptyConversation } from '../atoms/EmptyConversation/EmptyConversation'
import { IEmptyConversationList } from './EmptyConversationList.types'
import styles from './EmptyConversationList.module.scss'

export const EmptyConversationList: FC<IEmptyConversationList> = () => {
  return (
    <div className={styles['empty-conversation']}>
      <div className={styles['empty-conversation__text']}>New messages will appear here</div>
      <EmptyConversation loading={true} containerClasses={styles['empty-conversation__item']} />
      <EmptyConversation loading={true} containerClasses={styles['empty-conversation__item']} />
      <EmptyConversation loading={true} containerClasses={styles['empty-conversation__item']} />
    </div>
  )
}
