import { GlobalActions } from 'redux/Global/actions'
import UserPublic from 'services/shared/models/UserPublic'

const initialState = {
  featuredGuides: [],
} as GlobalState

export function globalReducer(state = initialState, action: { payload: any; type: string }) {
  switch (action.type) {
    case GlobalActions.SET_FEATURED_GUIDES:
      return {
        ...state,
        featuredGuides: UserPublic.fromArray(action.payload),
      }

    default:
      return state
  }
}

export interface GlobalState {
  featuredGuides: UserPublic[]
}
