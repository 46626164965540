import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import {
  CreateExperience,
  GetExperience,
  GetExperiences,
  UpdateExperience,
  DeleteExperience,
} from 'services-new/experience/experience-api'
import Experience, { ExperienceInput } from 'services-new/experience/experience.model'
import { initialState, InitialState } from 'utils/api'

export const experiencesAdapter = createEntityAdapter<Experience>()

export const experiencesSlice = createSlice({
  name: 'experiences',
  initialState: experiencesAdapter.getInitialState<InitialState>(initialState),
  reducers: {
    reset: (state) => Object.assign(state, experiencesAdapter.getInitialState<InitialState>(initialState)),
  },
  extraReducers: (builder) => {
    builder.addCase(CreateExperience.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(CreateExperience.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      experiencesAdapter.setOne(state, new Experience(action.payload.data))
    })
    builder.addCase(CreateExperience.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
    builder.addCase(UpdateExperience.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(UpdateExperience.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      experiencesAdapter.setOne(state, new Experience(action.payload.data))
    })
    builder.addCase(UpdateExperience.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
    builder.addCase(DeleteExperience.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(DeleteExperience.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      experiencesAdapter.removeOne(state, action.payload.data.id)
    })
    builder.addCase(DeleteExperience.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
    builder.addCase(GetExperience.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(GetExperience.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      experiencesAdapter.addOne(state, new Experience(action.payload.data))
    })
    builder.addCase(GetExperience.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
    builder.addCase(GetExperiences.pending, (state, action) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(GetExperiences.fulfilled, (state, action: PayloadAction<AxiosResponse<ExperienceInput[]>>) => {
      state.loading = false
      state.loaded = true
      experiencesAdapter.addMany(state, Experience.fromArray(action.payload.data))
    })
    builder.addCase(GetExperiences.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
  },
})
export const { reset } = experiencesSlice.actions
export default experiencesSlice.reducer
