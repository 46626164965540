import { Button } from 'components-new/common/Button/Button'
import classNames from 'classnames'
import React, { useState } from 'react'
import { CardForm } from 'components-new/common/CardForm/CardForm'
import { useDispatch } from 'react-redux'
import { CreateExternalAccount } from 'services-new/stripe/payments-api'
import { ButtonGroup } from 'components-new/common/ButtonGroup/ButtonGroup'
import { BankAccountForm } from 'components-new/common/BankAccountForm/BankAccountForm'
import { useUser } from 'hooks/useUser'
import s from './PaymentsAddMethod.module.scss'

export type TCreditCardDetailsValues = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

export type TPaymentsAddMethodProps = {
  className?: string
}

export const PaymentsAddMethod: React.FC<TPaymentsAddMethodProps> = ({ className }) => {
  const { user } = useUser()
  const [activeTab, setActiveTab] = useState<string>('card')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const isInactive = user?.hostInfo?.status === 'inactive'

  const onFormSubmit = async ({ token }: { token: string }) => {
    setIsLoading(true)
    await dispatch(CreateExternalAccount({ external_account: token }))
    setIsLoading(false)
  }

  const footer = ({ formState: { isDirty, errors } }: any) => (
    <footer className={s.footer}>
      <Button type="submit" loading={isLoading} disabled={!isDirty || Object.keys(errors).length > 0}>
        Add Payment Method
      </Button>
    </footer>
  )

  return (
    <div className={classNames(s.container, className)}>
      <h3>Add New Payment Method</h3>
      {isInactive ? (
        <p>You need to Setup your Stripe Account to Add New Payment Method</p>
      ) : (
        <>
          <ButtonGroup<string>
            className={s.switch}
            activeItemId={activeTab}
            items={[
              {
                title: 'Debit Card',
                id: 'card',
              },
              {
                title: 'Bank Account',
                id: 'bank_account',
              },
            ]}
            onItemChange={(tab) => setActiveTab(tab.id)}
          />
          {activeTab === 'card' && <CardForm onSubmit={onFormSubmit} afterForm={footer} />}
          {activeTab === 'bank_account' && <BankAccountForm onSubmit={onFormSubmit} afterForm={footer} />}
        </>
      )}
    </div>
  )
}
