import Pin from 'assets/img/pin.png'
import Icon from 'components/Icon/Icon'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { Marker } from 'react-map-gl'
import { MapGLPinProps } from './MapGLPin.types'
import styles from './MapGLPin.module.scss'

export const MapGLPin = forwardRef<HTMLButtonElement, MapGLPinProps>((props, ref) => {
  const {
    isActive,
    isHovered,
    wasClicked,
    latitude,
    longitude,
    onClick,
    theme = 'circle',
    icon,
    children,
    ...nativeButtonProps
  } = props

  const className = classNames({
    [styles['map-gl-pin']]: !icon,
    [styles['map-gl-pin--hovered']]: !!isHovered,
    [styles['map-gl-pin--was-clicked']]: !!wasClicked,
    [styles['map-gl-pin--active']]: !!isActive,
    [styles['map-gl-pin--disabled']]: !onClick,
  })

  const offsetLeft = theme === 'circle' ? -10 : -25
  const offsetTop = theme === 'circle' ? -10 : -50

  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      className={className}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
    >
      <button ref={ref} onClick={onClick} className={styles['map-gl-pin__button']} {...nativeButtonProps}>
        {theme === 'white' ? (
          <div className={styles['map-gl-pin-detailed']}>
            {!!icon && <Icon className={styles['map-gl-pin-detailed__icon']} icon={icon} />}
            {children}
          </div>
        ) : theme === 'circle' ? (
          <img src={Pin} alt="pin" width={20} height={20} />
        ) : (
          <Icon className={styles['map-gl-pin__icon']} icon="pin" />
        )}
      </button>
    </Marker>
  )
})
