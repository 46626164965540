import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { AcceptBooking, CancelBooking, RejectBooking } from 'services-new/booking/booking-api'
import Booking from 'services-new/booking/booking.model'
import {
  CreateConversation,
  GetConversation,
  GetConversations,
  LoadMoreMessages,
  RemoveConversation,
  SendMessage,
} from 'services-new/conversation/conversation-api'
import Conversation, { ConversationInput } from 'services-new/conversation/conversation.model'
import Message from 'services-new/conversation/message.model'
import { initialState, InitialState } from 'utils/api'

export const conversationAdapter = createEntityAdapter<Conversation>({
  sortComparer: (a, b) => (a.updatedAt < b.updatedAt ? 1 : -1),
})

const conversationsInitailState = Object.assign({}, initialState, {
  draftConversation: null,
  currentConversation: null,
})

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: conversationAdapter.getInitialState<
    InitialState & { draftConversation: Conversation | null; currentConversation: Conversation | null }
  >(conversationsInitailState),
  reducers: {
    setDraftConversation: (state, action: PayloadAction<Conversation | null>) => {
      state.draftConversation = action.payload
    },
    setCurrentConversation: (state, action: PayloadAction<Conversation | null>) => {
      state.currentConversation = action.payload
    },
    reset: (state) =>
      Object.assign(state, conversationAdapter.getInitialState<InitialState>(conversationsInitailState)),
  },
  extraReducers: (builder) => {
    builder.addCase(CreateConversation.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(CreateConversation.fulfilled, (state, action) => {
      state.loading = false
      conversationAdapter.addOne(state, new Conversation(action.payload.data.conversation))
    })
    builder.addCase(CreateConversation.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    builder.addCase(GetConversation.pending, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: true,
        error: null,
      }
      state.loading = true
      state.error = null
    })
    builder.addCase(GetConversation.fulfilled, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: null,
      }
      state.loading = false
      state.error = null
      const conversation = new Conversation(action.payload.data.conversation)
      conversation.messages = Message.orderMessages(Message.fromArray(action.payload.data.messages))
      conversationAdapter.setOne(state, conversation)
    })
    builder.addCase(GetConversation.rejected, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: action.error,
      }
      state.loading = false
      state.error = action.error
    })
    builder.addCase(RemoveConversation.fulfilled, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: null,
      }
      conversationAdapter.removeOne(state, action.meta.arg)
    })
    builder.addCase(RemoveConversation.rejected, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: action.error,
      }
    })
    builder.addCase(RemoveConversation.pending, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(SendMessage.pending, (state, action) => {
      state.loaders[action.meta.arg.id] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(SendMessage.fulfilled, (state, action) => {
      state.loaders[action.meta.arg.id] = {
        loading: false,
        error: null,
      }
      const { selectById } = conversationAdapter.getSelectors()

      const currentMessages = selectById(state, action.payload.data.conversation.id)?.messages || []
      currentMessages.unshift(new Message(action.payload.data.message))

      const conversation = new Conversation(action.payload.data.conversation)
      conversation.messages = Message.orderMessages(currentMessages)

      conversationAdapter.setOne(state, conversation)
    })
    builder.addCase(SendMessage.rejected, (state, action) => {
      state.loaders[action.meta.arg.id] = {
        loading: false,
        error: action.error,
      }
    })
    builder.addCase(LoadMoreMessages.pending, (state, action) => {
      state.loaders[action.meta.arg.id] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(LoadMoreMessages.fulfilled, (state, action) => {
      state.loaders[action.meta.arg.id] = {
        loading: false,
        error: null,
      }
      const { selectById } = conversationAdapter.getSelectors()

      const currentMessages = selectById(state, action.payload.data.conversation.id)?.messages || []
      currentMessages.unshift(...Message.fromArray(action.payload.data.messages))

      const conversation = new Conversation(action.payload.data.conversation)

      conversation.messages = Message.orderMessages(currentMessages)

      conversationAdapter.setOne(state, conversation)
    })
    builder.addCase(LoadMoreMessages.rejected, (state, action) => {
      state.loaders[action.meta.arg.id] = {
        loading: false,
        error: action.error,
      }
    })
    builder.addCase(GetConversations.pending, (state, action) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(GetConversations.fulfilled, (state, action: PayloadAction<AxiosResponse<ConversationInput[]>>) => {
      state.loading = false
      const newConversations = Conversation.fromArray(action.payload.data)

      newConversations.forEach((newConversation) => {
        const { selectById } = conversationAdapter.getSelectors()

        const currentConversation = selectById(state, newConversation.id)
        if (currentConversation?.messages) {
          newConversation.messages = Message.orderMessages(currentConversation?.messages)
        }
      })

      conversationAdapter.setAll(state, newConversations)
    })
    builder.addCase(GetConversations.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })

    // ON BOOKING UPDATES
    builder.addCase(AcceptBooking.fulfilled, (state, action) => {
      const booking = new Booking(action.payload.data)
      const { selectAll } = conversationAdapter.getSelectors()
      const conversations = selectAll(state)

      conversations.forEach((conversation) => {
        if (conversation.booking?.id === booking.id) {
          conversation.booking = booking
          conversationAdapter.setOne(state, conversation)
        }
      })

      if (state.currentConversation?.booking?.id === booking.id) {
        state.currentConversation.booking = booking
      }
    })

    builder.addCase(RejectBooking.fulfilled, (state, action) => {
      const booking = new Booking(action.payload.data)
      const { selectAll } = conversationAdapter.getSelectors()
      const conversations = selectAll(state)

      conversations.forEach((conversation) => {
        if (conversation.booking?.id === booking.id) {
          conversation.booking = booking
          conversationAdapter.setOne(state, conversation)
        }
      })

      if (state.currentConversation?.booking?.id === booking.id) {
        state.currentConversation.booking = booking
      }
    })

    builder.addCase(CancelBooking.fulfilled, (state, action) => {
      const booking = new Booking(action.payload.data)
      const { selectAll } = conversationAdapter.getSelectors()
      const conversations = selectAll(state)

      conversations.forEach((conversation) => {
        if (conversation.booking?.id === booking.id) {
          conversation.booking = booking
          conversationAdapter.setOne(state, conversation)
        }
      })

      if (state.currentConversation?.booking?.id === booking.id) {
        state.currentConversation.booking = booking
      }
    })
  },
})
export const { setDraftConversation, setCurrentConversation, reset } = conversationsSlice.actions
export default conversationsSlice.reducer
