import Post from 'services-new/post/post.model'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { initialState, InitialState } from '../../utils/api'
import { GetPostsRelated } from '../../services-new/post/post-api'

export const postsRelatedAdapter = createEntityAdapter<Post>({
  selectId: (post) => post.slug,
})

export const postsRelatedSlice = createSlice({
  name: 'postsRelated',
  initialState: postsRelatedAdapter.getInitialState<InitialState>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetPostsRelated.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(GetPostsRelated.fulfilled, (state, action) => {
      state.loading = false
      postsRelatedAdapter.setAll(state, Post.fromArray(action.payload.data))
    })
    builder.addCase(GetPostsRelated.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
  },
})

export default postsRelatedSlice.reducer
