export const EventCreationContainerActions = {
  SET_ANONYMOUS_PATH: '@creationPage/setAnonymousPath',
  SET_ERROR: '@creationPage/setError',
  SET_DRAFT_PROPERTY: '@creationPage/setDraftProperty',
  SET_DRAFT: '@creationPage/setDraft',
  SET_VALIDATION_ERRORS: '@creationPage/setValidationErrors',
  SET_DRAFT_SECTIONS: '@creationPage/setDraftSections',
  SET_DRAFT_PROPERTIES: '@creationPage/setDraftProperties',
  SET_DRAFT_INCLUDE_OPTIONS: '@creationPage/setDraftIncludeOptions',
  SET_DRAFT_LOCATIONS: '@creationPage/setDraftLocations',
  SET_DRAFT_NEW_EVENTS: '@creationPage/setDraftNewEvents',
  SET_LOADING: '@creationPage/setLoading',
}

export function setAnonymousPath(value) {
  return {
    type: EventCreationContainerActions.SET_ANONYMOUS_PATH,
    payload: value,
  }
}

export function setError(value) {
  return {
    type: EventCreationContainerActions.SET_ERROR,
    payload: value,
  }
}

export function setDraft(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT,
    payload: value,
  }
}

export function setDraftProperty(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT_PROPERTY,
    payload: value,
  }
}

export function setValidationErrors(value) {
  return {
    type: EventCreationContainerActions.SET_VALIDATION_ERRORS,
    payload: value,
  }
}

export function setDraftSections(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT_SECTIONS,
    payload: value,
  }
}

export function setDraftProperties(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT_PROPERTIES,
    payload: value,
  }
}

export function setDraftIncludeOptions(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT_INCLUDE_OPTIONS,
    payload: value,
  }
}

export function setDraftLocations(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT_LOCATIONS,
    payload: value,
  }
}

export function setDraftNewEvents(value) {
  return {
    type: EventCreationContainerActions.SET_DRAFT_NEW_EVENTS,
    payload: value,
  }
}
