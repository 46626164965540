import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useBreakpoints = () => {
  const theme = useTheme()
  // noSsr needed since useMediaQuery was returning always true for the first time
  return {
    isMobile: !useMediaQuery(theme.breakpoints.up('md'), { noSsr: true }),
    isDesktop: useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true }),
  }
}
