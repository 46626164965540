import React, { forwardRef } from 'react'

import { FormField } from '../FormField/FormField'
import { TextAreaProps } from './TextArea.types'

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(props, ref) {
  const {
    children,
    className,
    placeholder,
    required,
    name,
    value,
    label,
    error,
    success,
    defaultValue,
    minLength,
    maxLength,
    ...nativeProps
  } = props

  const parsedValue = value || ''

  return (
    <FormField label={label} error={error} success={success} value={value} className={className}>
      <textarea
        ref={ref}
        placeholder={placeholder}
        required={required}
        name={name}
        value={parsedValue}
        defaultValue={defaultValue}
        minLength={minLength}
        maxLength={maxLength}
        {...nativeProps}
      />
    </FormField>
  )
})
