import { accountLink, getUrlParams, HOME_PAGE } from 'global/routes'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import LocalStorageService from 'utils/LocalStorageService'
import { useUser } from './useUser'

export const useLoginRedirect = ({
  useLocalStorage = false,
}: {
  useLocalStorage?: boolean
} = {}): {
  redirectUrl: string
} => {
  const { search } = useLocation()
  const history = useHistory()

  const { isLoggedIn, isHost, user } = useUser()
  const params = getUrlParams(search) as {
    redirect?: string
  }

  let redirectUrl = ''

  if (useLocalStorage && !params.redirect) {
    redirectUrl = LocalStorageService.getRedirect() || accountLink('details')
  } else {
    redirectUrl = params.redirect ? params.redirect : accountLink('details')
  }

  LocalStorageService.setRedirect(redirectUrl)

  useEffect(() => {
    if (isLoggedIn) {
      if (isHost && redirectUrl === accountLink('details')) {
        history.push(accountLink(user!.marketingPages.length ? 'calendar' : 'profile'))
      } else if (redirectUrl) {
        history.push(redirectUrl)
      }
    }
  }, [isLoggedIn, redirectUrl, isHost, user])

  return {
    redirectUrl,
  }
}
