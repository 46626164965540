import { Icon } from 'components/Icon/Icon'
import React, { FC } from 'react'
import styles from './PaymentProtectionMessage.module.scss'

export const PaymentProtectionMessage: FC<{
  elementRef?: React.RefObject<HTMLDivElement>
  onClose: () => void
}> = ({ elementRef, onClose }) => {
  const close = () => {
    onClose()
  }

  return (
    <div ref={elementRef} className={styles['conversation-protection-message']}>
      <div className={styles['conversation-protection-message__lock']}>
        <Icon icon="lock" className={styles['conversation-protection-message__lock--icon']} />
      </div>
      <div className={styles['conversation-protection-message__text']}>
        We've got your back. Communicate through Outguided so you can utilize our support staff and be covered by our no
        show guarantee.
      </div>
      <Icon onClick={close} icon="close" className={styles['conversation-protection-message__close']} />
    </div>
  )
}
