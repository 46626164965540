import React, { useRef, useState } from 'react'
import { Collapse } from '@material-ui/core'
import ArrowIcon from 'assets/img/arrow-down.svg'
import styles from './FAQItem.module.scss'
// import PlusIcon from '*.svg'

interface IFAQItem {
  faq: {
    question: string
    answer: string
  }
  id: number
}

export const FAQItem: React.FC<IFAQItem> = ({ faq, id }) => {
  const [showAnswer, setShowAnswer] = useState(false)
  const elEef = useRef<HTMLDivElement>(null)

  let calculatedHeight
  if (elEef.current) {
    calculatedHeight = elEef.current.scrollHeight
  } else {
    calculatedHeight = '0px'
  }

  return (
    <>
      <dt className={styles['faq-item__title']}>
        <button
          aria-expanded={showAnswer ? 'true' : 'false'}
          aria-controls={'faq' + id}
          onClick={() => setShowAnswer((prev) => !prev)}
        >
          <span>{faq.question}</span>
          <img
            src={ArrowIcon}
            alt=""
            className={styles['faq-item__icon']}
            style={showAnswer ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
          />
        </button>
      </dt>
      <dd
        className={styles['faq-item__content']}
        ref={elEef}
        id={'faq' + id}
        style={showAnswer ? { height: calculatedHeight } : { height: '0px' }}
      >
        <div className={styles['faq-item__inner']} dangerouslySetInnerHTML={{ __html: faq.answer }} />
      </dd>
    </>
  )
}
