import { useUser } from 'hooks/useUser'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { Icon } from 'components/Icon/Icon'
import { Avatar } from 'components-new/common/Avatar/Avatar'
import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './ConversationHeader.module.scss'
import { IConversationHeader } from './ConversationHeader.types'

export const ConversationHeader: FC<IConversationHeader> = (props: IConversationHeader) => {
  const { conversation, onBackClick } = props
  const { user } = useUser()
  const recipient = conversation.getFirstRecipient(user!.id)!
  const { isMobile } = useBreakpoints()

  const textSectionClasses = classNames({
    [styles['conversation-header__text-section']]: true,
    [styles['conversation-header__text-section--center']]: true,
  })

  // TODO - change when response rate is implemented
  // const textSectionClasses = classNames({
  //   [styles['conversation-header__text-section']]: true,
  //   [styles['conversation-header__text-section--center']]: !recipient.isHost,
  // })

  return (
    <div className={styles['conversation-header']}>
      {isMobile && (
        <Icon
          // TODO: Navigate through history hook
          onClick={() => onBackClick?.()}
          className={styles['conversation-header__icon']}
          icon={'chevron_left'}
        />
      )}
      <Avatar image={recipient.image} text={recipient.initials} size="medium" />
      <div className={textSectionClasses}>
        <div className={styles['conversation-header__recipient']}>{recipient.displayName}</div>
        {/* {recipient.isHost && (*/}
        {/*  <div className={styles['conversation-header__response-rate']}>*/}
        {/*    /!* TODO: Hardcoded, to be discussed*!/*/}
        {/*    Response rate: <span className={styles['conversation-header__response-rate--number']}>98%</span>*/}
        {/*  </div>*/}
        {/* )}*/}
      </div>
    </div>
  )
}
