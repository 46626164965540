import { Image as ImageComponent } from 'components-new/common/Image/Image'
import Image from 'services/shared/models/Image'
import Icon from 'components/Icon/Icon'
import { Button } from 'components-new/common/Button/Button'
import { shareTwitter } from 'utils/sharer'
import { shareFacebook } from 'utils/sharer'
import { useBreakpoints } from 'hooks/useBreakpoints'
import React from 'react'
import { blogLink } from 'global/routes'
import { Wrapper } from '../Wrapper/Wrapper'
import styles from './PostContainer.module.scss'

interface IPost {
  publishedAt: string
  title: string
  content: string
  image: Image | null
}

export const PostContainer: React.FC<IPost> = ({ publishedAt, title, content, image }) => {
  const { isDesktop } = useBreakpoints()

  return (
    <Wrapper className={styles['post-container']}>
      {!isDesktop && (
        <div className={styles['post-container__controls']}>
          <Button theme="back" size="inline" className={styles['post-container__back-button']} to={blogLink()}>
            <Icon className={styles['post-container__back-icon']} icon="fat_arrow" />
            Back
          </Button>
          <span className={styles['post-container__share-text']}>Share</span>
          <div className={styles['post-container__share-links']}>
            <button onClick={shareTwitter} className={styles['post-container__share-link']}>
              <Icon className={styles['post-container__share-icon']} icon="twitter" />
            </button>
            <button onClick={shareFacebook} className={styles['post-container__share-link']}>
              <Icon className={styles['post-container__share-icon']} icon="facebook" />
            </button>
          </div>
        </div>
      )}
      <div className={styles['post-container__image-container']}>
        {image && (
          <ImageComponent
            loading="lazy"
            alt={image.alternativeText}
            src={image.getLargeSize()}
            srcSet={image.getSrcSet()}
            sizes={['100vw', '(min-width: 1265px) 1680px']}
            wrapperClassName={styles['post-container__image-wrapper']}
            className={styles['post-container__image']}
          />
        )}
        <div className={styles['post-container__info']}>
          {publishedAt && <span className={styles['post-container__date']}>{publishedAt}</span>}
          {title && <h1 className={styles['post-container__title']}>{title}</h1>}
        </div>
      </div>
      <div className={styles['post-container__article']}>
        {isDesktop && (
          <div className={styles['post-container__controls']}>
            <Button theme="back" size="inline" className={styles['post-container__back-button']} to={blogLink()}>
              <Icon className={styles['post-container__back-icon']} icon="fat_arrow" />
              Back
            </Button>
            <span className={styles['post-container__share-text']}>Share</span>
            <div className={styles['post-container__share-links']}>
              <button onClick={shareTwitter} className={styles['post-container__share-link']}>
                <Icon className={styles['post-container__share-icon']} icon="twitter" />
              </button>
              <button onClick={shareFacebook} className={styles['post-container__share-link']}>
                <Icon className={styles['post-container__share-icon']} icon="facebook" />
              </button>
            </div>
          </div>
        )}
        {content && (
          <main className={styles['post-container__main']} dangerouslySetInnerHTML={{ __html: content }}></main>
        )}
      </div>
    </Wrapper>
  )
}
