import { BookingActivities } from 'redux/Booking/actions'

const initialState = {
  guests: 1,
  eventDetails: null,
  marketingDetails: null,
  note: '',
  discount: null,
}

export function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case BookingActivities.SET_GUESTS:
      return {
        ...state,
        guests: action.payload,
      }

    case BookingActivities.SET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: action.payload,
      }

    case BookingActivities.SET_MARKETING_DETAILS:
      return {
        ...state,
        marketingDetails: action.payload,
      }

    case BookingActivities.SET_NOTE:
      return {
        ...state,
        note: action.payload,
      }

    case BookingActivities.SET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      }

    default:
      return state
  }
}
