import HostInfoPublic, { HostInfoPublicInput } from './HostInfoPublic'

export interface HostInfoOutput {
  id: number
  display_name: string
  languages: string
  description: string
  guiding_since: string
  images: (string | number)[]
  image: string | number | null
}

export type HostInfoInput = HostInfoPublicInput & {
  status?: 'active' | 'pending' | 'inactive'
  stripe_id?: string
  stripe_auth_token?: string
  stripe_auth_link?: string
}
class HostInfo extends HostInfoPublic {
  status: 'active' | 'pending' | 'inactive'
  stripeId?: string
  stripeAuthToken?: string
  stripeAuthLink?: string

  constructor(hostInfo: HostInfoInput) {
    super(hostInfo)
    this.status = hostInfo?.status || 'inactive'
    this.stripeId = hostInfo?.stripe_id
    this.stripeAuthToken = hostInfo?.stripe_auth_token
    this.stripeAuthLink = hostInfo?.stripe_auth_link
  }

  static fromArray(input: HostInfoInput[] | null): HostInfo[] {
    return input ? input.map((i) => new HostInfo(i)) : []
  }

  static toOutput(data: HostInfo): HostInfoOutput {
    return {
      id: data.id,
      display_name: data.displayName,
      languages: data.languages.join(','),
      description: data.description,
      guiding_since: data.guidingSince,
      images: data.images.map((i) => i.id),
      image: data.image ? data.image.id : null,
    }
  }
}

export default HostInfo
