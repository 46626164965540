import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import Modal from '@material-ui/core/Modal'
import { useLockedBody } from 'hooks/useLockedBody'
import Icon from 'components/Icon/Icon'
import { Loader } from 'components-new/common/Loader/Loader'
import styles from './CustomModal.module.scss'
import { ICustomModal } from './CustomModal.types'

export const CustomModal: React.FC<ICustomModal> = ({
  className,
  children,
  theme = 'default',
  containerStyles,
  containerClassName,
  contentClassName,
  open,
  handleClose,
  title,
  isLoading,
  showClose = false,
  onBackgroundClick,
}) => {
  const generatedClassNames = classNames(className, {
    [styles['custom-modal']]: true,
    [styles[`custom-modal--${theme}`]]: !!theme,
  })
  const { locked, setLocked } = useLockedBody()
  const elRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setLocked(open)
  }, [open])

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (elRef.current && !elRef.current.contains(e.target as Node)) {
      console.log('background click')
      onBackgroundClick?.()
    }
  }

  return (
    <Modal className={generatedClassNames} open={open} onClose={handleClose} onClick={onContainerClick}>
      <>
        <div
          className={classNames(styles['custom-modal__container'], { [containerClassName!]: !!containerClassName })}
          style={containerStyles}
        >
          <div
            ref={elRef}
            className={classNames(styles['custom-modal__content'], { [contentClassName!]: !!contentClassName })}
          >
            {isLoading ? (
              <Loader className={styles['custom-modal__loader']} />
            ) : (
              <>
                {showClose && (
                  <div className={styles['custom-modal__close-button']}>
                    <Icon className={styles['custom-modal__icon']} icon="close" onClick={handleClose} />
                  </div>
                )}
                {title && <div className={styles['custom-modal__header']}>{title}</div>}
                {children}
              </>
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}
