import React, { forwardRef } from 'react'
import classNames from 'classnames'
import patternForLightTheme from 'assets/img/outguided/pattern_primary.png'
import patternForDarkTheme from 'assets/img/outguided/pattern_white.png'
import * as CSS from 'csstype'
import styles from './Wrapper.module.scss'
import { IWrapper } from './Wrapper.types'

export const Wrapper = forwardRef<HTMLDivElement, IWrapper>(
  (
    {
      className,
      children,
      containerClasses,
      containerStyles,
      contentClasses,
      contentStyles,
      paddingType = 'normal',
      tagName: Tag = 'section',
      backgroundImage,
      showOverlay = false,
      theme = 'white',
      id,
    },
    ref
  ) => {
    const generatedContainerClasses = classNames(className, styles.wrapper, containerClasses, {
      [styles[`wrapper--${theme}`]]: !!theme,
    })

    const generatedContentClasses = classNames(styles.wrapper__content, contentClasses, {
      [styles[`wrapper--${paddingType}`]]: !!paddingType,
    })

    const generateOverlay = () => {
      let src

      switch (theme) {
        case 'dark':
          src = patternForDarkTheme
          break
        case 'light':
        case 'white':
          src = patternForLightTheme
          break
        case 'none':
        default:
          return null
      }

      return (
        <figure className={styles.wrapper__overlay}>
          <img className="img-fill" src={src} alt="" />
        </figure>
      )
    }

    const backgroundStyles: CSS.Properties<string> = {
      backgroundImage: undefined,
      backgroundSize: undefined,
    }

    if (backgroundImage) {
      backgroundStyles.backgroundImage = `url(${backgroundImage})`
      backgroundStyles.backgroundSize = 'cover'
      backgroundStyles.backgroundPosition = 'center center'
    }

    return (
      <Tag className={generatedContainerClasses} style={{ ...containerStyles, ...backgroundStyles }} ref={ref} id={id}>
        <div className={generatedContentClasses} style={{ ...contentStyles }}>
          {showOverlay ? generateOverlay() : null}
          {children}
        </div>
      </Tag>
    )
  }
)
