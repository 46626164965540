import React, { ChangeEvent, FC, useMemo, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDate, setGuests, setTime } from 'redux/Checkout/actions'
import { AppDispatch } from 'store'
import { useCheckoutContext } from 'containers-new/CheckoutContainer/CheckoutContext'
import { Select } from 'components-new/forms/Select/Select'
import { useHistory } from 'react-router'
import { getDurationString, formatDuration } from 'utils/datesHelpers'
import { Input } from 'components-new/forms/Input/Input'

import DatePicker from 'components-new/forms/DatePicker/DatePicker'
import { checkoutLink } from 'global/routes'
import { FormLabel } from 'components-new/forms/FormLabel/FormLabel'

import classNames from 'classnames'
import { AppDate } from 'services-new/shared/date'
import { useSearchContext } from 'containers-new/SearchContainer/SearchContext'
import { EventDetailsSelectProps } from './EventDetailsSelect.types'

import styles from './EventDetailsSelect.module.scss'

export const EventDetailsSelect: FC<EventDetailsSelectProps> = (props) => {
  const { children, standAlone, className } = props
  const {
    state,
    checkout,
    isValidating,
    schedule: { fetch: calculateAvailableDates, dates: availableDates, isLoading: isCalculatingAvailability },
  } = useCheckoutContext()
  const { globalSearchParams } = useSearchContext()

  const dispatch = useDispatch<AppDispatch>()
  const { guests, date, time } = state
  const { minimumCapacity, maximumCapacity } = checkout
  const history = useHistory()
  const [selectedMonth, setSelectedMonth] = useState<Date>()
  const [selectedTime, setSelectedTime] = useState<number | null>(time)
  const [selectedDay, setSelectedDay] = useState<AppDate | null>(
    date?.clone().addTimezoneOffset() || globalSearchParams.minDate || null
  )

  const currentAvailableDate = useMemo(() => {
    if (selectedDay) {
      return availableDates.find((date) => date.date.toDateString() === selectedDay.toDateString())
    } else if (availableDates?.[0]) {
      setSelectedDay(availableDates[0].date)
    }
  }, [selectedDay, availableDates])

  const options = useMemo(() => {
    const arr = []
    for (let i = minimumCapacity; i <= maximumCapacity; i += 1) {
      arr.push(i)
    }
    return arr
  }, [minimumCapacity, maximumCapacity])

  const handleMonthChange = useCallback(
    (newMonth: Date) => {
      setSelectedMonth(newMonth)
    },
    [setSelectedMonth]
  )

  // initial month setup
  useLayoutEffect(() => {
    if (standAlone) {
      handleMonthChange(globalSearchParams.minDate ? globalSearchParams.minDate : new Date())
    } else {
      handleMonthChange(date ? date : new Date())
    }
  }, [])

  useEffect(() => {
    if (selectedMonth) {
      calculateAvailableDates(selectedMonth)
    }
  }, [selectedMonth])

  useEffect(() => {
    if (!currentAvailableDate || (selectedTime !== null && !currentAvailableDate.time.includes(selectedTime))) {
      setSelectedTime(null)
    } else if (selectedTime === null && currentAvailableDate.time.length) {
      setSelectedTime(currentAvailableDate.time[0])
    }
  }, [currentAvailableDate, selectedTime])

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (currentAvailableDate) {
      setSelectedTime(parseInt(e.target.value))
    }
  }

  useEffect(() => {
    if (currentAvailableDate && state.marketingPage) {
      if (!standAlone) {
        history.push(
          checkoutLink(
            state.marketingPage.id,
            currentAvailableDate.date.clone().removeTimezoneOffset().toISOString(),
            selectedTime
          ),
          {
            scroll: false,
          }
        )
      }
      dispatch(setDate(currentAvailableDate.date.clone().removeTimezoneOffset()))
      if (selectedTime !== null && currentAvailableDate.time.includes(selectedTime)) {
        dispatch(setTime(selectedTime))
      }
    }
  }, [currentAvailableDate, selectedTime])

  const handleDayChange = (day: Date): void => {
    calculateAvailableDates(day)
    setSelectedDay(new AppDate(day))
  }

  const containerClasses = classNames({
    [styles['event-details']]: !!styles['event-details'],
    [className!]: !!className,
  })

  return (
    <div className={containerClasses}>
      <div className={`${styles['event-details__column']} ${styles['event-details__column--calendar']}`}>
        <DatePicker
          availableDays={availableDates.map((d) => d.date)}
          minDate={new AppDate()}
          selected={selectedDay}
          onChange={handleDayChange}
          onMonthChange={handleMonthChange}
          disabled={isCalculatingAvailability}
        />
      </div>
      <div className={`${styles['event-details__column']} ${styles['event-details__column--details']}`}>
        {!selectedDay && !state.date && !isValidating && (
          <>
            <h2 className={styles['event-details__date']}>Please select date</h2>
            <p>Add your travel dates for exact pricing.</p>
            <p>This trip has a duration of {getDurationString(formatDuration(state.marketingPage?.duration))}.</p>
          </>
        )}
        {selectedDay && (!currentAvailableDate || !currentAvailableDate?.time?.length) && (
          <>
            <h2 className={styles['event-details__date']}>No available events</h2>
            <p>Please select another date</p>
          </>
        )}
        {currentAvailableDate && !!currentAvailableDate.time.length && (
          <>
            <FormLabel className={styles['event-details__label']}>Your trip date:</FormLabel>
            <h2 className={styles['event-details__date']}>{currentAvailableDate.date.getLocalDateString()}</h2>
            <FormLabel className={styles['event-details__label']}>Your trip start time:</FormLabel>
            {currentAvailableDate.time.map((timeSlot) => (
              <Input
                className={styles['event-details__input']}
                type="radio"
                name="date"
                onChange={handleTimeChange}
                key={timeSlot}
                value={timeSlot}
                label={AppDate.getDateTimeFromMinutesAfterMidnight(
                  currentAvailableDate.date,
                  timeSlot
                ).getLocalTimeString()}
                checked={selectedTime === timeSlot}
              />
            ))}
            <Select
              options={options}
              type="number"
              label="Number of guests"
              placeholder="Select number of guests"
              value={guests}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                dispatch(setGuests(parseInt(e.target.value)))
              }}
            />
          </>
        )}
        <div className={styles['event-details__footer']}>{children}</div>
      </div>
    </div>
  )
}
