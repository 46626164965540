import { Footer } from 'components-new/layout/Footer/Footer'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { HomeContainer } from 'containers-new/HomeContainer/HomeContainer'
import React from 'react'
import { IMainPage } from './MainPage.types'
import styles from './MainPage.module.scss'

export const MainPage: React.FC<IMainPage> = () => {
  return (
    <div className={styles['main-page']}>
      <Navbar withTransparency withFixedPosition withOffset />
      <HomeContainer />
      <Footer img={footerImage} />
    </div>
  )
}
