import React from 'react'
import userIconFilled from 'assets/icons/icon_user_filled.svg'
import { Image } from 'components-new/common/Image/Image'
import { Button } from 'components-new/common/Button/Button'
import classNames from 'classnames'
import { guideLink } from 'global/routes'
import { SendMessageButton } from '../SendMessageButton/SendMessageButton'
import { IExperienceHost } from './ExperienceHost.types'
import styles from './ExperienceHost.module.scss'

export const ExperienceHost: React.FC<IExperienceHost> = ({ host, className, experience }) => {
  const { displayName, hostInfo, slug } = host

  const image = hostInfo?.image

  const classes = classNames({
    [className!]: !!className,
    [styles['experience-host']]: !!styles['experience-host'],
  })

  const CtaButton = () => (
    <Button theme="plain" size="wide" className={styles['experience-host__button']} to={guideLink(slug)}>
      View Profile
    </Button>
  )

  const imageClasses = classNames({
    [styles['experience-host__image']]: !!styles['experience-host__image'],
    [styles['experience-host__image--not-available']]: !image,
  })

  return (
    <div className={classes}>
      <>
        <Image
          wrapperClassName={imageClasses}
          src={image ? image.getSmallSize() : userIconFilled}
          theme="round"
          alt={`Your guide: ${displayName}`}
        />

        <div className={styles['experience-host__details']}>
          <h3 className={styles['experience-host__title']}>Your guide</h3>
          <h4 className={styles['experience-host__name']}>{displayName}</h4>
          <div className={styles['experience-host__buttons']}>
            <CtaButton />
            <SendMessageButton
              theme="plain"
              size="wide"
              experience={experience}
              className={styles['experience-host__button']}
            />
          </div>
        </div>

        {hostInfo && hostInfo.description && (
          <div className={styles['experience-host__description']}>{hostInfo.description}</div>
        )}
        <CtaButton />
        <SendMessageButton
          theme="plain"
          size="wide"
          experience={experience}
          className={styles['experience-host__button']}
        />
      </>
    </div>
  )
}
