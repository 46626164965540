export const SearchFormActions = {
  SET_DATE_RANGE: '@searchForm/setDateRange',
  SET_PRICE_RANGE: '@searchForm/setPriceRange',
  SET_GUESTS: '@searchForm/setGuests',
  SET_ACTIVITIES: '@searchForm/setActivities',
  SET_LOCATION: '@searchForm/setLocation',
  SET_LOCATIONS: '@searchForm/setLocations',
  SET_START_DATE: '@searchForm/startDate',
  SET_END_DATE: '@searchForm/endDate',
  SET_AGGREGATIONS: '@searchForm/setAggregations',
  SET_PROPERTIES: '@searchForm/setProperties',
  SET_COORDINATES: '@searchForm/setCoordinates',
}

export function setDateRange(value) {
  return {
    type: SearchFormActions.SET_DATE_RANGE,
    payload: value,
  }
}

export function setPriceRange(value) {
  return {
    type: SearchFormActions.SET_PRICE_RANGE,
    payload: value,
  }
}

export function setGuests(value) {
  return {
    type: SearchFormActions.SET_GUESTS,
    payload: value,
  }
}

export function setActivities(value) {
  return {
    type: SearchFormActions.SET_ACTIVITIES,
    payload: value,
  }
}

export function setLocation(value) {
  return {
    type: SearchFormActions.SET_LOCATION,
    payload: value,
  }
}

export function setLocations(value) {
  return {
    type: SearchFormActions.SET_LOCATIONS,
    payload: value,
  }
}

export function setStartDate(value) {
  return {
    type: SearchFormActions.SET_START_DATE,
    payload: value,
  }
}

export function setEndDate(value) {
  return {
    type: SearchFormActions.SET_END_DATE,
    payload: value,
  }
}

export function setAggregations(value) {
  return {
    type: SearchFormActions.SET_AGGREGATIONS,
    payload: value,
  }
}

export function setProperties(value) {
  return {
    type: SearchFormActions.SET_PROPERTIES,
    payload: value,
  }
}

export function setCoordinates(value) {
  return {
    type: SearchFormActions.SET_COORDINATES,
    payload: value,
  }
}
