import React, { forwardRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import classNames from 'classnames'
import { Image } from 'components-new/common/Image/Image'
import { experienceLink } from 'global/routes'
import { EventDetails } from 'components-new/common/EventDetails/EventDetails'
import { Carousel } from 'components-new/common/Carousel/Carousel'
import styles from './EventCard.module.scss'
import { EventCardProps } from './EventCard.types'

export const EventCard = forwardRef<HTMLDivElement, EventCardProps>((props, ref) => {
  const { event, isActive = false, layout = 'list', className, carousel = true, ...other } = props
  const history = useHistory()
  const { images, image, title, id, slug } = event

  const containerClasses = classNames({
    [className!]: !!className,
    [styles['event-card']]: !!styles['event-card'],
    [styles[`event-card--${layout}`]]: !!layout,
    [styles['event-card--active']]: !!isActive,
  })

  const onImageClick = () => {
    history.push(experienceLink(slug))
  }

  const sizes = [
    '100vw',
    '(min-width: 585px) 640px',
    '(min-width: 1265px) 640px',
    '(min-width: 945px) 640px',
  ] as string[]

  const Gallery = useMemo(() => {
    return carousel ? (
      <Carousel className={styles['event-card__carousel']}>
        {images.map((image) => (
          <Image src={image.getSmallSize()} key={image.id} alt={title} ratio={3 / 4} loading="lazy" />
        ))}
      </Carousel>
    ) : image ? (
      <Image src={image.getSmallSize()} alt={title} ratio={3 / 4} loading="lazy" />
    ) : null
  }, [id, carousel])

  return useMemo(
    () => (
      <div ref={ref} id={'' + id} className={containerClasses} {...other}>
        <Link to={experienceLink(slug)} className={styles['event-card__overlay']} />
        <div className={styles['event-card__image']} onClick={onImageClick}>
          {Gallery}
        </div>
        <EventDetails className={styles['event-card__description']} event={event} />
      </div>
    ),
    [isActive, id]
  )
})
