import { HeaderActions } from './actions'

const initialState = {
  fadeOn: false,
  locations: [],
}

export function headerReducer(state = initialState, action) {
  switch (action.type) {
    case HeaderActions.SET_FADE:
      return {
        ...state,
        fadeOn: action.payload,
      }

    case HeaderActions.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      }

    default:
      return state
  }
}
