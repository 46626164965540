import React from 'react'
import { SectionWrapper } from 'containers-new/SectionWrapper/SectionWrapper'
import { IncludedIcon } from '../IncludedIcon/IncludedIcon'
import { IIconsSection } from './IconsSection.types'
import styles from './IconsSection.module.scss'

export const IconsSection: React.FC<IIconsSection> = ({ icons, title }) => {
  return icons.length > 0 ? (
    <SectionWrapper title={title}>
      <div className={styles['icons-section']}>
        {icons.map((obj) => (
          <IncludedIcon
            key={obj.title}
            title={obj.title}
            description={obj.description}
            className={styles['icons-section__icon']}
          />
        ))}
      </div>
    </SectionWrapper>
  ) : null
}
