import React from 'react'
import { ITextSection } from './TextSection.types'

export const TextSection: React.FC<ITextSection> = ({ text }) => {
  return text ? (
    <p>
      {text.split('\n').map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={key}>
          {item}
          <br />
        </span>
      ))}
    </p>
  ) : null
}
