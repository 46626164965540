// LocalStorageService.js
const LocalStorageService = (function () {
  let _service

  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken(jwtToken) {
    localStorage.setItem('access_token', jwtToken)
  }

  function _getJWTToken() {
    return localStorage.getItem('access_token')
  }

  function _clearToken() {
    localStorage.removeItem('access_token')
  }

  function _setHostFlag(isHost) {
    localStorage.setItem('is_host', isHost)
  }

  function _getHostFlag() {
    return localStorage.getItem('is_host') === 'true'
  }

  function _setPhoneModalFlag(value) {
    localStorage.setItem('phone_modal', value)
  }

  function _getPhoneModalFlag() {
    return localStorage.getItem('phone_modal') === 'true'
  }

  function _clearLocalStorage() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('is_host')
    localStorage.removeItem('phone_modal')
  }

  function _setPaymentProtectionCloseFlag() {
    localStorage.setItem('payment_protection_close', 'true')
  }

  function _getPaymentProtectionCloseFlag() {
    return localStorage.getItem('payment_protection_close') === 'true'
  }

  return {
    getService: _getService,
    setRedirect: (value) => {
      localStorage.setItem('redirect', value)
    },
    getRedirect: () => {
      return localStorage.getItem('redirect')
    },
    setToken: _setToken,
    getJWTToken: _getJWTToken,
    clearToken: _clearToken,
    setHostFlag: _setHostFlag,
    getHostFlag: _getHostFlag,
    setPhoneModalFlag: _setPhoneModalFlag,
    getPhoneModalFlag: _getPhoneModalFlag,
    clearLocalStorage: _clearLocalStorage,
    setPaymentProtectionCloseFlag: _setPaymentProtectionCloseFlag,
    getPaymentProtectionCloseFlag: _getPaymentProtectionCloseFlag,
  }
})()

export default LocalStorageService
