// TODO: REWRITE ME
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.scss'

const AutocompleteSearchInput = ({
  label,
  value,
  placeholder,
  isLoading,
  options = [],
  onSelect,
  autocomplete,
  onInputChange = () => {},
}) => {
  const ref = useRef({})
  const input = useRef(null)
  const [showList, setShowList] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const hideDropDown = () => {
    setShowList(false)
  }

  const showDropDown = () => {
    setShowList(true)
    // if(input.current){
    //     input.current.focus()
    // }
  }

  const onOptionSelect = (option) => {
    onSelect(option)
    hideDropDown()
    setInputValue('')
  }

  const onInput = (value) => {
    setInputValue(value)
    onInputChange(value)
  }

  const selectedLabel = useMemo(() => {
    let label = ''
    if (!value) {
      return label
    }
    if (!value.label) {
      const selected = options.find((option) => option.value && option.value === value)
      if (selected && selected.label) {
        label = selected.label
      }
    }
    if (!label) {
      label = value && value.label ? value.label : value ? value : ''
    }
    return label
  }, [value, options])

  const containerClasses = classNames({
    [styles.locationFilterBox]: true,
    [styles.locationFilterBoxDisabled]: !autocomplete,
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        hideDropDown()
      }
    }
    if (value) {
      const selected = options.find((option) => option.value && option.value === value)
      onOptionSelect(selected)
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const InputIcon = useMemo(
    () => (autocomplete ? null : <Icon icon="drop_arrow" containerClassName={styles.icon}></Icon>),
    [autocomplete]
  )

  return (
    <div className={containerClasses} ref={ref}>
      <label className={styles.locationLabel}>{label}</label>
      <div className={styles.inputWrapper}>
        {!(autocomplete && showList) && <div className={styles.inputPlaceholder}>{selectedLabel || placeholder}</div>}
        <button className={styles.inputTrigger} onClick={() => showDropDown()} />
        {autocomplete && showList && (
          <input
            autoFocus
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            type="text"
            ref={input}
            className={styles.input}
            value={inputValue}
            onChange={(e) => onInput(e.target.value)}
          />
        )}
        {InputIcon}
      </div>
      <div className={styles.autocompleteListContainer}>
        {isLoading && <div className={styles.autocompleteList}>Loading...</div>}
        {showList && !isLoading && options.length > 0 && (
          <ul className={styles.autocompleteList}>
            {options.map((opt, index) => (
              <li key={index} onClick={() => onOptionSelect(opt)}>
                {opt.label ? opt.label : opt}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default AutocompleteSearchInput
