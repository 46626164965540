import React from 'react'
import { Button } from 'components-new/common/Button/Button'
import { Image } from 'components-new/common/Image/Image'
import styles from './ExperiencePreviewHeader.module.scss'
import { IExperiencePreviewHeader } from './ExperiencePreviewHeader.types'

export const ExperiencePreviewHeader: React.FC<IExperiencePreviewHeader> = ({ experience, onDetailsClick }) => {
  const { title, image, description } = experience
  return (
    <div className={styles['experience-preview-header']}>
      <div className={styles['experience-preview-header__content-wrapper']}>
        {image && <Image src={image?.getSmallSize()} alt="" className={styles['experience-preview-header__image']} />}
        <div>
          <h2 className={styles['experience-preview-header__title']}>{title}</h2>
          {/* {description && <p className={styles['experience-preview-header__description']}>{description}</p>} */}
        </div>
      </div>
      <Button
        theme="clean"
        size="inline"
        onClick={() => onDetailsClick?.()}
        className={styles['experience-preview-header__button']}
      >
        Details
      </Button>
    </div>
  )
}
