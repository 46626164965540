import React, { forwardRef, FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Rating from 'components/Rating/Rating'
import { checkoutLink, experienceLink, HOST_PAGE } from 'global/routes'
import { formattedPrice } from 'utils/numberhelper'
import { matchPath, useLocation } from 'react-router'
import { Button } from '../Button/Button'
import styles from './EventDetails.module.scss'
import { EventDetailsProps } from './EventDetails.types'

export const EventDetails = forwardRef<HTMLDivElement, EventDetailsProps>((props, ref) => {
  const { event, className } = props
  const currentPage = useLocation()

  const match = matchPath(currentPage.pathname, {
    path: HOST_PAGE,
    exact: true,
    strict: false,
  })

  const {
    id,
    rating,
    title,
    location,
    description,
    pricing: { price },
    slug,
    capacityString,
    durationString,
    host,
  } = event

  const classes = classNames({
    [styles['event-details']]: !!styles['event-details'],
    [className!]: !!className,
  })

  const truncate = (string: string = '', length: number = 70) => {
    return string.length > length ? string.slice(0, length) + '…' : string
  }

  return (
    <div className={classes}>
      {location?.address && <div className={styles['event-details__address']}>{location?.address.toString()}</div>}

      <h2 className={styles['event-details__title']}>
        <Link to={experienceLink(slug)}>{title}</Link>
      </h2>
      <p className={styles['event-details__preview']}>{truncate(description)}</p>

      {(capacityString || durationString || (host && host.hostInfo?.displayName)) && (
        <div className={styles['event-details__details']}>
          {capacityString && <span>{capacityString}</span>}
          {durationString && <span>{durationString}</span>}
          {host && host.hostInfo?.displayName && <span>{host.hostInfo?.displayName}</span>}
        </div>
      )}
      <div className={styles['event-details__summary']}>
        <div className={styles['event-details__summary--price']}>
          from <span>{formattedPrice(price, 'USD')}</span>
        </div>
        {rating && (
          <div className={styles['event-details__summary--rating']}>
            <Rating rating={rating} />
          </div>
        )}
      </div>
      {match?.isExact && (
        <Button className={styles['event-details__cta']} to={checkoutLink(id)} theme="secondary" size="small">
          Request Booking
        </Button>
      )}
    </div>
  )
})
