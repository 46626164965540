import { createAxiosAsyncThunk, deleteRequest$, getRequest$, postRequest$, putRequest$ } from 'utils/api'
import { ExternalAccount } from './payments.model'

export const GetExternalAccounts = createAxiosAsyncThunk<{ data: Array<ExternalAccount> }, undefined>(
  'payments/getExternalAccounts',
  async () => await getRequest$('stripe/external_accounts')
)

export const RemoveExternalAccount = createAxiosAsyncThunk<ExternalAccount, string>(
  'payments/removeExternalAccounts',
  async (id) => await deleteRequest$('stripe/external_accounts/' + id)
)

export const UpdateExternalAccount = createAxiosAsyncThunk<ExternalAccount, Partial<ExternalAccount>>(
  'payments/updateExternalAccounts',
  async ({ id, ...data }) => await putRequest$('stripe/external_accounts/' + id, data)
)

export const CreateExternalAccount = createAxiosAsyncThunk<ExternalAccount, { external_account: string }>(
  'payments/createExternalAccounts',
  async (data) => await postRequest$('stripe/external_accounts', data)
)
