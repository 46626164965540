import { Loader } from 'components-new/common/Loader/Loader'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { Footer } from 'components-new/layout/Footer/Footer'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import Seo from 'components/Seo/Seo'
import { BrowseContainer } from 'containers-new/BrowseContainer/BrowseContainer'
import { useRequestAsync } from 'hooks/useRequestAsync'
import { NotFoundPage } from 'pages-new/NotFoundPage/NotFoundPage'
import { getRequest$ } from 'utils/api'
import React, { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router'
import { SearchParams } from 'hooks/useSearch'

export interface BrowseEntry {
  slug: string
  title: string | null
  subtitle: string | null
  country: string | null
  state: string | null
  city: string | null
  activity: string | null
  search_params?: SearchParams
}
export interface BrowseInput {
  match: BrowseEntry | null
  aggregations: BrowseEntry[]
}

export const BrowsePage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const [match, setMatch] = useState<BrowseEntry | null>()
  const [aggregations, setAggregations] = useState<BrowseEntry[]>([])

  const { isLoading, fetch, responseError } = useRequestAsync<{ slug?: string }, BrowseInput>(
    (params) => getRequest$(`explore-pages/browse${params && params.slug ? `/${params.slug}` : ''}`),
    (results) => {
      setMatch(results.data.match)
      setAggregations(results.data.aggregations)
    }
  )

  useLayoutEffect(() => {
    fetch({ slug })
  }, [slug])

  if (isLoading) {
    return <Loader fullScreen />
  }

  if (responseError) {
    return (
      <>
        <NotFoundPage />
        <Seo statusCode={responseError.statusCode} />
      </>
    )
  }

  return (
    <>
      <Seo
        title={match ? match.title : 'Browse all Adventures'}
        description={
          match
            ? match.subtitle
            : 'Browse anything from bonefishing to heliskiing, Outguided has the best guided trips and adventures. Come find your wild with us in the islands'
        }
      />
      <Navbar withTransparency withFixedPosition withOffset />
      <BrowseContainer match={match} aggregations={aggregations} />
      <Footer img={footerImage} />ž
    </>
  )
}
