import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import fishing from 'assets/img/activities/fishing.jpg'
import skiing from 'assets/img/activities/skiing.jpg'
import hunting from 'assets/img/activities/hunting.jpg'
import backcountry from 'assets/img/activities/backcountry.jpg'
import horseriding from 'assets/img/activities/horseriding.jpg'
import overnight from 'assets/img/activities/overnight.jpg'
import surfing from 'assets/img/activities/surfing.jpg'
import firsttimer from 'assets/img/activities/firsttimer.jpg'
import { TripCard } from '../TripCard/TripCard'
import styles from './TripTypes.module.scss'

export const TripTypes: React.FC = () => {
  return (
    <Wrapper>
      <h2 className={styles['trip-types__title']}>Browse by trip type:</h2>
      <div className={styles['trip-types__cards-container']}>
        {/* <TripCard
          src={fishing}
          className={styles['trip-types__fishing-card']}
          text="Flats Fishing"
          activity="fishing"
        ></TripCard> */}
        <TripCard
          src={skiing}
          className={styles['trip-types__skiing-card']}
          text="Heli-Skiing"
          searchParams={{ activities: ['skiing'], properties: [{ name: 'Method', value: ['Heli'] }] }}
        ></TripCard>
        <TripCard
          src={hunting}
          className={styles['trip-types__hunting-card']}
          text="Guided Hunts"
          searchParams={{ activities: ['hunting'] }}
        ></TripCard>
        {/* <TripCard
          src={backcountry}
          className={styles['trip-types__backcountry-card']}
          text="Backcountry Trips"
        ></TripCard> */}
        <TripCard
          src={horseriding}
          className={styles['trip-types__horseriding-card']}
          text="Trips in Montana"
          searchParams={{ location: 'united states,montana,' }}
        ></TripCard>
        {/* <TripCard src={overnight} className={styles['trip-types__overnight-card']} text="Overnight Trips"></TripCard> */}
        <TripCard
          src={firsttimer}
          className={styles['trip-types__firsttimer-card']}
          text="Fishing in Florida"
          searchParams={{ activities: ['fishing'], location: 'united states,florida,' }}
        ></TripCard>
        {/* <TripCard src={surfing} className={styles['trip-types__surfing-card']} text="Learn how to surf!"></TripCard> */}
      </div>
    </Wrapper>
  )
}
