import { HEADER_FORM_DATA, postRequest$, createAxiosAsyncThunk, getCancelTokenSource } from 'utils/api'
import { ImageInput } from 'services/shared/models/Image'

const cancelToken = getCancelTokenSource()

export const uploadFile = async <K>(file: File) => {
  const bodyFormData = new FormData()
  bodyFormData.append('files', file)
  return await postRequest$<K[]>(`upload`, bodyFormData, { ...HEADER_FORM_DATA, cancelToken: cancelToken.token })
}

export const cancelUploadFile = () => {
  cancelToken.cancel()
}

export const UploadImage = createAxiosAsyncThunk<ImageInput[], { file: File; uploaderId?: string }>(
  'upload/uploadImage',
  async ({ file }) => await uploadFile<ImageInput>(file)
)
