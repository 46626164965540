import React, { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { useHistory } from 'react-router-dom'
import { useRequestAsync } from 'hooks/useRequestAsync'
import { getRequest$ } from 'utils/api'
import { accountLink } from 'global/routes'
import { Loader } from 'components-new/common/Loader/Loader'

export const AccountPaymentsVerify: React.FC<{}> = () => {
  const history = useHistory()

  const { isLoading, fetch, responseError } = useRequestAsync<{ slug?: string }, any>(
    () => getRequest$('stripe/connect_url'),
    (results) => {
      if (results.data.url) {
        window.location.replace(results.data.url)
      } else {
        history.push(accountLink('payments'))
      }
    }
  )

  useEffect(() => {
    fetch()
  }, [])

  return isLoading ? <Loader fullScreen /> : null
}
