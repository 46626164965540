import React, { FC, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { useHistory, useParams } from 'react-router'
import { checkoutLink, messageLink } from 'global/routes'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { Wrapper } from '../Wrapper/Wrapper'
import { IAccountMessagesContainer } from './AccountMessagesContainer.types'
import { useMessagesContext } from './MessagesContext'
import styles from './AccountMessagesContainer.module.scss'
import { ConversationsList } from './components/ConversationsList/ConversationsList'
import { Conversation as ConversationComponent } from './components/Conversation/Conversation'
import { ExperiencePreview } from './components/ExperiencePreview/ExperiencePreview'
import { NoConversations } from './components/atoms/NoConversations/NoConversations'

export const AccountMessagesContainer: FC<IAccountMessagesContainer> = (props) => {
  const { conversation } = props
  const { conversations, sendMessage, isLoading } = useMessagesContext()
  const { details } = useParams<{ id: string; details: string }>()
  const { isMobile } = useBreakpoints()
  const history = useHistory()
  const height = use100vh()

  useEffect(() => {
    if (!isMobile && conversations.length > 0 && !conversation) {
      history.push(messageLink(conversations[0].id))
    }
  }, [conversations, isMobile, conversation])

  return (
    <Wrapper
      containerStyles={{ height: height ? height - 128 : '100vh' }}
      className={styles['messages-container']}
      contentClasses={styles['messages-container__inner']}
      paddingType={isMobile ? 'none' : 'normal'}
    >
      {((isMobile && !conversation && conversations.length) || !isMobile) && (
        <ConversationsList conversation={conversation} className={styles['messages-container__list']} />
      )}
      {!conversation && !conversations.length && !isLoading() && (
        <NoConversations className={styles['messages-container__content']} />
      )}
      {conversation && (!isMobile || !details) && (
        <ConversationComponent
          onSend={sendMessage}
          conversation={conversation}
          className={styles['messages-container__content']}
        />
      )}
      {conversation && conversation.experience && ((isMobile && details) || !isMobile) && (
        <ExperiencePreview
          onCloseClick={() => history.push(messageLink(conversation.id))}
          experience={conversation.experience}
          booking={conversation.booking}
          conversation={conversation}
          className={styles['messages-container__details']}
        />
      )}
    </Wrapper>
  )
}
