import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FormLabelProps } from './FormLabel.types'
import styles from './FormLabel.module.scss'

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(function FormLabel(props, ref) {
  const { children, className, ...nativeProps } = props
  const classes = classNames({
    [className!]: !!className,
    [styles['form-label']]: !!styles['form-label'],
  })
  return (
    <label ref={ref} className={classes} {...nativeProps}>
      {children}
    </label>
  )
})
