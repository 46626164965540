import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { browseLink, exploreLink } from 'global/routes'
import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import styles from './BrowseMap.module.scss'
import { BrowseMapProps } from './BrowseMap.types'

export const BrowseMap: React.FC<BrowseMapProps> = ({ items, className, children }) => {
  const linksList = items.map((item) => {
    return (
      <li className={styles['browse-map__link']} key={item.slug}>
        {item.city || !!item.search_params ? (
          <a href={exploreLink({ slug: item.slug })}>
            {item.search_params ? item.title : item.city || item.country || item.state || item.activity}
          </a>
        ) : (
          <Link to={browseLink(item.slug)}>{item.city || item.country || item.state || item.activity}</Link>
        )}
      </li>
    )
  })

  return (
    <Wrapper className={classNames(styles['browse-map__wrapper'], className)}>
      <div className={classNames(styles['browse-map__container'], styles['browse-map__pursuit'])}>
        {children}
        <ul className={styles['browse-map__links-list']}>{linksList}</ul>
      </div>
    </Wrapper>
  )
}
