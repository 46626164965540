import { Button } from 'components-new/common/Button/Button'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UpdateUser } from 'services-new/auth/auth-api'
import { useUser } from 'hooks/useUser'
import { toast } from 'react-toastify'
import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import { PasswordChangeFormInputsValues } from 'components-new/forms/PasswordChangeFormInputs/PasswordChangeFormInputs.types'
import { PasswordChangeFormInputs } from 'components-new/forms/PasswordChangeFormInputs/PasswordChangeFormInputs'
import { FormWrapperProps } from 'components-new/forms/FormWrapper/FormWrapper.types'
import { FormGroup } from 'components-new/forms/FormGroup/FormGroup'
import { isFulfilled } from '@reduxjs/toolkit'
import styles from './PasswordChange.module.scss'

export const PasswordChange: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const updateDetails: FormWrapperProps<PasswordChangeFormInputsValues>['onSubmit'] = async (
    data: any,
    formMethods
  ) => {
    setIsLoading(true)
    const result: any = await dispatch(UpdateUser({ id: user!.id, password: data.password }))
    setIsLoading(false)

    if (isFulfilled(result)) {
      formMethods.reset()
      toast('Password Changed!')
    }
  }

  return (
    <FormWrapper<PasswordChangeFormInputsValues>
      className={styles['password-change__wrapper']}
      onSubmit={updateDetails}
      defaultValues={{
        password: '',
        confirmPassword: '',
      }}
      afterForm={({ formState: errors }) => (
        <Button
          className={styles['password-change__button']}
          loading={isLoading}
          disabled={Object.keys(errors || {}).length > 0}
          type="submit"
          size="small"
        >
          Change
        </Button>
      )}
    >
      {(methods) => (
        <FormGroup>
          <PasswordChangeFormInputs />
        </FormGroup>
      )}
    </FormWrapper>
  )
}
