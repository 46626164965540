import { Button } from 'components-new/common/Button/Button'
import { setDraftConversation } from 'features/conversation/ConversationsSlice'
import { experienceLink, loginLink, messageLink } from 'global/routes'
import { useUser } from 'hooks/useUser'
import React, { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import Conversation from 'services-new/conversation/conversation.model'
import { useMessagesContext } from 'containers-new/AccountMessagesContainer/MessagesContext'
import { SendMessageButtonProps } from './SendMessageButton.types'

export const SendMessageButton = forwardRef<HTMLAnchorElement, SendMessageButtonProps>(
  ({ experience, className, children, ...rest }, ref) => {
    const { user } = useUser()
    const dispatch = useDispatch()
    const history = useHistory()
    const { conversations } = useMessagesContext()
    const { host } = experience

    const showSendMessageButton = !user || (user && host && user.id !== host.id)

    const createConversation = async () => {
      if (!user) {
        history.push(loginLink(experienceLink(experience.slug)))
      } else {
        const currentConversation = conversations.find((c) => c.experience && c.experience.id === experience.id)
        if (currentConversation) {
          history.push(messageLink(currentConversation.id))
        } else {
          const conversation = new Conversation({})
          conversation.experience = experience
          conversation.users = [user, host!]
          dispatch(setDraftConversation(conversation))
          history.push(messageLink('new'))
        }
      }
    }

    return (
      <>
        {showSendMessageButton && (
          <Button {...rest} ref={ref} className={className} onClick={createConversation}>
            {children ? children : 'Send message'}
          </Button>
        )}
      </>
    )
  }
)
