import Booking, { BookingInput } from 'services-new/booking/booking.model'
import Experience, { ExperienceInput } from 'services-new/experience/experience.model'
import { AppDate } from 'services-new/shared/date'
import User from 'services/shared/models/User'
import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'
import Message, { MessageInput } from './message.model'

export interface ConversationInput {
  id: string
  created_at: string
  updated_at: string
  messages?: MessageInput[] | null
  unreadMessagesCount?: number | null
  attributes: {
    last_message: MessageInput | null
    users: Array<UserPublicInput>
    booking: BookingInput
    marketing_page: ExperienceInput
  }
}

export interface ConversationOutput {
  marketing_page: number
}

class Conversation {
  id: string
  createdAt: AppDate
  updatedAt: AppDate
  booking: Booking | null
  experience: Experience | null
  users: Array<UserPublic | User>
  lastMessage: Message | null
  messages?: Message[]
  unreaded: boolean

  constructor(conversation: Partial<ConversationInput>) {
    this.id = conversation.id || 'new'
    this.createdAt = conversation.created_at ? new AppDate(conversation.created_at) : new AppDate()
    this.updatedAt = conversation.updated_at ? new AppDate(conversation.updated_at) : new AppDate()
    this.messages = conversation.messages ? Message.fromArray(conversation.messages) : undefined
    this.unreaded = conversation.unreadMessagesCount === null || !!conversation.unreadMessagesCount
    this.lastMessage = null
    this.booking = null
    this.users = []
    this.experience = null

    if (conversation.attributes) {
      this.users = UserPublic.fromArray(conversation.attributes.users)
      this.experience = conversation.attributes.marketing_page
        ? new Experience(conversation.attributes.marketing_page)
        : null
      this.booking = conversation.attributes.booking ? new Booking(conversation.attributes.booking) : null
      this.lastMessage = conversation.attributes.last_message ? new Message(conversation.attributes.last_message) : null
    }
  }

  getRecipients(id: User['id']): UserPublic[] {
    return this.users.filter((user) => user.id !== id)
  }

  getFirstRecipient(id: User['id']): UserPublic | null {
    return this.users.find((user) => user.id !== id) || null
  }

  getMessageAuthor(message: Message): UserPublic | null {
    return this.users.find((user) => user.id === message.authorId) || null
  }

  static fromArray(input: ConversationInput[] | null): Conversation[] {
    return input ? input.map((i) => new Conversation(i)) : []
  }

  static toOutput(data: Partial<Conversation>): ConversationOutput {
    return {
      marketing_page: data.experience!.id,
    }
  }
}

export default Conversation
