import { SearchParams } from 'hooks/useSearch'
import { AppDate } from 'services-new/shared/date'
import Activity, { ActivityInput } from 'services/shared/models/Activity'
import Address, { AddressInput } from 'services/shared/models/Address'
import Image, { ImageInput } from 'services/shared/models/Image'

export interface ExplorePageInput {
  id: number
  title: string
  subtitle?: string
  description: string
  slug: string
  address: AddressInput
  activity: ActivityInput
  published_at: string
  image: ImageInput
  search_params: SearchParams
  content: Array<ContentRichText | ContentFaqsList | ContentTripsList>
}

export type FaqItem = {
  id: number
  title: string
  description: string
}

export type TripItem = {
  id: number
  trip_id: number
}
interface ContentComponent {
  __component: string
  id: number
}
interface ContentRichText extends ContentComponent {
  __component: 'content.rich-text'
  text: string
}
interface ContentFaqsList extends ContentComponent {
  __component: 'content.faqs-list'
  faqs: Array<FaqItem>
}
interface ContentTripsList extends ContentComponent {
  __component: 'content.trips-list'
  title: string
  trips: Array<TripItem>
}

class ExplorePage {
  id: number
  title: string
  subtitle?: string
  description: string
  slug: string
  address: Address
  content: Array<ContentRichText | ContentFaqsList | ContentTripsList>
  activity: Activity | null
  image: Image | null
  publishedAt: AppDate | null
  searchParams?: SearchParams

  constructor(page: ExplorePageInput) {
    this.id = page.id
    this.content = page.content
    this.title = page.title
    this.subtitle = page.subtitle
    this.image = page.image ? new Image(page.image) : null
    this.description = page.description
    this.publishedAt = page.published_at ? new AppDate(page.published_at) : null
    this.slug = page.slug
    this.address = new Address(page.address)
    this.activity = page.activity ? new Activity(page.activity) : null
    this.searchParams = page.search_params
  }

  static fromArray(input: ExplorePageInput[] | string | null): ExplorePage[] {
    return input && Array.isArray(input) ? input.map((i) => new ExplorePage(i)) : []
  }
}

export default ExplorePage
