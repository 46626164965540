/**
 * Activities
 */
import { ActivityActions } from 'redux/General/activities/actions'

const initialState = {
  values: [],
  loading: false,
}

export function activityReducer(state = initialState, action) {
  switch (action.type) {
    case ActivityActions.GET_ACTIVITIES:
      return {
        ...state,
        loading: true,
      }

    case ActivityActions.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        values: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
