import { bookingAdapter } from 'features/booking/BookingSlice'
import { GetBookings } from 'services-new/booking/booking-api'
import { RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo } from 'react'
import { useUser } from 'hooks/useUser'
import { Wrapper } from '../Wrapper/Wrapper'
import styles from './AccountCalendarContainer.module.scss'
import { AccountCalendar } from './components/AccountCalendar/AccountCalendar'

export const AccountCalendarContainer: React.FC<{}> = () => {
  const state = useSelector((s: RootState) => s.bookings)
  const { user } = useUser()
  const dispatch = useDispatch()
  const { selectAll } = bookingAdapter.getSelectors()

  const bookings = useMemo(() => {
    return selectAll(state).filter((booking) => booking.isHostedByUser(user))
  }, [state])

  useEffect(() => {
    dispatch(GetBookings())
  }, [])

  return (
    <Wrapper containerClasses={styles['adc']}>
      <div className={styles['adc__block']}>
        <h2 className={styles['adc__header']}>Calendar</h2>
        <AccountCalendar bookings={bookings} />
      </div>
    </Wrapper>
  )
}
