import { AppDate } from 'services-new/shared/date'
import { UserInput } from 'services/shared/models/User'

export interface CheckoutInput {
  user: boolean | UserInput
  amount: number
  sub_amount: number
  discount_amount: number
  discount: any
  is_date_valid: boolean
  tax_amount: number
  tax: number
  fees_amount: number
  minimum_capacity: number
  maximum_capacity: number
}

class Checkout {
  user: boolean | UserInput
  amount: number
  subAmount: number
  discountAmount: number
  discount: any
  isDateValid: boolean
  taxAmount: number
  tax: number
  feesAmount: number
  minimumCapacity: number
  maximumCapacity: number

  constructor(checkout: CheckoutInput) {
    this.user = checkout.user
    this.amount = checkout.amount / 100
    this.subAmount = checkout.sub_amount / 100
    this.discountAmount = checkout.discount_amount / 100
    this.feesAmount = checkout.fees_amount / 100
    this.taxAmount = checkout.tax_amount / 100
    this.tax = checkout.tax
    this.discount = checkout.discount
    this.isDateValid = checkout.is_date_valid
    this.minimumCapacity = checkout.minimum_capacity
    this.maximumCapacity = checkout.maximum_capacity
  }
}

export default Checkout
