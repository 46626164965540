import React, { forwardRef, useRef, useState, useEffect } from 'react'
import InputPhone from 'react-phone-number-input'
import { useMergeRefs } from 'hooks/useMergeRefs'
import { FormField } from '../FormField/FormField'
import { PhoneInputProps } from './PhoneInput.types'
import 'react-phone-number-input/style.css'

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(function PhoneInput(props, ref) {
  const {
    children,
    className,
    name,
    placeholder,
    value,
    label,
    error,
    success,
    disabled,
    defaultValue,
    hideControl,
    onChange,
    ...nativeProps
  } = props

  const [initialValue, setInitialValue] = useState(value as string)
  const internalInputRef = useRef<HTMLInputElement>(null)
  const refs = useMergeRefs([internalInputRef, ref])

  const onPhoneChange = (val: string) => {
    if (internalInputRef.current) {
      // @ts-ignore
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor?.(window.HTMLInputElement.prototype, 'value').set
      nativeInputValueSetter?.call(internalInputRef.current, val ? '' + val : '')
      const event = new Event('input', { bubbles: true })
      internalInputRef.current.dispatchEvent(event)
    }
  }

  useEffect(() => {
    if (internalInputRef.current && internalInputRef.current.value) {
      setInitialValue(internalInputRef.current.value)
    }
  }, [internalInputRef])

  return (
    <>
      <FormField
        disabled={disabled}
        className={className}
        label={label}
        error={error}
        success={success}
        hideControl={hideControl}
        append={<input type="hidden" name={name} ref={refs} onInput={onChange} {...nativeProps}></input>}
      >
        <InputPhone
          international={false}
          // countryCallingCodeEditable={false}
          placeholder={placeholder}
          defaultCountry="US"
          defaultValue={defaultValue}
          value={initialValue}
          onChange={onPhoneChange}
        />
      </FormField>
    </>
  )
})
