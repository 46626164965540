import { Input } from 'components-new/forms/Input/Input'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useStripe } from '@stripe/react-stripe-js'
import { CreateTokenBankAccountData } from '@stripe/stripe-js'
import { Select } from 'components-new/forms/Select/Select'
import { useRequestAsync } from 'hooks/useRequestAsync'
import { getRequest$ } from 'utils/api'
import { Loader } from 'components-new/common/Loader/Loader'
import { FormMessage } from 'components-new/forms/FormMessage/FormMessage'
import { BankAccountFormInputsProps } from './BankAccountFormInputs.types'

export const BankAccountFormInputs: FC<BankAccountFormInputsProps> = (props) => {
  const { currency } = props
  const {
    register,
    watch,
    setError,
    setValue,
    getFieldState,
    formState: { errors },
  } = useFormContext()
  const timeoutRef = useRef<NodeJS.Timeout>()
  const stripe = useStripe()
  const [tokenError, setTokenError] = useState<string | undefined>()
  const [countries, setCountries] = useState<{ name: string; value: string }[]>([])
  const selectedCountry = watch('country')

  const { isLoading: isLoadingCountries, fetch: fetchCountries } = useRequestAsync<{ slug?: string }, any>(
    () => getRequest$('stripe/countries'),
    (results) => {
      setCountries(results.data ? results.data.map((item: any) => ({ name: item.name, value: item.code })) : [])
    }
  )

  useEffect(() => {
    fetchCountries()
  }, [])

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== 'token') {
        setValue('token', '', {
          shouldValidate: false,
        })

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(async () => {
          const fieldsState = {
            holderNameState: getFieldState('account_holder_name'),
            routingNumberState: getFieldState('routing_number'),
            accountNumberState: getFieldState('account_number'),
          }
          console.log(fieldsState, value)
          if (
            Object.values(fieldsState).every((s) => !s.error) &&
            Object.values(fieldsState).filter((s) => s.isDirty).length >= 3 // skip country, token and holder type
          ) {
            setTokenError('')
            setValue('token', '', {
              shouldValidate: true,
            })
            const { account_holder_name, account_holder_type, country, routing_number, account_number } = value

            const { token, error } = await stripe!.createToken('bank_account', {
              account_holder_name,
              account_holder_type,
              country,
              routing_number,
              account_number,
              currency,
            } as CreateTokenBankAccountData)
            if (error) {
              switch (error.param) {
                case 'bank_account[routing_number]':
                  setError('routing_number', { type: 'custom', message: error.message })
                  break
                case 'bank_account[account_number]':
                  setError('account_number', { type: 'custom', message: error.message })
                  break
                default:
                  setTokenError(error.message)
                  break
              }
            } else if (token?.id) {
              setValue('token', token.id, {
                shouldValidate: true,
              })
            }
          }
        }, 50)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, stripe])

  if (isLoadingCountries) {
    return <Loader />
  }

  return (
    <>
      <Input {...register('token', { required: true })} type="hidden"></Input>
      <Input
        {...register('account_holder_name', {
          required: 'This field is required',
        })}
        placeholder="Account Holder Name"
        label="Account Holder Name"
        error={errors?.account_holder_name?.message}
      />
      <Controller
        name="account_holder_type"
        rules={{
          required: 'This field is required',
        }}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            error={error?.message}
            placeholder="Account Holder Type"
            label="Account Holder Type"
            options={[
              { label: 'Company', value: 'company' },
              { label: 'Individual', value: 'individual' },
            ]}
            onChange={(value) => {
              field.onChange(value)
            }}
          />
        )}
      />

      <Select
        {...register('country', {
          required: 'This field is required',
        })}
        placeholder="Country"
        label="Country"
        error={errors?.country?.message}
        options={[{ label: 'United States', value: 'US' }]}
        defaultValue="US"
      />
      {selectedCountry === 'US' && (
        <Input
          {...register('routing_number', {
            required: 'This field is required',
            shouldUnregister: true,

            pattern: {
              value: /^([0-9]\d*)(\.\d+)?$/,
              message: 'Routing number must be a number',
            },
            validate: {
              accountLength: (value) => {
                return ('' + value).length === 9 || 'Rounting number must be 9 numbers length'
              },
            },
          })}
          placeholder="Routing Number"
          label="Routing Number"
          error={errors?.routing_number?.message}
        />
      )}
      <Input
        {...register('account_number', {
          required: 'This field is required',

          pattern: {
            value: /^([0-9]\d*)(\.\d+)?$/,
            message: 'Account number must be a number',
          },
          validate: {
            minAccountLength: (value) => {
              return ('' + value).length >= 6 || 'Account number must be at least 6 digits'
            },
            maxAccountLength: (value) => {
              return ('' + value).length <= 17 || 'Account number must be at most 17 digits'
            },
          },
        })}
        placeholder="Account Number"
        label="Account Number"
        error={errors?.account_number?.message}
      />
      {tokenError && <FormMessage type="error">{tokenError}</FormMessage>}
    </>
  )
}
