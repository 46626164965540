// TODO: REWRITE ME
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from 'components/Icon/Icon'
import styles from './Rating.module.scss'

const Rating = ({ rating, className, size }) => {
  const containerClasses = classNames({
    [styles.container]: true,
    [styles.sm]: size === 'sm',
    [styles.lg]: size === 'lg',
    [className]: true,
  })

  return (
    <div className={containerClasses}>
      <Icon icon="star" />
      <span className="pl-1 pl-sm-0 pl-md-1">{(rating && rating.toFixed(1)) || 'No ratings yet'}</span>
    </div>
  )
}

Rating.propTypes = {
  rating: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

Rating.defaultProps = {
  rating: '',
  size: 'sm',
}

export default Rating
