import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import React, { FC, useRef } from 'react'
import { CardFormProps, CardFormValues } from './CardForm.types'
import { CardFormInputs } from './CardFormInputs/CardFormInputs'

const DEFAULT_VALUES = {
  token: '',
  cardNumber: undefined,
  cardExpiry: undefined,
  cardCvc: undefined,
}
export const CardForm: FC<CardFormProps> = (props) => {
  const { onSubmit = async () => {} } = props
  const formRef = useRef<any>()
  return (
    <FormWrapper<CardFormValues>
      {...props}
      onSubmit={async (...rest) => {
        await onSubmit(...rest)
        formRef.current.reset()
      }}
    >
      {(methods) => <CardFormInputs ref={formRef} currency="USD" />}
    </FormWrapper>
  )
}
