import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Observable, Subscription } from 'rxjs'

export function useRequest<K, T>(
  observable$: (params?: K) => Observable<T>,
  callback: (result: T) => void
): {
  isLoading: isLoading
  fetch: fetch<K>
  responseError: responseError
} {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [responseError, setResponseError] = useState<any>(null)
  const subscription = useRef<Subscription | null>(null)

  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe()
      }
    }
  }, [])

  const fetch = (params?: K) => {
    setIsLoading(true)
    setResponseError(null)
    subscription.current = observable$(params).subscribe(
      (result) => {
        callback(result)
        setIsLoading(false)
      },
      (e: any) => {
        setResponseError(e)
      }
    )
  }

  return {
    isLoading,
    fetch,
    responseError,
  }
}

export type fetch<T> = (params?: T) => void
export type responseError = any
export type isLoading = boolean
