import { createAxiosAsyncThunk, getRequest$, postRequest$, putRequest$, deleteRequest$ } from 'utils/api'
import Experience, { ExperienceInput, ExperienceOutput } from './experience.model'

// EXPERIENCES
export const GetExperiences = createAxiosAsyncThunk<ExperienceInput[], void>(
  'experiences/getExperiences',
  async () => await getRequest$(`experiences`)
)

// EXPERIENCE
export const GetExperience = createAxiosAsyncThunk<ExperienceInput, number>(
  'experiences/getExperience',
  async (id) => await getRequest$(`experiences/${id}`)
)

export const CreateExperience = createAxiosAsyncThunk<ExperienceInput, ExperienceOutput>(
  'experiences/createExperience',
  async (data) => await postRequest$(`experiences`, data)
)

export const UpdateExperience = createAxiosAsyncThunk<ExperienceInput, ExperienceOutput>(
  'experiences/updateExperience',
  async ({ id, ...data }) => await putRequest$(`experiences/${id}`, data)
)

export const DeleteExperience = createAxiosAsyncThunk<ExperienceInput, { id: Experience['id'] }>(
  'experiences/deleteExperience',
  async ({ id }) => await deleteRequest$(`experiences/${id}`)
)
