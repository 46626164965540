import { Input } from 'components-new/forms/Input/Input'
import { TextArea } from 'components-new/forms/TextArea/TextArea'
import { Button } from 'components-new/common/Button/Button'
import { PhotoUpload } from 'components-new/common/PhotoUpload/PhotoUpload'
import Image from 'services/shared/models/Image'
import { Multiselect } from 'components-new/forms/Multiselect/Multiselect'
import { LocationEdit } from 'components-new/common/LocationEdit/LocationEdit'
import { useUser } from 'hooks/useUser'
import classNames from 'classnames'
import { languageOptions } from 'utils/optionHelpers'
import { UpdateUser } from 'services-new/auth/auth-api'
import User from 'services/shared/models/User'
import { useDispatch } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import React, { useMemo, useRef, useState } from 'react'
import { FormLabel } from 'components-new/forms/FormLabel/FormLabel'
import { FloatingComponent } from 'components-new/common/FloatingComponent/FloatingComponent'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import s from './HostDetailsForm.module.scss'

export const HostDetailsForm: React.FC<{ id?: string }> = ({ id }) => {
  const [formDisabledFlag, setFormDisabledFlag] = useState(false)
  const { user, isLoading } = useUser()
  const dispatch = useDispatch()
  const geocoderContainerRef = useRef<HTMLDivElement>(null)
  const [languageSearchValue, setLanguageSearchValue] = useState<string>('')

  const formMethods = useForm<User>({
    defaultValues: useMemo(() => user!, [user!.id]),
  })

  const onLanguagesSearchChange = (value: string) => {
    setLanguageSearchValue(value)
  }

  const languagesOptions = languageOptions.filter((item) => item.toLowerCase().includes(languageSearchValue))

  const updateDetails = async (data: any) => {
    await dispatch(
      UpdateUser(User.toOutput({ ...data, hostInfo: { ...data.hostInfo, images: user!.hostInfo!.images } }))
    )
  }

  const isDisabled = Object.keys(formMethods.formState.errors || {}).length > 0

  return (
    <form id={id} className={s.container} onSubmit={formMethods.handleSubmit(updateDetails)}>
      <FormProvider {...formMethods}>
        <div className={s.columns}>
          <div className={classNames(s['-image'])}>
            <FormLabel>Business Profile Image</FormLabel>
            <Controller
              name="hostInfo.image"
              render={({ field, fieldState: { error } }) => {
                return (
                  <PhotoUpload
                    id="profile_image"
                    ref={field.ref}
                    onUpload={() => setFormDisabledFlag(true)}
                    onUploadFinish={() => setFormDisabledFlag(false)}
                    value={field.value ? [new Image(field.value)] : []}
                    onChange={(images) => {
                      field.onChange(images[0] ?? null)
                    }}
                    max={1}
                    error={(error as any)?.message}
                  />
                )
              }}
            />
          </div>
          <div className={classNames(s['-name'])}>
            <Controller
              name="hostInfo.displayName"
              rules={{ required: 'This field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} error={error?.message} placeholder="Business Name" label="Business Name" />
              )}
            />
            <Controller
              name="hostInfo.languages"
              rules={{ required: 'This field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Multiselect
                  {...field}
                  error={error?.message}
                  theme="default"
                  placeholder="Languages"
                  label="Languages"
                  value={field.value || []}
                  options={languagesOptions}
                  onChange={(value) => {
                    console.log({ value })
                    field.onChange(value)
                  }}
                  searchValue={languageSearchValue}
                  onSearchValueChange={onLanguagesSearchChange}
                />
              )}
            ></Controller>
          </div>
        </div>
        <Controller
          name="hostInfo.description"
          rules={{ required: 'This field is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextArea
              {...field}
              error={error?.message}
              type="textarea"
              placeholder="About Your Guiding Business"
              label="About Your Guiding Business"
            />
          )}
        />
        <Controller
          name="hostInfo.guidingSince"
          rules={{
            minLength: { value: 4, message: 'Year format is invalid' },
            maxLength: { value: 4, message: 'Year format is invalid' },
            min: { value: 1801, message: 'Year needs to be greater than 1800' },
            max: {
              value: new Date().getFullYear(),
              message: 'Year need to be less than or equal to ' + new Date().getFullYear(),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Input {...field} error={error?.message} type="number" placeholder="Guiding Since" label="Guiding Since" />
          )}
        />
        <div className={s.geocoder}>
          <p>Your Business Location</p>
          <div ref={geocoderContainerRef}></div>
        </div>
        <div className={s.mapContainer}>
          <Controller
            name="location"
            rules={{ required: 'This field is required' }}
            render={({ field, fieldState: { error } }) => (
              <LocationEdit
                location={field.value || user!.location}
                onLocationChange={(location) => field.onChange({ ...location, id: user!.location?.id })}
                mapHeight={200}
                error={error?.message}
                geocoderContainerRef={geocoderContainerRef}
              />
            )}
          ></Controller>
        </div>
        <FloatingComponent stickyOnMobile>
          <Wrapper contentClasses={s.actionButtons}>
            <Button loading={formDisabledFlag || isLoading} disabled={isDisabled} type="submit" size="small">
              Save Changes
            </Button>
          </Wrapper>
        </FloatingComponent>
      </FormProvider>
    </form>
  )
}
