export class AppDate extends Date {
  toLocal(): AppDate {
    return new AppDate(
      this.getUTCFullYear(),
      this.getUTCMonth(),
      this.getUTCDate(),
      this.getUTCHours(),
      this.getUTCMinutes(),
      this.getUTCSeconds(),
      this.getUTCMilliseconds()
    )
  }

  getTimezoneOffsetInMilliseconds() {
    return this.getTimezoneOffset() * 60000
  }

  removeTimezoneOffset(): AppDate {
    return this.removeTime(this.getTimezoneOffsetInMilliseconds())
  }

  addTimezoneOffset(): AppDate {
    return this.addTime(this.getTimezoneOffsetInMilliseconds())
  }

  addTime(timeInMilliseconds: number): AppDate {
    this.setTime(this.getTime() + timeInMilliseconds)
    return this
  }

  removeTime(timeInMilliseconds: number): AppDate {
    this.setTime(this.getTime() - timeInMilliseconds)
    return this
  }

  clone(): AppDate {
    return new AppDate(this.getTime())
  }

  getEndOfMonth(): AppDate {
    this.setMonth(this.getMonth() + 1)
    this.setDate(1)
    this.setHours(0, 0, -1, 0)
    return this
  }

  getStartOfMonth(): AppDate {
    this.setDate(this.getMonth() === new Date().getMonth() ? new Date().getDate() : 1)
    this.setHours(0, 0, 0, 0)
    return this
  }

  getStartOfDay(): AppDate {
    this.setHours(0, 0, 0, 0)
    return this
  }

  getLocalDateString(): string {
    return new Intl.DateTimeFormat(undefined, { weekday: 'short', day: 'numeric', month: 'long' })
      .format(this)
      .toString()
  }
  getLocalNumericDateString(): string {
    return new Intl.DateTimeFormat(undefined, { day: 'numeric', month: 'numeric', year: 'numeric' })
      .format(this)
      .toString()
  }
  getLocalDateYearString(): string {
    return new Intl.DateTimeFormat(undefined, { year: 'numeric', day: 'numeric', month: 'numeric' })
      .format(this)
      .toString()
  }
  getISODateString(): string {
    return this.toISOString().split('T')[0]
  }
  getLocalDateTimeString(): string {
    return new Intl.DateTimeFormat(undefined, { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' })
      .format(this)
      .toString()
  }

  getLocalTimeString(): string {
    return new Intl.DateTimeFormat(undefined, { hour: 'numeric', minute: 'numeric' }).format(this).toString()
  }

  static getDateTimeFromMinutesAfterMidnight(date: AppDate, minutesAfterMidnight: number) {
    const millisecondsAfterMidnight = minutesAfterMidnight * 60000
    return date.clone().getStartOfDay().addTime(millisecondsAfterMidnight)
  }

  static fromEpochTimestamp(time: number): AppDate {
    return new AppDate(time * 1000)
  }
}
