import { useCheckoutContext } from 'containers-new/CheckoutContainer/CheckoutContext'
import React, { FC } from 'react'
import Rating from 'components/Rating/Rating'
import { Button } from 'components-new/common/Button/Button'
import classNames from 'classnames'
import { Image } from 'components-new/common/Image/Image'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import { Link } from 'react-router-dom'
import { guideLink } from 'global/routes'
import { useEmbed } from 'hooks/useEmbed'
import { SendMessageButton } from '../SendMessageButton/SendMessageButton'
import { ExperienceFooterProps } from './ExperienceFooter.types'
import styles from './ExperienceFooter.module.scss'

export const ExperienceFooter: FC<ExperienceFooterProps> = ({ experience, onCtaClick = () => {} }) => {
  const { formattedSubAmount } = useCheckoutContext()
  const { supportPhone, supportPhoneClean } = useSettingsContext()
  const { host, rating, title } = experience
  const { isTripEmbed } = useEmbed({ trip: experience })

  return (
    <div className={styles['experience-footer']}>
      <div
        className={classNames([styles['experience-footer__column'], styles['experience-footer__column--host-info']])}
      >
        {host?.imageUrl && (
          <Link to={guideLink(host.slug)} className={styles['experience-footer__image-link']}>
            <Image
              src={host.imageUrl}
              alt="Event guide"
              wrapperClassName={styles['experience-footer__image']}
              theme="round"
            />
          </Link>
        )}
        <p className={styles['experience-footer__title']}>{title}</p>
        {rating && <Rating rating={rating} className={styles['experience-footer__rating']} />}
      </div>
      <div
        className={classNames([styles['experience-footer__column'], styles['experience-footer__column--event-info']])}
      >
        <div className={styles['experience-footer__price']}>
          <p>{formattedSubAmount}</p>
          <SendMessageButton
            className={styles['experience-footer__cta']}
            experience={experience}
            theme="primary"
            size="small"
          />
          <Button className={styles['experience-footer__cta']} onClick={onCtaClick} theme="secondary" size="small">
            Request Booking
          </Button>
        </div>
        {!isTripEmbed && (
          <p className={styles['experience-footer__additional-info']}>
            Please contact us if there are any questions: <a href={`tel:${supportPhoneClean}`}>{supportPhone}</a>
          </p>
        )}
      </div>
    </div>
  )
}
