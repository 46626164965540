import { getAlpha2CountryCode } from 'utils/country-helper'

type AggregationLocation = { key: string; doc_count: number }

export interface AggregationsInput {
  min_price: number
  max_price: number
  locations: AggregationLocation[]
}

class Aggregations {
  minPrice: number
  maxPrice: number
  countries: string[]

  constructor(data: AggregationsInput) {
    this.minPrice = data.min_price
    this.maxPrice = data.max_price
    this.countries = data.locations.map((loc) => loc.key).map(getAlpha2CountryCode)
  }
}

export default Aggregations
