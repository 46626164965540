import { MutableRefObject, ForwardedRef, useCallback } from 'react'

export function useMergeRefs(refs: Array<MutableRefObject<any> | ForwardedRef<any>>) {
  return useCallback(
    (ref: any) => {
      refs.forEach((r) => {
        if (typeof r === 'function') {
          r(ref)
        } else if (r !== null) {
          r.current = ref
        }
      })
    },
    [...refs]
  )
}
