import { useCallback, useMemo, useState } from 'react'

export const useStepValidator = <T>(
  steps: Step<T>[],
  args: T,
  setActiveStepCallback: (step: Step<T>, args: T) => void
): useStepValidatorReturn => {
  const [activeStepIndex, setActiveIndex] = useState<number>(0)

  const isStepValidToLeave = useCallback(
    (index: number) => {
      if (steps[index].validToLeave) {
        if (steps[index].validToLeave!(args)) {
          return true
        }
      } else {
        return true
      }
      return false
    },
    [args]
  )

  const isStepValidToEnter = useCallback(
    (index: number) => {
      if (steps[index].validToEnter) {
        if (steps[index].validToEnter!(args)) {
          return true
        }
      } else {
        return true
      }
      return false
    },
    [args]
  )

  const setActiveStepIndex = (index: number) => {
    if (isStepValidToEnter(index)) {
      setActiveStepCallback(steps[index], args)
    }
  }

  const setActiveStep = (step: number | string) => {
    let index = step as number
    if (typeof step === 'string') {
      index = steps.findIndex((s) => s.slug === step)
    }
    setActiveStepIndex(index)
  }

  const activeStepBackInformation = useMemo(() => {
    return steps[activeStepIndex].back?.(args)
  }, [activeStepIndex, args])

  const hasPrevStep = useMemo(() => {
    return !!steps[activeStepIndex - 1]
  }, [activeStepIndex, steps])

  const hasNextStep = useMemo(() => {
    return !!steps[activeStepIndex + 1]
  }, [activeStepIndex, steps])

  const nextStep = () => {
    if (hasNextStep) {
      setActiveStepIndex(activeStepIndex + 1)
    }
  }

  const prevStep = () => {
    if (hasPrevStep) {
      setActiveStepIndex(activeStepIndex - 1)
    }
  }

  const isCurrentStepValidToLeave = isStepValidToLeave(activeStepIndex)
  const isCurrentStepValidToEnter = isStepValidToEnter(activeStepIndex)

  return {
    setActiveStepIndex,
    setActiveStep,
    setActiveIndex,
    nextStep,
    prevStep,
    hasPrevStep,
    hasNextStep,
    isCurrentStepValidToLeave,
    isCurrentStepValidToEnter,
    isStepValidToLeave,
    isStepValidToEnter,
    activeStepIndex,
    activeStepBackInformation,
  }
}

export interface useStepValidatorReturn {
  setActiveStepIndex: (index: number) => void
  setActiveStep: (step: number | string) => void
  setActiveIndex: (index: number) => void
  isStepValidToEnter: (index: number) => boolean
  isStepValidToLeave: (index: number) => boolean
  nextStep: () => void
  prevStep: () => void
  hasPrevStep: boolean
  hasNextStep: boolean
  isCurrentStepValidToLeave: boolean
  isCurrentStepValidToEnter: boolean
  activeStepIndex: number
  activeStepBackInformation?: { label: string; link: string }
}

export interface Step<DataType> {
  label: string
  slug: string
  validToLeave?: (args: DataType) => boolean
  validToEnter?: (args: DataType) => boolean
  back?: BackFunction<DataType>
}

type BackFunction<DataType> = (args: DataType) => { label: string; link: string }
