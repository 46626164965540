import React, { useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './Accordion.module.scss'

interface IAccordion {
  className?: string
  children: React.ReactNode
  id: number
}

export const Accordion: React.FC<IAccordion> = ({ className, children, id }) => {
  const [showAccordion, setShowAccordion] = useState<boolean>(true)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const elRef = useRef<HTMLDivElement>(null)

  function checkHeight() {
    if (elRef.current && elRef.current.scrollHeight) {
      setContentHeight(elRef.current.scrollHeight)
    }
  }

  useLayoutEffect(() => {
    checkHeight()
    window.addEventListener('resize', checkHeight)
    return () => {
      window.removeEventListener('resize', checkHeight)
    }
  }, [])

  return (
    <>
      <button
        className={classNames(styles['accordion__button'], className)}
        onClick={() => setShowAccordion((prev) => !prev)}
        aria-expanded={showAccordion ? 'true' : 'false'}
        aria-controls={'accordion' + id}
      >
        {showAccordion ? '- Hide' : '+ Show'}
      </button>
      <div
        className={styles['accordion__content']}
        ref={elRef}
        id={'accordion' + id}
        style={showAccordion ? { height: `${contentHeight}px` } : { height: '0px' }}
      >
        {children}
      </div>
    </>
  )
}
