import React, { useState, FC } from 'react'
import { MapGL } from 'components-new/common/MapGL/MapGL'
import { MapGLPin } from 'components-new/common/MapGLPin/MapGLPin'
import { ExperienceLocationProps } from './ExperienceLocation.types'
import styles from './ExperienceLocation.module.scss'

const defaultMapState = {
  width: '100%',
  height: '100%',
  zoom: 11,
}

export const ExperienceLocation: FC<ExperienceLocationProps> = ({ location }) => {
  const [viewport] = useState(defaultMapState)
  return (
    location && (
      <div className={styles['experience-location']}>
        <h3 className={styles['experience-location__header']}>Location</h3>
        <div className={styles['experience-location__map']}>
          <MapGL
            {...viewport}
            latitude={location.latitude}
            longitude={location.longitude}
            disabled={true}
            controls={true}
          >
            <MapGLPin latitude={location.latitude} longitude={location.longitude} />
          </MapGL>
        </div>
      </div>
    )
  )
}
