import React from 'react'
import classNames from 'classnames'
import { IOurValuesCard } from './OurValuesCard.types'
import styles from './OurValuesCard.module.scss'

export const OurValuesCard: React.FC<IOurValuesCard> = ({ className, name, description }) => {
  return (
    <div className={classNames(className, styles['our-values-card'])}>
      <div className={styles['our-values-card__name']}>{name}</div>
      <p className={styles['our-values-card__description']}>{description}</p>
    </div>
  )
}
