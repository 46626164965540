import React, { FC } from 'react'

import { FormField } from '../FormField/FormField'
import { InputWrapperProps } from './InputWrapper.types'

export const InputWrapper: FC<InputWrapperProps> = (props) => {
  const { children, type, label, error, success, disabled } = props

  return (
    <FormField label={label} type={type} error={error} success={success} disabled={disabled}>
      {children}
    </FormField>
  )
}
