import { Navbar } from 'components-new/layout/Navbar/Navbar'
import React, { useEffect } from 'react'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { Footer } from 'components-new/layout/Footer/Footer'
import { HostContainer } from 'containers-new/HostContainer/HostContainer'
import { Loader } from 'components-new/common/Loader/Loader'
import { useParams } from 'react-router'
import { useHostDetailsBySlugOrId } from 'hooks/useHostDetailsBySlugOrId'
import Seo from 'components/Seo/Seo'
import { guideLink } from 'global/routes'
import { useEmbed } from 'hooks/useEmbed'

export const HostPage: React.FC<{}> = () => {
  const { slugOrId } = useParams<{ slugOrId: string }>()
  const { fetch, isLoading, responseError, result: user } = useHostDetailsBySlugOrId()
  const { isGuideEmbed } = useEmbed({ guide: user })

  useEffect(() => {
    fetch(slugOrId)
  }, [slugOrId])

  return (
    <>
      {responseError && <Seo statusCode={responseError.statusCode} />}
      {user && (
        <Seo
          title={user.name}
          description={user.hostInfo?.description}
          image={user.image ? user.imageUrl : null}
          canonical={guideLink(user.slug)}
        />
      )}

      {!user || isLoading ? (
        <Loader fullScreen />
      ) : (
        <>
          <Navbar withFixedPosition withEmbed={isGuideEmbed} />
          <HostContainer hostBasics={user} />
          {!isGuideEmbed && <Footer img={footerImage} />}
        </>
      )}
    </>
  )
}
