import { SearchPageActions } from './actions'

const initialState = {
  advancedFiltersVisible: false,
  shouldRunSearch: false,
  zoomOnResults: false,
}

export function searchPageReducer(state = initialState, action) {
  switch (action.type) {
    case SearchPageActions.SET_ADVANCED_FILTERS:
      return {
        ...state,
        advancedFiltersVisible: action.payload,
      }
    case SearchPageActions.RUN_SEARCH:
      return {
        ...state,
        shouldRunSearch: action.payload,
      }
    case SearchPageActions.ZOOM_ON_RESULTS:
      return {
        ...state,
        zoomOnResults: action.payload,
      }
    default:
      return state
  }
}
