import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { AccountMessagesContainer } from 'containers-new/AccountMessagesContainer/AccountMessagesContainer'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { GetConversation } from 'services-new/conversation/conversation-api'
import { useMessagesContext } from 'containers-new/AccountMessagesContainer/MessagesContext'
import { usePrevious } from 'hooks/usePrevious'
import Conversation from 'services-new/conversation/conversation.model'
import { setDraftConversation, setCurrentConversation } from 'features/conversation/ConversationsSlice'
import { IMessagesPage } from './MessagesPage.types'

export const MessagesPage: React.FC<IMessagesPage> = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { conversations, draft } = useMessagesContext()
  const previousId = usePrevious<Conversation['id']>(id)
  useLayoutEffect(() => {
    if (previousId === 'new') {
      dispatch(setDraftConversation(null))
    }
    if (id && id !== 'new') {
      dispatch(GetConversation(id))
    }
  }, [id])

  const conversation = useMemo(() => {
    return conversations.find((c) => c.id === id) || null
  }, [id, conversations])

  useEffect(() => {
    dispatch(setCurrentConversation(conversation))
    return () => {
      dispatch(setCurrentConversation(null))
    }
  }, [conversation])

  return <AccountMessagesContainer conversation={conversation || draft} />
}
