import { GetPost, GetPosts } from 'services-new/post/post-api'
import Post from 'services-new/post/post.model'
import { initialState, InitialState } from 'utils/api'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const postsAdapter = createEntityAdapter<Post>({
  selectId: (post) => post.slug,
})

export const postsSlice = createSlice({
  name: 'posts',
  initialState: postsAdapter.getInitialState<InitialState>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetPosts.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(GetPosts.fulfilled, (state, action) => {
      state.loading = false
      postsAdapter.setAll(state, Post.fromArray(action.payload.data))
    })
    builder.addCase(GetPosts.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    builder.addCase(GetPost.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(GetPost.fulfilled, (state, action) => {
      state.loading = false
      postsAdapter.setOne(state, new Post(action.payload.data))
    })
    builder.addCase(GetPost.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export default postsSlice.reducer
