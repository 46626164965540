import Image, { ImageInput } from './Image'

export interface SectionInput {
  __component: SectionTypes
  id: number
  title: string
  text?: string
  points?: SectionPoint[]
  icons?: SectionIcon[]
  image?: ImageInput
}

type SectionPoint = {
  id?: number
  text: string
}

export interface SectionOutput {
  __component: SectionTypes
  id?: number
  title: string
  text?: string
  points?: SectionPoint[]
  icons?: SectionIcon[]
  image?: Image
}

export enum SectionTypes {
  TextSection = 'components.text-section',
  TextWithPointsSection = 'components.text-with-points-section',
  IconsSection = 'components.icons-section',
  TextWithImage = 'components.text-with-image-section',
}

export type SectionIcon = {
  title: string
  description: string
}

export const EXPERIENCE_SECTIONS = [
  {
    title: "What you'll do",
    type: SectionTypes.TextSection,
  },
  {
    title: 'What to bring',
    type: SectionTypes.TextWithPointsSection,
  },
  {
    title: "What's included",
    type: SectionTypes.IconsSection,
  },
] as Array<{
  title: string
  type: SectionTypes
}>

class Section {
  __component: SectionTypes
  id: number
  title: string
  text?: string
  points?: SectionPoint[]
  icons?: SectionIcon[]
  image?: Image

  constructor(section: SectionInput) {
    this.__component = section.__component
    this.id = section.id || 0
    this.title = section.title
    this.text = section.text || ''
    this.points = section.points || []
    this.icons = section.icons || []
    this.image = section.image ? new Image(section.image) : undefined
  }

  static fromArray(input: SectionInput[] | null): Section[] {
    return input ? input.map((i) => new Section(i)) : []
  }

  static toOutput(data: Section): SectionOutput {
    return {
      __component: data.__component,
      id: data.id || undefined,
      title: data.title,
      text: data.text,
      points: data.points?.filter((p) => p.text),
      icons: data.icons,
      image: data.image,
    }
  }
}

export default Section
