import { useCallback, useEffect, useRef, useState } from 'react'
import { fetchMarketingPageSchedule$ } from 'services/marketing-page/marketing-page-api'
import { AppDate } from 'services-new/shared/date'
import { useRequest } from './useRequest'

export default ({ experienceId, guests }: { experienceId?: number | null; guests: number }): useScheduleReturn => {
  const [dates, setDates] = useState<Array<AvailableDate>>([])
  const checkedMonths = useRef<Array<string>>([])
  const {
    isLoading,
    responseError,
    fetch: fetchRequest,
  } = useRequest<{ id: number; gte: Date; lte: Date; guests: number }, AvailableDate[]>(
    fetchMarketingPageSchedule$,
    (response) => {
      setDates((prev) =>
        prev.concat(
          response
            .map((r) => ({ ...r, date: new AppDate(r.date).toLocal() }))
            .filter((d) => !prev.some((p) => p.date.getTime() === d.date.getTime()))
        )
      )
    }
  )

  const fetch = useCallback(
    (month: Date): void => {
      const monthStart = new AppDate(month.getTime()).clone().getStartOfMonth()

      if (!experienceId || checkedMonths.current.includes(monthStart.toString())) {
        return
      }

      checkedMonths.current.push(monthStart.toString())
      fetchRequest({
        gte: monthStart.clone().removeTimezoneOffset(),
        lte: monthStart.clone().getEndOfMonth().removeTimezoneOffset(),
        guests,
        id: experienceId,
      })
    },
    [experienceId, guests]
  )

  useEffect(() => {
    checkedMonths.current = []
  }, [experienceId])

  return {
    dates,
    error: responseError,
    isLoading: isLoading,
    fetch,
  }
}

export type AvailableDate = { date: AppDate; time: number[] }

export interface useScheduleReturn {
  dates: AvailableDate[]
  isLoading: boolean
  error: any
  fetch: (month: Date) => void
}
