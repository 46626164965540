import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { Icon } from 'components/Icon/Icon'
import UserPublic from 'services/shared/models/UserPublic'
import { Image } from 'components-new/common/Image/Image'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import styles from './HostInfo.module.scss'

interface IHostInfo {
  hostBasics: UserPublic
}

export const HostInfo: React.FC<IHostInfo> = ({ hostBasics }) => {
  const { guideDefaultBackground } = useSettingsContext()
  const image = hostBasics.background_image ? hostBasics.background_image : guideDefaultBackground

  return (
    <>
      <Wrapper containerClasses={styles['host-info']}>
        <div className={styles['host-info__details']}>
          {hostBasics.location?.address && (
            <address className={styles['host-info__address']}>
              <Icon icon="map_pin" className={styles['host-info__address-icon']} />
              {hostBasics.location?.address.locationString}
            </address>
          )}
          <h1 className={styles['host-info__name']}>{hostBasics.name}</h1>
          <p className={styles['host-info__description']}>
            {hostBasics.hostInfo ? hostBasics.hostInfo.description : ''}
          </p>
        </div>
      </Wrapper>
    </>
  )
}
