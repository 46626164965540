import moment from 'moment-timezone'

/*
  This method is meant to take a date from the backend (in UTC time)
  and return the same date, month, hour, etc. in the user's local timezone.

  This allows us to manipulate user input (which always comes in local time)
  without needing any other UTC/timezone changes. The only thing to do
  afterwards is to change date data back to UTC time before sending data to
  the server.
*/
export const backendUTCToLocal = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )

export const localToUtcIsoString = (date) =>
  moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()]).toISOString()

export const localToBackendUtc = (date) =>
  moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()]).toDate()

/* Accepts a date; returns a Date object of midnight UTC-time of the argument */
export const roundDateToDayUTC = (date) => moment.utc(date).hour(0).minutes(0).seconds(0).milliseconds(0).toDate()

export const roundDateToDayLocal = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate())

export const getEndOfDayUTC = (date) =>
  moment.utc(date).hour(0).minutes(0).seconds(0).milliseconds(0).add(1, 'day').subtract(1, 'millisecond').toDate()

export const roundDateToMonthUTC = (date) => moment.utc(date).startOf('month').toDate()

export const filterDuplicatedDates = (dates) =>
  dates
    .map((date) => date.getTime())
    .filter((date, index, self) => self.indexOf(date) === index)
    .map((date) => new Date(date))

export const formatHours = (date, tz = null) =>
  tz ? moment(date).tz(moment.tz.guess()).format('hh:mm A') : moment(date).format('hh:mm A')

export const formatDay = (date, tz = null) =>
  false ? moment(date).tz(moment.tz.guess()).format('ddd, MMM D') : moment(date).format('ddd, MMM D')

export const calculateDuration = ({ hours = 0, days = 0 }) => {
  let minutes = 0
  minutes += hours * 60
  minutes += days * 24 * 60
  return minutes
}

export const formatDuration = (durationInMinutes) => {
  if (!durationInMinutes || typeof durationInMinutes !== 'number' || durationInMinutes < 0) {
    return {
      days: 0,
      hours: 0,
    }
  }

  const days = Math.floor(durationInMinutes / (60 * 24))
  const hours = (durationInMinutes % (60 * 24)) / 60
  return { days, hours }
}

export const getDurationString = ({ days, hours }) => {
  const parts = []
  if (days) {
    parts.push(`${days} ${days > 1 ? 'days' : 'day'}`)
  }
  if (hours) {
    parts.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`)
  }
  return parts.join(', ')
}

export const getCapacityString = ({ min, max }) => {
  const parts = []
  if (min) {
    parts.push(min)
  }
  if (max && max !== min) {
    parts.push(max)
  }
  return parts.length ? `${parts.join('-')} guest${parts[parts.length - 1] > 1 ? 's' : ''}` : ''
}

export const getDayFromWeekDay = (dayOfWeek) => {
  const daysMap = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
  }
  if (!Object.prototype.hasOwnProperty.call(daysMap, dayOfWeek)) {
    throw new ReferenceError(`dayOfWeek must be one of: ${Object.keys(daysMap).join(', ')}`)
  }
  return daysMap[dayOfWeek]
}

export const getShortDayFromWeekDay = (dayOfWeek) => {
  const daysMap = {
    0: 'su',
    1: 'mo',
    2: 'tu',
    3: 'we',
    4: 'th',
    5: 'fr',
    6: 'sa',
  }
  if (!Object.prototype.hasOwnProperty.call(daysMap, dayOfWeek)) {
    throw new ReferenceError(`dayOfWeek must be one of: ${Object.keys(daysMap).join(', ')}`)
  }
  return daysMap[dayOfWeek]
}

export const getTimeFromMinutesAfterMidnight = (minutesAfterMidnight) => {
  if (minutesAfterMidnight >= 24 * 60 || minutesAfterMidnight < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  return moment
    .utc()
    .hours(minutesAfterMidnight / 60 || 0)
    .minutes(minutesAfterMidnight % 60 || 0)
    .format('hh:mm A')
}
