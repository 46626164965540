import React, { FC, useMemo } from 'react'
import { ICustomListItem } from './CustomList.types'
import styles from './CustomList.module.scss'

export const CustomList: FC<ICustomListItem> = ({ listItems }) => {
  const preparePoints = useMemo(() => {
    return listItems.map((point: string) => <li key={point}>{point}</li>)
  }, [listItems])

  return listItems.length > 0 ? <ul className={styles.customList}>{preparePoints}</ul> : null
}
