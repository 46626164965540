import React from 'react'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { ForGuidesContainer } from 'containers-new/ForGuidesContainer/ForGuidesContainer'
import { Footer } from 'components-new/layout/Footer/Footer'
import Seo from 'components/Seo/Seo'
import styles from './CustomerPolicy.module.scss'

export const CustomerPolicy: React.FC<{}> = () => (
  <div className={styles['main-page']}>
    <Seo title={'Customer Policy'} />
    <Navbar withFixedPosition hideSearch withTripList={false} withEmbed={true} />
    <ForGuidesContainer withForm={false} />
  </div>
)
