export const formattedPrice = (num: any, currency?: string | undefined) => {
  if (typeof num !== 'number') {
    return 'n/a'
  }
  const result = Number(num).toLocaleString(
    'en-US',
    currency ? { style: 'currency', currency } : { maximumFractionDigits: 2 }
  )
  return result.slice(-3) === '.00' ? result.slice(0, -3) : result
}

export const generateRandomInt = (min: number, max: number) => {
  const tmpMin = Math.ceil(min)
  const tmpMax = Math.floor(max)
  return Math.floor(Math.random() * (tmpMax - tmpMin)) + tmpMin
}

export const isNumericString = (str: string | number) => {
  return /^-?\d+$/.test('' + str)
}
