import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { OurValuesCard } from './OurValuesCard/OurValuesCard'
import styles from './OurValues.module.scss'
import { IOurValues } from './OurValues.types'

const tmpData = [
  {
    name: 'Stewardship',
    description: `We live and love the adventurous life, and as such see ourselves as caretakers 
    of the resources that provide it. We are proud that 2 percent of our 
    revenue is donated to a conservation group that works to protect the wild places. `,
  },
  {
    name: 'Hunger for Excellence',
    description: `We are hungry to learn, hungry to be the best we can be, and hungry to 
    crush it everyday. Hunger has no boundaries and helps drive us towards excellence. 
    This requires us to be all in, all the time.`,
  },
  {
    name: 'Outdoors for Everyone',
    description: `The wild places are our sanctuary. It’s our responsibility to help enable
     access to the wild for all people, regardless of race, gender, beliefs, background or ability.`,
  },
  {
    name: 'Innovate',
    description: `We’re looking for progress.  With our technology, guides spend more time 
    doing what they love -- leading unforgettable adventures in the outdoors -- 
    and less time on their back office. `,
  },
]

export const OurValues: React.FC<IOurValues> = () => {
  return (
    <Wrapper containerClasses={styles['our-values']}>
      <h2 className={styles['our-values__header']}>Our Values</h2>
      <div className={styles['our-values__cards']}>
        {tmpData.map((item) => (
          <OurValuesCard
            name={item.name}
            description={item.description}
            key={item.name}
            className={styles['our-values__card']}
          />
        ))}
      </div>
    </Wrapper>
  )
}
