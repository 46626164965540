import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from 'hooks/useUser'
import { accountLink, paymentsVerify } from 'global/routes'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { useRequestAsync } from 'hooks/useRequestAsync'
import { getRequest$, postRequest$ } from 'utils/api'
import { Loader } from 'components-new/common/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { GetBookings } from 'services-new/booking/booking-api'
import { RootState } from 'store'
import Stripe from 'stripe'
import { AppDate } from 'services-new/shared/date'
import { formattedPrice } from 'utils/numberhelper'
import { paymentsAdapter } from 'features/stripe/PaymentsSlice'
import { GetExternalAccounts } from 'services-new/stripe/payments-api'
import { Button } from 'components-new/common/Button/Button'
import { bookingAdapter } from '../../features/booking/BookingSlice'
import { PaymentsExternalAccountsList } from './components/PaymentsExternalAccountsList/PaymentsExternalAccountsList'
import { PaymentsAddMethod } from './components/PaymentsAddMethod/PaymentsAddMethod'
import { PaymentsSummaryCard, TPaymentsSummaryCardProps } from './components/PaymentsSummaryCard/PaymentsSummaryCard'
import { TPaymentsActiveSummaryCardTab } from './AccountPaymentsContainer.types'
import s from './AccountPaymentsContainer.module.scss'

export const AccountPaymentsContainer: React.FC<unknown> = () => {
  const state = useSelector((s: RootState) => s.bookings)
  const externalAccounts = useSelector((rootState: RootState) => rootState.payments)
  const dispatch = useDispatch()
  const { user } = useUser()
  const history = useHistory()
  const [activeSummaryCardTab, setActiveSummaryCardTab] = useState<TPaymentsActiveSummaryCardTab>('BALANCE')
  const [balance, setBalance] = useState<any>()
  const location = useLocation()
  const status = user?.hostInfo && user?.hostInfo.status

  const { selectAll: paymentsSelectAll } = paymentsAdapter.getSelectors()
  const { selectAll } = bookingAdapter.getSelectors()

  const stripe = useMemo(() => {
    return loadStripe(process.env.REACT_APP_STRIPE_PK as string)
  }, [])

  const bookings = useMemo(() => {
    return selectAll(state).filter((booking) => booking.isHostedByUser(user))
  }, [state, user])

  const hasInstantPayout = useMemo(() => {
    return !!paymentsSelectAll(externalAccounts)
      .find((i) => i.default_for_currency)
      ?.available_payout_methods?.includes('instant')
  }, [externalAccounts])

  const {
    isLoading: isLoadingPayouts,
    fetch: fetchPayouts,
    results: payments,
  } = useRequestAsync<{ slug?: string }, { data: Stripe.Payout[] }>(() => getRequest$('stripe/payouts'))

  const validBookings = bookings.filter((b) => ['accepted', 'past'].includes(b.status))
  const pastBookings = validBookings.filter((b) => ['past'].includes(b.status))
  const futureBookings = validBookings.filter((b) => ['accepted'].includes(b.status))

  const balances = {
    available: balance?.[hasInstantPayout ? 'instant_available' : 'available']?.map((i: any) => ({
      amount: i.amount / 100,
      currency: 'usd',
    }))[0] ?? {
      amount: 0,
      currency: 'usd',
    },
    paid: {
      amount: payments ? payments?.data?.data?.reduce((total, p) => total + p.amount, 0) / 100 : 0,
      currency: 'usd',
    },
    future: {
      amount: futureBookings.reduce((total, b) => total + b.subAmount, 0),
      currency: 'usd',
    },
    total: {
      amount: validBookings.reduce((total, b) => total + b.subAmount, 0),
      currency: 'usd',
    },
  }

  useEffect(() => {
    const isSuccess = location.search.endsWith('success=true')

    if (isSuccess) {
      toast('Settings have been saved.')
    }
  }, [])

  const summaryCardData: TPaymentsSummaryCardProps['data'] = {
    BALANCE: [
      {
        title: 'Total Income',
        value: formattedPrice(balances.total.amount, balances.total.currency),
        variant: 'heading',
      },
      {
        title: 'Paid Out',
        value: formattedPrice(balances.paid.amount, balances.paid.currency),
      },
      {
        title: 'Available Upon Completion',
        value: formattedPrice(balances.future.amount, balances.future.currency),
      },
      {
        title: 'Processing',
        value: formattedPrice(
          status !== 'active'
            ? 0
            : balances.total.amount - balances.future.amount - balances.paid.amount - balances.available.amount,
          'usd'
        ),
      },
    ].filter((n) => !!n),
    INCOME_HISTORY: [
      {
        title: 'Total Income',
        value: formattedPrice(
          validBookings.reduce((total, b) => total + b.subAmount, 0),
          'USD'
        ),
        variant: 'heading',
      },
      ...(futureBookings.length
        ? [
            {
              title: 'Future Bookings',
              variant: 'subheading',
            },
            ...futureBookings.map((b) => ({
              title: `${b.startDate.getLocalNumericDateString()} — ${b.user.displayName}`,
              value: formattedPrice(b.subAmount, 'USD'),
            })),
          ]
        : []),
      ...(pastBookings.length
        ? [
            {
              title: 'Past Bookings',
              variant: 'subheading',
            },
            ...pastBookings.map((b) => ({
              title: `${b.startDate.getLocalNumericDateString()} — ${b.user.displayName}`,
              value: formattedPrice(b.subAmount, 'USD'),
            })),
          ]
        : []),
    ].filter((n) => !!n),
    TRANSFERRED_FUNDS: [
      {
        title: 'Total Transfers',
        value: formattedPrice(
          payments ? payments.data.data.reduce((total, p) => total + p.amount, 0) / 100 : 0,
          payments?.data.data[0]?.currency || 'usd'
        ),
        variant: 'heading',
      },
      ...(payments
        ? payments.data.data.map((p) => ({
            title: `${AppDate.fromEpochTimestamp(p.created).getLocalNumericDateString()} — paid to ${p.source_type}`,
            value: formattedPrice(p.amount / 100, p.currency),
          }))
        : []),
    ].filter((n) => !!n),
  }

  const onSummaryCardTabChange = (value: TPaymentsActiveSummaryCardTab) => setActiveSummaryCardTab(value)

  const onStripeSetup = () => {
    history.push(paymentsVerify())
  }
  const onStripeUpdate = () => {
    history.push(paymentsVerify())
  }
  const onCreatePayout = () => {
    createPayout({
      amount: balances.available.amount * 100,
      currency: balances.available.currency,
      method: hasInstantPayout ? 'instant' : 'standard',
    })
  }

  const { isLoading: isLoadingBalance, fetch: fetchBalance } = useRequestAsync<{ slug?: string }, any>(
    () => getRequest$('stripe/balance'),
    (results) => {
      if (results.data) {
        setBalance(results.data)
      }
    }
  )

  const { isLoading: isLoadingCreatePayout, fetch: createPayout } = useRequestAsync(
    (args) => postRequest$('stripe/payouts', args),
    () => {
      fetchPayouts()
      fetchBalance()
    }
  )

  useEffect(() => {
    dispatch(GetBookings())
  }, [])

  useEffect(() => {
    if (status !== 'inactive') {
      dispatch(GetExternalAccounts())
    }

    if (status === 'active') {
      fetchBalance()
      fetchPayouts()
    }
  }, [status])

  const cardContent = () => {
    switch (activeSummaryCardTab) {
      case 'BALANCE':
        return (
          <>
            <div className={s.highlight}>
              <p>Available For Payout</p>
              <span>
                {formattedPrice(
                  status !== 'active'
                    ? balances.total.amount - balances.future.amount - balances.paid.amount
                    : balances.available.amount,
                  'usd'
                )}
              </span>
            </div>
            {status !== 'active' && (
              <div className={s['stripe-status']}>
                {status === 'inactive' && (
                  <p>
                    You must setup your stripe account first to get paid. We will then transfer you payments from Stripe
                    to either your credit card or bank account with direct deposit.
                  </p>
                )}
                {status === 'pending' && <p>Your account is being verified by Stripe.</p>}
              </div>
            )}
            {status === 'inactive' ? (
              <Button theme="secondary" size="small" onClick={onStripeSetup}>
                Setup Stripe Account
              </Button>
            ) : (
              <Button theme="plain-orange-secondary" size="small" onClick={onStripeUpdate}>
                Update Stripe Account
              </Button>
            )}
            {status === 'active' && (
              <Button
                theme="secondary"
                size="small"
                onClick={onCreatePayout}
                disabled={!(balances.available && balances.available.amount > 0)}
              >
                Get Paid
              </Button>
            )}
          </>
        )
      case 'TRANSFERRED_FUNDS':
        return null
      case 'INCOME_HISTORY':
        return (
          <Button theme="secondary" size="small" to={accountLink('bookings')}>
            See Bookings
          </Button>
        )
    }
  }

  return (
    <Wrapper className={s.container} contentClasses={s.containerInner}>
      <Elements stripe={stripe}>
        <div className={s.summaryTabArea}>
          {isLoadingPayouts || isLoadingBalance || isLoadingCreatePayout ? (
            <Loader />
          ) : (
            <PaymentsSummaryCard
              activeTab={activeSummaryCardTab}
              data={summaryCardData}
              onTabChange={onSummaryCardTabChange}
            >
              {cardContent()}
            </PaymentsSummaryCard>
          )}
        </div>
        {user?.hostInfo && (
          <>
            <PaymentsExternalAccountsList className={s.PaymentsExternalAccountsList} />
            <PaymentsAddMethod className={s.cardDetails} />
          </>
        )}
      </Elements>
    </Wrapper>
  )
}
