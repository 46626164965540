import classNames from 'classnames'
import { Button } from 'components-new/common/Button/Button'
import { useMessagesContext } from 'containers-new/AccountMessagesContainer/MessagesContext'
import { messagesLink } from 'global/routes'
import React, { forwardRef } from 'react'
import { Icon } from 'components/Icon/Icon'
import { useHistory } from 'react-router'
import { MessagesButtonProps } from './MessagesButton.types'
import styles from './MessagesButton.module.scss'

export const MessagesButton = forwardRef<HTMLAnchorElement, MessagesButtonProps>(({ className, ...rest }, ref) => {
  const { unreadedMessagesCount } = useMessagesContext()
  const history = useHistory()

  const onMessagesLinkClick = () => {
    history.push(messagesLink())
  }

  return (
    <Button
      {...rest}
      ref={ref}
      size="small"
      theme="clean"
      className={classNames({
        [className!]: !!className,
        [styles['messages-button']]: true,
        [styles['messages-button--prominent']]: unreadedMessagesCount > 0,
      })}
      onClick={() => onMessagesLinkClick()}
    >
      <Icon icon="mail" className="icon-2" />
      <sup>{unreadedMessagesCount}</sup>
    </Button>
  )
})
