import { useCallback, useMemo } from 'react'
import MarketingDetails from 'services/marketing-page/models/MarketingDetails'
import { useSearch } from './useSearch'

export default ({ marketingPageId, activityName }: { marketingPageId: number; activityName: string }) => {
  const { run, results } = useSearch()

  const fetchSimilarExperiences = useCallback(() => {
    if (!activityName) {
      return
    }
    run({
      _start: 0,
      _limit: 5,
      _sort: 'created_at:DESC',
      activities: [activityName],
    })
  }, [activityName])

  return {
    fetchSimilarExperiences,
    similarExperiences: useMemo(() => {
      return results.filter((el: MarketingDetails) => el.id !== marketingPageId).slice(0, 4)
    }, [results, marketingPageId]),
  }
}

export interface useSimilarExperiencesReturn {
  fetchSimilarExperiences: () => void
  similarExperiences: MarketingDetails[]
}
