import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, InitialState } from 'utils/api'
import {
  CreateExternalAccount,
  GetExternalAccounts,
  RemoveExternalAccount,
  UpdateExternalAccount,
} from 'services-new/stripe/payments-api'
import { ExternalAccount } from 'services-new/stripe/payments.model'

export const paymentsAdapter = createEntityAdapter<ExternalAccount>({
  selectId: (object) => object.id,
})

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: paymentsAdapter.getInitialState<InitialState>(initialState),
  reducers: {
    setAsDefault(state, action: PayloadAction<string>) {
      const { selectAll } = paymentsAdapter.getSelectors()
      const externalAccounts = selectAll(state)
      paymentsAdapter.setAll(
        state,
        externalAccounts.map((e) => ({ ...e, default_for_currency: e.id === action.payload }))
      )
    },
    reset: (state) => Object.assign(state, paymentsAdapter.getInitialState<InitialState>(initialState)),
  },
  extraReducers: (builder) => {
    builder.addCase(GetExternalAccounts.pending, (state) => {
      state.loading = true
      state.loaded = false
      state.error = null
    })
    builder.addCase(GetExternalAccounts.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      paymentsAdapter.setAll(state, action.payload.data.data)
    })
    builder.addCase(GetExternalAccounts.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
    builder.addCase(RemoveExternalAccount.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(RemoveExternalAccount.fulfilled, (state, action) => {
      state.loading = false
      paymentsAdapter.removeOne(state, action.payload.data.id)
    })
    builder.addCase(RemoveExternalAccount.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    builder.addCase(UpdateExternalAccount.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(UpdateExternalAccount.fulfilled, (state, action) => {
      state.loading = false
      paymentsAdapter.setOne(state, action.payload.data)
    })
    builder.addCase(UpdateExternalAccount.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    builder.addCase(CreateExternalAccount.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(CreateExternalAccount.fulfilled, (state, action) => {
      state.loading = false
      paymentsAdapter.addOne(state, action.payload.data)
    })
    builder.addCase(CreateExternalAccount.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
  },
})

export const { setAsDefault, reset } = paymentsSlice.actions

export default paymentsSlice.reducer
