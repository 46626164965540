import { FloatingComponent } from 'components-new/common/FloatingComponent/FloatingComponent'
import { ExperienceGrid } from 'components-new/common/ExperienceGrid/ExperienceGrid'
import { useCheckoutContext } from 'containers-new/CheckoutContainer/CheckoutContext'
import { ExperienceLocation } from 'containers-new/ExperienceContainer/components/ExperienceLocation/ExperienceLocation'
import { ExperienceHost } from 'containers-new/ExperienceContainer/components/ExperienceHost/ExperienceHost'
import { ExperienceHeader } from 'containers-new/ExperienceContainer/components/ExperienceHeader/ExperienceHeader'
import { ExperienceFooter } from 'containers-new/ExperienceContainer/components/ExperienceFooter/ExperienceFooter'
import { checkoutLink, initCheckoutLink } from 'global/routes'
import useSimilarExperiences from 'hooks/useSimilarExperiences'
import React, { FC, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import Section, { SectionTypes } from 'services/shared/models/Section'
import classNames from 'classnames'
import { Mixpanel } from 'utils/mixpanel'
import { useEmbed } from 'hooks/useEmbed'
import { ExperienceContainerProps } from './ExperienceContainer.types'
import styles from './ExperienceContainer.module.scss'
import { TextSection } from './components/TextSection/TextSection'
import { TextWithPointsSection } from './components/TextWithPointsSection/TextWithPointsSection'
import { IconsSection } from './components/IconsSection/IconsSection'
import { DetailsSection } from './components/DetailsSection/DetailsSection'
import { ExperienceCalendar } from './components/ExperienceCalendar/ExperienceCalendar'

type ExperienceSectionTypes = SectionTypes | 'advanced-details'

const sectionsComponents: { [key in ExperienceSectionTypes]?: FC<any> } = {
  [SectionTypes.TextSection]: TextSection,
  [SectionTypes.IconsSection]: IconsSection,
  [SectionTypes.TextWithPointsSection]: TextWithPointsSection,
  'advanced-details': DetailsSection,
}
const topSectionsComponents: { [key in ExperienceSectionTypes]?: FC<any> } = {
  [SectionTypes.TextSection]: TextSection,
}
const bottomSectionsComponents: { [key in ExperienceSectionTypes]?: FC<any> } = {
  [SectionTypes.IconsSection]: IconsSection,
  [SectionTypes.TextWithPointsSection]: TextWithPointsSection,
  'advanced-details': DetailsSection,
}

const SectionsOrder = ["What you'll do", 'What to bring', "What's included", 'Advanced details']

export const ExperienceContainer: FC<ExperienceContainerProps> = ({ experience, preview: isPreviewMode }) => {
  const { host, sections, properties, activities, location } = experience
  const { isTripEmbed } = useEmbed({ trip: experience })
  const history = useHistory()
  const activity = activities.find(({ name }) => name && name.toLowerCase() !== 'general')

  const { fetchSimilarExperiences, similarExperiences } = useSimilarExperiences({
    marketingPageId: experience.id,
    activityName: experience.activities[0]?.label,
  })

  useEffect(() => {
    Mixpanel.track('Experience page', {
      marketingPageId: experience.id,
      bookingTitle: experience.title,
    })
  }, [])

  useEffect(() => {
    if (!isPreviewMode && !isTripEmbed) {
      fetchSimilarExperiences()
    }
  }, [isPreviewMode])

  const getSections = (sections: Section[]) => {
    const parsedSections = sections.sort((a, b) => {
      return SectionsOrder.indexOf(a.title) < SectionsOrder.indexOf(b.title) ? -1 : 1
    })

    return parsedSections.map((section: any, index: number) => {
      const Component = sectionsComponents[section.__component as ExperienceSectionTypes] as FC
      return (
        <Wrapper
          paddingType="small"
          theme={index === 1 ? 'silver' : undefined}
          key={section.title}
          className={styles['experience-container__wrapper']}
          contentClasses={index === 1 ? styles['experience-container__wrapper--with-background'] : undefined}
        >
          <Component {...section} />
        </Wrapper>
      )
    })
  }

  const TopSections = useMemo(
    () =>
      getSections(
        sections.filter((section) => {
          return !!topSectionsComponents[section.__component as ExperienceSectionTypes]
        })
      ),
    [sections, activity]
  )

  const bottomSections = useMemo(() => {
    const parsedSections = sections.filter((section) => {
      return !!bottomSectionsComponents[section.__component as ExperienceSectionTypes]
    })
    if (activity) {
      parsedSections.push({
        // @ts-ignore
        __component: 'advanced-details',
        properties,
        activityName: activity.name,
        title: 'Advanced details',
      })
    }
    return parsedSections
  }, [sections, activity])

  const BottomSections = useMemo(() => getSections(bottomSections), [bottomSections])

  const { state, isCurrentStepValidToLeave, activeStepIndex, steps } = useCheckoutContext()

  const goToCheckout = () => {
    const { date, time } = state
    history.push(
      date && time
        ? checkoutLink(
            state.marketingPage!.id,
            date.toISOString(),
            time,
            isCurrentStepValidToLeave ? steps[activeStepIndex + 1].slug : undefined
          )
        : initCheckoutLink(state.marketingPage!.id)
    )
  }

  return (
    <div className={styles['experience-container']}>
      <Wrapper paddingType="small" className={styles['experience-container__wrapper']}>
        <ExperienceHeader experience={experience} />
      </Wrapper>
      {TopSections}
      {host && (
        <Wrapper
          paddingType="small"
          className={styles['experience-container__wrapper']}
          contentClasses={styles['experience-container__wrapper--with-background']}
        >
          <ExperienceHost
            className={classNames(styles['experience-container__wrapper--host'])}
            host={host}
            experience={experience}
          />
        </Wrapper>
      )}
      {!isPreviewMode && (
        <Wrapper paddingType="small" className={styles['experience-container__wrapper']}>
          <ExperienceCalendar onClick={goToCheckout} />
        </Wrapper>
      )}
      {location && (
        <Wrapper paddingType="small" className={styles['experience-container__wrapper']}>
          <ExperienceLocation location={location} />
        </Wrapper>
      )}
      {BottomSections}
      {!isPreviewMode && similarExperiences.length > 0 && (
        <Wrapper paddingType="small" className={styles['experience-container__wrapper']}>
          <ExperienceGrid experiences={similarExperiences} />
        </Wrapper>
      )}
      {!isPreviewMode && (
        <FloatingComponent stickyOnMobile>
          <Wrapper paddingType="small">
            <ExperienceFooter experience={experience} onCtaClick={goToCheckout} />
          </Wrapper>
        </FloatingComponent>
      )}
    </div>
  )
}
