import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import React from 'react'
import styles from './AccountDetailsContainer.module.scss'
import { AccountDetails } from './components/AccountDetails/AccountDetails'
import { PasswordChange } from './components/PasswordChange/PasswordChange'

export const AccountDetailsContainer: React.FC = () => {
  return (
    <>
      <Wrapper className={styles['account-details-container__wrapper']}>
        <h2>Personal Details</h2>
      </Wrapper>
      <AccountDetails />
      <Wrapper className={styles['account-details-container__wrapper']}>
        <h2>Password Change</h2>
        <PasswordChange />
      </Wrapper>
    </>
  )
}
