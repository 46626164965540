// TODO: TO BE DELETED-> from react boiler plate. it is kept for good practises

import Activity from 'services/shared/models/Activity'

export const ActivityActions = {
  GET_ACTIVITIES: '@activities',
  GET_ACTIVITIES_SUCCESS: '@activities/success',
}

export function getActivities() {
  return {
    type: ActivityActions.GET_ACTIVITIES,
  }
}

export function setActivitiesSuccess(activities) {
  return {
    type: ActivityActions.GET_ACTIVITIES_SUCCESS,
    payload: activities.map((activity) => new Activity(activity)).sort((a, b) => (b.name === 'Other' ? -1 : 1)),
  }
}
