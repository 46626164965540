import React, { forwardRef } from 'react'
import { useMemo } from 'react'

import { FormField } from '../FormField/FormField'
import { InputProps } from './Input.types'

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const {
    children,
    className,
    inputClassName,
    type = 'text',
    placeholder,
    required,
    name,
    value,
    label,
    error,
    success,
    disabled,
    checked,
    description,
    defaultValue,
    minLength,
    maxLength,
    max,
    min,
    hideControl,
    prepend,
    append,
    theme,
    autoComplete,
    ...nativeProps
  } = props

  const newValue = useMemo(() => {
    let val = Number.isNaN(value) ? '' : value
    if (type === 'number' && Number.isInteger(value)) {
      if (val && max && val > max) {
        val = max
      }
      if (val && min && val < min) {
        val = min
      }
    }
    return val
  }, [value])

  return (
    <FormField
      disabled={disabled}
      label={label}
      type={type}
      error={error}
      success={success}
      value={value}
      hideControl={hideControl}
      checked={checked}
      className={className}
      inputClassName={inputClassName}
      prepend={prepend}
      append={append}
      theme={theme}
      description={description}
    >
      <input
        ref={ref}
        type={type}
        placeholder={placeholder}
        required={required}
        name={name}
        value={newValue}
        defaultValue={defaultValue}
        minLength={minLength}
        maxLength={maxLength}
        max={max}
        min={min}
        disabled={disabled}
        checked={checked}
        autoComplete={autoComplete}
        {...nativeProps}
      />
    </FormField>
  )
})
