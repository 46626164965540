import { Button } from 'components-new/common/Button/Button'
import { ButtonProps } from 'components-new/common/Button/Button.types'
import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import styles from './StylishButton.module.scss'

type StylishButtonProps = ButtonProps

const StylishButton = React.forwardRef<HTMLAnchorElement, StylishButtonProps>(({ children, ...btnProps }, ref) => {
  return (
    <Button theme="light" size="extra-small" {...btnProps} ref={ref}>
      <div className={styles['stylish-button__content']}>
        <div className={styles['stylish-button__text-container']}>
          <div className={styles['stylish-button__heading']}>Explore more</div>
          <div>{children}</div>
        </div>
        <ChevronRightIcon className={styles['stylish-button__icon']}></ChevronRightIcon>
      </div>
    </Button>
  )
})

export default StylishButton
