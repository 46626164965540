import { Loader } from 'components-new/common/Loader/Loader'
import { useSettings } from 'hooks/useSettings'
import { useUser } from 'hooks/useUser'
import React, { FC, createContext, useContext, useEffect } from 'react'
import { SettingsContext, SettingsContextProviderProps } from './SettingsContext.types'

const SettingsContextInstance = createContext<SettingsContext>({} as SettingsContext)

export const SettingsContextProvider: FC<SettingsContextProviderProps> = (props) => {
  const { children } = props
  const { result, fetch } = useSettings()
  const { setup, isLoaded } = useUser()

  useEffect(() => {
    fetch()
    setup()
  }, [])

  return isLoaded && result ? (
    <SettingsContextInstance.Provider value={result}>{children}</SettingsContextInstance.Provider>
  ) : (
    <Loader fullScreen />
  )
}

export function useSettingsContext(): SettingsContext {
  const context = useContext(SettingsContextInstance)

  if (!context) {
    throw new Error('useSettingsContext used without provider!')
  }

  return context
}
