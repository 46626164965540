import { useBreakpoints } from 'hooks/useBreakpoints'
import React, { FC, RefObject, useEffect, useRef, useState, useLayoutEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { messageLink, messagesLink } from 'global/routes'
import { Loader } from 'components-new/common/Loader/Loader'
import Message from 'services-new/conversation/message.model'
import classNames from 'classnames'
import { useMessagesContext } from 'containers-new/AccountMessagesContainer/MessagesContext'
import { LoadMoreMessages } from 'services-new/conversation/conversation-api'
import { useDispatch } from 'react-redux'
import { PaymentProtectionMessage } from '../atoms/PaymentProtectionMessage/PaymentProtectionMessage'
import { TypeMessageInput } from '../atoms/TypeMessageInput/TypeMessageInput'
import { Message as MessageComponent } from '../Message/Message'
import { ConversationHeader } from '../ConversationHeader/ConversationHeader'
import { NoConversations } from '../atoms/NoConversations/NoConversations'
import { NoMessages } from '../atoms/NoMessages/NoMessages'
import { ExperiencePreviewHeader } from '../ExperiencePreviewHeader/ExperiencePreviewHeader'
import LocalStorageService from '../../../../utils/LocalStorageService'
import styles from './Conversation.module.scss'
import { IConversation } from './Conversation.types'

export const Conversation: FC<IConversation> = (props: IConversation) => {
  const { conversation, onSend, className } = props
  const { isLoading } = useMessagesContext()
  const AccountMessagesContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const [paymentProtectionClose, setPaymentProtectionClose] = useState(true)

  const { isMobile } = useBreakpoints()
  const history = useHistory()
  const dispatch = useDispatch()
  const [paymentProtectionHeight, setPaymentProtectionHeight] = useState(0)
  const paymentProtectionRef = useRef<HTMLDivElement | null>(null)

  const hasMoreMessages = useMemo(() => {
    return conversation.messages ? !conversation.messages.some((m) => m.index === 0) : false
  }, [conversation.messages, conversation.messages?.length])

  useLayoutEffect(() => {
    scrollToBottom()
  }, [conversation?.messages, conversation?.messages?.length])

  useEffect(() => {
    setPaymentProtectionClose(LocalStorageService.getPaymentProtectionCloseFlag())
  }, [paymentProtectionClose])

  useEffect(() => {
    if (paymentProtectionRef.current) {
      setPaymentProtectionHeight(paymentProtectionRef.current.clientHeight)
    }
  })

  const scrollToBottom = () => {
    AccountMessagesContainerRef.current?.scrollTo(0, AccountMessagesContainerRef.current.scrollHeight)
  }

  const onSendMessage = (message: string) => {
    onSend?.(conversation, message)
  }

  const onScroll = async () => {
    if (AccountMessagesContainerRef.current?.scrollTop === 0 && hasMoreMessages && !isLoading(conversation.id)) {
      await dispatch(
        LoadMoreMessages({
          id: conversation.id,
          fromIndex: Math.min(...conversation.messages!.map((m) => m.index)) + 1,
        })
      )
    }
  }

  useEffect(() => {
    if (AccountMessagesContainerRef.current) {
      onScroll()
    }
  }, [AccountMessagesContainerRef.current])

  const onPaymentProtectionMessageClose = () => {
    LocalStorageService.setPaymentProtectionCloseFlag()
    setPaymentProtectionClose(true)
  }
  if (isLoading(conversation.id) && !conversation.messages) {
    return <Loader />
  }

  return (
    <div className={classNames([styles['conversation'], { [className!]: !!className }])}>
      {conversation ? (
        <>
          <ConversationHeader conversation={conversation} onBackClick={() => history.push(messagesLink())} />
          {isMobile && conversation?.experience && (
            <ExperiencePreviewHeader
              onDetailsClick={() => history.push(messageLink(conversation.id, 'details'))}
              experience={conversation.experience}
            />
          )}
          {!paymentProtectionClose && (
            <PaymentProtectionMessage elementRef={paymentProtectionRef} onClose={onPaymentProtectionMessageClose} />
          )}
          <div
            ref={AccountMessagesContainerRef}
            onScroll={onScroll}
            className={classNames(styles['conversation__messages-list'], {
              [styles['conversation__messages-list--has-messages']]: !!conversation.messages?.length,
            })}
          >
            {conversation.messages?.length
              ? conversation.messages.map((message: Message) => (
                  <MessageComponent key={message.index} conversation={conversation} message={message} />
                ))
              : !isLoading(conversation.id) && <NoMessages conversation={conversation} />}
          </div>
          <TypeMessageInput
            onMessageSend={(message: string) => onSendMessage(message)}
            isLoading={isLoading(conversation.id)}
          />
        </>
      ) : (
        <NoConversations />
      )}
    </div>
  )
}
