import ActivityFilter from 'components-new/search/ActivityFilter/ActivityFilter'
import AutocompleteLocationFilter from 'containers/AutocompleteLocationFilter/AutocompleteLocationFilter'
import { Button } from 'components-new/common/Button/Button'
import { useSearchContext } from 'containers-new/SearchContainer/SearchContext'
import { Icon } from 'components/Icon/Icon'
import { setLocations } from 'redux/SearchForm/actions'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import React, { useState, forwardRef, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { EXPLORE_PAGE, SEARCH_PAGE } from 'global/routes'
import { onChangeHandler } from '../ActivityFilter/ActivityFilter.types'
import styles from './SearchBar.module.scss'
import { SearchBarProps } from './SearchBar.types'

export const SearchBar = forwardRef<HTMLDivElement, SearchBarProps>((props, ref) => {
  const { onSubmit = () => {}, hidden = false, size = 'normal', className } = props
  const currentPage = useLocation()

  const isSearchPage = useMemo(() => {
    const page = currentPage.pathname.match(/^\/\w+\/?/)
    return page && (SEARCH_PAGE.startsWith(page[0]) || EXPLORE_PAGE.startsWith(page[0]))
  }, [])

  const { runSearch, isSearched, globalSearchParams } = useSearchContext()
  const dispatch = useDispatch()

  const [activity, setActivity] = useState<string | null | undefined>(globalSearchParams.activities?.[0])
  const [location, setLocation] = useState<string | null | undefined>(globalSearchParams.location)
  const [searchPrefilled, setSearchPrefilled] = useState<boolean>(false)

  const handleSearchClick = () => {
    runSearch({
      withSearchReset: true,
      searchParams: {
        activities: activity ? [activity] : [],
        location: location ? location : undefined,
      },
    })
    onSubmit()
  }

  useEffect(() => {
    if (isSearched && !searchPrefilled) {
      if (globalSearchParams.activities?.[0]) {
        setActivity(globalSearchParams.activities?.[0])
      }
      if (globalSearchParams.location) {
        dispatch(
          setLocations([
            {
              label: globalSearchParams.location
                .split(',')
                .filter((part) => part)
                .map((part) =>
                  part
                    .split(' ')
                    .map((p) => `${p[0].toUpperCase()}${p.slice(1)}`)
                    .join(' ')
                )
                .reverse()
                .join(', '),
              value: globalSearchParams.location,
            },
          ])
        )
        setLocation(globalSearchParams.location)
      }
      setSearchPrefilled(true)
    }
  }, [isSearched])

  const onActivityChange: onChangeHandler = (option) => {
    setActivity(option?.value)
    if (isSearchPage && searchPrefilled) {
      runSearch({
        withSearchReset: true,
        searchParams: {
          activities: option?.value ? [option?.value] : [],
          location: location ? location : undefined,
        },
      })
    }
  }

  const onLocationChange: onChangeHandler = (option) => {
    setLocation(option?.value)
    if (isSearchPage && searchPrefilled) {
      runSearch({
        withSearchReset: true,
        searchParams: {
          location: option?.value ? option?.value : undefined,
          activities: activity ? [activity] : [],
        },
      })
    }
  }

  const searchBarClasses = classNames({
    [className!]: !!className,
    [styles['search-bar']]: !!styles['search-bar'],
    [styles['search-bar--hidden']]: !!hidden,
    [styles[`search-bar--${size}`]]: !!size,
    [styles[`search-bar--search-page`]]: isSearchPage,
  })

  return (
    <>
      <div className={searchBarClasses} ref={ref}>
        <div className={classNames(styles['search-bar__filter'], styles['search-bar__filter--location'])}>
          <AutocompleteLocationFilter onChange={onLocationChange} value={location} />
        </div>
        <div className={classNames(styles['search-bar__filter'], styles['search-bar__filter--activity'])}>
          <ActivityFilter onChange={onActivityChange} value={activity} />
        </div>
        {!isSearchPage && (
          <>
            <Button
              onClick={handleSearchClick}
              className={styles['search-bar__secondary-button']}
              theme="clean"
              size="small"
            >
              <Icon icon="loupe" />
            </Button>
            <Button
              onClick={handleSearchClick}
              className={styles['search-bar__button']}
              theme="tertiary"
              radius="small"
              size="small"
            >
              Search
            </Button>
          </>
        )}
      </div>
    </>
  )
})
