import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Loader } from 'components-new/common/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { RemoveExternalAccount, UpdateExternalAccount } from 'services-new/stripe/payments-api'
import { RootState } from 'store'
import { paymentsAdapter, setAsDefault } from 'features/stripe/PaymentsSlice'
import { useUser } from 'hooks/useUser'
import { PaymentsExternalAccount } from '../PaymentsExternalAccount/PaymentsExternalAccount'
import s from './PaymentsExternalAccountsList.module.scss'

interface IPaymentsExternalAccountsList {
  className?: string
}

export const PaymentsExternalAccountsList: React.FC<IPaymentsExternalAccountsList> = ({ className }) => {
  const { user } = useUser()
  const dispatch = useDispatch()
  const state = useSelector((rootState: RootState) => rootState.payments)
  const { selectAll } = paymentsAdapter.getSelectors()
  const isInactive = user?.hostInfo?.status === 'inactive'

  const externalAccounts = useMemo(() => {
    return selectAll(state)
  }, [state])

  const onDelete = (id: string) => {
    dispatch(RemoveExternalAccount(id))
  }

  const onDefault = async (id: string) => {
    await dispatch(UpdateExternalAccount({ id, default_for_currency: true }))
    dispatch(setAsDefault(id))
  }

  if (!state.loaded && !isInactive) {
    return <Loader />
  }

  return (
    <div className={classNames(s.container, className)}>
      {state.loading && <Loader overlay={true} />}
      <h3>Your Accounts and Debit Cards</h3>
      <div className={s.content}>
        {externalAccounts.length === 0 && <p>No accounts connected. Please add new payment method.</p>}
        {externalAccounts.length > 0 &&
          externalAccounts.map((account) => {
            const { object, default_for_currency, last4, id } = account
            switch (object) {
              case 'card':
                return (
                  <PaymentsExternalAccount
                    key={id}
                    id={id}
                    label="Card"
                    title={account.brand}
                    isDefault={!!default_for_currency}
                    number={`∗∗∗∗ ∗∗∗∗ ∗∗∗∗ ${last4}`}
                    onDelete={onDelete}
                    onDefault={onDefault}
                  />
                )
              case 'bank_account':
                return (
                  <PaymentsExternalAccount
                    key={id}
                    id={id}
                    label="Account"
                    title={account.account_holder_name!}
                    isDefault={!!default_for_currency}
                    number={`∗∗∗∗∗∗∗∗∗ ∗∗∗∗∗∗∗∗${last4}`}
                    onDelete={onDelete}
                    onDefault={onDefault}
                  />
                )
              default:
                return null
            }
          })}
      </div>
    </div>
  )
}
