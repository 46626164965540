import React, { FC } from 'react'
import { Input } from 'components-new/forms/Input/Input'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components-new/common/Button/Button'
import { useForm } from 'react-hook-form'
import { Loader } from 'components-new/common/Loader/Loader'
import loading from 'assets/lottie/loading-white.json'
import styles from './TypeMessageInput.module.scss'
import { ITypeMessageInput, TypeMessageInputForm } from './TypeMessageInput.types'

export const TypeMessageInput: FC<ITypeMessageInput> = (props: ITypeMessageInput) => {
  const { onMessageSend, isLoading } = props

  const { register, reset, handleSubmit } = useForm<TypeMessageInputForm>({
    defaultValues: {
      value: '',
    },
  })

  const onSubmitInput = ({ value }: TypeMessageInputForm) => {
    onMessageSend(value)
    reset()
  }

  const renderSendButton = () => (
    <Button type={'submit'} className={styles['send-button']}>
      {isLoading ? (
        <Loader animationData={loading} size={30} className={styles['send-button__loader']} />
      ) : (
        <Icon icon={'arrow_up'} className={styles['send-button__icon']} />
      )}
    </Button>
  )
  return (
    <form className={styles['type-message-input']} onSubmit={handleSubmit(onSubmitInput)}>
      <Input
        {...register('value', {
          required: true,
        })}
        placeholder="Type a message"
        autoComplete="off"
        append={renderSendButton()}
        className={styles['type-message-input__label']}
      />
    </form>
  )
}
