import { Input } from 'components-new/forms/Input/Input'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormGroup } from '../FormGroup/FormGroup'
import { PasswordChangeFormInputsProps } from './PasswordChangeFormInputs.types'

export const PasswordChangeFormInputs: FC<PasswordChangeFormInputsProps> = (props) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <Controller
        name="password"
        rules={{
          deps: ['confirmPassword'],
          required: 'This field is required',
          minLength: {
            value: 7,
            message: 'Minimum password length is 7 characters',
          },
          pattern: {
            message: 'Field cannot contain spaces',
            value: /^\S*$/,
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Input {...field} type="password" error={error?.message} placeholder="New Password" label="New Password" />
        )}
      />
      <Controller
        name="confirmPassword"
        rules={{
          required: 'This field is required',
          minLength: {
            value: 7,
            message: 'Minimum password length is 7 characters',
          },
          pattern: {
            message: 'Field cannot contain spaces',
            value: /^\S*$/,
          },
          validate: {
            confirmPasswordMatch: (value) => {
              return value === getValues('password') || 'Confirm Password dont match'
            },
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            error={error?.message}
            placeholder="Confirm New Password"
            label="Confirm New Password"
            type="password"
          />
        )}
      />
    </>
  )
}
