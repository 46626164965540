import { CheckoutState } from 'redux/Checkout/reducer'
import { AppDate } from 'services-new/shared/date'

export class CheckoutDTO {
  guests: number
  marketingPageId?: number | null
  discountCode: string
  date: AppDate | null
  time: number | null

  constructor(checkout: CheckoutState) {
    this.marketingPageId = checkout.marketingPage?.id
    this.guests = checkout.guests
    this.discountCode = checkout.discountCode
    this.date = checkout.date
    this.time = checkout.time
  }
}

export const prepareDataToSave = (checkout: CheckoutState) =>
  new CheckoutDTO({
    ...checkout,
  })
