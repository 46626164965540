import { checkoutLink, experienceLink } from 'global/routes'
import { CheckoutState } from 'redux/Checkout/reducer'
import { useCheckout } from 'hooks/useCheckout'
import { useStepValidator } from 'hooks/useStepValidator'
import useSchedule, { useScheduleReturn } from 'hooks/useSchedule'
import { isEqual } from 'lodash'
import React, { FC, createContext, useContext, useState, useCallback } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useEmbed } from 'hooks/useEmbed'
import { CheckoutContext, CheckoutValidatorData } from './CheckoutContext.types'

const CheckoutContextInstance = createContext<CheckoutContext>({} as CheckoutContext)

export const CHECKOUT_STEPS = [
  {
    label: 'Select Dates',
    slug: 'dates',
    validToEnter: (data: CheckoutValidatorData) => {
      return !!data.state.marketingPage
    },
    validToLeave: (data: CheckoutValidatorData) => {
      return data.checkout.isDateValid
    },
    back: (data: CheckoutValidatorData) => {
      return {
        label: 'Back to event details',
        link: data.state.marketingPage ? experienceLink(data.state.marketingPage?.slug) : '/',
      }
    },
  },
  {
    label: 'Request Booking',
    slug: 'booking',
    validToEnter: (data: CheckoutValidatorData) => {
      return !!data.state.date && Number.isInteger(data.state.time) && data.checkout.isDateValid
    },
    validToLeave: (data: CheckoutValidatorData) => {
      return true
    },
    back: (data: CheckoutValidatorData) => {
      return {
        label: 'Back',
        link: data.state.marketingPage
          ? checkoutLink(data.state.marketingPage.id, data.state.date?.toISOString(), data.state.time)
          : '/',
      }
    },
  },
  {
    label: 'Confirmation',
    slug: 'confirm',
  },
]

export const CheckoutContextProvider: FC<{ initialStepSlug?: string }> = (props) => {
  const { children, initialStepSlug = null } = props

  const checkoutObject = useCheckout()
  const history = useHistory()
  const { sourceUrl } = useEmbed({ trip: checkoutObject.state.marketingPage })

  const [propsToValidate, setPropsToValidate] = useState<
    Pick<CheckoutState, 'date' | 'time' | 'marketingPage' | 'guests' | 'discountCode'>
  >({
    date: checkoutObject.state.date,
    time: checkoutObject.state.time,
    marketingPage: checkoutObject.state.marketingPage,
    guests: checkoutObject.state.guests,
    discountCode: checkoutObject.state.discountCode,
  })

  const validatorObject = useStepValidator<CheckoutValidatorData>(
    CHECKOUT_STEPS,
    { state: checkoutObject.state, checkout: checkoutObject.checkout },
    useCallback((step, args) => {
      const { date, time } = args.state
      if (date) {
        history.push(checkoutLink(args.state.marketingPage!.id, date?.toISOString(), time, step.slug))
      }
    }, [])
  )

  const availabilityObject: useScheduleReturn = useSchedule({
    experienceId: checkoutObject.state.marketingPage?.id || null,
    guests: checkoutObject.state.guests,
  })

  useEffect(() => {
    const stepIndex = initialStepSlug ? CHECKOUT_STEPS.findIndex((step) => step.slug === initialStepSlug) : 0
    validatorObject.setActiveIndex(stepIndex)
  }, [initialStepSlug])

  useEffect(() => {
    if (propsToValidate.marketingPage) {
      checkoutObject.validate({ source: sourceUrl })
    }
  }, [propsToValidate])

  const complete = (data: any) => {
    return checkoutObject.complete({ source: sourceUrl, ...data })
  }

  useEffect(() => {
    setPropsToValidate((prev) => {
      const newSate = {
        date: checkoutObject.state.date,
        time: checkoutObject.state.time,
        marketingPage: checkoutObject.state.marketingPage,
        guests: checkoutObject.state.guests,
        discountCode: checkoutObject.state.discountCode,
      }
      if (isEqual(prev, newSate)) {
        return prev
      }
      return newSate
    })
  }, [checkoutObject.state])

  return (
    <CheckoutContextInstance.Provider
      value={{
        ...checkoutObject,
        ...validatorObject,
        complete,
        schedule: availabilityObject,
        steps: CHECKOUT_STEPS,
      }}
    >
      {children}
    </CheckoutContextInstance.Provider>
  )
}

export function useCheckoutContext(): CheckoutContext {
  const context = useContext(CheckoutContextInstance)

  if (!context) {
    throw new Error('useCheckoutContext used without provider!')
  }

  return context
}
