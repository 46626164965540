import { useLocation } from 'react-router'

declare global {
  interface Window {
    OG: {
      appType?: 'iOS' | 'Android'
      appVersion?: string
    }
  }
}

window.OG = window.OG || {}

export const useMobileApp = () => {
  const location = useLocation()
  const isDev = location.search.includes('dev_mobile_app=true')
  if (isDev) {
    window.OG = {
      appType: 'iOS',
      appVersion: '0.0.0',
    }
  }

  const isMobileApp = !!window.OG.appType

  return {
    isMobileApp,
    mobileAppType: window.OG.appType,
    mobileAppVersion: window.OG.appVersion,
  }
}
