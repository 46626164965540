export const HeaderActions = {
  SET_FADE: '@header/setFade',
  SET_LOCATIONS: '@header/setLocations',
}

export function setFade(value) {
  return {
    type: HeaderActions.SET_FADE,
    payload: value,
  }
}

export function setLocations(value) {
  return {
    type: HeaderActions.SET_LOCATIONS,
    payload: value,
  }
}
