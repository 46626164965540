import React, { MouseEventHandler } from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import BadgeLicensed from 'assets/img/guide/badge-licensed.svg'
import BadgeInsured from 'assets/img/guide/badge-insured.svg'
import BadgeGuiding from 'assets/img/guide/badge-guiding.svg'
import { Icon } from 'components/Icon/Icon'
import UserPublic from 'services/shared/models/UserPublic'
import { Image } from 'components-new/common/Image/Image'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import styles from './HeroHost.module.scss'

interface IHeroHost {
  hostBasics: UserPublic
}

export const HeroHost: React.FC<IHeroHost> = ({ hostBasics }) => {
  const { guideDefaultBackground } = useSettingsContext()
  const image = hostBasics.background_image ? hostBasics.background_image : guideDefaultBackground

  return (
    <>
      {image && (
        <Image
          src={image.getHugeSize()}
          srcSet={image.getSrcSet()}
          sizes={['150vw']}
          alt={hostBasics.name}
          className={styles['hero-host__bg']}
          theme="rect"
        />
      )}
      <Wrapper containerClasses={styles['hero-host']}>
        <div className={styles['hero-host__avatar-wrapper']}>
          {hostBasics.hostInfo?.image && (
            <Image
              src={hostBasics.hostInfo?.image.getSmallSize()}
              alt={hostBasics.name}
              wrapperClassName={styles['hero-host__avatar']}
              theme="round"
            />
          )}
        </div>
      </Wrapper>
    </>
  )
}
