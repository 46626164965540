import Experience, { ExperienceInput } from 'services-new/experience/experience.model'
import { AppDate } from 'services-new/shared/date'
import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'

export interface BookingInput {
  id: number
  status: 'pending' | 'accepted' | 'rejected' | 'cancelled' | 'past'
  note: string | null
  amount: number
  cancel_amount?: number
  fees_amount: number
  sub_amount: number
  created_at: string
  updated_at: string
  end_date: string
  start_date: string
  guests: number
  host: UserPublicInput
  user: UserPublicInput
  marketing_page: ExperienceInput
}

const BOOKING_STATUS_NAME_MAP: Record<Booking['status'], Booking['statusName']> = {
  pending: 'Request',
  accepted: 'Scheduled',
  cancelled: 'Cancelled',
  past: 'Past',
  rejected: 'Denied',
}

class Booking {
  id: number
  status: 'pending' | 'accepted' | 'rejected' | 'cancelled' | 'past'
  statusName: 'Request' | 'Scheduled' | 'Cancelled' | 'Past' | 'Denied'
  note: string | null
  amount: number
  cancelAmount?: number
  feesAmount: number
  subAmount: number
  startDate: AppDate
  endDate: AppDate
  durationInMinutes: number
  createdAt: string
  updatedAt: string
  guests: number
  user: UserPublic
  host: UserPublic
  experience: Experience

  constructor(booking: BookingInput) {
    this.id = booking.id
    this.status = booking.status
    this.cancelAmount = booking.cancel_amount ? booking.cancel_amount / 100 : booking.cancel_amount
    this.note = booking.note
    this.amount = booking.amount / 100
    this.feesAmount = booking.fees_amount / 100
    this.subAmount = booking.sub_amount / 100
    this.startDate = new AppDate(booking.start_date).toLocal()
    this.endDate = new AppDate(booking.end_date).toLocal()
    this.durationInMinutes = (this.endDate.getTime() - this.startDate.getTime()) / 1000 / 60
    this.createdAt = booking.created_at
    this.updatedAt = booking.updated_at
    this.experience = new Experience(booking.marketing_page)
    this.guests = booking.guests
    this.host = booking.host && (typeof booking.host === 'number' ? booking.host : new UserPublic(booking.host))
    this.user = booking.user && (typeof booking.user === 'number' ? booking.user : new UserPublic(booking.user))
    this.statusName = BOOKING_STATUS_NAME_MAP[booking.status]
  }

  isCancellableForUser(user?: UserPublic | null) {
    if (!user) {
      return false
    }
    if (user.id === this.host.id) {
      return ['accepted'].includes(this.status)
    } else if (user.id === this.user.id) {
      return ['accepted', 'pending'].includes(this.status)
    }
    return false
  }

  isHostedByUser(user?: UserPublic | null) {
    if (!user) {
      return false
    }

    if (user.id === this.host.id) {
      return true
    }
    return false
  }

  isUsers(user?: UserPublic | null) {
    if (!user) {
      return false
    }

    if (user.id === this.user.id) {
      return true
    }
    return false
  }

  static fromArray(input: BookingInput[] | null): Booking[] {
    return input ? input.map((i) => new Booking(i)) : []
  }
}

export default Booking
