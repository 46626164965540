import Axios from 'axios-observable'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { toast } from 'react-toastify'
import * as selectn from 'selectn'
import LocalStorageService from 'utils/LocalStorageService'

Axios.interceptors.request.use(
  (config) => {
    const localStorageService = LocalStorageService.getService()

    const token = localStorageService.getJWTToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export const HEADER_FORM_DATA = {
  headers: { 'Content-Type': 'multipart/form-data' },
}

export function handleHttpError(error) {
  // eslint-disable-next-line max-len

  let message =
    selectn('message[0].messages[0].message', error) || selectn('data[0].message', error) || error?.message || error

  const details = []
  const errorDetails = selectn('data.errors', error) || {}
  Object.keys(errorDetails).forEach((key) => {
    if (errorDetails[key].length) {
      details.push(errorDetails[key][0])
    }
  })
  if (message === 'Forbidden') {
    message = 'Oops! Something went wrong! Please try again later'
  }
  toast(`${message}${details.length ? ' - ' + details.join(', ') : ''}`)

  if (error?.status === 403) {
    // TODO: add handling for not-auth requests
    // console.error('Not authorized - 403');
  } else if (error?.status === 404) {
    // TODO: add handling for not found requests
    // console.error('Not found- 404');
  } else {
    // TODO: add handling for other requests
    // console.error('Other error from http');
  }

  throw error
}

function apiCall$(httpCall, skipErrorHandling) {
  if (skipErrorHandling) {
    return httpCall
  }
  return httpCall.pipe(
    catchError((error) => throwError(handleHttpError(selectn('response.data', error)))),
    map((response) => response.data)
  )
}

export function getRequest$(path, options = {}, skipErrorHandling = false) {
  return apiCall$(Axios.get(path, options), skipErrorHandling)
}

export function postRequest$(path, data, options = {}, skipErrorHandling = false) {
  return apiCall$(Axios.post(path, data, options), skipErrorHandling)
}

export function putRequest$(path, data, options = {}, skipErrorHandling = false) {
  return apiCall$(Axios.put(path, data, options), skipErrorHandling)
}

export function deleteRequest$(path, options = {}, skipErrorHandling = false) {
  return apiCall$(Axios.delete(path, options), skipErrorHandling)
}
