import React, { cloneElement, forwardRef, ReactElement, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Loader } from '../Loader/Loader'
import { ButtonProps } from './Button.types'
import styles from './Button.module.scss'

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(function Button(
  {
    children,
    className,
    theme = 'primary',
    size = 'normal',
    text = 'normal',
    type = 'button',
    to,
    clickOverlay,
    isActive,
    disabled,
    target,
    isNavLink,
    name,
    form,
    loading,
    radius = 'small',
    onClick,
    icon,
  },
  ref
) {
  const themes = theme ? (Array.isArray(theme) ? theme : [theme]) : []
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const generatedClasses = classNames(
    className,
    {
      [styles['button']]: !!styles['button'],
      [styles[`button--${size}`]]: !!size,
      [styles[`button--radius-${radius}`]]: !!radius,
      [styles[`button--text-${text}`]]: !!text,
      [styles[`button--disabled`]]: !!disabled,
      [styles[`button--loading`]]: !!loading,
      [styles[`button--active`]]: !!isActive,
      [styles[`button--hover`]]: isHovered,
    },
    themes.map((t) => styles[`button--${t}`])
  )

  let Component: any = 'button'
  const rest = {}

  if (to) {
    let isExternalLink = false

    if (typeof to === 'string') {
      isExternalLink = to.includes('://') || to.includes('#')
    }

    if (isExternalLink) {
      Component = 'a'
      Object.assign(rest, {
        href: to,
        target,
        ...(target === '_blank' && { rel: 'noopener noreferrer' }),
      })
    } else {
      Component = isNavLink ? NavLink : Link
      Object.assign(rest, {
        to,
        innerRef: ref,
      })
    }
  } else {
    if (name) {
      Object.assign(rest, {
        name,
      })
    }
  }

  const overlayWrapper = (content: ReactElement) => {
    return clickOverlay ? (
      <>
        <Component
          to={to}
          onClick={onClick}
          className={styles['button__overlay']}
          type={type}
          disabled={disabled}
          form={form}
          onMouseEnter={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
          {...rest}
        ></Component>
        <span className={generatedClasses}>{content}</span>
      </>
    ) : (
      <Component
        className={generatedClasses}
        onClick={onClick}
        type={type}
        disabled={disabled}
        form={form}
        onMouseEnter={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        {...rest}
      >
        {content}
      </Component>
    )
  }

  return overlayWrapper(
    <>
      {!!icon && cloneElement(icon, { className: styles[`button__icon`] })}
      {children}
      {loading && <Loader className={styles['button__loader']} size={50} />}
    </>
  )
})
