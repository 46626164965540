import { Image as ImageComponent } from 'components-new/common/Image/Image'
import Image from 'services/shared/models/Image'
import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './BlogCard.module.scss'

interface IBlogCard {
  className?: string
  slug: string
  image: Image | null
  title: string
  excerpt: string
}

export const BlogCard: React.FC<IBlogCard> = ({ className, slug, image, title, excerpt }) => {
  return (
    <Link to={'/blog/' + slug} className={classNames(className, styles['blog-card'])}>
      {image && (
        <ImageComponent
          loading="lazy"
          src={image.getSmallSize()}
          alt={image.alternativeText}
          className={styles['blog-card__image']}
          srcSet={image.getSrcSet()}
          sizes={['100vw', '(min-width: 585px) 640px', '(min-width: 1265px) 960px']}
        />
      )}
      <h3 className={styles['blog-card__title']}>{title}</h3>
      <p className={styles['blog-card__description']}>{excerpt}</p>
    </Link>
  )
}
