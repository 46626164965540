import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ActivitiesProperty from 'services/shared/models/ActivitiesProperty'
import Activity from 'services/shared/models/Activity'
import { searchMarketingPagesRaw$ } from 'services/marketing-page/marketing-page-api'
import MarketingDetails, { MarketingDetailsElasticInput } from 'services/marketing-page/models/MarketingDetails'

import { keys, difference, uniqBy } from 'lodash'
import { ReplaySubject } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { AppDate } from 'services-new/shared/date'

export const DEFAULT_SEARCH_PARAMS = {
  activities: [],
  coordinates: undefined,
  properties: [],
  ids: [],
  location: undefined,
  featured: undefined,
  recent: undefined,
  guests: undefined,
  minDate: undefined,
  maxDate: undefined,
  _limit: 100,
  _start: 0,
  _sort: undefined,
} as SearchParams

export const useSearch = (): useSearchReturn => {
  const [results, setResults] = useState<MarketingDetails[]>([])
  // set Total results to -1 initially to allow to check if there was any search run
  const [totalResults, setTotalResults] = useState<number>(-1)
  const [isLoading, setLoadingFlag] = useState<boolean>(false)
  const lastParams = useRef<SearchParams>({})
  const lastArgs = useRef<RunArgs>({})
  const subject$ = useRef(new ReplaySubject<SearchParams>())

  const validateSearchParams = useCallback((params) => {
    const invalidParams = difference(keys(params), keys(DEFAULT_SEARCH_PARAMS))
    return invalidParams.length === 0
  }, [])

  const searchCallback = useCallback((response: any) => {
    const {
      hits: {
        hits,
        total: { value: total },
      },
    } = response

    const marketingDetails = hits.map((hit: MarketingDetailsElasticInput) => new MarketingDetails(hit._source))

    setResults((prev) =>
      lastArgs.current.isLoadingMore
        ? lastArgs.current.order === 'desc'
          ? uniqBy([...marketingDetails, ...prev].reverse(), 'id').reverse()
          : uniqBy([...prev, ...marketingDetails], 'id')
        : marketingDetails
    )
    setTotalResults(total)
    setLoadingFlag(false)
  }, [])

  useEffect(() => {
    const subscription = subject$.current
      .pipe(
        tap(() => {
          setLoadingFlag(true)
        }),
        switchMap((data: SearchParams) => {
          return searchMarketingPagesRaw$(data)
        })
      )
      .subscribe(searchCallback)

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const run: RunSearch = useCallback(
    (params, args = {}) => {
      if (!validateSearchParams(params)) {
        throw new Error('invalid useSearch() search params')
      }
      lastParams.current = params
      lastArgs.current = args
      subject$.current.next(params)
    },
    [subject$, lastArgs, lastParams, setLoadingFlag]
  )

  const reset = useCallback(() => {
    setTotalResults(-1)
    setResults([])
    lastParams.current = {}
  }, [setTotalResults, setResults, lastParams])

  const hasMore = useMemo(() => {
    return results.length < totalResults
  }, [results, totalResults])

  const isSearched = useMemo(() => {
    return totalResults !== -1
  }, [totalResults])

  const loadMore = useCallback(
    (args) => {
      if (lastParams.current && lastParams.current._limit) {
        run(
          {
            ...lastParams.current,
            _start: (lastParams.current._start || 0) + lastParams.current._limit,
          },
          { ...args, isLoadingMore: true }
        )
      }
    },
    [lastParams.current]
  )

  return {
    run,
    reset,
    loadMore,
    isLoading,
    hasMore,
    isSearched,
    results,
    totalResults,
  }
}

type RunArgs = { isLoadingMore?: boolean; order?: 'asc' | 'desc' }
type RunSearch = (params: SearchParams, args?: RunArgs) => void
export interface useSearchReturn {
  run: RunSearch
  reset: () => void
  loadMore: (args?: { order?: 'asc' | 'desc' }) => void
  isLoading: boolean
  isSearched: boolean
  hasMore: boolean
  results: MarketingDetails[]
  totalResults: number
}

interface Object {
  [key: string]: any
}
export interface SearchParams extends Object {
  activities?: Activity['name'][]
  properties?: {
    name: ActivitiesProperty['name']
    value: ActivitiesProperty['values']
  }[]
  coordinates?: Coordinates | null
  location?: string
  featured?: boolean
  recent?: boolean
  guests?: number
  minDate?: AppDate
  maxDate?: AppDate
  _limit?: number
  _start?: number
  _sort?: string | null
}

export type ParsedSearchParams = Omit<SearchParams, 'minDate' | 'maxDate'> & {
  minDate?: string
  maxDate?: string
}

export type Coordinates =
  | string
  | {
      top_left: {
        lat: string
        lon: string
      }
      bottom_right: {
        lat: string
        lon: string
      }
    }

export interface ElasticSearchResults<T> {
  hits: {
    hits: T[]
    total: {
      value: number
      relation: string
    }
  }
}
