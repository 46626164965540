import React, { useMemo } from 'react'
import { SectionWrapper } from 'containers-new/SectionWrapper/SectionWrapper'
import { CustomList } from 'components-new/common/CustomList/CustomList'
import { TextSection } from '../TextSection/TextSection'
import { ITextWithPointsSection } from './TextWithPointsSection.types'
import styles from './TextWithPointsSection.module.scss'

export const TextWithPointsSection: React.FC<ITextWithPointsSection> = ({ text, points, title }) => {
  // we need to filter out empty points due to exprience preview / react form
  // setting up empty points with placeholder

  const filteredPoints = useMemo(() => {
    return points.filter((p) => !!p && p.text)
  }, [points])

  return text || filteredPoints.length > 0 ? (
    <SectionWrapper title={title}>
      <div className={styles['text-with-point-section']}>
        <TextSection text={text} />
        <CustomList listItems={filteredPoints.map((point) => point.text)} />
      </div>
    </SectionWrapper>
  ) : null
}
