import { SearchFormActions } from 'redux/SearchForm/actions'

const initialState = {
  minPrice: undefined,
  maxPrice: undefined,
  priceRange: undefined,
  dateRange: undefined,
  guests: undefined,
  activities: undefined,
  location: undefined,
  locations: [],
  startDate: undefined,
  endDate: undefined,
  coordinates: undefined,
  countries: [],
  properties: [],
}

export function searchFormReducer(state = initialState, action) {
  switch (action.type) {
    case SearchFormActions.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      }
    case SearchFormActions.SET_PRICE_RANGE:
      return {
        ...state,
        priceRange: action.payload,
      }
    case SearchFormActions.SET_GUESTS:
      return {
        ...state,
        guests: action.payload,
      }
    case SearchFormActions.SET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
      }
    case SearchFormActions.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      }
    case SearchFormActions.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      }
    case SearchFormActions.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      }
    case SearchFormActions.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      }
    case SearchFormActions.SET_AGGREGATIONS:
      return {
        ...state,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
        countries: action.payload.countries,
      }
    case SearchFormActions.SET_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      }
    case SearchFormActions.SET_COORDINATES:
      return {
        ...state,
        coordinates: action.payload,
      }

    default:
      return state
  }
}
