import React from 'react'
import _ from 'lodash'
import starIcon from 'assets/icons/icon_star_yellow.svg'
import classNames from 'classnames'
import { Image } from 'components-new/common/Image/Image'
import styles from './ReviewCard.module.scss'
import { IReviewCard } from './ReviewCard.types'

export const ReviewCard: React.FC<IReviewCard> = ({ className, item }) => {
  // @ts-ignore
  let stars: any = []
  _.times(item.stars, (i) => {
    stars.push(<img src={starIcon} alt="" key={i} className={styles['review-card__star']} />)
  })
  return (
    <div className={classNames(className, styles['review-card'])}>
      <Image loading="lazy" src={item.image} alt={item.name} className={styles['review-card__image']} />
      <div className={styles['review-card__name']}>{item.name}</div>
      <div className={styles['review-card__stars-wrapper']}>{stars}</div>
      <p className={styles['review-card__description']}>"{item.description}"</p>
    </div>
  )
}
