import React from 'react'
import { FC } from 'react'
import { messageLink } from 'global/routes'
import { useHistory } from 'react-router'
import Conversation from 'services-new/conversation/conversation.model'
import { Loader } from 'components-new/common/Loader/Loader'
import classNames from 'classnames'
import { ConversationPreview } from '../ConversationPreview/ConversationPreview'
import { EmptyConversationList } from '../EmptyConversationList/EmptyConversationList'
import { useMessagesContext } from '../../MessagesContext'
import { IConversationsList } from './ConversationsList.types'
import styles from './ConversationsList.module.scss'

export const ConversationsList: FC<IConversationsList> = (props: IConversationsList) => {
  const { conversation: currentConversation, className } = props
  const { conversations, isLoading } = useMessagesContext()
  const history = useHistory()

  const onConversationClick = (data: any) => {
    history.push(messageLink(data.id))
  }

  if (isLoading() && conversations.length === 0) {
    return <Loader />
  }

  return (
    <div className={classNames([styles['conversation-list'], { [className!]: !!className }])}>
      <div className={styles['conversation-list__header']}>Messages</div>
      {conversations.length ? (
        conversations.map((conversation: Conversation) => (
          <ConversationPreview
            key={conversation.id}
            className={styles['conversation-list__item']}
            onClick={() => onConversationClick(conversation)}
            conversation={conversation}
            selected={!!conversation && conversation.id === currentConversation?.id}
          />
        ))
      ) : (
        <EmptyConversationList />
      )}
    </div>
  )
}
