import { Button } from 'components-new/common/Button/Button'
import { Image } from 'components-new/common/Image/Image'
import { experienceEditLink, experienceLink } from 'global/routes'
import { DeleteExperience, UpdateExperience } from 'services-new/experience/experience-api'
import Experience from 'services-new/experience/experience.model'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './EditTrip.module.scss'
import { EditTripProps } from './EditTrip.types'

export const EditTrip: React.FC<EditTripProps> = ({ data, durationDescription, guestsDescription, onEmbed }) => {
  const { id, title, locations, images, pricing, status } = data
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isPublished = status === 'published'

  const dispatch = useDispatch()

  const deleteEvent = async () => {
    setIsLoading(true)
    await dispatch(DeleteExperience({ id }))
    setIsLoading(false)
  }

  const onDelete = () => {
    if (window.confirm('Are you sure?')) {
      deleteEvent()
    }
  }

  const changeTripStatus = async (newStatus: string) => {
    setIsLoading(true)
    const newData = { ...data, status: newStatus }
    await dispatch(UpdateExperience(Experience.toOutput(newData)))
    setIsLoading(false)
  }

  return (
    <div className={styles['edit-trip__wrapper']}>
      <Image src={images[0].url} wrapperClassName={styles['edit-trip__image']} theme="rect" />
      <div className={styles['edit-trip__content']}>
        <div className={styles['edit-trip__description']}>
          <div>
            <h2>
              <Link to={isPublished ? experienceLink(data.slug) : experienceEditLink(data.id)}>{title}</Link>
            </h2>
            <p className={styles['edit-trip__location']}>
              {`${locations[0].address?.city}, ${
                locations[0].address?.state ? locations[0].address?.state : locations[0].address?.country
              }`}
            </p>
            <p className={styles['edit-trip__duration']}>
              {`${guestsDescription} `}&#183;{` ${durationDescription}`}
            </p>
            <p className={styles['edit-trip__total']}>Total </p>
            <span className={styles['edit-trip__pricing']}> ${pricing.price}</span>
          </div>
          {isPublished && (
            <span className={styles['edit-trip__published']}>
              <div className={styles['edit-trip__published-dot']} /> Published
            </span>
          )}
          {!isPublished && <span className={styles['edit-trip__unpublished']}>Unpublished</span>}
        </div>
        <hr />
        <div className={styles['edit-trip__buttons']}>
          {isPublished && (
            <Button
              onClick={() => changeTripStatus('draft')}
              theme="plain-orange-secondary"
              text="medium"
              size="extra-small"
              loading={isLoading}
            >
              Unpublish
            </Button>
          )}
          {!isPublished && (
            <Button
              onClick={() => changeTripStatus('published')}
              theme="orange"
              text="medium"
              size="extra-small"
              loading={isLoading}
            >
              Publish
            </Button>
          )}
          <Link to={experienceEditLink(id)}>
            <Button theme="plain" text="medium" size="extra-small" loading={isLoading}>
              Edit
            </Button>
          </Link>
          {isPublished && (
            <Button size="extra-small" text="medium" onClick={() => onEmbed?.()} theme="plain">
              Embed
            </Button>
          )}
          <Button onClick={onDelete} theme="transparent" text="medium" size="extra-small" loading={isLoading}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}
