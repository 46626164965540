import React, { FC } from 'react'
import { colorGray0, colorGray1 } from 'global/variables'
import ContentLoader from 'react-content-loader'
import { ISkeletonLoader } from './SkeletonLoader.types'

export const SkeletonLoader: FC<ISkeletonLoader> = (props: ISkeletonLoader) => {
  const { loading, children, height, width, title, ...otherProps } = props
  return (
    <ContentLoader
      speed={loading ? 4 : 0}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={colorGray0}
      foregroundColor={colorGray1}
      {...otherProps}
      width={width}
      height={height}
      title={loading ? title : ''}
    >
      {children}
    </ContentLoader>
  )
}
