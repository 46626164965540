import React, { forwardRef } from 'react'
import { FormField } from '../FormField/FormField'
import { SelectProps } from './Select.types'

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(props, ref) {
  const {
    children,
    className,
    placeholder,
    required,
    name,
    value,
    labelProp = 'label',
    valueProp = 'value',
    label,
    error,
    success,
    defaultValue,
    options,
    allowEmpty,
    theme,
    ...nativeProps
  } = props

  const FakeSelect = () => <></>

  return (
    <FormField label={label} error={error} success={success} type={'select'} prepend={<FakeSelect />} theme={theme}>
      <select
        ref={ref}
        placeholder={placeholder}
        required={required}
        name={name}
        value={typeof value === 'object' && value !== null ? value[valueProp] : value}
        defaultValue={typeof defaultValue === 'object' && value !== null ? defaultValue[valueProp] : defaultValue}
        {...nativeProps}
      >
        {allowEmpty && <option value="">Select {label}</option>}
        {options &&
          options.map((option) => (
            <option
              key={typeof option === 'object' ? option[valueProp] : option}
              value={typeof option === 'object' ? option[valueProp] : option}
            >
              {typeof option === 'object' ? option[labelProp] : option}
            </option>
          ))}
      </select>
    </FormField>
  )
})
