import { Result } from '@mapbox/mapbox-gl-geocoder'

export interface AddressInput {
  id?: number
  address?: string | null
  city: string | null
  state: string | null
  country: string | null
  zip?: string | null
}

export interface MapBoxProperties {
  id: 'place' | 'region' | 'country'
  text: string
}

class Address {
  id?: number
  address?: string | null
  city: string | null
  state: string | null
  country: string | null
  zip?: string | null
  locationString: string
  fullLocationString: string
  locationSearchString?: string

  constructor(address: AddressInput) {
    if (address.id) {
      this.id = address.id
    }
    this.address = address.address
    this.city = address.city
    this.state = address.state
    this.zip = address.zip
    this.country = address.country
    this.locationString = ''
    const countryParts = []

    if (address.country) {
      if (address.country.toLowerCase().indexOf('united states') === 0) {
        countryParts.push(...[address.city, address.state])
      } else {
        countryParts.push(...[address.city, address.country])
      }
    } else {
      countryParts.push(...[address.city, address.state])
    }

    this.locationString = countryParts.filter((part) => part).join(', ')
    this.fullLocationString = [address.city, address.state, address.country].filter((e) => e).join(', ')
    this.locationSearchString = Address.getSearchLocationString(address)
  }

  toString() {
    return this.locationString
  }
  static getSearchLocationString(obj: any) {
    const string = [obj.country || '', obj.state || '', obj.city || ''].join(',').toLowerCase()
    return string === ',,' ? undefined : string
  }
  static fromArray(input: AddressInput[] | null): Address[] {
    return input ? input.map((i) => new Address(i)) : []
  }

  static toOutput(data: Partial<Address>) {
    return {
      id: data.id,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country: data.country,
    }
  }
}
function extractLocation(
  locationProperties: Array<Result | MapBoxProperties> | undefined,
  propertyName: MapBoxProperties['id']
) {
  const findResult = locationProperties
    ? locationProperties.find((prop) => (typeof prop.id === 'string' ? prop.id.indexOf(propertyName) !== -1 : false))
    : null
  return findResult ? findResult.text : ''
}

export function extractAddressMapBox(mapResponse: Result) {
  const locationProperties = mapResponse.context
  const props = {
    city: extractLocation(locationProperties, 'place'),
    state: extractLocation(locationProperties, 'region'),
    country: extractLocation(locationProperties, 'country'),
  }

  if (!props.city) {
    props.city = extractLocation([mapResponse], 'place')
  }

  if (!props.state) {
    props.state = extractLocation([mapResponse], 'region')
  }

  if (!props.country) {
    props.country = extractLocation([mapResponse], 'country')
  }

  return new Address(props)
}

export default Address
