import { AcceptBooking, CancelBooking, RejectBooking } from 'services-new/booking/booking-api'

import { Avatar } from 'components-new/common/Avatar/Avatar'
import BookingModel from 'services-new/booking/booking.model'
import { Button } from 'components-new/common/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { Image as ImageComponent } from 'components-new/common/Image/Image'
import React, { useState } from 'react'
import { SendMessageButton } from 'containers-new/ExperienceContainer/components/SendMessageButton/SendMessageButton'
import classNames from 'classnames'
import { experienceLink } from 'global/routes'
import { formattedPrice } from 'utils/numberhelper'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useUser } from 'hooks/useUser'
import { RootState } from 'store'
import Booking from 'services-new/booking/booking.model'
import { Loader } from '../Loader/Loader'
import styles from './AccountBookingCard.module.scss'

interface IBookingCard {
  className?: string
  booking: BookingModel
  onCancelClick?: (booking: Booking) => void
}

export const AccountBookingCard: React.FC<IBookingCard> = ({ className, booking, onCancelClick }) => {
  const state = useSelector((s: RootState) => s.bookings)
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useUser()
  // for host
  const onAcceptClick = async () => {
    await dispatch(AcceptBooking(booking.id))
  }
  const onDeclineClick = async () => {
    await dispatch(RejectBooking(booking.id))
  }

  const onCancel = async () => {
    onCancelClick?.(booking)
  }

  const isHosting = booking.isHostedByUser(user)

  const onDetailsClick = () => {
    history.push(experienceLink(booking.experience.slug))
  }

  return (
    <div className={classNames(styles['abc'], className, { [styles['abc--is-users-booking']]: booking.isUsers(user) })}>
      {state.loaders[booking.id]?.loading && <Loader overlay />}

      <div className={styles['abc__status-row']}>
        <div className={styles['abc__status']}>{booking.user.displayName} requested a booking</div>
        <div className={styles['abc__type']}>{booking.statusName}</div>
      </div>
      <div className={styles['abc__info-additional']}>
        <div className={styles['abc__name']}>{booking.experience.title}</div>
        <div className={styles['abc__guests']}>
          {booking.guests === 1 ? '1 guest' : `${booking.guests} guests`} &bull; {booking.experience.durationString}
        </div>
      </div>
      <div className={styles['abc__image-wrapper']}>
        {booking.experience.image ? (
          <ImageComponent
            loading="lazy"
            src={booking.experience.image?.getSmallSize()}
            alt={booking.experience.title}
            srcSet={booking.experience.image?.getSrcSet()}
            sizes={['100vw', '(min-width: 585px) 640px', '(min-width: 1265px) 960px']}
          />
        ) : (
          <div className={styles['abc__image-placeholder']}></div>
        )}
        <div className={styles['abc__guest-info']}>
          {booking.user.initials && <Avatar image={booking.user.image} text={booking.user.initials} size="compact" />}
          <span className={styles['abc__guest-name']}>{booking.user.displayName}</span>
        </div>
      </div>
      {booking.note && (
        <div className={styles['abc__note-wrapper']}>
          <div className={styles['abc__note']}>Additional message:</div>
          <div>{booking.note}</div>
        </div>
      )}
      {booking.status === 'pending' && !isHosting && (
        <div className={styles['abc__note-wrapper']}>
          <div>
            Your card will not be charged until the guide accepts your booking. You will get an email letting you know
            if your booking was accepted or declined within 24 hours.
          </div>
        </div>
      )}
      <div className={styles['abc__details-wrapper']}>
        <div className={styles['abc__details-row']}>
          <div className={styles['abc__date-price']}>
            <div className={styles['abc__date']}>Starts at {booking.startDate.getLocalDateTimeString()}.</div>
            <div className={styles['abc__price']}>
              Total <span>{formattedPrice(isHosting ? booking.subAmount : booking.amount, 'usd')}</span>
            </div>
          </div>
          {isHosting && booking.status === 'pending' && (
            <Button theme="plain" size="small" text="medium" onClick={onDeclineClick}>
              Decline
            </Button>
          )}
          {booking.isCancellableForUser(user) && (
            <Button theme="plain" size="small" text="medium" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
        <div className={styles['abc__buttons-wrapper']}>
          {booking.status === 'pending' && isHosting ? (
            <Button
              theme="secondary"
              size="small"
              text="medium"
              onClick={onAcceptClick}
              className={styles['abc__button']}
            >
              Accept Booking
            </Button>
          ) : (
            booking.experience.status === 'published' && (
              <Button
                theme="primary"
                size="small"
                text="medium"
                onClick={onDetailsClick}
                className={styles['abc__button']}
              >
                See Details
              </Button>
            )
          )}
          <SendMessageButton
            theme="plain"
            size="small"
            text="medium"
            className={styles['abc__button']}
            experience={booking.experience}
          >
            <Icon icon="message" className={styles['abc__button-icon']} />
            Send Message
          </SendMessageButton>
        </div>
      </div>
    </div>
  )
}
