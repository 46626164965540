import { GetBooking, GetBookings } from 'services-new/booking/booking-api'
import Booking from 'services-new/booking/booking.model'
import { RootState } from 'store'
import { bookingAdapter } from 'features/booking/BookingSlice'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'components-new/common/Loader/Loader'
import { AccountBookingCard } from 'components-new/common/AccountBookingCard/AccountBookingCard'
import { BookingCancelCalculationModal } from 'components-new/modals/BookingCancelCalculationModal/BookingCancelCalculationModal'
import { Wrapper } from '../Wrapper/Wrapper'
import styles from './AccountBookingsContainer.module.scss'
import { BookingSingle } from './components/BookingSingle/BookingSingle'
import { BookingList } from './components/BookingList/BookingList'

export const AccountBookingsContainer = () => {
  const state = useSelector((s: RootState) => s.bookings)
  const dispatch = useDispatch()
  const { selectAll } = bookingAdapter.getSelectors()
  const { selectById } = bookingAdapter.getSelectors()
  const { id } = useParams<{ id: string }>()

  const [cancelledBookingId, setCancelledBookingId] = useState<Booking['id'] | null>(null)

  const bookings = useMemo(() => {
    return selectAll(state)
  }, [state])

  const booking = useMemo(() => {
    return selectById(state, id)
  }, [state])

  useEffect(() => {
    if (id) {
      dispatch(GetBooking(+id))
    } else {
      dispatch(GetBookings())
    }
  }, [id])

  const bookingsRequests: Booking[] = []
  const bookingsScheduled: Booking[] = []
  const bookingsPrevious: Booking[] = []
  const bookingsCancelled: Booking[] = []
  const bookingsDenied: Booking[] = []

  bookings.forEach((item) => {
    switch (item.status) {
      case 'pending':
        bookingsRequests.push(item)
        break
      case 'accepted':
        bookingsScheduled.push(item)
        break
      case 'cancelled':
        bookingsCancelled.push(item)
        break
      case 'past':
        bookingsPrevious.push(item)
        break
      case 'rejected':
        bookingsDenied.push(item)
        break
    }
  })
  if (!state.loaded) {
    return <Loader />
  }
  return (
    <Wrapper containerClasses={styles['abc']}>
      <BookingCancelCalculationModal
        open={!!cancelledBookingId}
        bookingId={cancelledBookingId}
        onClose={() => setCancelledBookingId(null)}
      />
      {id ? (
        <BookingSingle header="Selected Booking" booking={booking} />
      ) : (
        <>
          <BookingList header="Booking Requests">
            {bookingsRequests.map((item) => (
              <AccountBookingCard
                booking={item}
                key={item.id}
                onCancelClick={(booking) => setCancelledBookingId(booking.id)}
              />
            ))}
          </BookingList>
          <BookingList header="Upcoming Confirmed Bookings">
            {bookingsScheduled.map((item) => (
              <AccountBookingCard
                booking={item}
                key={item.id}
                onCancelClick={(booking) => setCancelledBookingId(booking.id)}
              />
            ))}
          </BookingList>
          <BookingList header="Previous Bookings">
            {bookingsPrevious.map((item) => (
              <AccountBookingCard booking={item} key={item.id} />
            ))}
          </BookingList>
          <BookingList header="Cancelled Bookings">
            {bookingsCancelled.map((item) => (
              <AccountBookingCard booking={item} key={item.id} />
            ))}
          </BookingList>
          <BookingList header="Denied Bookings">
            {bookingsDenied.map((item) => (
              <AccountBookingCard booking={item} key={item.id} />
            ))}
          </BookingList>
        </>
      )}
    </Wrapper>
  )
}
