import { useState } from 'react'
import {
  fetchMarketingPageDetails$,
  fetchMarketingPageDetailsBySlug$,
} from 'services/marketing-page/marketing-page-api'
import MarketingDetails, { MarketingDetailsInput } from 'services/marketing-page/models/MarketingDetails'
import { isNumericString } from 'utils/numberhelper'
import { useRequest } from './useRequest'

export const useMarketingPageDetailsBySlugOrId = () => {
  const [result, setResult] = useState<MarketingDetails | null>(null)

  const {
    isLoading,
    responseError,
    fetch: fetchRequest,
  } = useRequest<string, MarketingDetailsInput>(
    (params?: string) =>
      isNumericString(params as string) ? fetchMarketingPageDetails$(params) : fetchMarketingPageDetailsBySlug$(params),
    (marketingDetailsResponse) => {
      setResult(new MarketingDetails(marketingDetailsResponse))
    }
  )

  const fetch = (slug: string): void => {
    fetchRequest(slug)
  }

  return {
    isLoading,
    responseError,
    result,
    fetch,
  }
}
