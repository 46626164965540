import React from 'react'
import { Link } from 'react-router-dom'
import iconsPath from 'assets/icons/sprite.svg'
import classNames from 'classnames'
import styles from './PoweredByButton.module.scss'

interface IPoweredByButton {
  className?: string
}

export const PoweredByButton: React.FC<IPoweredByButton> = ({ className }) => (
  <div className={classNames(className, styles['powered-by-button'])}>
    <span>powered by</span>
    <svg
      className={styles['powered-by-button__logo']}
      viewBox="0 0 138 31"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref={`${iconsPath}#icon_brand_logo`} />
    </svg>
    <svg
      className={styles['powered-by-button__logo-small']}
      viewBox="0 0 31 31"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref={`${iconsPath}#icon_brand_logo_small`} />
    </svg>
  </div>
)
