import React, { useEffect, useRef, useState } from 'react'

import { AccountActions } from 'components-new/common/AccountActions/AccountActions'
import { HomeButton } from 'components-new/common/HomeButton/HomeButton'
import { Link, useLocation } from 'react-router-dom'
import { SearchBar } from 'components-new/search/SearchBar/SearchBar'
import { SideMenu } from 'components-new/layout/SideMenu/SideMenu'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { becomeGuideLink, customerPolicyLink, loginLink, registerLink } from 'global/routes'
import classNames from 'classnames'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useUser } from 'hooks/useUser'
import useWindowSize from 'hooks/useWindowSize'
import { useMobileApp } from 'hooks/useMobileApp'
import { PoweredByButton } from 'components-new/common/PoweredByButton/PoweredByButton'
import styles from './Navbar.module.scss'
import { INavbar } from './Navbar.types'

export const Navbar: React.FC<INavbar> = ({
  withFixedPosition = false,
  withAbsolutePosition = false,
  withTransparency = false,
  withTripList = true,
  withEmbed = false,
  theme,
  withOffset = false,
  logoMobile = true,
  hideSearch = false,
  customLogo,
  children,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isTransparent, setTransparent] = useState(withTransparency)
  const navBarRef = useRef<HTMLDivElement>(null)
  const topBarRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const { windowWidth } = useWindowSize()
  const { isMobile, isDesktop } = useBreakpoints()
  const { isLoggedIn, isHost } = useUser()
  const { isMobileApp } = useMobileApp()
  const { pathname } = useLocation()
  const shouldHideNavigation = isMobileApp && pathname.startsWith('/account')

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (withTransparency) {
        if (windowWidth < 960) {
          if (Math.abs(currPos.y) > 0) {
            setTransparent(false)
          } else {
            setTransparent(true)
          }
        } else {
          if (Math.abs(currPos.y) > height) {
            setTransparent(false)
          } else {
            setTransparent(true)
          }
        }
      }
    },
    undefined,
    undefined,
    undefined,
    10
  )

  useEffect(() => {
    if (navBarRef.current) {
      setHeight(navBarRef.current.getBoundingClientRect().top)
    }
  }, [navBarRef, windowWidth])

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const searchBarClasses = classNames({
    [styles['navbar__search']]: true,
    [styles['navbar__search--prominent']]: isTransparent,
  })

  const isNavBarFixed = withFixedPosition && ((withTransparency && !isTransparent) || !withTransparency)

  useEffect(() => {
    document.body.style.paddingTop = withFixedPosition && !withTransparency ? '80px' : '0'
  }, [withFixedPosition])

  if (shouldHideNavigation) {
    return null
  }

  return (
    <>
      <Wrapper
        ref={navBarRef}
        paddingType="small"
        containerClasses={classNames(styles.navbar, {
          [styles['navbar--with-transparency']]: withTransparency,
          [styles['navbar--transparent']]:
            (withTransparency && isTransparent) || (!withFixedPosition && withTransparency),
          [styles['navbar--with-fixed']]: withFixedPosition,
          [styles['navbar--with-absolute']]: withAbsolutePosition,
          [styles['navbar--with-offset']]: withOffset,
          [styles['navbar--with-search']]: !hideSearch,
          [styles['navbar--with-embed']]: !!withEmbed,
          [styles['navbar--fixed']]: isNavBarFixed,
          [styles[`navbar--${theme}`]]: !!theme,
          [styles['navbar--mobile-app']]: isMobileApp,
        })}
        contentClasses={styles.navbar__content}
      >
        {customLogo ? (
          <>{customLogo}</>
        ) : withEmbed ? (
          <PoweredByButton />
        ) : (
          <HomeButton className={styles['navbar__logo']} size={logoMobile && isMobile ? 'small' : 'normal'} />
        )}
        {!hideSearch && !withEmbed ? (
          <div className={searchBarClasses}>
            <SearchBar ref={topBarRef} size={!isTransparent || isMobile ? 'normal' : 'wide'} />
          </div>
        ) : (
          <>{children}</>
        )}
        {isMobileApp ? (
          <div />
        ) : withEmbed ? (
          <>
            <div className={styles['navbar__account-actions']}>
              {/* <Link className={styles['navbar__account-link']} to={customerPolicyLink()}>*/}
              {/*  Customer Policy*/}
              {/* </Link>*/}
              {!isLoggedIn && (
                <>
                  <Link className={styles['navbar__account-link']} to={loginLink()}>
                    Sign in
                  </Link>
                  <Link className={styles['navbar__account-link']} to={registerLink()}>
                    Register
                  </Link>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles['navbar__account-actions']}>
              {withTripList && !isLoggedIn && isDesktop && (
                <Link className={styles['navbar__account-link']} to={'/forguides'}>
                  List your trips
                </Link>
              )}
              {withTripList && isLoggedIn && isDesktop && !isHost && (
                <Link className={styles['navbar__account-link']} to={becomeGuideLink()}>
                  Become a Guide
                </Link>
              )}
              <AccountActions onClick={handleDrawerToggle} />
            </div>
            <SideMenu drawerOpen={drawerOpen} onClose={() => setDrawerOpen(false)} />
          </>
        )}
      </Wrapper>
    </>
  )
}
