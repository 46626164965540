import React, { FC } from 'react'
import Seo from 'components/Seo/Seo'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { Button } from 'components-new/common/Button/Button'
import backgroundImage from 'assets/img/404/bg-404.jpg'
import * as CSS from 'csstype'
import styles from './NotFoundPage.module.scss'

const backgroundStyles: CSS.Properties<string> = {
  backgroundImage: undefined,
  backgroundSize: undefined,
  backgroundPosition: undefined,
}
backgroundStyles.backgroundImage = `url(${backgroundImage})`
backgroundStyles.backgroundSize = 'cover'
backgroundStyles.backgroundPosition = 'center'

export const NotFoundPage: FC<{}> = () => {
  return (
    <>
      {<Seo statusCode={404} />}
      <Navbar />
      <div className={styles['not-found-page']} style={{ ...backgroundStyles }}>
        <h1 className={styles['not-found-page__header']}>Ooops</h1>
        <h2 className={styles['not-found-page__subheader']}>Oh no, something not found</h2>
        <Button to="/" theme="secondary" className={styles['image-gallery__overlay']}>
          Back to homepage
        </Button>
      </div>
    </>
  )
}
