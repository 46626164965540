import { TPaymentsActiveSummaryCardTab } from 'containers-new/AccountPaymentsContainer/AccountPaymentsContainer.types'
import { TButtonGroupProps } from 'components-new/common/ButtonGroup/ButtonGroup.types'
import { ButtonGroup } from 'components-new/common/ButtonGroup/ButtonGroup'
import classNames from 'classnames'
import React from 'react'
import s from './PaymentsSummaryCard.module.scss'

export type TListItem = {
  title: string
  value?: string
  variant?: string
}

type TData = Record<TPaymentsActiveSummaryCardTab, TListItem[]>

export type TPaymentsSummaryCardProps = {
  data: TData
  activeTab: TPaymentsActiveSummaryCardTab
  onTabChange(value: TPaymentsActiveSummaryCardTab): void
  // Optional
  className?: string
}

export const PaymentsSummaryCard: React.FC<TPaymentsSummaryCardProps> = ({
  className,
  activeTab,
  data,
  onTabChange,
  children,
}) => {
  const tabsItems: TButtonGroupProps<TPaymentsActiveSummaryCardTab>['items'] = [
    {
      title: 'Balance',
      id: 'BALANCE',
    },
    {
      title: 'Income History',
      id: 'INCOME_HISTORY',
    },
    {
      title: 'Transferred Funds',
      id: 'TRANSFERRED_FUNDS',
    },
  ]

  const onTabItemChange: TButtonGroupProps<TPaymentsActiveSummaryCardTab>['onItemChange'] = (item) =>
    onTabChange(item.id)

  return (
    <div className={classNames(s.container, className)}>
      <>
        <ButtonGroup<TPaymentsActiveSummaryCardTab>
          activeItemId={activeTab}
          items={tabsItems}
          onItemChange={onTabItemChange}
        />

        <ul className={s.list}>
          {data[activeTab].map((item, i) => (
            <li
              key={i}
              className={classNames({
                [s['variant-' + item.variant]]: !!item.variant,
              })}
            >
              <p>{item.title}</p>
              {item.value && <span>{item.value}</span>}
            </li>
          ))}
        </ul>
      </>
      <footer>{children}</footer>
    </div>
  )
}
