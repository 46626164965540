import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { messagesColorPalette } from 'global/variables'
import { Image } from '../Image/Image'
import { IAvatar } from './Avatar.types'
import styles from './Avatar.module.scss'

export const Avatar: FC<IAvatar> = (props: IAvatar) => {
  const { text, image, size, className } = props

  const [color, setColor] = useState<string>('')
  const getCharIndex = (str: string): number => str.charCodeAt(0) - 65

  useEffect(() => {
    setColor(
      messagesColorPalette[
        text.split('').reduce((n, char) => {
          return n + getCharIndex(char)
        }, 0) % 8
      ]
    )
  }, [text, messagesColorPalette])

  const classes = classNames({
    [styles['avatar']]: true,
    [className!]: !!className,
    [styles['avatar--' + size]]: true,
  })

  return (
    <div style={{ background: color }} className={classes}>
      {image ? (
        <Image alt={'avatar'} src={image.getSmallSize()} theme={'round'} className={styles['avatar--' + size]} />
      ) : (
        <>{text}</>
      )}
    </div>
  )
}
