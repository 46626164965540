import React from 'react'
import { Button } from 'components-new/common/Button/Button'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import BgImage from 'assets/img/for-guides/forguides-bg.jpg'
import styles from 'containers-new/ForGuidesContainer/components/TopBanner/TopBanner.module.scss'
import { useUser } from 'hooks/useUser'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { RegisterFormInputs } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs'
import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import { RegisterFormValues } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs.types'
import { accountLink, becomeGuideLink, loginLink } from 'global/routes'
import { useHistory } from 'react-router'
import { FormWrapperProps } from 'components-new/forms/FormWrapper/FormWrapper.types'
import { RegisterUser } from 'services-new/auth/auth-api'
import { isFulfilled } from '@reduxjs/toolkit'

export const TopBanner = () => {
  const { isLoading } = useUser({ asHost: true })
  const dispatch = useDispatch()
  const history = useHistory()

  const onRegister: FormWrapperProps<RegisterFormValues>['onSubmit'] = async (data) => {
    if (isFulfilled(await dispatch(RegisterUser(data)))) {
      history.push(becomeGuideLink())
    }
  }

  return (
    <Wrapper theme="none" className={styles['top-banner']} backgroundImage={BgImage}>
      <div className={styles['top-banner__container']}>
        <div className={classNames(styles['top-banner__row'], styles['top-banner__row--copy'])}>
          <h2 className={styles['top-banner__form-subheader']}>Join the Outguided family</h2>
          <h1 className={styles['top-banner__form-header']}>
            Spend more time
            <br />
            doing what you love
          </h1>
          <p className={styles['top-banner__form-description']}>
            Sign up and list for free, charge whatever you want. Booking requests sent straight to your phone.
          </p>
        </div>
        <div className={classNames(styles['top-banner__row'], styles['top-banner__row--form'])}>
          <FormWrapper<RegisterFormValues>
            onSubmit={onRegister}
            afterForm={({ formState: errors }) => (
              <>
                <div className={styles['top-banner__buttons']}>
                  <Button
                    loading={isLoading}
                    disabled={Object.keys(errors || {}).length > 0}
                    type="submit"
                    size="small"
                    className={styles['top-banner__button-register']}
                  >
                    Register
                  </Button>
                  <Button to={loginLink(accountLink('details'))} theme="link-small">
                    Already have account?
                  </Button>
                </div>
              </>
            )}
          >
            {(methods) => <RegisterFormInputs />}
          </FormWrapper>
        </div>
      </div>
    </Wrapper>
  )
}
