import React, { FC, useEffect, useRef } from 'react'
import classNames from 'classnames'
import useElementSize from 'hooks/useElementSize'
import styles from './FloatingComponent.module.scss'
import { FloatingComponentProps } from './FloatingComponent.types'

export const FloatingComponent: FC<FloatingComponentProps> = ({
  children,
  className,
  type = 'footer',
  stickyOnMobile,
}) => {
  const ref = useRef(null)
  const { height } = useElementSize(ref)

  useEffect(() => {
    if (type === 'footer') {
      document.body.style.paddingBottom = `${height}px`
    } else {
      document.body.style.paddingTop = `${height}px`
    }
    return () => {
      if (type === 'footer') {
        document.body.style.paddingBottom = '0'
      } else {
        document.body.style.paddingTop = '0'
      }
    }
  }, [height])

  const sectionClassNames = classNames({
    [className!]: !!className,
    [styles['floating-component']]: !!styles['floating-component'],
    [styles['floating-component--footer']]: type === 'footer',
    [styles['floating-component--header']]: type === 'header',
    [styles['floating-component--relative-on-mobile']]: stickyOnMobile !== true,
  })

  return (
    <div className={sectionClassNames} ref={ref}>
      {children}
    </div>
  )
}
