import React, { FC } from 'react'
import classNames from 'classnames'
import { IBookingStateText } from './BookingStateText.types'
import styles from './BookingStateText.module.scss'

export const BookingStateText: FC<IBookingStateText> = ({ className, status }) => {
  const classes = classNames({
    [styles['booking-state-text']]: true,
    [className!]: !!className,
    [styles[`booking-state-text--${status}`]]: !!status,
  })

  return <span className={classes}>{status}</span>
}
