import React from 'react'
import { Button } from 'components-new/common/Button/Button'
import { accountLink } from 'global/routes'
import { AccountBookingCard } from 'components-new/common/AccountBookingCard/AccountBookingCard'
import Booking from 'services-new/booking/booking.model'
import { useHistory } from 'react-router'
import { useUser } from 'hooks/useUser'
import styles from './BookingSingle.module.scss'

interface IBookingSingle {
  header: string
  booking: Booking | undefined
}

export const BookingSingle: React.FC<IBookingSingle> = ({ header, booking }) => {
  const history = useHistory()

  return (
    <div className={styles['bs__block']}>
      <Button
        onClick={() => history.push(accountLink('bookings'))}
        theme="link"
        className={styles['bs__button-return']}
      >
        Return to all bookings
      </Button>
      <h2>{header}</h2>
      {booking ? (
        <div className={styles['bs__cards-wrapper']}>
          <AccountBookingCard booking={booking} />
        </div>
      ) : (
        <div>No booking found</div>
      )}
    </div>
  )
}
