import { accountLink, becomeGuideLink, experienceEditLink } from 'global/routes'
import {
  AccountPages,
  AccountPagesMobile,
  UserAccountPages,
  UserAccountPagesMobile,
} from 'pages-new/AccountPage/AccountPage'
import { Button } from 'components-new/common/Button/Button'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { useMobileApp } from 'hooks/useMobileApp'
import { Link, useLocation } from 'react-router-dom'
import React, { FC } from 'react'
import classNames from 'classnames'
import { useUser } from 'hooks/useUser'
import { AccountNavBarProps } from './AccountNavBar.types'
import styles from './AccountNavBar.module.scss'

export const AccountNavBar: FC<AccountNavBarProps> = ({ page }) => {
  const { isMobileApp } = useMobileApp()
  const { isHost } = useUser()
  const { pathname } = useLocation()

  if (
    isMobileApp &&
    ![accountLink('details'), accountLink('profile'), accountLink('trips'), accountLink()].includes(pathname)
  ) {
    return null
  }

  return (
    <Wrapper containerClasses={styles['account-navbar']} contentClasses={styles['account-navbar__content']}>
      <ul className={styles['account-navbar__list']}>
        {isMobileApp
          ? isHost
            ? (Object.keys(AccountPagesMobile) as Array<keyof typeof AccountPagesMobile>).map((slug) => (
                <li
                  className={classNames({
                    [styles['account-navbar__item']]: true,
                    [styles['account-navbar__item--active']]: page === slug,
                  })}
                  key={slug}
                >
                  <Link to={accountLink(slug)}>{AccountPagesMobile[slug]}</Link>
                </li>
              ))
            : (Object.keys(UserAccountPagesMobile) as Array<keyof typeof UserAccountPagesMobile>).map((slug) => (
                <li
                  className={classNames({
                    [styles['account-navbar__item']]: true,
                    [styles['account-navbar__item--active']]: page === slug,
                  })}
                  key={slug}
                >
                  <Link to={accountLink(slug)}>{UserAccountPagesMobile[slug]}</Link>
                </li>
              ))
          : isHost
          ? (Object.keys(AccountPages) as Array<keyof typeof AccountPages>).map((slug) => (
              <li
                className={classNames({
                  [styles['account-navbar__item']]: true,
                  [styles['account-navbar__item--active']]: page === slug,
                })}
                key={slug}
              >
                <Link to={accountLink(slug)}>{AccountPages[slug]}</Link>
              </li>
            ))
          : (Object.keys(UserAccountPages) as Array<keyof typeof UserAccountPages>).map((slug) => (
              <li
                className={classNames({
                  [styles['account-navbar__item']]: true,
                  [styles['account-navbar__item--active']]: page === slug,
                })}
                key={slug}
              >
                <Link to={accountLink(slug)}>{UserAccountPages[slug]}</Link>
              </li>
            ))}
      </ul>
      {isHost ? (
        <Button theme="plain-orange" to={experienceEditLink('new')}>
          + Create New Trip
        </Button>
      ) : (
        <Button className={styles['account-navbar__become-guide']} theme="orange" to={becomeGuideLink()}>
          + Become Guide
        </Button>
      )}
    </Wrapper>
  )
}
