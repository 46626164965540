import { Button } from 'components-new/common/Button/Button'
import { checkoutLink, guideLink, messageLink, pageLink } from 'global/routes'
import { Image as ImageComponent } from 'components-new/common/Image/Image'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import classNames from 'classnames'
import React from 'react'
import { useUser } from 'hooks/useUser'
import { formattedPrice } from 'utils/numberhelper'
import { useDispatch, useSelector } from 'react-redux'
import { AcceptBooking, CancelBooking, RejectBooking } from 'services-new/booking/booking-api'
import { RootState } from 'store'
import { Loader } from 'components-new/common/Loader/Loader'
import { Icon } from 'components/Icon/Icon'
import { BookingStateText } from '../atoms/BookingStateText/BookingStateText'
import { IExperiencePreview } from './ExperiencePreview.types'
import styles from './ExperiencePreview.module.scss'

export const ExperiencePreview: React.FC<IExperiencePreview> = ({
  experience,
  conversation,
  booking,
  onCloseClick,
  className,
}) => {
  const { image, title, description, host } = experience
  const { isMobile } = useBreakpoints()
  const { user } = useUser()
  const isHost = user && user.id === host?.id
  const history = useHistory()
  const dispatch = useDispatch()
  const state = useSelector((s: RootState) => s.bookings)

  const onBookNow = () => history.push(checkoutLink(experience!.id))

  const onCancel = async () => {
    await dispatch(CancelBooking(booking!.id))
    // hacky way of refreshing booking entity in conversation
    history.push(messageLink(conversation.id))
  }

  const onApprove = async () => {
    await dispatch(AcceptBooking(booking!.id))
    // hacky way of refreshing booking entity in conversation
    history.push(messageLink(conversation.id))
  }

  const onReject = async () => {
    await dispatch(RejectBooking(booking!.id))
    // hacky way of refreshing booking entity in conversation
    history.push(messageLink(conversation.id))
  }

  const isBookingLoading = state.loaders[booking!.id]?.loading

  const ActionButtons = () => (
    <>
      {!isHost && (!booking || ['rejected', 'cancelled'].includes(booking.status)) && (
        <>
          <Button size="wide" theme="secondary" className={styles['experience-preview__cta']} onClick={onBookNow}>
            Request Booking
          </Button>
          <p>
            By clicking Request Booking, I agree to Outguided LLC’s{' '}
            <Link to={pageLink('terms-and-conditions')}>Terms of Use</Link> and{' '}
            <Link to={pageLink('privacy-policy')}>Privacy Policy</Link>.
          </p>
        </>
      )}
      {booking?.isCancellableForUser(user) && (
        <>
          <Button size="wide" className={styles['experience-preview__cta']} onClick={onCancel}>
            Cancel Booking
          </Button>
        </>
      )}
      {booking?.isHostedByUser(user) && booking.status === 'pending' && (
        <>
          <Button size="wide" className={styles['experience-preview__cta']} onClick={onApprove}>
            Approve
          </Button>
          <Button size="wide" theme="plain" className={styles['experience-preview__cta']} onClick={onReject}>
            Reject
          </Button>
        </>
      )}
    </>
  )

  return (
    <div className={classNames([styles['experience-preview'], { [className!]: !!className }])}>
      <div className={styles['experience-preview__container']}>
        <div className={styles['experience-preview__info']}>
          <div className={styles['experience-preview__title-wrapper']}>
            <h2 className={styles['experience-preview__title']}>Trip Information</h2>
            {isMobile && (
              <Icon icon="close" className={styles['experience-preview__close']} onClick={() => onCloseClick?.()} />
            )}
          </div>
          {booking && (
            <div className={styles['experience-preview__status-wrapper']}>
              <span className={styles['experience-preview__status']}>Status</span>
              <BookingStateText className={styles['experience-preview__status']} status={booking.status} />
            </div>
          )}
          {image && (
            <ImageComponent
              loading="lazy"
              alt={image.alternativeText}
              src={image.getLargeSize()}
              srcSet={image.getSrcSet()}
              sizes={['100vw', '(min-width: 1265px) 1680px']}
              wrapperClassName={styles['experience-preview__image-wrapper']}
              className={styles['experience-preview__image']}
            />
          )}
          <h2 className={styles['experience-preview__title']}>{title}</h2>
          <p className={styles['experience-preview__description']}>{description}</p>
        </div>
        <div className={styles['experience-preview__details']}>
          <h2 className={styles['experience-preview__title']}>Trip Details</h2>
          {host && (
            <div className={styles['experience-preview__details-block']}>
              <div>
                <h3>Hosted by:</h3>
                <Link to={guideLink(host.slug)}>View</Link>
              </div>
              <span>{host?.displayName}</span>
            </div>
          )}
          {booking && (
            <>
              <div className={styles['experience-preview__details-block']}>
                <h3>Guest name:</h3>
                <span>{booking.user.displayName}</span>
              </div>
              <div className={styles['experience-preview__details-block']}>
                <h3>Selected date:</h3>
                <span>{booking.startDate.getLocalDateTimeString()}</span>
              </div>
              <div className={styles['experience-preview__details-block']}>
                <h3>Guests:</h3>
                <span>{booking.guests}</span>
              </div>
            </>
          )}
        </div>
        {booking && (
          <div className={styles['experience-preview__payment']}>
            <h2 className={styles['experience-preview__title']}>Payment Details</h2>
            {isHost ? (
              <ul>
                <li>
                  <span>You are getting paid</span>
                  <span>{formattedPrice(booking.subAmount, 'USD')}</span>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <span>Price</span>
                  <span>{formattedPrice(booking.subAmount, 'USD')}</span>
                </li>
                <li>
                  <span>Fees</span>
                  <span>{formattedPrice(booking.feesAmount, 'USD')}</span>
                </li>
                <li>
                  <span>Total Price</span>
                  <span>{formattedPrice(booking.amount, 'USD')}</span>
                </li>
              </ul>
            )}
          </div>
        )}
        <div className={styles['experience-preview__protection']}>
          <h2 className={styles['experience-preview__protection-title']}>Always communicate through Outguided</h2>
          <p className={styles['experience-preview__protection-text']}>
            We've got your back. Communicate through Outguided so you can utilize our support staff and be covered by
            our no show guarantee.
          </p>
        </div>
      </div>
      <div className={styles['experience-preview__ctas-wrapper']}>
        {isBookingLoading ? <Loader /> : <ActionButtons />}
      </div>
    </div>
  )
}
