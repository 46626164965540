import React, { FC } from 'react'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components-new/common/Button/Button'
import { Link } from 'react-router-dom'
import { useUser } from 'hooks/useUser'
import { guideHomeLink, searchLink } from 'global/routes'
import classNames from 'classnames'
import { INoConversations } from './NoConversations.types'
import styles from './NoConversations.module.scss'

export const NoConversations: FC<INoConversations> = ({ className }) => {
  const { isHost } = useUser()
  const classes = classNames({
    [className!]: !!className,
    [styles['no-conversations']]: !!styles['no-conversations'],
  })
  return (
    <div className={classes}>
      <Icon icon="communication" className={styles['no-conversations__icon']} />
      <div className={styles['no-conversations__title']}>You don’t have messages yet</div>
      <div className={styles['no-conversations__subtitle']}>
        {isHost
          ? 'As soon as a guest contacts you, messages will appear here'
          : 'As soon as you contact a host, messages will appear here'}
      </div>
      <div className={styles['no-conversations__button']}>
        <Link to={isHost ? guideHomeLink() : searchLink()}>
          <Button size={'small'} theme={isHost ? 'primary' : 'secondary'}>
            {isHost ? 'Go to my profile' : 'Browse experience'}
          </Button>
        </Link>
      </div>
    </div>
  )
}
