import { AsYouType, CountryCode, getCountries } from 'libphonenumber-js'

import { getName, getNames, registerLocale, getAlpha2Code } from 'i18n-iso-countries'

registerLocale(require('i18n-iso-countries/langs/en.json'))

type CountryCodeResult = Array<string | CountryCode>

const COUNTRY_EXCEPTIONS_MAP: { [key: string]: string } = {
  'united states': 'united states of america',
}

export const getAlpha2CountryCode = (country: string): string => {
  return getAlpha2Code(
    COUNTRY_EXCEPTIONS_MAP[country.toLowerCase()] ? COUNTRY_EXCEPTIONS_MAP[country.toLowerCase()] : country,
    'en'
  )
}

export const countriesList = getNames('en')

// not used in new system
export const getCountryCodes = (lang: string): CountryCodeResult[] => {
  const countryCodes = getCountries()

  const result: CountryCodeResult[] = []

  countryCodes.forEach((code) => {
    const countryName = getName(code, lang)
    if (countryName) {
      result.push([code, countryName])
    }
  })
  result.sort((a, b) => a[1].localeCompare(b[1]))
  return result
}

// not used in new system
export const getFormattedPhoneNumber = (countryCode: CountryCode, phone: string): string => {
  return new AsYouType(countryCode).input(phone)
}
