import { RegisterFormValues } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs.types'
import { Checkout } from 'redux/Checkout/actions'
import { AppDate } from 'services-new/shared/date'
import Booking from 'services-new/booking/booking.model'
import MarketingDetails from 'services/marketing-page/models/MarketingDetails'

const initialState = {
  guests: 1,
  discountCode: '',
  date: null,
  time: null,
  note: '',
  marketingPage: null,
  booking: null,
  userDetails: {},
}

export function checkoutReducer(state: CheckoutState = initialState, action: { payload: any; type: string }) {
  switch (action.type) {
    case Checkout.SET_GUESTS:
      return {
        ...state,
        guests: action.payload,
      }
    case Checkout.SET_DISCOUNT_CODE:
      return {
        ...state,
        discountCode: action.payload,
      }
    case Checkout.SET_DATE:
      return {
        ...state,
        date: action.payload,
      }
    case Checkout.SET_TIME:
      return {
        ...state,
        time: action.payload,
      }
    case Checkout.SET_NOTE:
      return {
        ...state,
        note: action.payload,
      }
    case Checkout.SET_MARKETING_PAGE:
      return {
        ...state,
        marketingPage: action.payload,
      }
    case Checkout.SET_BOOKING:
      return {
        ...initialState,
        booking: action.payload,
      }
    case Checkout.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      }
    case Checkout.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export interface CheckoutState {
  guests: number
  discountCode: string
  date: AppDate | null
  time: number | null
  marketingPage: MarketingDetails | null
  booking: Booking | null
  note: string
  userDetails: RegisterFormValues
}
