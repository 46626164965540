import Icon from 'components/Icon/Icon'
import React, { forwardRef, MouseEventHandler, useState } from 'react'
import classNames from 'classnames'
import { FormField } from '../FormField/FormField'
import { TMultiselectProps } from './Multiselect.types'
import s from './Multiselect.module.scss'

const Tag: React.FC<{
  title: string
  onClick(option: any): void
}> = ({ title, onClick }) => (
  <div className={s.tag} onClick={onClick}>
    {title}
    <Icon icon="close" className={s.icon} />
  </div>
)

export const Multiselect = forwardRef<HTMLDivElement, TMultiselectProps>(function Multiselect(props, ref) {
  const {
    children,
    className,
    placeholder,
    options,
    name,
    value,
    labelProp = 'label',
    valueProp = 'value',
    label,
    error,
    success,
    theme,
    searchValue,
    onChange,
    onSearchValueChange,
    ...nativeProps
  } = props

  const [isFocused, setIsFocused] = useState(false)

  const FakeMultiselect = () => <></>

  const isOptionSelected = (option: any) => {
    return option[valueProp] ? !!value.find((item) => item[valueProp] === option[valueProp]) : !!value.includes(option)
  }

  const removeSelectedOption =
    (option: any): React.MouseEventHandler<HTMLDivElement> =>
    (e) => {
      e.preventDefault()
      onChange(
        value.filter((item) => {
          if (item[valueProp]) return item[valueProp] !== option[valueProp]
          return item !== option
        })
      )
    }

  const selectOption =
    (option: any): React.MouseEventHandler<HTMLDivElement> =>
    (e) => {
      e.preventDefault()
      setIsFocused(false)
      onSearchValueChange?.('')
      const itemExist = isOptionSelected(option)
      if (!itemExist) {
        onChange([...value, option])
      }
    }

  const inputValue = typeof value[0] === 'string' ? value : value.map((item) => item[valueProp])

  const onInputBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setTimeout(() => {
      setIsFocused(false)
    }, 150)
  }

  const onInputFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setIsFocused(true)
  }

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onSearchValueChange?.(e.target.value)
  }

  return (
    <FormField
      name={name}
      label={label}
      error={error}
      success={success}
      type="multiselect"
      prepend={<FakeMultiselect />}
      theme={theme}
      className={s.formField}
      hasFocus={isFocused}
      disableEvents
    >
      <div className={s.container} ref={ref}>
        {value ? (
          <div className={s.inputBox}>
            <div className={s.selectedItems}>
              {(value as any[]).map((item, i) => (
                <Tag
                  key={i}
                  title={typeof item === 'string' ? item : item[labelProp]}
                  onClick={removeSelectedOption(item)}
                />
              ))}
            </div>
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onBlur={onInputBlur}
              onFocus={onInputFocus}
              onChange={onInputChange}
            />
          </div>
        ) : (
          <div className={s.container}>{placeholder}</div>
        )}
        <div
          className={classNames('multiseect-dropdown', s.dropdown, {
            [s.show]: isFocused,
          })}
        >
          {options.map((item, i) => (
            <div
              key={i}
              className={classNames(s.dropdownOption, {
                [s.selected]: isOptionSelected(item),
              })}
              onClick={selectOption(item)}
            >
              {item[valueProp] || item}
            </div>
          ))}
        </div>
        <input type={'hidden'} id={name} name={name} value={inputValue} />
      </div>
    </FormField>
  )
})
