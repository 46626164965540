import Seo from 'components/Seo/Seo'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { Footer } from 'components-new/layout/Footer/Footer'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { PostContainer } from 'containers-new/PostContainer/PostContainer'
import { GetPost } from 'services-new/post/post-api'
import { RootState } from 'store'
import { postLink } from 'global/routes'
import Post from 'services-new/post/post.model'
import { PostsRelated } from 'components-new/common/PostsRelated/PostsRelated'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import React, { useLayoutEffect } from 'react'
import { postsAdapter } from 'features/post/PostsSlice'
import { Loader } from '../../components-new/common/Loader/Loader'
import { NotFoundPage } from '../NotFoundPage/NotFoundPage'

export const BlogPostPage: React.FC<{}> = () => {
  const { slug } = useParams<{ slug: string }>()
  const state = useSelector((s: RootState) => s.posts)

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(GetPost(slug))
  }, [slug])

  let post: Post | undefined
  {
    const { selectById } = postsAdapter.getSelectors()
    post = selectById(state, slug)
  }

  if (state.loading) {
    return <Loader fullScreen />
  }

  if (!post) {
    return <NotFoundPage />
  }

  return (
    <div>
      <Seo
        title={post.title}
        description={post.excerpt}
        image={post.image ? post.image.url : null}
        canonical={postLink(post.slug)}
      />
      <Navbar withFixedPosition />
      <PostContainer title={post.title} content={post.content} image={post.image} publishedAt={post.publishedAt} />
      {post && <PostsRelated id={post.id} />}
      <Footer img={footerImage} />
    </div>
  )
}
