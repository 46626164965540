import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LogoutUser, Me } from 'services-new/auth/auth-api'
import { RootState } from 'store'
import { setLoaded } from 'features/user/UserSlice'
import LocalStorageService from 'utils/LocalStorageService'
import { isRejected } from '@reduxjs/toolkit'

export const useUser = ({ asHost = false }: { asHost?: boolean } = {}) => {
  const { loading, user, loaded } = useSelector((s: RootState) => s.user)
  const dispatch = useDispatch()
  const localStorageService = LocalStorageService.getService()
  const accessToken = localStorageService.getJWTToken()

  const handleLogout = useCallback(async () => {
    await dispatch(LogoutUser())
  }, [])

  const setup = useCallback(async () => {
    const token = localStorageService.getJWTToken()
    if (token) {
      const result: any = await dispatch(Me())
      if (isRejected(result)) {
        if (token && result.error?.status === 401) {
          handleLogout()
        }
      }
    } else {
      dispatch(setLoaded(true))
    }
  }, [])

  return {
    handleLogout,
    setup,
    isLoaded: loaded,
    isLoading: loading,
    user,
    isHost: !!user?.isHost,
    isLoggedIn: !!user,
    localStorageService,
    accessToken,
  }
}
