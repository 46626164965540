import React, { useEffect, useMemo, useState } from 'react'
import { CustomModal } from 'components-new/modals/CustomModal/CustomModal'
import { Button } from 'components-new/common/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { CalculateCancelBooking, CancelBooking } from 'services-new/booking/booking-api'
import { RootState } from 'store'
import { formattedPrice } from 'utils/numberhelper'
import { bookingAdapter } from 'features/booking/BookingSlice'
import { useUser } from 'hooks/useUser'
import { isRejected } from '@reduxjs/toolkit'
import styles from './BookingCancelCalculationModal.module.scss'

export const BookingCancelCalculationModal = ({
  bookingId,
  open = false,
  onClose,
}: {
  bookingId?: number | null
  open: boolean
  onClose?: () => void
}) => {
  const [isOpened, setOpenedFlag] = useState<boolean>(open)
  const state = useSelector((s: RootState) => s.bookings)
  const dispatch = useDispatch()
  const { selectById } = bookingAdapter.getSelectors()
  const { user } = useUser()

  const cancelledBookingbooking = useMemo(() => {
    return bookingId ? selectById(state, bookingId) : null
  }, [state, bookingId])

  const close = () => {
    setOpenedFlag(false)
    onClose?.()
  }

  const cancelBooking = async () => {
    if (bookingId) {
      await dispatch(CancelBooking(bookingId))
      close?.()
    }
  }

  const calculateCancelBooking = async () => {
    if (bookingId) {
      const result = await dispatch(CalculateCancelBooking(bookingId))
      if (isRejected(result)) {
        close?.()
      }
    }
  }

  useEffect(() => {
    if (open) {
      setOpenedFlag(open)
    }
  }, [open])

  useEffect(() => {
    if (open && bookingId) {
      calculateCancelBooking()
    }
  }, [bookingId, open])

  return (
    <CustomModal
      open={isOpened}
      handleClose={close}
      title="You are about to cancel booking!"
      isLoading={!!bookingId && state.loaders[bookingId]?.loading}
    >
      {cancelledBookingbooking?.cancelAmount && !cancelledBookingbooking.isHostedByUser(user) && (
        <h3 className={styles['booking-cancel-calculation-modal__info']}>
          You will get <strong>{formattedPrice(cancelledBookingbooking?.cancelAmount, 'USD')}</strong> back
        </h3>
      )}
      <div className={styles['booking-cancel-calculation-modal__buttons']}>
        <Button theme="primary" onClick={close}>
          Close
        </Button>
        <Button theme="secondary" onClick={cancelBooking}>
          Cancel Booking
        </Button>
      </div>
    </CustomModal>
  )
}
