import mapStyle from 'assets/json/mapStyleWithBathymetry.json'
import { useMergeRefs } from 'hooks/useMergeRefs'
import React, { forwardRef, useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react'
import ReactMapGL, {
  ExtraState,
  InteractiveMap,
  NavigationControl,
  ViewportChangeHandler,
  ViewportProps,
  GeolocateControl,
} from 'react-map-gl'
import ReactGeoCoder from 'react-map-gl-geocoder'
import { debounceTime } from 'rxjs/operators'
import { ReplaySubject } from 'rxjs'
import { normalizeLongitude, normalizeLatitude } from 'geolocation-utils'
import classNames from 'classnames'
import { MapGLBounds, MapGLProps, MapGLViewportProps } from './MapGL.types'
import styles from './MapGL.module.scss'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

export const MapGL = forwardRef<InteractiveMap, MapGLProps>((props, ref) => {
  const {
    children,
    className,
    latitude,
    longitude,
    disabled = false,
    controls = false,
    geocoder = false,
    geolocate = false,
    geocoderContainerRef,
    zoom,
    onMapMove,
    onViewportChange = () => {},
    ...rest
  } = props

  const [viewport, setViewport] = useState<MapGLViewportProps>({
    latitude,
    longitude,
    zoom,
    width: '100%',
    height: '100%',
  })

  const mapProps = disabled
    ? {
        dragRotate: false,
        scrollZoom: false,
        dragPan: false,
        doubleClickZoom: false,
        touchZoom: false,
        touchRotate: false,
      }
    : {}

  const [interactionState, setInteractionState] = useState<ExtraState>({})
  const mapRef = useRef<ReactMapGL>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const mergedRefs = useMergeRefs([ref, mapRef])
  const onViewportChange$ = useRef<ReplaySubject<void>>(new ReplaySubject())

  useEffect(() => {
    const subscription = onViewportChange$.current.pipe(debounceTime(100)).subscribe(() => {
      const map = mapRef.current!.getMap()
      const b = map.getBounds()

      const newBounds = {
        west: b.getWest(),
        north: b.getNorth(),
        south: b.getSouth(),
        east: b.getEast(),
      } as MapGLBounds

      for (const type in newBounds) {
        if (newBounds[type]) {
          if (['north', 'south'].includes(type)) {
            newBounds[type] = normalizeLatitude(newBounds[type])
          }
          if (['east', 'west'].includes(type)) {
            newBounds[type] = normalizeLongitude(newBounds[type])
          }
        }
      }

      onMapMove?.(newBounds)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const handleViewportChangeWithoutMapMove: ViewportChangeHandler = useCallback(
    (newViewport) => {
      let newView = { ...viewport, ...newViewport, width: '100%', height: '100%' }
      setViewport(newView)
      onViewportChange(newView)
    },
    [onViewportChange]
  )

  const handleViewportChangeWithMapMove: ViewportChangeHandler = useCallback(
    (newViewport) => {
      let newView = { ...viewport, ...newViewport, width: '100%', height: '100%' }
      setViewport(newView)
      onViewportChange(newView)
      if (interactionState.isDragging || interactionState.isZooming) {
        onViewportChange$.current.next()
      }
    },
    [onViewportChange, interactionState]
  )

  useLayoutEffect(() => {
    const newViewport: Pick<ViewportProps, 'latitude' | 'longitude' | 'zoom'> = { latitude, longitude, zoom }
    setViewport({ ...viewport, ...newViewport })
  }, [latitude, longitude, zoom])

  const mapStyles = classNames({
    [className!]: !!className,
    [styles['map-gl']]: !!styles['map-gl'],
    [styles['map-gl--disabled']]: !!disabled,
  })

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 }

      return handleViewportChangeWithoutMapMove({
        ...newViewport,
        ...geocoderDefaultOverrides,
      })
    },
    [handleViewportChangeWithoutMapMove]
  )

  return (
    <ReactMapGL
      {...rest}
      ref={mergedRefs}
      {...viewport}
      onViewportChange={!!onMapMove ? handleViewportChangeWithMapMove : handleViewportChangeWithoutMapMove}
      onInteractionStateChange={(s) => setInteractionState({ ...s })}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle={mapStyle}
      className={mapStyles}
      // @ts-ignore
      renderChildrenInPortal
      dragRotate={false}
      scrollZoom
      {...mapProps}
    >
      {controls && <NavigationControl className={styles['map-gl__controls']} showZoom={true} showCompass={false} />}
      {geocoder && (
        <ReactGeoCoder
          mapRef={mapRef}
          containerRef={geocoderContainerRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          position="top-left"
          enableEventLogging={false}
          marker={false}
          language="en"
          clearOnBlur={true}
          clearAndBlurOnEsc={true}
          collapsed={false}
          placeholder="Search for location"
        />
      )}
      {geolocate && (
        <GeolocateControl
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 10,
          }}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation
        />
      )}
      {children}
    </ReactMapGL>
  )
})
