import { getDayFromWeekDay, getTimeFromMinutesAfterMidnight } from 'utils/datesHelpers'

type WeekDays = 1 | 2 | 3 | 4 | 5 | 6 | 7

export interface AvailabilityInput {
  day_of_week: WeekDays
  minutes_after_midnight: number
}

const PREDEFINED_AVAILABILITY = (): AvailabilityInput[] => {
  const calculated = [] as AvailabilityInput[]
  ;[0, 1, 2, 3, 4, 5, 6].forEach((day_of_week) => {
    let hour = 8
    while (hour <= 20) {
      calculated.push({
        day_of_week: day_of_week,
        minutes_after_midnight: hour * 60,
      } as AvailabilityInput)
      hour = hour + 1
    }
  })
  return calculated
}

class Availability {
  dayOfWeek: WeekDays
  formattedDay: string
  minutesAfterMidnight: number
  formattedTime: string
  id: string

  constructor(availability: AvailabilityInput) {
    this.dayOfWeek = availability.day_of_week
    this.formattedDay = getDayFromWeekDay(availability.day_of_week)
    this.minutesAfterMidnight = availability.minutes_after_midnight
    this.formattedTime = getTimeFromMinutesAfterMidnight(availability.minutes_after_midnight)
    this.id = `${this.dayOfWeek}${this.minutesAfterMidnight}`
  }

  // static fromArray(input: AvailabilityInput[] | null): Availability[] {
  //   return input ? input.map((i) => new Availability(i)) : []
  // }

  static fromArray(input: AvailabilityInput[] | null): Availability[] {
    return PREDEFINED_AVAILABILITY().map((i) => new Availability(i))
  }
}

export default Availability
