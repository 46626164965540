import React from 'react'
import classNames from 'classnames'
import styles from './WhatProvideCard.module.scss'

interface IWhatProvideCard {
  className?: string
  icon: string
  name: string
  description: string
}

export const WhatProvideCard: React.FC<IWhatProvideCard> = ({ className, icon = '', name, description }) => {
  return (
    <div className={classNames(className, styles['what-provide-card'])}>
      {icon && <img src={icon} alt="" className={styles['what-provide-card__icon']} />}
      <div className={styles['what-provide-card__name']} dangerouslySetInnerHTML={{ __html: name }}></div>
      <p className={styles['what-provide-card__description']}>{description}</p>
    </div>
  )
}
