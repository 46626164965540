import { InputWrapper } from 'components-new/forms/InputWrapper/InputWrapper'
import { Input } from 'components-new/forms/Input/Input'
import { FormGroup } from 'components-new/forms/FormGroup/FormGroup'
import React, { FC, forwardRef, useImperativeHandle } from 'react'
import { useFormContext } from 'react-hook-form'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'

import {
  StripeCardCvcElementChangeEvent,
  StripeCardElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import { CardFormInputsProps } from './CardFormInputs.types'

const INPUT_OPTIONS = {
  style: {
    base: {
      iconColor: '#1b1b1b',
      color: '#1b1b1b',
      fontWeight: 500,
      fontFamily: 'National 2, Open Sans, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#1b1b1b',
        backgroundColor: '#FAFAFA',
      },
      '::placeholder': {
        color: '#d1d1d1',
      },
    },
    invalid: {
      iconColor: '#bb3637',
      color: '#bb3637 ',
    },
  },
}

export const CardFormInputs = forwardRef<{}, CardFormInputsProps>((props, ref) => {
  const { currency } = props

  const {
    register,
    reset,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext()

  const elements = useElements()
  const stripe = useStripe()

  const clear = () => {
    elements!.getElement(CardNumberElement)?.clear()
    elements!.getElement(CardExpiryElement)?.clear()
    elements!.getElement(CardCvcElement)?.clear()
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      clear()
    },
  }))

  const onChange = async (
    value:
      | StripeCardElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => {
    setValue('token', '', {
      shouldValidate: false,
    })

    setValue(
      value.elementType,
      { ...value },
      {
        shouldValidate: !(value.empty && !touchedFields[value.elementType]),
        shouldTouch: true,
      }
    )

    if (value.complete) {
      const cardElement = elements!.getElement(CardNumberElement)
      setValue('token', '', {
        shouldValidate: true,
      })
      const result = await stripe!.createToken(cardElement!, { currency })
      if (result.token?.id) {
        setValue('token', result.token.id, {
          shouldValidate: true,
        })
      }
    }
  }

  const cardFieldValidator = (value: any, name: string) => {
    if (!value) {
      return `${name} is required`
    } else if (value.empty) {
      return `${name} is required`
    } else if (!value.complete) {
      return `${name} is invalid`
    }
    return true
  }

  return (
    <>
      <Input {...register('token', { required: true })} type="hidden"></Input>
      <Input
        {...register('cardNumber', {
          validate: (value) => cardFieldValidator(value, 'Card number'),
        })}
        type="hidden"
      ></Input>
      <Input
        {...register('cardExpiry', {
          validate: (value) => cardFieldValidator(value, 'Expiry date'),
        })}
        type="hidden"
      ></Input>
      <Input
        {...register('cardCvc', {
          validate: (value) => cardFieldValidator(value, 'CVV'),
        })}
        type="hidden"
      ></Input>
      <InputWrapper error={errors.cardNumber?.message} label="Card number">
        <CardNumberElement options={INPUT_OPTIONS} onChange={onChange} />
      </InputWrapper>
      <FormGroup columns>
        <InputWrapper error={errors.cardExpiry?.message} label="Expiration date">
          <CardExpiryElement options={INPUT_OPTIONS} onChange={onChange} />
        </InputWrapper>
        <InputWrapper error={errors.cardCvc?.message} label="CVV">
          <CardCvcElement options={INPUT_OPTIONS} onChange={onChange} />
        </InputWrapper>
      </FormGroup>
    </>
  )
})
