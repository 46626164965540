import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { BlogCard } from 'components-new/common/BlogCard/BlogCard'
import { RootState } from 'store'
import { GetPostsRelated } from 'services-new/post/post-api'
import { postsRelatedAdapter } from 'features/post/PostsRelatedSlice'
import { Loader } from 'components-new/common/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import React, { useLayoutEffect } from 'react'
import styles from './PostsRelated.module.scss'

interface IPostsRelated {
  id: number
}

export const PostsRelated: React.FC<IPostsRelated> = ({ id }) => {
  const state = useSelector((s: RootState) => s.postsRelated)
  const { selectAll } = postsRelatedAdapter.getSelectors()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(GetPostsRelated(id))
  }, [id])

  const postsRelated = selectAll(state)

  if (state.loading) {
    return <Loader fullScreen />
  }

  if (!postsRelated || postsRelated === []) {
    return null
  }

  return (
    <Wrapper className={styles['posts-related']}>
      <h2 className={styles['posts-related__header']}>More Posts</h2>
      <div className={styles['posts-related__cards-wrapper']}>
        {postsRelated.map((item) => (
          <BlogCard
            slug={item.slug}
            className={styles['posts-related__single-card']}
            image={item.image}
            title={item.title}
            excerpt={item.excerpt}
            key={item.slug}
          />
        ))}
      </div>
    </Wrapper>
  )
}
