import Icon from 'components/Icon/Icon'
import classNames from 'classnames'
import React, { cloneElement, FocusEvent, ChangeEventHandler, useMemo, useState } from 'react'

import { FC } from 'react'

import { FormMessage } from '../FormMessage/FormMessage'
import { FormLabel } from '../FormLabel/FormLabel'
import { FormFieldProps } from './FormField.types'
import styles from './FormField.module.scss'

export const FormField: FC<FormFieldProps> = (props) => {
  const {
    name,
    children,
    className,
    inputClassName,
    type,
    error,
    success,
    disabled,
    label,
    checked,
    hideControl,
    prepend,
    append,
    theme,
    value,
    description,
    disableEvents,
    hasFocus,
  } = props

  const [isFocused, setIsFocused] = useState<boolean>(false)

  // empty string support (when there is no error message added)
  const hasError = type !== 'hidden' ? typeof error === 'string' || !!error : false
  const hasSuccess = type !== 'hidden' ? typeof success === 'string' || !!success : false
  const hasIcon = hasError || hasSuccess

  const onFocusHandler = (e: FocusEvent<any>) => {
    if (!disableEvents) setIsFocused(true)
  }

  const onBlurHandler = (e: FocusEvent<any>) => {
    if (!disableEvents) setIsFocused(false)
  }

  const finalDescription = useMemo(() => {
    const parts = [description]
    const maxLength = children?.props.maxLength

    parts.push(
      maxLength && maxLength > 0 && typeof value === 'string'
        ? `${Math.abs(maxLength - value.length)} symbols ${maxLength - value.length >= 0 ? 'left' : 'over'}`
        : undefined
    )

    return parts.filter((p) => p).join(', ')
  }, [value, description])

  const containerClass = classNames({
    [className!]: !!className,
    [styles['form-field']]: !!styles['form-field'],
    [styles['form-field--disabled']]: !!disabled,
    [styles['form-field--has-icon']]: hasIcon,
    [styles['form-field--has-error']]: hasError,
    [styles['form-field--has-success']]: hasSuccess,
    [styles['form-field--checked']]: !!checked,
    [styles[`form-field--${type}`]]: !!type,
    [styles[`form-field--${theme}`]]: !!theme,
    [styles[`form-field--focused`]]: isFocused || hasFocus,
  })

  const inputClass = classNames({
    [styles['form-field__input']]: !!styles['form-field__input'],
    [styles['form-field__input--disabled']]: !!disabled,
    [styles['form-field__input--has-icon']]: hasIcon,
    [styles['form-field__input--has-error']]: hasError,
    [styles['form-field__input--has-success']]: hasSuccess,
    [styles['form-field__input--checked']]: !!checked,
    [styles[`form-field__input--${type}`]]: !!type,
    [styles[`form-field__input--${theme}`]]: !!theme,
    [styles[`form-field__input--focused`]]: isFocused || hasFocus,
    [inputClassName!]: !!inputClassName,
  })

  const Field = useMemo(() => {
    return (
      <div className={inputClass} onBlur={onBlurHandler} onFocus={onFocusHandler}>
        {prepend &&
          cloneElement(prepend, {
            className: classNames({
              [prepend.props.className]: !!prepend.props.className,
              [styles['form-field__prepend']]: !!styles['form-field__prepend'],
            }),
          })}
        {children}
        {append &&
          cloneElement(append, {
            className: classNames({
              [append.props.className]: !!append.props.className,
              [styles['form-field__append']]: !!styles['form-field__append'],
            }),
          })}
        {hasIcon && <Icon icon={hasError ? 'error' : 'success_2'} />}
      </div>
    )
  }, [hasIcon, hasError, inputClass, children])

  return hideControl ? (
    <FormLabel htmlFor={name} className={containerClass}>
      {Field}
    </FormLabel>
  ) : (
    <FormLabel htmlFor={name} className={containerClass}>
      <div className={styles['form-field__wrapper']}>
        {(label || error || success) && (
          <div className={styles['form-field__header']}>
            {label}
            {(error || success) && (
              <FormMessage type={error ? 'error' : 'success'} className={styles['form-field__message']}>
                {error ? error : success}
              </FormMessage>
            )}
          </div>
        )}
        {Field}
        {finalDescription && <p className={styles['form-field__description']}>{finalDescription}</p>}
      </div>
    </FormLabel>
  )
}
