import mixpanel from 'mixpanel-browser'
// Init mixpanel with mixpanel.init(token) in the App component.
// TODO - add env production check?

let actions = {
  identify: (id: string) => {
    mixpanel.identify(id)
  },
  alias: (id: string) => {
    mixpanel.alias(id)
  },
  track: (name: string, props?: any) => {
    mixpanel.track(name, props)
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props)
    },
  },
}

export const Mixpanel = actions
