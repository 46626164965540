import React, { FC } from 'react'
import { useUser } from 'hooks/useUser'
import { Avatar } from 'components-new/common/Avatar/Avatar'
import { INoMessages } from './NoMessages.types'
import styles from './NoMessages.module.scss'

export const NoMessages: FC<INoMessages> = (props: INoMessages) => {
  const { conversation } = props
  const { user } = useUser()
  const recipient = conversation.getFirstRecipient(user!.id)!

  return (
    <>
      <div className={styles['no-messages']}>
        <div className={styles['no-messages__photo']}>
          <Avatar image={recipient.image} text={recipient.initials} size={'x-huge'} />
        </div>
        <div className={styles['no-messages__from']}>{recipient.displayName}</div>
      </div>
    </>
  )
}
