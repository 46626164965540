import React, { FC, PropsWithChildren } from 'react'

import Icon from 'components/Icon/Icon'
import ButtonGoogle from 'assets/img/button-google.png'
import { Image } from 'components-new/common/Image/Image'
import classNames from 'classnames'
import { SocialConnectProps } from './SocialConnect.types'
import s from './SocialConnect.module.scss'

const Separator: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className={s['social-connect__separator']}>
      <span>{children}</span>
    </div>
  )
}

const Button: FC<SocialConnectProps> = ({ className, type }) => {
  const classes = classNames({
    [className!]: !!className,
    [s['social-connect']]: true,
  })

  switch (type) {
    case 'google':
      return (
        <a href={`${process.env.REACT_APP_BACKEND_URL}/connect/google`} className={classes}>
          <Image src={ButtonGoogle} className={s['social-connect__button']} />
        </a>
      )

    default:
      return null
  }
}
export const SocialConnect = Object.assign(Button, {
  Separator,
})
