import classNames from 'classnames'
import React, { FC } from 'react'
import { use100vh } from 'react-div-100vh'
import Lottie from 'react-lottie'
import defaultAnimationData from 'assets/lottie/loading-gray.json'
import { LoaderProps } from './Loader.types'
import styles from './Loader.module.scss'

export const Loader: FC<LoaderProps> = (props) => {
  const { fullScreen, size = 100, className, animationData, fillHeight, overlay } = props
  const height = use100vh()

  const style = fullScreen ? { height: height ? height : '100vh', paddingBottom: document.body.style.paddingTop } : {}

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData ? animationData : defaultAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const classes = classNames({
    [className!]: !!className,
    [styles['loader']]: !!styles['loader'],
    [styles['loader--full-screen']]: !!fullScreen,
    [styles['loader--fill-height']]: !!fillHeight,
    [styles['loader--overlay']]: !!overlay,
  })

  return (
    <div style={style} className={classes}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  )
}
