import { Button } from 'components-new/common/Button/Button'
import { Loader } from 'components-new/common/Loader/Loader'
import { EditTrip } from 'containers-new/AccountEditTripsContainer/components/EditTrip/EditTrip'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { experiencesAdapter } from 'features/experience/ExperiencesSlice'
import { experienceEditLink, getTripEmbedCode } from 'global/routes'
import { GetExperiences } from 'services-new/experience/experience-api'
import { RootState } from 'store'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EmbedModal } from 'components-new/modals/EmbedModal/EmbedModal'
import Experience from 'services-new/experience/experience.model'
import styles from './AccountEditTripsContainer.module.scss'

export const AccountEditTripsContainer: React.FC = () => {
  const state = useSelector((s: RootState) => s.experiences)
  const { selectAll } = experiencesAdapter.getSelectors()
  const dispatch = useDispatch()
  const [embedTrip, setEmbedTrip] = useState<Experience | null>(null)

  useEffect(() => {
    dispatch(GetExperiences())
  }, [])

  const editTrips = useMemo(() => {
    return selectAll(state)
  }, [state])

  const tripList = () => {
    if (!state.loaded) {
      return <Loader />
    }
    if (editTrips.length === 0) {
      return (
        <>
          <h3>No trips yet!</h3>{' '}
          <Button theme="plain-orange" to={experienceEditLink('new')}>
            + Create New Trip
          </Button>
        </>
      )
    }

    return editTrips.map((data) => {
      return (
        <EditTrip
          data={data}
          onEmbed={() => setEmbedTrip(data)}
          durationDescription={data.durationString}
          guestsDescription={data.capacityString}
          key={data.id}
        />
      )
    })
  }

  return (
    <Wrapper className={styles['edit-trips__wrapper']}>
      <div className={styles['edit-trips__trips-list']}>{tripList()}</div>
      {embedTrip && (
        <EmbedModal code={getTripEmbedCode(embedTrip.id)} onClose={() => setEmbedTrip(null)} open={!!embedTrip} />
      )}
    </Wrapper>
  )
}
