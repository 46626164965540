const SIZE_URL = `https://WORKER/SOURCE-IMAGE?width=SIZE`

const buildSizeUrl = (size: keyof ImageSizes, url: ImageSizes[keyof ImageSizes]) => {
  if (!process.env.REACT_APP_CF_WORKER || url.indexOf('assets.outguided.com') === -1) {
    return url
  }
  return SIZE_URL.replace('WORKER', process.env.REACT_APP_CF_WORKER)
    .replace('SIZE', size)
    .replace('SOURCE-IMAGE', url.split('/').pop()!)
}

const buildFormatSizeUrl = (size: keyof FormatsImageSizes, obj: FormatsImageSizesInput) => {
  return obj[size] ? obj[size].url : ''
}
export interface ImageInput {
  id: number
  name: string
  ext: string
  width: number | null
  height: number | null
  url: string
  formats?: FormatsImageSizesInput
  alternativeText: string
}

export enum Sizes {
  Small = '640',
  Medium = '960',
  Large = '1680',
  Huge = '1920',
}

export enum FormatsSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Huge = 'huge',
}

type FormatsImageSizesInput = {
  [key in FormatsSizes]: {
    url: string
  }
}

type FormatsImageSizes = {
  [key in FormatsSizes]: string
}

type ImageSizes = { [key in Sizes]: string }
class Image {
  id: number | string
  name: string
  ext: string
  width?: number | undefined
  height?: number | undefined
  url: string
  sizes: ImageSizes & FormatsImageSizes
  alternativeText: string
  formats?: FormatsImageSizesInput

  constructor(image: ImageInput | string) {
    if (typeof image === 'string') {
      let url = image
      if (url.charAt(0) === '/') {
        url = `${window.location.protocol}//${window.location.host}${url}`
      }
      this.id = image
      this.url = url
      this.name = image
      this.ext = `.${image.split('.').pop() as string}`
      this.alternativeText = ''
    } else {
      this.id = image.id
      this.name = image.name
      this.ext = image.ext
      this.width = image.width || undefined
      this.height = image.height || undefined
      this.url = image.url
        .replace('outguided-prod.s3.us-east-2.amazonaws.com', 'assets.outguided.com')
        .replace('s3.us-east-2.amazonaws.com/assets.outguided.com', 'assets.outguided.com')
      this.alternativeText = image.alternativeText
      this.formats = image.formats
    }

    this.sizes = {} as ImageSizes & FormatsImageSizes
    Object.values(Sizes).forEach((size) => {
      this.sizes[size] = buildSizeUrl(size, this.url)
    })

    if (typeof image !== 'string' && image.formats) {
      Object.values(FormatsSizes).forEach((size) => {
        this.sizes[size] = buildFormatSizeUrl(size, image.formats!)
      })
    }
  }

  getSmallSize() {
    return this.sizes[FormatsSizes.Small] ? this.sizes[FormatsSizes.Small] : this.sizes[Sizes.Small]
  }

  getMediumSize() {
    return this.sizes[FormatsSizes.Medium] ? this.sizes[FormatsSizes.Medium] : this.sizes[Sizes.Medium]
  }

  getLargeSize() {
    return this.sizes[FormatsSizes.Large] ? this.sizes[FormatsSizes.Large] : this.sizes[Sizes.Large]
  }

  getHugeSize() {
    return this.sizes[FormatsSizes.Huge] ? this.sizes[FormatsSizes.Huge] : this.sizes[Sizes.Huge]
  }

  getSrcSet() {
    return [
      `${this.getSmallSize()} ${Sizes.Small}w`,
      `${this.getMediumSize()} ${Sizes.Medium}w`,
      `${this.getLargeSize()} ${Sizes.Large}w`,
      `${this.getHugeSize()} ${Sizes.Huge}w`,
    ]
  }

  static fromArray(input: ImageInput[] | null): Image[] {
    return input ? input.map((i) => new Image(i)) : []
  }

  toString() {
    return this.url
  }
}

export default Image
