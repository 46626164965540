import classNames from 'classnames'
import React, { FC, useState, ReactEventHandler } from 'react'
import styles from './Image.module.scss'
import { ImageProps } from './Image.types'

export const Image: FC<ImageProps> = ({
  src,
  alt = '',
  theme = 'default',
  srcSet = [],
  sizes = [],
  className,
  wrapperClassName,
  append,
  prepend,
  ratio = null,
  onClick,
  style,
  width,
  height,
  ...restImageProps
}) => {
  const [imageState, setImageState] = useState<'error' | 'loading' | 'ready'>('loading')

  const onError: ReactEventHandler<HTMLImageElement> = (event) => {
    setImageState('error')
  }

  const onLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    setImageState('ready')
  }

  if (!src) {
    return null
  }

  const buildSrcSet = srcSet.join(', ')
  const buildSizes = sizes.join(', ')

  const allStyles = style || {}
  // To get padding value, calculate the ratio of height to width -
  // for example for 4x3 it'll be 3/4 * 100
  if (typeof ratio === 'number') {
    allStyles.paddingTop = ratio * 100 + '%'
  }

  const containerClasses = classNames({
    [wrapperClassName!]: !!wrapperClassName,
    [styles['image']]: !!styles['image'],
    [styles[`image--${imageState}`]]: true,
    [styles['image--with-fixed-ratio']]: typeof ratio === 'number',
    [styles['image--with-full-ratio']]: ratio === 'full',
    [styles[`image--${theme}`]]: !!theme && styles[`image--${theme}`],
    [styles['image--with-click']]: !!onClick,
  })

  const imageClasses = classNames({
    [className!]: !!className,
    [styles['image__img']]: !!styles['image__img'],
  })
  const isPrerender = window.navigator.userAgent.indexOf('Prerender') > -1
  const calculatedWidth = typeof ratio === 'number' || isPrerender ? width || 1000 : width
  const calculatedHeight =
    typeof ratio === 'number' || isPrerender
      ? (typeof ratio === 'number' ? ratio : 3 / 4) * ((typeof height === 'string' ? parseInt(height) : height) || 1000)
      : height

  return (
    <figure style={allStyles} className={containerClasses}>
      {prepend}
      <img
        className={imageClasses}
        src={src}
        srcSet={buildSrcSet}
        sizes={buildSizes}
        alt={alt}
        {...restImageProps}
        width={calculatedWidth}
        height={calculatedHeight}
        onClick={onClick}
        onError={onError}
        onLoad={onLoad}
      />
      {append}
    </figure>
  )
}
