import Review, { ReviewInput } from 'services/reviews/models/Review'
import Location, { LocationInput } from 'services/shared/models/Location'
import Availability, { AvailabilityInput } from 'services/shared/models/Availability'
import Activity, { ActivityInput } from 'services/shared/models/Activity'
import UserPublic, { UserPublicInput } from 'services/shared/models/UserPublic'
import Image, { ImageInput } from 'services/shared/models/Image'
import { getCapacityString, getDurationString, formatDuration } from 'utils/datesHelpers'
import Section, { SectionInput } from 'services/shared/models/Section'
import Pricing, { PricingInput } from 'services/shared/models/Pricing'

export enum MarketingDetailsStatus {
  Published = 'published',
  Draft = 'draft',
  Cancelled = 'cancelled',
}

export interface MarketingDetailsInput {
  id: number
  slug: string
  guests: number
  base_price: number
  variable_price: number
  capacity: number
  minimum_capacity: number
  maximum_capacity: number
  days_capacity: number
  hours_capacity: number
  title: string
  duration: number
  description: string
  pre_trip_information: string
  properties: any[]
  rating: number
  images: ImageInput[]
  image_order: ImageInput['name'][]
  sections: SectionInput[]
  status: MarketingDetailsStatus
  hosts: UserPublicInput[]
  reviews: ReviewInput[]
  pricing: PricingInput | null
  locations: LocationInput[]
  activities: ActivityInput[]
  availability: AvailabilityInput[]
  parent: number | null
}
export interface MarketingDetailsElasticInput {
  id: string
  _source: MarketingDetailsInput
}
class MarketingDetails {
  id: number
  slug: string
  guests: number
  basePrice: number
  variablePrice: number
  minimum_capacity: number
  maximum_capacity: number
  days_capacity: number
  hours_capacity: number
  title: string
  duration: number
  description: string
  preTripInformation: string
  properties: any[]
  rating: number | null
  images: Image[]
  image?: Image
  imageUrl?: Image['url']
  sections: Section[]
  status: string
  hosts: UserPublic[]
  host: UserPublic | null
  reviews: Review[]
  locations: Location[]
  location: Location | null
  activities: Activity[]
  availability: Availability[]
  capacityString: string
  durationString: string
  pricing: Pricing
  parent: number | null

  constructor(marketingPage: MarketingDetailsInput) {
    this.id = marketingPage.id
    this.slug = marketingPage.slug
    this.guests = marketingPage.capacity
    this.basePrice = marketingPage.base_price / 100
    this.parent = marketingPage.parent

    if (marketingPage.minimum_capacity > 0) {
      this.minimum_capacity = marketingPage?.minimum_capacity
      this.maximum_capacity = marketingPage?.maximum_capacity
      this.variablePrice = marketingPage.variable_price / 100
    } else {
      this.minimum_capacity = 1
      this.maximum_capacity = marketingPage.capacity
      this.variablePrice = marketingPage.base_price / 100
    }

    this.days_capacity = marketingPage?.days_capacity
    this.hours_capacity = marketingPage?.hours_capacity

    this.title = marketingPage.title
    this.duration = marketingPage.duration
    this.preTripInformation = marketingPage.pre_trip_information
    this.description = marketingPage.description
    this.properties = marketingPage.properties
    this.rating = marketingPage.rating && marketingPage.rating > 0 ? marketingPage.rating : null
    this.images = Image.fromArray(marketingPage.images)

    this.image = this.images[0] || undefined
    this.imageUrl = this.image?.getLargeSize() || undefined

    this.sections = Section.fromArray(marketingPage.sections)
    this.status = marketingPage.status
    this.hosts = UserPublic.fromArray(marketingPage.hosts)
    this.host = this.hosts.length ? this.hosts[0] : null
    this.reviews = Review.fromArray(marketingPage.reviews)
    this.locations = Location.fromArray(marketingPage.locations)
    this.location = this.locations.length ? this.locations[0] : null
    this.activities = Activity.fromArray(marketingPage.activities)
    this.availability = Availability.fromArray(marketingPage.availability)
    this.pricing = new Pricing(marketingPage.pricing || {})
    this.capacityString = getCapacityString({
      min: this.pricing.minimumCapacity,
      max: this.pricing.maximumCapacity,
    })
    this.durationString = getDurationString(formatDuration(this.duration))
  }

  static fromArray(input: MarketingDetailsInput[] | string | null): MarketingDetails[] {
    return input && Array.isArray(input) ? input.map((i) => new MarketingDetails(i)) : []
  }
}

export default MarketingDetails
