import React, { useRef } from 'react'
import { ExperienceGrid } from 'components-new/common/ExperienceGrid/ExperienceGrid'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import UserPublic from 'services/shared/models/UserPublic'
import { HostLocation } from 'containers-new/HostContainer/components/HostLocation/HostLocation'
import { SectionImage } from 'containers-new/HostContainer/components/SectionImage/SectionImage'
import { useEmbed } from 'hooks/useEmbed'
import { AboutHost } from './components/AboutHost/AboutHost'
import { HeroHost } from './components/HeroHost/HeroHost'
import styles from './HostContainer.module.scss'
import { HostInfo } from './components/HostInfo/HostInfo'

export const HostContainer: React.FC<{
  hostBasics: UserPublic
}> = ({ hostBasics }) => {
  const experienceRef = useRef<HTMLDivElement>(null)
  const { isGuideEmbed } = useEmbed({ guide: hostBasics })
  return (
    <>
      <HeroHost hostBasics={hostBasics} />
      {isGuideEmbed && hostBasics.marketingPages.length > 0 && (
        <Wrapper ref={experienceRef} containerClasses={styles['host-container__experiences']}>
          <ExperienceGrid experiences={hostBasics.marketingPages} title={hostBasics.label} />
        </Wrapper>
      )}
      <HostInfo hostBasics={hostBasics} />
      {hostBasics.sections && hostBasics.sections.length > 0 && <AboutHost items={hostBasics.sections} />}
      {!isGuideEmbed && hostBasics.marketingPages.length > 0 && (
        <Wrapper ref={experienceRef} containerClasses={styles['host-container__experiences']}>
          <ExperienceGrid experiences={hostBasics.marketingPages} title={hostBasics.label} />
        </Wrapper>
      )}
      {hostBasics.location && (
        <HostLocation latitude={hostBasics.location.latitude} longitude={hostBasics.location.longitude} />
      )}
      {/* <SectionImage /> */}
    </>
  )
}
