import classNames from 'classnames'
import React from 'react'
import styles from './SectionWrapper.module.scss'
import { ISectionWrapper } from './SectionWrapper.types'

export const SectionWrapper: React.FC<ISectionWrapper> = ({ title = '', subtitle, theme, children, className }) => {
  const classes = classNames({
    [className!]: !!className,
    [styles['section-wrapper']]: !!styles['section-wrapper'],
    [styles[`section-wrapper--${theme}`]]: !!theme,
  })
  return (
    <div className={classes}>
      {title && (
        <h2 className={styles['section-wrapper__title']}>
          {title}
          {subtitle && <span>{subtitle}</span>}
        </h2>
      )}
      <div className={styles['section-wrapper__content']}>{children}</div>
    </div>
  )
}
