import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import React from 'react'
import lensImg from 'assets/img/homepage-lens.jpg'
import { Image } from 'components-new/common/Image/Image'
import StylishButton from 'components-new/common/StylishButton/StylishButton'
// import icon_lens from 'assets/icons/icon_lens.svg'
// import icon_outguided from 'assets/icons/icon_logo_textonly.svg'
import { blogLink } from 'global/routes'
import styles from './Lens.module.scss'

export const Lens: React.FC = () => {
  const up = (
    <div className={styles['lens__left-up']}>
      {/* <div className={styles['lens__header']}>
        Lens
        <img src={icon_lens} alt="shutter icon" style={{ verticalAlign: 'bottom' }} />
      </div>
      <div className={styles['lens__sub-header']}>
        by
        <img src={icon_outguided} alt="outguided" />
      </div> */}
    </div>
  )
  const down = (
    <div className={styles['lens__down']}>
      <div className={styles['lens__left-down']}>
        <div className={styles['lens__footer']}>Stories and Adventures from the field.</div>
        {/* <div className={styles['lens__sub-footer']}>Always drink upstream of the herd.</div> */}
      </div>
      <StylishButton to={blogLink()} className={styles['lens__button']} clickOverlay={true}>
        Read our Blog
      </StylishButton>
    </div>
  )

  const overlay = (
    <div className={styles['lens__content-overlay']}>
      {up}
      {down}
    </div>
  )

  return (
    <Wrapper>
      <Image className={styles['lens__image']} src={lensImg} prepend={overlay} />
    </Wrapper>
  )
}
