import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  BecomeGuide,
  DeleteUser,
  LoginUser,
  LogoutUser,
  Me,
  RegisterUser,
  ResetPassword,
} from 'services-new/auth/auth-api'

import { reset as resetUser } from 'features/user/UserSlice'
import { reset as resetExperience } from 'features/experience/ExperiencesSlice'
import { reset as resetBookings } from 'features/booking/BookingSlice'
import { reset as resetConversations } from 'features/conversation/ConversationsSlice'
import { reset as resetPayments } from 'features/stripe/PaymentsSlice'
import LocalStorageService from 'utils/LocalStorageService'

export const UserMiddleware = createListenerMiddleware()

UserMiddleware.startListening({
  matcher: isAnyOf(Me.rejected, LogoutUser.fulfilled, DeleteUser.fulfilled),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(resetUser())
    LocalStorageService.clearLocalStorage()
    window.location.reload()
  },
})

UserMiddleware.startListening({
  matcher: isAnyOf(
    Me.rejected,
    LogoutUser.fulfilled,
    LoginUser.fulfilled,
    RegisterUser.fulfilled,
    ResetPassword.fulfilled,
    BecomeGuide.fulfilled
  ),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(resetExperience())
    listenerApi.dispatch(resetBookings())
    listenerApi.dispatch(resetPayments())
    listenerApi.dispatch(resetConversations())
  },
})
