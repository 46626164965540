import { createAxiosAsyncThunk, getRequest$, postRequest$ } from 'utils/api'
import { BookingInput } from './booking.model'

export const GetBooking = createAxiosAsyncThunk<BookingInput, number>(
  'bookings/getBooking',
  async (id) => await getRequest$(`bookings/${id}`)
)

export const GetBookings = createAxiosAsyncThunk<BookingInput[], void>(
  'bookings/getBookings',
  async () => await getRequest$(`bookings?_sort=created_at:DESC`)
)

export const AcceptBooking = createAxiosAsyncThunk<BookingInput, number>(
  'bookings/acceptBooking',
  async (id) => await postRequest$(`bookings/${id}/accept`)
)

export const CancelBooking = createAxiosAsyncThunk<BookingInput, number>(
  'bookings/cancelBooking',
  async (id) => await postRequest$(`bookings/${id}/cancel`)
)

export const CalculateCancelBooking = createAxiosAsyncThunk<{ amount: number; booking: BookingInput }, number>(
  'bookings/calculateCancelBooking',
  async (id) => await getRequest$(`bookings/${id}/cancel`)
)

export const RejectBooking = createAxiosAsyncThunk<BookingInput, number>(
  'bookings/rejectBooking',
  async (id) => await postRequest$(`bookings/${id}/reject`)
)
