import { AppDate } from 'services-new/shared/date'

export interface MessageInput {
  author: string
  body: string
  conversationSid: string
  created_at: string
  updated_at: string
  index: number
  sid: number
}

export interface MessageOutput {
  body: string
}

class Message {
  authorId: number
  body: string
  id: number
  updatedAt: AppDate
  createdAt: AppDate
  index: number

  constructor(message: MessageInput) {
    this.authorId = parseInt(message.author)
    this.body = message.body
    this.id = message.sid
    this.index = message.index
    this.updatedAt = new AppDate(message.updated_at)
    this.createdAt = new AppDate(message.created_at)
  }

  static fromArray(input: MessageInput[] | null): Message[] {
    return input ? input.map((i) => new Message(i)) : []
  }

  static toOutput(data: Partial<Message>): MessageOutput {
    return {
      body: data.body!,
    }
  }

  static orderMessages(input: Message[]): Message[] {
    return input
      .filter((value, index, self) => self.map((x) => x.index).indexOf(value.index) === index)
      .sort((a, b) => (a.index < b.index ? -1 : 1))
  }
}

export default Message
