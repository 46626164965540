import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FormMessageProps } from './FormMessage.types'
import styles from './FormMessage.module.scss'

export const FormMessage = forwardRef<HTMLLabelElement, FormMessageProps>(function FormMessage(props, ref) {
  const { children, type, className, ...nativeProps } = props

  const classes = classNames({
    [styles['form-message']]: true,
    [styles[`form-message--${type}`]]: !!type,
    [className!]: !!className,
  })

  return (
    <span ref={ref} className={classes} {...nativeProps}>
      {children}
    </span>
  )
})
