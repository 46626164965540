import selectn from 'selectn'
import { extractAddressMapBox } from 'services/shared/models/Address'

export const prepareLocation = (locations) => {
  if (locations && locations.length > 0) {
    const city = selectn('[0].address.city', locations)
    const state = selectn('[0].address.state', locations)
    const country = selectn('[0].address.country', locations)

    if (city && state) {
      return `${city}, ${state}`
    }
    if (city && country) {
      return `${city}, ${country}`
    }
    if (state && country) {
      return `${state}, ${country}`
    }
    if (country) {
      return `${country}`
    }
  }
  return ''
}

export const parseMapBoxLocation = (location) => {
  const address = extractAddressMapBox(location)

  return {
    label: address.fullLocationString,
    value: address.locationSearchString,
    location,
  }
}

export const getLatLonFromLocation = (location) => ({
  latitude: parseFloat(location.coordinates.split(',').shift()),
  longitude: parseFloat(location.coordinates.split(',').pop()),
})
