import Booking, { BookingInput } from 'services-new/booking/booking.model'
import HostInfo, { HostInfoInput, HostInfoOutput } from 'services/shared/models/HostInfo'
import Address, { AddressInput } from './Address'
import Location, { LocationInput, LocationOutput } from './Location'
import UserPublic, { UserPublicInput } from './UserPublic'

export interface UserOutput {
  id: number
  email: string
  username: string
  first_name: string
  last_name: string
  password: string
  phone: string
  dateOfBirth: string
  address: AddressInput
  host_info: HostInfoOutput | null
  location: LocationOutput | null
}

export type TAvailability = {
  id: number
  date: string
}

export interface AuthUserInput {
  jwt: string
  user: UserInput
}

export type UserInput = Omit<UserPublicInput, 'host_info'> & {
  username: string
  provider: string
  password: string
  resetPasswordToken: string
  confirmed: boolean
  blocked: boolean
  gender: string
  date_of_birth: string
  phone: string
  active: boolean
  host_info: HostInfoInput | null
  location: LocationInput | null
  bookings: BookingInput[]
  availability: TAvailability[]
  address: AddressInput
}

class User extends UserPublic {
  username: string
  provider: string
  password?: string
  resetPasswordToken: string
  confirmed: boolean
  blocked: boolean
  gender: string
  dateOfBirth: string
  phone: string
  active: boolean
  hostInfo: HostInfo | null
  bookings: Booking[]
  sections: any
  location: Location | null
  notAvailableHostDays: string[]
  address: Address | null

  constructor(user: UserInput) {
    super(user)
    this.username = user.email
    this.provider = user.provider
    this.resetPasswordToken = user.resetPasswordToken
    this.confirmed = user.confirmed
    this.blocked = user.blocked
    this.gender = user.gender
    this.dateOfBirth = user.date_of_birth
    this.phone = user.phone
    this.active = user.active
    this.hostInfo = this.isHost && user.host_info ? new HostInfo(user.host_info) : null
    this.bookings = Booking.fromArray(user.bookings)
    this.location = user.location && new Location(user.location)
    this.notAvailableHostDays = user.availability ? user.availability.map((a) => a.date) : []
    this.address = user.address && new Address(user.address)
  }

  static toOutput(data: Partial<User>) {
    return {
      id: data.id,
      first_name: data.firstName,
      last_name: data.lastName,
      password: data.password,
      gender: data.gender,
      date_of_birth: data.dateOfBirth,
      phone: data.phone,
      location: data.location ? Location.toOutput(data.location) : null,
      host_info: data.hostInfo ? HostInfo.toOutput(data.hostInfo) : null,
      address: data.address ? Address.toOutput(data.address) : null,
      image: data.image ? data.image.id : null,
    }
  }
}

export default User
