import React from 'react'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { Footer } from 'components-new/layout/Footer/Footer'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { AboutUsContainer } from 'containers-new/AboutUsContainer/AboutUsContainer'
import Seo from 'components/Seo/Seo'
import { IAboutPage } from './AboutPage.types'

const AboutPage: React.FC<IAboutPage> = () => {
  return (
    <div>
      <Seo title={'About Us'} />
      <Navbar withFixedPosition />
      <AboutUsContainer />
      <Footer img={footerImage} />
    </div>
  )
}

export default AboutPage
