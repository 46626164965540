import React from 'react'
import { Image } from 'components-new/common/Image/Image'
import { useSearchContext } from 'containers-new/SearchContainer/SearchContext'
import StylishButton from 'components-new/common/StylishButton/StylishButton'
import classNames from 'classnames'
import { ParamsObject } from 'containers-new/SearchContainer/SearchContext.types'
import styles from './TripCard.module.scss'

export type TripCardProps = {
  src: string | undefined
  className?: string
  text: string
  searchParams?: ParamsObject
}

export const TripCard: React.FC<TripCardProps> = ({ src, className, text, searchParams }) => {
  const { runSearch } = useSearchContext()
  const handleSearchClick = () => {
    searchParams &&
      runSearch({
        withSearchReset: true,
        searchParams: searchParams,
      })
  }
  const classesName = classNames({
    [className!]: !!className,
    [styles['trip-card']]: !!styles['trip-card'],
  })

  return (
    <div className={classesName}>
      <Image
        prepend={
          <div className={styles['trip-card__button_wrapper']}>
            <StylishButton onClick={handleSearchClick} clickOverlay={true} className={styles['trip-card__button']}>
              {text}
            </StylishButton>
          </div>
        }
        src={src}
        className={styles['trip-card__image']}
      ></Image>
    </div>
  )
}
