import React, { FC, Children, useState, useEffect } from 'react'
import MaterialUiCarousel from 'react-material-ui-carousel'
import classNames from 'classnames'
import { CarouselProps } from './Carousel.types'
import styles from './Carousel.module.scss'

export const Carousel: FC<CarouselProps> = (props) => {
  const { className, children: childrenProp } = props
  const children = Children.toArray(childrenProp)
  const [loading, setLoading] = useState<boolean>(true)
  const classes = classNames({
    [className!]: !!className,
    [styles['carousel--loading']]: loading,
    [styles['carousel']]: !!styles['carousel'],
  })

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLoading(false)
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (children.length === 1) {
    return <>{children}</>
  }

  return (
    <div className={classes}>
      <MaterialUiCarousel
        autoPlay={false}
        animation={'slide'}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: '3px',
          },
        }}
        indicatorIconButtonProps={{
          className: styles['carousel__indicator'],
        }}
        activeIndicatorIconButtonProps={{
          className: styles['carousel__indicator--active'],
        }}
        navButtonsProps={{
          className: styles['carousel__button'],
        }}
      >
        {children}
      </MaterialUiCarousel>
    </div>
  )
}
