import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import flagImage from 'assets/img/about/flag-image.png'
import image1 from 'assets/img/about/about-us-1.jpg'
import image2 from 'assets/img/about/about-us-2.jpg'
import image3 from 'assets/img/about/about-us-3.jpg'
import image4 from 'assets/img/about/about-us-4.jpg'
import image5 from 'assets/img/about/about-us-5.jpg'
import image6 from 'assets/img/about/about-us-6.jpg'
import image7 from 'assets/img/about/about-us-7.jpg'
import image8 from 'assets/img/about/about-us-8.jpg'
import Image from 'services/shared/models/Image'
import classNames from 'classnames'
import { IOurStory } from './OurStory.types'
import styles from './OurStory.module.scss'

export const OurStory: React.FC<IOurStory> = () => {
  const getResizedImage = (url: string) => {
    return new Image(url).getMediumSize()
  }

  return (
    <Wrapper containerClasses={styles['our-story']}>
      <h2 className={styles['our-story__header']}>Our Story</h2>
      <div className={styles['our-story__origin-wrapper']}>
        <img src={flagImage} alt="" className={styles['our-story__flag']} />
        <span className={styles['our-story__origin']}>Proudly Made in Madison, Georgia</span>
      </div>
      <div className={styles['our-story__row']}>
        <p className={styles['our-story__paragraph']}>
          While researching guided fly fishing trips, Brooks and Tim (Outguided Co-Founders) could not believe how hard
          it was to research and book the right guide. We were stuck playing phone tag, building a spreadsheet with
          possible options, and emailing back and forth. The discovery and booking process...sucked.
        </p>
        <div className={styles['our-story__images-wrapper']}>
          <div className={classNames(styles['our-story__images-row'], styles['our-story__images-row--1'])}>
            <img
              src={getResizedImage(image1)}
              alt=""
              className={classNames(styles['our-story__image'], styles['our-story__image--1'])}
            />
            <img
              src={getResizedImage(image2)}
              alt=""
              className={classNames(styles['our-story__image'], styles['our-story__image--2'])}
            />
          </div>
          <div className={classNames(styles['our-story__images-row'], styles['our-story__images-row--2'])}>
            <img
              src={getResizedImage(image3)}
              alt=""
              className={classNames(styles['our-story__image'], styles['our-story__image--3'])}
            />
            <img
              src={getResizedImage(image4)}
              alt=""
              className={classNames(styles['our-story__image'], styles['our-story__image--4'])}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles['our-story__row'], styles['our-story__row--2'])}>
        <div className={styles['our-story__images-wrapper']}>
          <div className={classNames(styles['our-story__images-row'], styles['our-story__images-row--3'])}>
            <img
              src={getResizedImage(image5)}
              alt=""
              className={classNames(styles['our-story__image'], styles['our-story__image--5'])}
            />
            <img
              src={getResizedImage(image6)}
              alt=""
              className={classNames(styles['our-story__image'], styles['our-story__image--6'])}
            />
          </div>

          <img
            src={getResizedImage(image7)}
            alt=""
            className={classNames(styles['our-story__image'], styles['our-story__image--7'])}
          />
        </div>
        <p className={styles['our-story__paragraph']}>
          Ultimately, we had a great trip and caught some fish, but this problem stuck with us. If it was this hard for
          both of us, how hard would it be for people who were less experienced anglers or looking to go out there for
          the first time? After all, we believe life is all about new experiences and the people you do them with.
        </p>
      </div>
      <div className={classNames(styles['our-story__row'], styles['our-story__row--3'])}>
        <p className={styles['our-story__paragraph']}>
          Months later, we couldn’t get rid of this idea. There are so many incredible guided adventures out there, but
          there was no easy way of finding the right guide. We realized how big the problem was. Not just in fishing,
          but across almost every guided adventure -- from hunting to backcountry skiing to rafting and everything in
          between. Then, we set out to work. Outguided was born.
        </p>
        <div className={styles['our-story__images-wrapper']}>
          <img
            src={getResizedImage(image8)}
            alt=""
            className={classNames(styles['our-story__image'], styles['our-story__image--8'])}
          />
        </div>
      </div>
    </Wrapper>
  )
}
