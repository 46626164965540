import Seo from 'components/Seo/Seo'
import { Loader } from 'components-new/common/Loader/Loader'
import { NotFoundPage } from 'pages-new/NotFoundPage/NotFoundPage'
import { useUser } from 'hooks/useUser'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { MessagesPage } from 'pages-new/MessagesPage/MessagesPage'
import { AccountDetailsContainer } from 'containers-new/AccountDetailsContainer/AccountDetailsContainer'
import { AccountCalendarContainer } from 'containers-new/AccountCalendarContainer/AccountCalendarContainer'
import { AccountPublicProfileContainer } from 'containers-new/AccountPublicProfileContainer/AccountPublicProfileContainer'
import { AccountEditTripsContainer } from 'containers-new/AccountEditTripsContainer/AccountEditTripsContainer'
import { AccountBookingsContainer } from 'containers-new/AccountBookingsContainer/AccountBookingsContainer'
import { AccountPaymentsContainer } from 'containers-new/AccountPaymentsContainer/AccountPaymentsContainer'
import { useParams } from 'react-router-dom'
import React, { FC, useMemo } from 'react'
import { AccountNavBar } from './components/AccountNavBar/AccountNavBar'

export enum AccountPages {
  calendar = 'Calendar',
  bookings = 'Bookings',
  messages = 'Messages',
  payments = 'Payments',
  trips = 'Manage / Edit Trips',
  profile = 'Edit Public Profile',
  details = 'Account Details',
}

export enum UserAccountPages {
  bookings = 'My Trips',
  messages = 'Messages',
  details = 'Account Details',
}

export enum AccountPagesMobile {
  trips = 'Manage / Edit Trips',
  profile = 'Edit Public Profile',
  details = 'Account Details',
}

export enum UserAccountPagesMobile {
  details = 'Account Details',
}

export const AccountPage: FC<{}> = () => {
  const { page = 'details' } = useParams<{ page: keyof typeof AccountPages }>()
  const { isLoggedIn, isLoading } = useUser()

  const accountComponent = useMemo(() => {
    switch (page) {
      case 'calendar':
        return <AccountCalendarContainer />
      case 'bookings':
        return <AccountBookingsContainer />
      case 'messages':
        return <MessagesPage />
      case 'details':
        return <AccountDetailsContainer />
      case 'profile':
        return <AccountPublicProfileContainer />
      case 'trips':
        return <AccountEditTripsContainer />
      case 'payments':
        return <AccountPaymentsContainer />
      default:
        return <>{page}</>
    }
  }, [page])

  if (!isLoggedIn) {
    return isLoading ? <Loader fullScreen /> : <NotFoundPage />
  }

  return (
    <>
      <Seo noIndex={true} />
      <Navbar hideSearch={true} />
      <AccountNavBar page={page} />
      {accountComponent}
    </>
  )
}
