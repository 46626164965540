import { EventCreationContainerActions } from 'redux/EventCreationContainer/actions'

const initialState = {
  anonymousPath: undefined,
  errorMessage: '',
  eventDraft: null,
  validationErrors: {},
}

export function eventCreationContainerReducer(state = initialState, action) {
  switch (action.type) {
    case EventCreationContainerActions.SET_ANONYMOUS_PATH:
      return {
        ...state,
        anonymousPath: action.payload,
      }

    case EventCreationContainerActions.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case EventCreationContainerActions.SET_DRAFT_PROPERTY:
      return {
        ...state,
        eventDraft: { ...state.eventDraft, ...action.payload },
      }

    case EventCreationContainerActions.SET_DRAFT:
      return {
        ...state,
        eventDraft: action.payload,
      }

    case EventCreationContainerActions.SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.payload,
      }

    case EventCreationContainerActions.SET_DRAFT_SECTIONS:
      return {
        ...state,
        eventDraft: {
          ...state.eventDraft,
          sections: action.payload,
        },
      }

    case EventCreationContainerActions.SET_DRAFT_PROPERTIES:
      return {
        ...state,
        eventDraft: {
          ...state.eventDraft,
          properties: action.payload,
        },
      }

    case EventCreationContainerActions.SET_DRAFT_INCLUDE_OPTIONS:
      return {
        ...state,
        eventDraft: {
          ...state.eventDraft,
          includeOptions: action.payload,
        },
      }

    case EventCreationContainerActions.SET_DRAFT_LOCATIONS:
      return {
        ...state,
        eventDraft: {
          ...state.eventDraft,
          locations: action.payload,
        },
      }

    case EventCreationContainerActions.SET_DRAFT_NEW_EVENTS:
      return {
        ...state,
        eventDraft: {
          ...state.eventDraft,
          events: action.payload,
        },
      }

    default:
      return state
  }
}
