import { trim } from 'lodash'
import Image, { ImageInput } from './Image'

export type HostInfoPublicInput = {
  id?: number
  display_name: string
  rating?: number
  languages?: string
  description?: string
  guiding_since?: string
  images: ImageInput[]
  image: ImageInput | null
}

class HostInfoPublic {
  id: number
  displayName: string
  rating: number
  languages: string[]
  description: string
  guidingSince: string
  images: Image[]
  image: Image | null

  constructor(hostInfo: HostInfoPublicInput) {
    this.id = hostInfo?.id || 0
    this.displayName = hostInfo?.display_name
    this.rating = hostInfo?.rating || 0
    this.languages = hostInfo?.languages ? hostInfo?.languages.split(',').map((language: string) => trim(language)) : []
    this.description = hostInfo?.description || ''
    this.guidingSince = hostInfo?.guiding_since || ''
    this.images = Image.fromArray(hostInfo?.images) || []
    this.image = hostInfo.image ? new Image(hostInfo.image) : null
  }

  static fromArray(input: HostInfoPublicInput[] | null): HostInfoPublic[] {
    return input ? input.map((i) => new HostInfoPublic(i)) : []
  }
}

export default HostInfoPublic
