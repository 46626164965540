import React, { FC, useMemo } from 'react'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { Image } from 'components-new/common/Image/Image'
import { Button } from 'components-new/common/Button/Button'
import { Carousel } from 'components-new/common/Carousel/Carousel'
import classNames from 'classnames'
import { Sizes } from 'services/shared/models/Image'
import styles from './ImageGallery.module.scss'
import { ImageGalleryProps } from './ImageGallery.types'

export const ImageGallery: FC<ImageGalleryProps> = ({
  images,
  alt,
  className,
  layout = 'grid',
  maxImages = null,
  size = Sizes.Medium,
  onSeeMoreClick,
}) => {
  const { isMobile } = useBreakpoints()

  const validImages = maxImages ? images.slice(0, maxImages) : images
  const listGalleryStartFromOne =
    validImages.length % 6 === 0 || validImages.length % 6 === 1 || validImages.length % 6 === 4

  const classes = classNames({
    [className!]: !!className,
    [styles['image-gallery']]: !!styles['image-gallery'],
    [styles[`image-gallery--${layout}`]]: layout && !!styles[`image-gallery--${layout}`],
    [styles['image-gallery--mobile']]: styles['image-gallery--mobile'] && isMobile,
    [styles['image-gallery--from-one']]: styles['image-gallery--from-one'] && listGalleryStartFromOne,
    [styles[`image-gallery--${maxImages ? Math.min(images.length, maxImages) : maxImages}`]]: maxImages,
  })

  const moreImages = maxImages ? images.length - maxImages : false
  const hasMoreImages = maxImages ? moreImages > 0 : false
  const ratio = layout === 'list' ? 3 / 4 : isMobile ? 10 / 16 : 'full'

  return useMemo(
    () => (
      <>
        <div className={classes}>
          {layout === 'grid' && isMobile ? (
            <Carousel>
              {images.map((image) => (
                <Image
                  src={image.getSmallSize()}
                  key={image.getSmallSize()}
                  ratio={ratio}
                  alt={alt}
                  width={image.width}
                  height={image.height}
                />
              ))}
            </Carousel>
          ) : (
            <>
              {validImages.map((image) => (
                <div key={image.sizes[size]}>
                  <Image
                    ratio={ratio}
                    src={image.sizes[size]}
                    onClick={onSeeMoreClick}
                    alt={alt}
                    width={image.width}
                    height={image.height}
                  />
                </div>
              ))}
              {hasMoreImages && (
                <Button onClick={onSeeMoreClick} className={styles['image-gallery__overlay']}>
                  Show {moreImages} more image{moreImages > 1 ? 's' : ''}
                </Button>
              )}
            </>
          )}
        </div>
      </>
    ),
    [isMobile, images]
  )
}
