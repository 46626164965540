import React from 'react'
import { ReviewCard } from 'components-new/common/ReviewCard/ReviewCard'
// @ts-ignore
import sampleImage1 from 'assets/img/review-sample-photo-1.jpg'
import sampleImage2 from 'assets/img/review-sample-photo-2.jpg'
import sampleImage3 from 'assets/img/review-sample-photo-3.jpg'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import styles from './Reviews.module.scss'
import { IReviews } from './Reviews.types'

const tmpData = [
  {
    image: sampleImage1,
    name: 'Chris R.',
    stars: 5,
    description:
      // eslint-disable-next-line max-len
      'Outguided was super easy to use and the captain we booked was top-notch. I didn’t have to play phone tag – booking took just a couple of minutes. Highly recommend.',
  },
  {
    image: sampleImage2,
    name: 'Captain Lionel',
    stars: 4,
    description:
      // eslint-disable-next-line max-len
      'With Outguided,  I get bookings sent for my approval in my sleep. I can spend more time doing what I love and less time managing my back office.',
  },
  {
    image: sampleImage3,
    name: 'Abby S.',
    stars: 5,
    description:
      // eslint-disable-next-line max-len
      'I love how I can search by different filters. Outguided makes it super easy to plan and book the next adventure.',
  },
]

export const Reviews: React.FC<IReviews> = () => {
  return (
    <Wrapper containerClasses={styles.reviews}>
      <h2 className={styles.reviews__header}>What people are saying</h2>
      <div className={styles.reviews__cards}>
        {tmpData.map((item) => (
          <ReviewCard item={item} className={styles['reviews__single-review']} key={item.name} />
        ))}
      </div>
    </Wrapper>
  )
}
