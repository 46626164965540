import { RootState } from 'store'
import Image from 'services/shared/models/Image'
import { Image as ImageComponent } from 'components-new/common/Image/Image'
import { Icon } from 'components/Icon/Icon'
import { Loader } from 'components-new/common/Loader/Loader'
import { removeFile, StateUploadItem } from 'features/upload/UploadSlice'
import { UploadImage } from 'services-new/upload/upload-api'
import classNames from 'classnames'
import Reorder, { reorder } from 'react-reorder'
import uniqBy from 'lodash/uniqBy'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect, forwardRef, useMemo } from 'react'
import styles from './PhotoUpload.module.scss'
import { PhotoUploadProps } from './PhotoUpload.types'

export const PhotoUpload = forwardRef<HTMLInputElement, PhotoUploadProps>((props, ref) => {
  const {
    onChange,
    value,
    error: validationError,
    max,
    onUpload: onUploadProp,
    onUploadFinish,
    id: uploaderId = 'images',
  } = props
  const [uploadedFiles, setUploadedFiles] = useState<StateUploadItem[]>([])

  const { files } = useSelector((s: RootState) => s.upload)

  const uploaderFiles = useMemo(() => {
    return files.filter((f) => f.uploaderId === uploaderId)
  }, [files, uploaderId])

  const imagesBeingUploaded = useMemo(() => uploaderFiles.filter((file) => !file.result), [uploaderFiles])
  const imagesAlreadyUploaded = useMemo(() => uploaderFiles.filter((file) => file.result), [uploaderFiles])

  useEffect(() => {
    setUploadedFiles(imagesAlreadyUploaded)
    imagesAlreadyUploaded.map((i) => dispatch(removeFile(i.result!)))
  }, [imagesAlreadyUploaded])

  const dispatch = useDispatch()

  const [error, setError] = useState<string>()

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDropAccepted: (filesToUpload) => {
      const uploadedFiles = value.length + imagesBeingUploaded.length
      filesToUpload
        .slice(0, max ? max - uploadedFiles : undefined)
        .forEach((file) => dispatch(UploadImage({ file, uploaderId })))
    },
    onDropRejected() {
      setError('You can upload only JPGs and PNGs files.')
    },
  })

  const uploadError = validationError || error
  const isValidToUpload = useMemo(() => !max || (max && value.length < max), [value])

  const onDelete = (deletedImage: Image) => {
    dispatch(removeFile(deletedImage))
    onChange?.(value.filter((image) => image !== deletedImage))
  }

  const onReorder = (event: any, previousIndex: number, nextIndex: number) => {
    onChange?.(reorder(value, previousIndex, nextIndex))
  }

  useEffect(() => {
    const allAlimages = value.concat(uploadedFiles.map((f) => f.result!))
    onChange?.(uniqBy(allAlimages, (e) => e.id))
  }, [uploadedFiles])

  useEffect(() => {
    if (imagesBeingUploaded.length) {
      onUploadProp?.()
    } else {
      onUploadFinish?.()
    }
  }, [imagesBeingUploaded])

  const dropZoneClasses = classNames({
    [styles['photos-upload__dropdown-area']]: !!styles['photos-upload__dropdown-area'],
    [styles['photos-upload__dropdown-area--active']]: isDragActive,
    [styles['photos-upload__dropdown-area--accept']]: isDragAccept,
    [styles['photos-upload__dropdown-area--reject']]: isDragReject,
    [styles['photos-upload__dropdown-area--error']]: !!uploadError,
  })

  return (
    <div className={styles['photos-upload']}>
      {typeof uploadError === 'string' && <p className={styles['photos-upload__error']}>{uploadError}</p>}
      <input {...getInputProps()} />
      <input type="text" ref={ref} onFocus={(e) => e.target.blur()} className={styles['photos-upload__ref-input']} />
      {isValidToUpload && (
        <>
          <div {...getRootProps({ className: dropZoneClasses })}>
            {imagesBeingUploaded.length ? (
              <Loader />
            ) : (
              <p>
                Drag & drop here or<span>browse</span>
              </p>
            )}
          </div>
        </>
      )}
      {value.length > 0 && (
        <Reorder
          reorderId={uploaderId}
          component="div"
          lock="vertical"
          onReorder={onReorder}
          holdTime={20}
          touchHoldTime={700}
          autoScroll={true}
          className={styles['photos-upload__images-area']}
        >
          {value.map((image, index) => (
            <div key={image.id}>
              <ImageComponent
                className={styles['photos-upload__image']}
                src={image.getSmallSize()}
                ratio={3 / 4}
                prepend={index === 0 && <div className={styles['photos-upload__image-overlay']}>Main image</div>}
                append={
                  <Icon
                    icon="close"
                    onClick={() => onDelete(image)}
                    containerClassName={classNames(
                      styles['photos-upload__image-overlay'],
                      styles['photos-upload__image-overlay--delete']
                    )}
                  />
                }
              />
            </div>
          ))}
        </Reorder>
      )}
    </div>
  )
})
