import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import React, { useMemo } from 'react'
import { SectionWrapper } from 'containers-new/SectionWrapper/SectionWrapper'
import styles from './DetailsSection.module.scss'
import { IDetailsSection } from './DetailsSection.types'

const flattenedPropertyNames = ['Additional']
const excludeProperties = ["What's included"]

const formatPropertyName = (name: string) => name.replace(' (if applicable)', '')

export const DetailsSection: React.FC<IDetailsSection> = ({ properties = [], activityName, title }) => {
  const { activities, generalActivity } = useSettingsContext()

  const results = useMemo(() => {
    return [activities.find((a) => a.name === activityName), generalActivity]
  }, [activities, generalActivity])

  const allProperties = useMemo(() => {
    return results
      ? results.reduce((acc: any, { properties: props }: any) => [...acc, ...(props ? props : [])], [])
      : []
  }, [results])

  const multiselectProperties = useMemo(() => {
    const multiselectProperties = properties.filter(
      ({ name }) => !excludeProperties.includes(name) && !flattenedPropertyNames.includes(name)
    )

    return multiselectProperties
      .map(({ name, value }) => {
        return { name, value: Array.isArray(value) ? value : [value] }
      })
      .filter((prop) => prop.value.length > 0)
  }, [properties])

  const flattenedProperties = useMemo(() => {
    const flattenedProperties = properties.filter(
      ({ name }) => !excludeProperties.includes(name) && flattenedPropertyNames.includes(name)
    )

    return flattenedProperties.filter(({ name }) => allProperties.some((allProp: any) => allProp.name === name))
  }, [properties, allProperties])

  if (multiselectProperties.length === 0 && flattenedProperties.length === 0) {
    return null
  }

  return (
    <SectionWrapper title={title}>
      <div className={styles['details-section']}>
        {multiselectProperties.map(
          ({ name, value }) =>
            value.length > 0 && (
              <div className={styles['details-section__item']} key={name}>
                <div className={styles['details-section__title']}>{formatPropertyName(name)}:</div>
                <div className={styles['details-section__value']}>
                  {value.map((el) => (
                    <span key={`${name}-${el}`}>{el}</span>
                  ))}
                </div>
              </div>
            )
        )}
        {flattenedProperties.map(({ name: flattenedPropertyName, value: flattenedPropertyValue }) => {
          const templateProperty = allProperties.find((allProp: any) => allProp.name === flattenedPropertyName)
          const allBooleanFields = templateProperty.values
          return allBooleanFields.map(({ value: possibleValue }: any) => (
            <div className={styles['details-section__item']} key={possibleValue}>
              <span className={styles['details-section__title']}>{possibleValue}:</span>
              <div className={styles['details-section__value']}>
                <span>{String(flattenedPropertyValue.includes(possibleValue)) === 'true' ? 'Yes' : 'No'}</span>
              </div>
            </div>
          ))
        })}
      </div>
    </SectionWrapper>
  )
}
