import {
  ADVENTURER_PAGE,
  LOGIN_PAGE,
  accountLink,
  becomeGuideLink,
  blogLink,
  experienceEditLink,
  guideLink,
  loginLink,
  pageLink,
  registerLink,
} from 'global/routes'
import { AccountPages, UserAccountPages } from 'pages-new/AccountPage/AccountPage'
import React, { useEffect } from 'react'

import Close from '@material-ui/icons/Close'
import { Drawer } from '@material-ui/core'
import { HomeButton } from 'components-new/common/HomeButton/HomeButton'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { useBreakpoints } from 'hooks/useBreakpoints'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import { useUser } from 'hooks/useUser'
import styles from './SideMenu.module.scss'
import { IDrawer } from './SideMenu.types'

export const SideMenu: React.FC<IDrawer> = ({ drawerOpen, onClose }) => {
  const { user, isHost, isLoggedIn, accessToken, handleLogout } = useUser()
  const { supportEmail, supportPhone, supportPhoneClean } = useSettingsContext()

  const handleDrawerToggle = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleLogIn = () => {
    handleDrawerToggle()
  }

  useEffect(() => {
    if (accessToken) {
      handleLogIn()
    }
  }, [accessToken])

  const handleLogOut = () => {
    toast('You have successfully logged out.')
    handleLogout()
    handleDrawerToggle()
  }

  const linkClasses = classNames(styles['side-menu__link'], styles['side-menu__link--primary'])
  const footerLinkClass = classNames(styles['side-menu__link'], styles['side-menu__link--secondary'])

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
      <div className={styles['side-menu__content']}>
        <div className={styles['side-menu__trigger']}>
          <HomeButton className={styles['side-menu__logo']} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={styles['side-menu__close']}
          >
            <Close />
          </IconButton>
        </div>
        <div className={styles['side-menu__menu']}>
          {!isLoggedIn && (
            <>
              <Link to={loginLink(accountLink('details'))} className={linkClasses}>
                Sign in
              </Link>
              <Link to={registerLink(accountLink('details'))} className={linkClasses}>
                Register
              </Link>
              <Link to="/forguides" className={linkClasses}>
                Become a Guide
              </Link>
              <hr className={styles['side-menu__separator']} />
              <Link to="/" className={linkClasses}>
                Home
              </Link>
              <Link to="/search" className={linkClasses}>
                Find a trip
              </Link>
            </>
          )}
          {isLoggedIn && !isHost && (
            <>
              <Link to="/search" className={linkClasses}>
                Find a trip
              </Link>
              <Link to={becomeGuideLink()} className={linkClasses}>
                Become a Guide
              </Link>
              <hr className={styles['side-menu__separator']} />
              {user &&
                (Object.keys(UserAccountPages) as Array<keyof typeof AccountPages>).map((slug) => (
                  <Link to={accountLink(slug)} key={slug} className={linkClasses} onClick={handleDrawerToggle}>
                    {AccountPages[slug]}
                  </Link>
                ))}
              <Link
                to="/"
                onClick={() => {
                  handleLogOut()
                  handleDrawerToggle()
                }}
                className={linkClasses}
              >
                Logout
              </Link>
            </>
          )}
          {isLoggedIn && isHost && (
            <>
              <Link to={accountLink('calendar')} className={linkClasses} onClick={() => onClose?.()}>
                Guide Dashboard
              </Link>
              <hr className={styles['side-menu__separator']} />
              {user &&
                (Object.keys(AccountPages) as Array<keyof typeof AccountPages>).map((slug) => (
                  <Link to={accountLink(slug)} key={slug} className={linkClasses} onClick={handleDrawerToggle}>
                    {AccountPages[slug]}
                  </Link>
                ))}
              {user && user.slug && (
                <Link to={guideLink(user.slug)} className={linkClasses} onClick={handleDrawerToggle}>
                  Your Public Profile
                </Link>
              )}
              <Link
                to="/"
                onClick={() => {
                  handleLogOut()
                  handleDrawerToggle()
                }}
                className={linkClasses}
              >
                Logout
              </Link>
            </>
          )}
        </div>
        <div className={styles['side-menu__support']}>
          <span className={classNames(styles['side-menu__text'])}>Support</span>
          <a
            href={`tel:${supportPhoneClean}`}
            className={classNames(
              styles['side-menu__link'],
              styles['side-menu__link--secondary'],
              styles['side-menu__link--underline']
            )}
          >
            {supportPhone}
          </a>
          <a
            href={`mailto:${supportEmail}`}
            className={classNames(
              styles['side-menu__link'],
              styles['side-menu__link--secondary'],
              styles['side-menu__link--underline']
            )}
          >
            {supportEmail}
          </a>
        </div>
        <div className={styles['side-menu__privacy']}>
          <Link to={ADVENTURER_PAGE} className={footerLinkClass}>
            For Adventurers
          </Link>
          <Link to="/about" className={footerLinkClass}>
            About Us
          </Link>
          <Link to={pageLink('terms-and-conditions')} className={footerLinkClass} onClick={handleDrawerToggle}>
            Terms & conditions
          </Link>
          <Link to={pageLink('privacy-policy')} className={footerLinkClass} onClick={handleDrawerToggle}>
            Privacy policy
          </Link>
          <Link to={blogLink()} className={footerLinkClass}>
            Blog
          </Link>
        </div>
        <span className={styles['side-menu__copyright']}>
          &copy; <span className={styles['side-menu__year']}>{new Date().getFullYear()}</span>
        </span>
      </div>
    </Drawer>
  )
}
