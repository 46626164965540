import Conversation, { ConversationInput, ConversationOutput } from 'services-new/conversation/conversation.model'
import { createAxiosAsyncThunk, deleteRequest$, getRequest$, postRequest$, putRequest$ } from 'utils/api'
import { MessageInput, MessageOutput } from './message.model'

// EXPERIENCES
export const GetConversations = createAxiosAsyncThunk<ConversationInput[], void>(
  'conversations/getConversations',
  async () => await getRequest$(`conversations`)
)

// EXPERIENCE
export const GetConversation = createAxiosAsyncThunk<
  { conversation: ConversationInput; messages: MessageInput[] },
  Conversation['id']
>('conversations/getConversation', async (id) => await getRequest$(`conversations/${id}`))

export const RemoveConversation = createAxiosAsyncThunk<boolean, Conversation['id']>(
  'conversations/removeConversation',
  async (id) => await deleteRequest$(`conversations/${id}`)
)

export const CreateConversation = createAxiosAsyncThunk<
  { conversation: ConversationInput; message: MessageInput | null },
  { conversation: ConversationOutput; message: MessageOutput }
>(
  'conversations/createConversation',
  async (data) =>
    await postRequest$(`conversations`, { marketing_page: data.conversation.marketing_page, body: data.message.body })
)

export const SendMessage = createAxiosAsyncThunk<
  { conversation: ConversationInput; message: MessageInput },
  { id: Conversation['id'] } & MessageOutput
>('conversations/updateConversation', async ({ id, ...data }) => await putRequest$(`conversations/${id}`, data))

export const LoadMoreMessages = createAxiosAsyncThunk<
  { conversation: ConversationInput; messages: MessageInput[] },
  { id: Conversation['id']; fromIndex: number }
>('conversations/loadMoreMessages', async ({ id, fromIndex }) => await getRequest$(`conversations/${id}/${fromIndex}`))
