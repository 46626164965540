import {
  CHECKOUT_PAGE,
  INIT_CHECKOUT_PAGE,
  CHECKOUT_WITHOUT_DATE_PAGE,
  BOOKING_CONFIRM_PAGE,
  EXPERIENCE_EDIT,
  EXPERIENCE_PAGE,
  LEGACY_EVENT_DETAILS_PAGE,
  HOME_PAGE,
  HOST_PAGE,
  HOST_PAGE_LEGACY,
  HOST_PAGE_LEGACY_2,
  HOST_PROFILE,
  RESET_PASSWORD_PAGE,
  SEARCH_PAGE,
  EXPLORE_PAGE,
  USER_PROFILE,
  DEFAULT_PAGE,
  GUIDE_PAGE,
  HOW_TO,
  GUIDE_POLICY,
  CUSTOMER_POLICY,
  ABOUT_PAGE,
  BLOG_PAGE,
  BLOG_POST_PAGE,
  ADVENTURER_PAGE,
  BROWSE_PAGE,
  ACCOUNT_PAGE,
  ACCOUNT_PAYMENTS_VERIFY,
  accountLink,
  LOGIN_PAGE,
  REGISTER_PAGE,
  FORGOT_PASSWORD_PAGE,
  BECOME_GUIDE_PAGE,
  AUTH_PAGE,
} from 'global/routes'

import { MainPage } from 'pages-new/MainPage/MainPage'
import AboutPage from 'pages-new/AboutPage/AboutPage'
import { NotFoundPage } from 'pages-new/NotFoundPage/NotFoundPage'
import { ExperiencePage } from 'pages-new/ExperiencePage/ExperiencePage'
import { HostPage } from 'pages-new/HostPage/HostPage'
import { BlogPage } from 'pages-new/BlogPage/BlogPage'
import { BlogPostPage } from 'pages-new/BlogPostPage/BlogPostPage'
import { BrowsePage } from 'pages-new/BrowsePage/BrowsePage'
import React, { useEffect } from 'react'
import { lazily } from 'react-lazily'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import { AccountPaymentsVerify } from 'pages-new/AccountPage/AccountPaymentsVerify'
import { LoginPage } from 'pages-new/LoginPage/LoginPage'
import { RegisterPage } from 'pages-new/RegisterPage/RegisterPage'
import { useUser } from 'hooks/useUser'
import { Mixpanel } from 'utils/mixpanel'
import { HowTo } from '../../pages-new/HowTo/HowTo'
import { GuidePolicy } from '../../pages-new/GuidePolicy/GuidePolicy'
import { CustomerPolicy } from '../../pages-new/CustomerPolicy/CustomerPolicy'
import { AuthRoute } from './components/AuthRoute'

const { DefaultPage } = lazily(() => import('pages-new/DefaultPage/DefaultPage'))
const { ForGuides } = lazily(() => import('pages-new/ForGuides/ForGuides'))
const { ForAdventurers } = lazily(() => import('pages-new/ForAdventurers/ForAdventurers'))
const { EventDetailsPage } = lazily(() => import('pages-new/EventDetailsPage/EventDetailsPage'))
const { CheckoutPage } = lazily(() => import('pages-new/CheckoutPage/CheckoutPage'))
const { AccountPage } = lazily(() => import('pages-new/AccountPage/AccountPage'))
const { AuthPage } = lazily(() => import('pages-new/AuthPage/AuthPage'))
const { BookingPage } = lazily(() => import('pages-new/BookingPage/BookingPage'))
const { ExperienceEditPage } = lazily(() => import('pages-new/ExperienceEditPage/ExperienceEditPage'))
const { HostPageLegacy } = lazily(() => import('pages-new/HostPageLegacy/HostPageLegacy'))
const SearchPage = React.lazy(() => import('pages-new/SearchPage/SearchPage'))
const ExplorePage = React.lazy(() => import('pages-new/ExplorePage/ExplorePage'))
const ResetPasswordPage = React.lazy(() => import('pages-new/ResetPasswordPage/ResetPasswordPage'))
const ForgotPasswordPage = React.lazy(() => import('pages-new/ForgotPasswordPage/ForgotPasswordPage'))
const BecomeGuidePage = React.lazy(() => import('pages-new/BecomeGuidePage/BecomeGuidePage'))

export const SwitchRoutes = () => {
  const location = useLocation()
  const { user } = useUser()

  const { boot, update, hide } = useIntercom()
  useEffect(() => {
    // Mixpanel.track('Page Loaded', { 'Page Path': location.pathname })
    if (location.pathname === '/') {
      boot()
      update({ hideDefaultLauncher: false })
    } else {
      hide()
      update({ hideDefaultLauncher: true })
    }
  }, [location])

  return (
    <Switch>
      <Route exact path={HOME_PAGE} component={MainPage} />
      <Route exact path={SEARCH_PAGE} component={SearchPage} />

      {/* UTILITY */}
      <Route exact path={RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
      <Route exact path={LOGIN_PAGE} component={LoginPage} />
      <Route exact path={AUTH_PAGE} component={AuthPage} />
      <Route exact path={REGISTER_PAGE} component={RegisterPage} />
      <Route exact path={FORGOT_PASSWORD_PAGE} component={ForgotPasswordPage} />
      <Route exact path={BECOME_GUIDE_PAGE} component={BecomeGuidePage} />

      <Route exact path={HOST_PAGE} component={HostPage} />
      <Route exact path={HOST_PAGE_LEGACY} component={HostPageLegacy} />
      <Redirect from={HOST_PROFILE} to={accountLink('details')} />
      <Route exact path={HOST_PAGE_LEGACY_2} component={HostPageLegacy} />

      <AuthRoute exact user={user} path={EXPERIENCE_EDIT} component={ExperienceEditPage} />
      <AuthRoute exact user={user} path={ACCOUNT_PAYMENTS_VERIFY} component={AccountPaymentsVerify} />
      <AuthRoute exact user={user} path={ACCOUNT_PAGE} component={AccountPage} />
      <Redirect from={USER_PROFILE} to={accountLink('details')} />

      {/* CHECKOUT PROCESS */}
      <AuthRoute exact user={user} path={BOOKING_CONFIRM_PAGE} component={BookingPage} />
      <Route exact path={CHECKOUT_WITHOUT_DATE_PAGE} component={CheckoutPage} />
      <Route exact path={INIT_CHECKOUT_PAGE} component={CheckoutPage} />
      <Route exact path={CHECKOUT_PAGE} component={CheckoutPage} />

      {/* DYNAMIC CMS PAGES */}
      <Route exact path={EXPERIENCE_PAGE} component={ExperiencePage} />
      <Route exact path={LEGACY_EVENT_DETAILS_PAGE} component={EventDetailsPage} />
      <Route exact path={EXPLORE_PAGE} component={ExplorePage} />
      <Route path={BROWSE_PAGE} component={BrowsePage} />
      <Route exact path={BLOG_PAGE} component={BlogPage} />
      <Route exact path={BLOG_POST_PAGE} component={BlogPostPage} />
      <Route exact path={DEFAULT_PAGE} component={DefaultPage} />

      {/* STATIC PAGES */}
      <Route exact path={ABOUT_PAGE} component={AboutPage} />
      <Route path={GUIDE_PAGE} exact component={ForGuides} />
      <Route path={HOW_TO} exact component={HowTo} />
      <Route path={GUIDE_POLICY} exact component={GuidePolicy} />
      <Route path={CUSTOMER_POLICY} exact component={CustomerPolicy} />
      <Route path={ADVENTURER_PAGE} exact component={ForAdventurers} />

      {/* LEGACY MESSAGES PAGES - MOVED TO ACCOUNT ROUTE */}
      <Redirect from="/messages/:id/:details?" to={`${accountLink('messages')}/:id/:details?`} />
      <Redirect from="/messages/:id?" to={`${accountLink('messages')}/:id?`} />
      <Redirect from="/messages" to={`${accountLink('messages')}`} />

      <Route component={NotFoundPage} />
    </Switch>
  )
}
