import HostInfoPublic, { HostInfoPublicInput } from 'services/shared/models/HostInfoPublic'
import MarketingDetails, { MarketingDetailsInput } from 'services/marketing-page/models/MarketingDetails'
import Image, { ImageInput } from 'services/shared/models/Image'
import Location, { LocationInput } from './Location'

type RoleInput = {
  id: number
  name: string
  type: string
}

export interface UserPublicInput {
  id: number
  slug: string
  first_name: string
  last_name: string
  email: string
  host_info: HostInfoPublicInput | null
  image: ImageInput
  role: RoleInput | number
  marketing_pages: MarketingDetailsInput[]
  sections: any
  background_image: ImageInput
  location: LocationInput
}

class UserPublic {
  id: number
  slug: string
  firstName: string
  lastName: string
  email: string
  hostInfo: HostInfoPublic | null
  image: Image | null
  imageUrl: string | null
  name: string
  label: string
  role: number | null
  marketingPages: MarketingDetails[]
  sections: any
  background_image: Image
  displayName: string
  isHost: boolean
  initials: string
  // TODO:
  location: Location | null

  // displayName: string

  constructor(user: UserPublicInput) {
    this.id = user.id || 0
    this.slug = user.slug
    this.firstName = user.first_name || ''
    this.lastName = user.last_name || ''
    this.email = user.email

    this.role = user.role ? (typeof user.role === 'number' ? user.role : user.role.id) : null
    this.isHost = process.env.REACT_APP_HOST_ROLE_ID
      ? parseInt(process.env.REACT_APP_HOST_ROLE_ID) === this.role
      : false

    this.image = user.image && new Image(user.image)
    this.imageUrl = this.image && this.image.url

    this.hostInfo = this.isHost && user.host_info ? new HostInfoPublic(user.host_info) : null

    // temporary solution until
    // we will have backend migration ready
    // TODO: check if backend is migrated and remove me
    if (this.hostInfo && !this.hostInfo.image && this.image) {
      this.hostInfo.image = this.image
    }

    this.name = this.hostInfo?.displayName || `${this.firstName}`

    this.sections = user.sections

    this.background_image = user.background_image && new Image(user.background_image)

    this.label = `${this.name}${
      this.name && (this.name[this.name.length - 1].toLowerCase() === 's' ? "'" : "'s")
    } Experiences`

    this.marketingPages = MarketingDetails.fromArray(user.marketing_pages)

    this.location = user.location && new Location(user.location)

    const suffix = this.lastName ? ` ${this.lastName.charAt(0).toUpperCase()}.` : ''
    this.displayName = this.hostInfo?.displayName ? this.hostInfo?.displayName : `${this.firstName}${suffix}`

    this.initials = this.displayName
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('')
  }

  static fromArray(input: UserPublicInput[] | null) {
    return input ? input.map((i) => new UserPublic(i)) : []
  }
}

export default UserPublic
