import { useState } from 'react'
import Settings, { SettingsInput } from 'services/settings/models/Settings'
import { getSettings$ } from 'services/settings/settings-api'
import { useRequest } from './useRequest'

export const useSettings = () => {
  const [result, setResult] = useState<Settings | null>(null)

  const {
    isLoading,
    responseError,
    fetch: fetchRequest,
  } = useRequest<undefined, SettingsInput>(getSettings$, (response) => {
    setResult(new Settings(response))
  })

  const fetch = (): void => {
    fetchRequest()
  }

  return {
    isLoading,
    responseError,
    result,
    fetch,
  }
}
