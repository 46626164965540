import thunk from 'redux-thunk'
import { rootReducer } from 'store/reducer'
import { configureStore } from '@reduxjs/toolkit'
import { UserMiddleware } from 'features/user/UserMiddleware'

export const store = configureStore({
  reducer: rootReducer,
  middleware: [UserMiddleware.middleware, thunk],
  devTools: true,
})

// export const store = createStore(rootReducer, )

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
