export function expirationDateFormatter(value: string) {
  return value
    .replace(
      /[^0-9]/g,
      '' // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      '0$1' // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      '0$1/$2' // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      '0' // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      '$1/$2' // To handle 113 > 11/3
    )
}

export function creditCardFormatter(value: string) {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  const matches = v.match(/\d{4,16}/g)
  const match = (matches && matches[0]) || ''
  const parts = []
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4))
  }
  if (parts.length) {
    return parts.join(' ')
  } else {
    return value
  }
}

export function ccvFormatter(value: string) {
  return value.replace(
    /(^[0-9]{4}).*/g,
    '$1' // To allow only 4 numbers
  )
}

export interface ZipCodeOptions {
  Canada: RegExp
  India: RegExp
  Mexico: RegExp
  Poland: RegExp
  'United Kingdom': RegExp
  'United States of America': RegExp
  Other: RegExp
}

export const zipCodeOptions: ZipCodeOptions = {
  Canada: /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY]-\d[ABCEGHJKLMNPRSTVXY]\d)$/,
  India: /^([1-9]\d{5})$/,
  Mexico: /^(\d{5})$/,
  Poland: /^(\d{2}-\d{3})$/,
  'United Kingdom': /^([A-Za-z][A-Ha-hJ-Yj-y]?\d[A-Za-z0-9]? ?\d[A-Za-z]{2})$/,
  'United States of America': /^(\d{5})|(^\d{5}-\d{4})$/,
  Other: /^(\d{5})$/,
}
