import Post from 'services-new/post/post.model'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import { BlogCard } from 'components-new/common/BlogCard/BlogCard'
import React from 'react'
import { Wrapper } from '../Wrapper/Wrapper'
import styles from './BlogContainer.module.scss'
import { BlogCardFeatured } from './components/BlogCardFeatured/BlogCardFeatured'

interface IBlogContainer {
  posts: Post[]
}

// const tmpFilterItems = [{ name: 'Hunting' }, { name: 'Fishing' }, { name: 'Scuba Diving' }, { name: 'Tips' }]

export const BlogContainer: React.FC<IBlogContainer> = ({ posts }) => {
  // const [activeItems, setActiveItems] = useState<number[]>([-1])
  const { featuredPost } = useSettingsContext()

  return (
    <Wrapper paddingType="small" className={styles['blog-container']}>
      <h1 className={styles['blog-container__header']}>Our Blog</h1>
      {featuredPost && (
        <div className={styles['blog-container__featured-cards-wrapper']}>
          <BlogCardFeatured
            slug={featuredPost.slug}
            publishedAt={featuredPost.publishedAt}
            image={featuredPost.image}
            title={featuredPost.title}
            excerpt={featuredPost.excerpt}
            className={styles['blog-container__featured-card']}
          />
        </div>
      )}
      {/* <Filters*/}
      {/*  items={tmpFilterItems}*/}
      {/*  name="filters1"*/}
      {/*  value={activeItems}*/}
      {/*  onChange={({ value }) => {*/}
      {/*    setActiveItems(value)*/}
      {/*  }}*/}
      {/* />*/}

      <div className={styles['blog-container__cards-wrapper']}>
        {posts.map((item) => (
          <BlogCard
            slug={item.slug}
            className={styles['blog-container__single-card']}
            image={item.image}
            title={item.title}
            excerpt={item.excerpt}
            key={item.slug}
          />
        ))}
      </div>
    </Wrapper>
  )
}
