import React, { useState } from 'react'
import { Icon } from 'components/Icon/Icon'
import Image from 'services/shared/models/Image'
import { Image as ImageComponent } from 'components-new/common/Image/Image'
import styles from './AboutHost.module.scss'

interface IAboutHost {
  items: Array<{
    title: string
    description: string
    image: Image
  }>
}

export const AboutHost: React.FC<IAboutHost> = ({ items }) => {
  const [currentItem, setCurrentItem] = useState(0)

  function switchPreviousSlide() {
    if (currentItem === 0) {
      setCurrentItem(items.length - 1)
    } else {
      setCurrentItem(currentItem - 1)
    }
  }

  function switchNextSlide() {
    if (currentItem === items.length - 1) {
      setCurrentItem(0)
    } else {
      setCurrentItem(currentItem + 1)
    }
  }

  return (
    <div className={styles['about-host']}>
      <div className={styles['about-host__carousel']}>
        <div
          className={styles['about-host__carousel-inner']}
          style={{ transform: `translateX(-${currentItem * 100}%)` }}
        >
          {items.map((item) => (
            <ImageComponent
              src={item.image.url}
              alt={item.image.alternativeText}
              className={styles['about-host__image']}
              wrapperClassName={styles['about-host__image-wrapper']}
              theme="rect"
              key={item.title}
            />
          ))}
        </div>
      </div>
      <div className={styles['about-host__main']}>
        <div className={styles['about-host__item']}>
          <h2 className={styles['about-host__item-title']}>{items[currentItem].title}</h2>
          <p className={styles['about-host__item-description']}>{items[currentItem].description}</p>
        </div>
        <div className={styles['about-host__controls']}>
          <div className={styles['about-host__numbers']}>
            {currentItem + 1} / {items.length}
          </div>
          <div className={styles['about-host__buttons']}>
            <button className={styles['about-host__button']} onClick={switchPreviousSlide}>
              <Icon icon="chevron_right_dark" className={styles['about-host__button-icon']} />
            </button>
            <button className={styles['about-host__button']} onClick={switchNextSlide}>
              <Icon icon="chevron_right_dark" className={styles['about-host__button-icon']} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
