import { AccountPages } from 'pages-new/AccountPage/AccountPage'
import { parse, ParsedQuery, stringify } from 'query-string'

// CONTENT PAGES
export const HOME_PAGE = '/'
export const SEARCH_PAGE = '/search/:location?'
export const EXPLORE_PAGE = '/explore/:slug'
export const ABOUT_PAGE = '/about'
export const GUIDE_PAGE = '/forguides'
export const ADVENTURER_PAGE = '/foradventurers'
export const HOW_TO = '/howto'
export const GUIDE_POLICY = '/guide-policy'
export const CUSTOMER_POLICY = '/customer-policy'
export const ALL_EXPERIENCES_PAGE = '/experiences'
export const EXPERIENCE_PAGE = '/experiences/:slugOrId'
export const HOST_PAGE = '/guides/:slugOrId'
export const HOST_PAGE_LEGACY_2 = '/guide/:slug'
export const HOST_PAGE_LEGACY = '/guide/page/:hostId'
export const DEFAULT_PAGE = '/page/:slug'
export const BLOG_PAGE = '/blog'
export const BLOG_POST_PAGE = '/blog/:slug'
export const BROWSE_PAGE = '/browse/:slug?'

// UTILITY
export const RESET_PASSWORD_PAGE = '/reset-password'
export const FORGOT_PASSWORD_PAGE = '/forgot-password'
export const LOGIN_PAGE = '/login'
export const AUTH_PAGE = '/auth/:type/callback'
export const REGISTER_PAGE = '/register'
export const BECOME_GUIDE_PAGE = '/become-guide'

// LEGACY
export const LEGACY_EVENT_DETAILS_PAGE = '/event/:marketingPageId'

// CHECKOUT
export const CHECKOUT_PAGE = '/checkout/:marketingPageId/:date/:time?/:step?'
export const INIT_CHECKOUT_PAGE = '/checkout/:marketingPageId/dates'
export const CHECKOUT_WITHOUT_DATE_PAGE = '/checkout/:marketingPageId'
export const BOOKING_CONFIRM_PAGE = '/checkout/:bookingId/confirm'

// ACCOUNT
export const HOST_PROFILE = '/guide/home'
export const USER_PROFILE = '/user/:id/home'

export const EXPERIENCE_EDIT = '/account/trips/:id/:step?'
export const ACCOUNT_PAGE = '/account/:page?/:id?/:details?'
export const ACCOUNT_PAYMENTS_VERIFY = '/account/payments/verify'

// TODO: maybe move it all to hook?
// const { accountLink ) = useLinks()?

export const accountLink = (page?: keyof typeof AccountPages, id?: string | number, details?: string) =>
  ACCOUNT_PAGE.replace('/:page?', page ? '/' + page : '')
    .replace('/:id?', id ? `/${id}` : '')
    .replace('/:details?', details ? `/details` : '')

export const paymentsVerify = () => ACCOUNT_PAYMENTS_VERIFY
export const messageLink = (id: string = '', details?: string) => accountLink('messages', id, details)
export const messagesLink = () => accountLink('messages')

export const guideLink = (slugOrId: string | number) => HOST_PAGE.replace(':slugOrId', '' + slugOrId)
export const pageLink = (slug: string) => DEFAULT_PAGE.replace(':slug', slug)
export const blogLink = () => BLOG_PAGE
export const hostProfileLink = () => HOST_PROFILE
export const userProfileLink = (id: number) => USER_PROFILE.replace(':id', id + '')
export const postLink = (slug: string) => BLOG_POST_PAGE.replace(':slug', slug)

export const guideHomeLink = () => HOST_PROFILE
export const experienceLink = (slugOrId?: string | number) =>
  EXPERIENCE_PAGE.replace('/:slugOrId', slugOrId ? '/' + slugOrId : '')
export const experienceEditLink = (id: number | string, step: string = '') =>
  EXPERIENCE_EDIT.replace(':id', '' + id).replace(':step?', step)

export const checkoutLink = (marketingPageId: number, date?: string | null, time?: number | null, step?: string) => {
  return date
    ? CHECKOUT_PAGE.replace(':marketingPageId', `${marketingPageId}`)
        .replace('/:date', `/${date}`)
        .replace('/:time?', Number.isInteger(time) ? `/${time}` : '')
        .replace('/:step?', step ? `/${step}` : '')
    : INIT_CHECKOUT_PAGE.replace(':marketingPageId', `${marketingPageId}`)
}

export const initCheckoutLink = (marketingPageId: number) => {
  return INIT_CHECKOUT_PAGE.replace(':marketingPageId', `${marketingPageId}`)
}

export const bookingSummaryLink = (bookingId: number) => {
  return BOOKING_CONFIRM_PAGE.replace(':bookingId', '' + bookingId)
}

export const browseLink = (slug?: string) => {
  return BROWSE_PAGE.replace('/:slug?', slug ? `/${slug}` : '')
}

export const searchLink = ({ query, location = '' }: { query?: string; location?: string } = {}) => {
  return query ? `${SEARCH_PAGE.replace(':location?', location)}?${query}` : SEARCH_PAGE.replace(':location?', location)
}

export const exploreLink = ({ query, slug = '' }: { query?: string; slug?: string } = {}) => {
  return query ? `${EXPLORE_PAGE.replace(':slug', slug)}?${query}` : EXPLORE_PAGE.replace(':slug', slug)
}

export const loginLink = (redirect?: string) => {
  const params = getUrlParams() as {
    redirect?: string
  }
  return `${LOGIN_PAGE}?redirect=${redirect || params.redirect || window.location.pathname}`
}

export const registerLink = (redirect?: string) => {
  const params = getUrlParams() as {
    redirect?: string
  }
  return `${REGISTER_PAGE}?redirect=${redirect || params.redirect || window.location.pathname}`
}

export const forgotPasswordLink = (redirect?: string) => {
  return `${FORGOT_PASSWORD_PAGE}${redirect ? '?redirect=' + redirect : ''}`
}

export const becomeGuideLink = () => {
  return `${BECOME_GUIDE_PAGE}`
}

export const customerPolicyLink = () => {
  return `${CUSTOMER_POLICY}`
}

export const getTripEmbedCode = (id: number) =>
  `<a href="${process.env.REACT_APP_EMBED_SITE_URL}${experienceLink(id)}" data-og-widget data-og-code>Embed Code</a>`

export const getGuideEmbedCode = (id: number) =>
  `<a href="${process.env.REACT_APP_EMBED_SITE_URL}${guideLink(id)}" data-og-widget data-og-code>Embed Code</a>`

export const getUrlParams = (search?: string) => {
  return parse(search || window.location.search, {
    parseNumbers: true,
    arrayFormat: 'bracket',
    arrayFormatSeparator: '|',
  }) as ParsedQuery<string | number>
}
export const stringifyUrlParams = (params: Record<string, any>) => {
  return stringify(params, {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: 'bracket',
    arrayFormatSeparator: '|',
  })
}
