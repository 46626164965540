// from node_modules
import React, { useEffect, FC } from 'react'
import { useHistory } from 'react-router'
import { accountLink, loginLink } from 'global/routes'
import { FormWrapper } from 'components-new/forms/FormWrapper/FormWrapper'
import { Button } from 'components-new/common/Button/Button'
import { Loader } from 'components-new/common/Loader/Loader'
import { useUser } from 'hooks/useUser'
import { RegisterFormValues } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs.types'
import { RegisterFormInputs } from 'components-new/forms/RegisterFormInputs/RegisterFormInputs'
import { UtilityPage } from 'pages-new/UtilityPage/UtilityPage'
import { FormWrapperProps } from 'components-new/forms/FormWrapper/FormWrapper.types'
import { RegisterUser } from 'services-new/auth/auth-api'
import { useDispatch } from 'react-redux'
import { isFulfilled } from '@reduxjs/toolkit'
import { SocialConnect } from 'components-new/common/SocialConnect/SocialConnect'
import { useLoginRedirect } from 'hooks/useLoginRedirect'
import s from './RegisterPage.module.scss'
import { RegisterPageProps } from './RegisterPage.types'

export const RegisterPage: FC<RegisterPageProps> = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoaded, isLoading } = useUser()
  const { redirectUrl } = useLoginRedirect()

  const onRegister: FormWrapperProps<RegisterFormValues>['onSubmit'] = async (data) => {
    await dispatch(RegisterUser(data))
  }

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <UtilityPage title="Register">
      <FormWrapper<RegisterFormValues>
        onSubmit={onRegister}
        afterForm={({ formState: errors }) => (
          <>
            <div className={s.buttons}>
              <Button to={loginLink(redirectUrl)} theme="link-small">
                Already have account?
              </Button>
              <Button loading={isLoading} disabled={Object.keys(errors || {}).length > 0} type="submit" size="small">
                Register
              </Button>
            </div>
          </>
        )}
      >
        {(methods) => <RegisterFormInputs />}
      </FormWrapper>
      <SocialConnect.Separator>or</SocialConnect.Separator>
      <SocialConnect type="google" />
    </UtilityPage>
  )
}
