import { Button } from 'components-new/common/Button/Button'
import { GuidesList } from 'containers-new/HomeContainer/components/GuidesList/GuidesList'
import { Image as ImageComponent } from 'components-new/common/Image/Image'
import { ParamsObject } from 'containers-new/SearchContainer/SearchContext.types'
import { Partners } from 'containers-new/HomeContainer/components/Partners/Partners'
import React from 'react'
import { Reviews } from 'containers-new/HomeContainer/components/Reviews/Reviews'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { generateRandomInt } from 'utils/numberhelper'
import { searchLink } from 'global/routes'
import { use100vh } from 'react-div-100vh'
import { useGeolocation } from 'hooks/useGeolocation'
import { useSearchContext } from 'containers-new/SearchContainer/SearchContext'
import styles from './HomeContainer.module.scss'
import { TripTypes } from './components/TripTypes/TripTypes'
import { QuickTripCards } from './components/QuickTripCards/QuickTripCards'
import { ModernWay } from './components/ModernWay/ModernWay'
import { Lens } from './components/Lens/Lens'
import { IHomeContainer } from './HomeContainer.types'
import { AreYouAGuide } from './components/AreYouAGuide/AreYouAGuide'
import { useSettingsContext } from './SettingsContext'

export const HomeContainer: React.FC<IHomeContainer> = ({}) => {
  const height = use100vh()
  const { locate, isLocating } = useGeolocation()
  const {
    supportPhone,
    supportPhoneClean,
    homepage: { images, title, subtitle, description },
  } = useSettingsContext()
  const { runSearch } = useSearchContext()
  const heroImage = images.length > 0 ? images[generateRandomInt(0, images.length)] : null

  const startGeoLocation = () => {
    locate((coordinates) => {
      const searchParams = {} as ParamsObject

      if (coordinates) {
        searchParams.coordinates = coordinates
      }

      runSearch({
        withSearchReset: true,
        searchParams,
      })
    })
  }

  return (
    <>
      <div className={styles['hero']}>
        {heroImage && (
          <ImageComponent
            wrapperClassName={styles['hero__image']}
            src={heroImage.getHugeSize()}
            srcSet={heroImage.getSrcSet()}
            ratio="full"
            loading="eager"
            sizes={['150vw']}
          />
        )}
        <div className={styles['hero__overlay']}></div>
        <Wrapper paddingType="normal">
          <div className={styles['hero__header']} style={{ height: height || '100vh' }}>
            <div className={styles['navbar-banner']}>
              <div className={styles['navbar-banner__content']}>
                Questions? Call or text us at <a href={`tel:${supportPhoneClean}`}>{supportPhone}</a>. Or tell us what
                you’re looking for with our{' '}
                <a href="https://outguided.typeform.com/to/gIzfNeJO" target="_blank" rel="noopener noreferrer">
                  trip request form
                </a>
              </div>
            </div>
            <h1 className={styles['hero__title']}>{title}</h1>
            <h2 className={styles['hero__subtitle']} dangerouslySetInnerHTML={{ __html: subtitle }} />
            <h3 className={styles['hero__details']}>{description}</h3>
            <Button
              className={styles['hero__cta-button']}
              theme="light"
              onClick={startGeoLocation}
              loading={isLocating}
            >
              Explore Trips Near Me
            </Button>
          </div>
        </Wrapper>
      </div>
      <QuickTripCards />
      <AreYouAGuide />
      <TripTypes />
      <Reviews />
      <Lens />
      <Partners />
    </>
  )
}
