import { Image } from 'components-new/common/Image/Image'
import backgroundImage from 'assets/img/outdoor.background.jpg'
import { BrowseEntry } from 'pages-new/BrowsePage/BrowsePage'
import { browseLink, exploreLink } from 'global/routes'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import styles from './BrowseContainer.module.scss'
import { BrowseMap } from './components/BrowseMap/BrowseMap'

export const BrowseContainer: React.FC<{ aggregations: BrowseEntry[]; match?: BrowseEntry | null }> = ({
  aggregations,
  match,
}) => {
  return (
    <div className={styles['browse']}>
      <Image
        wrapperClassName={styles['browse__background-image']}
        src={backgroundImage}
        ratio="full"
        sizes={['100vw']}
        theme="rect"
      />
      <div className={styles['browse__container']}>
        <div className={styles['browse__map']}>
          {match ? (
            <BrowseMap items={aggregations}>
              <h1 className={styles['browse__title']}>
                <a href={exploreLink({ slug: match.slug })}>{match.title}</a>
              </h1>
            </BrowseMap>
          ) : (
            <>
              <Wrapper>
                <h1 className={styles['browse__header']}>Browse Adventures:</h1>
              </Wrapper>
              <BrowseMap items={aggregations.filter((a) => !!a.activity)}>
                <h2 className={styles['browse__title']}>Browse By Pursuit</h2>
              </BrowseMap>
              <BrowseMap items={aggregations.filter((a) => !a.activity)}>
                <h2 className={styles['browse__title']}>Browse By Location</h2>
              </BrowseMap>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
