import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Seo from 'components/Seo/Seo'
import { experienceLink } from 'global/routes'
import { resetState, setMarketingPage } from 'redux/Checkout/actions'
import { useDispatch } from 'react-redux'
import { CheckoutContextProvider } from 'containers-new/CheckoutContainer/CheckoutContext'
import { useMarketingPageDetailsBySlugOrId } from 'hooks/useMarketingPageDetailsBySlugOrId'
import { Loader } from 'components-new/common/Loader/Loader'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { ExperienceContainer } from 'containers-new/ExperienceContainer/ExperienceContainer'
import { useEmbed } from 'hooks/useEmbed'

export const ExperiencePage: FC<{}> = () => {
  const { slugOrId } = useParams<{ slugOrId: string }>()
  const { fetch, isLoading, responseError, result: marketingPage } = useMarketingPageDetailsBySlugOrId()
  const { isTripEmbed } = useEmbed({ trip: marketingPage })
  const dispatch = useDispatch()

  useEffect(() => {
    fetch(slugOrId)
  }, [slugOrId])

  useEffect(() => {
    dispatch(resetState())
    dispatch(setMarketingPage(marketingPage))
  }, [marketingPage])

  return (
    <>
      {responseError && <Seo statusCode={responseError.statusCode} />}
      {marketingPage && (
        <Seo
          title={marketingPage.title}
          description={marketingPage.description}
          image={marketingPage.image ? marketingPage.image.url : null}
          canonical={experienceLink(marketingPage.slug)}
        />
      )}

      {!marketingPage || isLoading ? (
        <Loader fullScreen />
      ) : (
        <>
          <Navbar withFixedPosition withEmbed={isTripEmbed} />
          <CheckoutContextProvider>
            <ExperienceContainer experience={marketingPage} />
          </CheckoutContextProvider>
        </>
      )}
    </>
  )
}
