import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { FormGroupProps } from './FormGroup.types'
import styles from './FormGroup.module.scss'

export const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>(function FormGroup(props, ref) {
  const { children, className, columns = true, type, theme = 'default', button } = props

  const classes = classNames({
    [styles['form-group']]: true,
    [styles['form-group--columns']]: columns,
    [styles[`form-group--${type}`]]: !!type,
    [styles[`form-group--with-button`]]: !!button,
    [styles[`form-group--${theme}`]]: !!theme,
    [className!]: !!className,
  })

  return (
    <div ref={ref} className={classes}>
      {children}
      {button && <div className={styles['form-group__button']}>{button}</div>}
    </div>
  )
})
