import { getUrlParams } from 'global/routes'
import { useEffect, useMemo, useState } from 'react'
import Experience from 'services-new/experience/experience.model'
import UserPublic from 'services/shared/models/UserPublic'
import { useSessionStorage } from './useSessionStorage'

export const useEmbed = ({ trip, guide }: { trip?: Experience | null; guide?: UserPublic | null }) => {
  const [tripId, setTripId] = useState<number>()
  const [guideId, setGuideId] = useState<number>()

  const { get: getSource, set: storeSource, remove: removeSource } = useSessionStorage('source')
  const { get: getTripId, set: storeTripId, remove: removeTripId } = useSessionStorage('tripId')
  const { get: getGuideId, set: storeGuideId, remove: removeGuideId } = useSessionStorage('guideId')

  const { source } = getUrlParams() as {
    source?: string
  }

  useEffect(() => {
    if (source && source !== 'undefined' && trip) {
      storeSource(decodeURIComponent(source))
      storeTripId('' + trip.id)
      removeGuideId()
      setGuideId(undefined)
      setTripId(trip.id)
    } else {
      // currently we dont remove source at any point
    }
  }, [source, trip])

  useEffect(() => {
    if (source && guide && guide.isHost) {
      storeSource(decodeURIComponent(source))
      storeGuideId('' + guide.id)
      removeTripId()
      setTripId(undefined)
      setGuideId(guide.id)
    } else {
      // currently we dont remove source at any point
    }
  }, [source, guide])

  const isTripEmbed = useMemo(() => {
    return !!getSource() && !!trip && (getTripId() === '' + trip.id || getGuideId() === '' + trip.host?.id)
  }, [tripId, guideId, trip])

  const isGuideEmbed = useMemo(() => {
    return !!getSource() && !!guide && getGuideId() === '' + guide.id
  }, [guideId, tripId, guide])

  const sourceUrl = useMemo(() => {
    return isTripEmbed ? getSource() : undefined
  }, [isTripEmbed])

  return {
    sourceUrl,
    isTripEmbed,
    isGuideEmbed,
  }
}
