import React, { FC } from 'react'
import { Loader } from 'components-new/common/Loader/Loader'
import { EventDetailsSelect } from 'containers-new/CheckoutContainer/components/EventDetailsSelect/EventDetailsSelect'
import { useCheckoutContext } from 'containers-new/CheckoutContainer/CheckoutContext'
import { Button } from 'components-new/common/Button/Button'
import { ExperienceCalendarProps } from './ExperienceCalendar.types'
import styles from './ExperienceCalendar.module.scss'

export const ExperienceCalendar: FC<ExperienceCalendarProps> = ({ onClick }) => {
  const { isValidatedInitially, isValidating, isCurrentStepValidToLeave } = useCheckoutContext()
  return (
    <div className={styles['experience-calendar']}>
      <h3 className={styles['experience-calendar__header']}>Select Dates</h3>
      {!isValidatedInitially ? (
        <Loader />
      ) : (
        <EventDetailsSelect className={styles['experience-calendar__calendar']} standAlone>
          {onClick && (
            <Button
              theme="secondary"
              size="full"
              onClick={onClick}
              loading={isValidating}
              disabled={!isCurrentStepValidToLeave}
            >
              Request Booking
            </Button>
          )}
        </EventDetailsSelect>
      )}
    </div>
  )
}
