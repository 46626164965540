import React, { useEffect } from 'react'
import { CustomModal } from 'components-new/modals/CustomModal/CustomModal'
import { Button } from 'components-new/common/Button/Button'
import styles from './EmbedModal.module.scss'

declare global {
  interface Window {
    OGWidgets: any
  }
}

export const EmbedModal = ({ code, open = false, onClose }: { code: string; open: boolean; onClose?: () => void }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        window.OGWidgets.init()
      }, 500)
    }
  }, [open])

  return (
    <CustomModal
      open={open}
      handleClose={() => onClose?.()}
      title="Embed Code"
      contentClassName={styles['embed-modal']}
    >
      <h3 className={styles['embed-modal__info']}>Paste code below in a place when you want to show widget</h3>
      <div dangerouslySetInnerHTML={{ __html: code }} />
      <div className={styles['embed-modal__buttons']}>
        <Button theme="primary" onClick={() => onClose?.()}>
          Close
        </Button>
      </div>
    </CustomModal>
  )
}
