import React from 'react'
import { Partners } from 'containers-new/HomeContainer/components/Partners/Partners'
import { TopBanner } from 'containers-new/ForGuidesContainer/components/TopBanner/TopBanner'
import { WhatProvide } from 'components-new/common/WhatProvide/WhatProvide'
import { HowItWorks } from 'components-new/common/HowItWorks/HowItWorks'
import icon1 from 'assets/img/gift.svg'
import icon2 from 'assets/img/shield.svg'
import icon3 from 'assets/img/calendar-2.svg'
import icon4 from 'assets/img/settings.svg'
import { FAQ } from '../../components-new/FAQ/FAQ'
import { StayBooked } from './components/StayBooked/StayBooked'
import styles from './ForGuidesContainer.module.scss'

const faqs_guides = [
  {
    question: 'How does Outguided work?',
    answer: `<p>Outguided is an online booking platform where outdoor enthusiasts can find unique guided
      outdoor adventures. Hunting, Fishing, Skiing, Off-Roading, Diving, Dog-Sledding -- you name it.
      If it’s worth doing, it’s here.</p>
        <p>Outguided is built to equip guides with the modern tools to grow and operate their business.
        With Outguided, guides can spend more time doing what they’re best at: leading unforgettable
        trips in the outdoors.</p>
        <p>Guides on Outguided can earn extra monthly income by exposing their business to the ever-growing global Outguided audience.</p>
        <p> Starting a listing is completely free and easy—it only takes minutes from start to publish. You set
        your own rules, pricing, and offerings with the ability to block important dates and build your
        ideal hosting schedule, so you only guide when you want. You then set up direct deposit to get
        paid as soon as the trip begins.</p>
       `,
  },
  {
    question: 'How Does Payment Processing Work?',
    answer: `<p>When you create a profile on Outguided, you’ll be asked to connect to Stripe. It’s like a Venmo
      or PayPal for businesses and is used by trusted companies like Lyft, Amazon, and Target. You’ll
      plug in your payment routing information into Stripe.</p>
      <p>On the day of a trip, customers will have an Outguided QR code in their email. You’ll scan the
      QR code with your smartphone when the trip starts. Your money will be transferred to your bank
      account.</p>
       `,
  },
  {
    question: 'How should I manage my availability?',
    answer: `<p>You set the availability on the trip page. No one wants to keep multiple schedules up to
      date. We get that. Open up your calendar for all of the months that your trip runs.</p>
      <p>There are no instant bookings on Outguided. Every booking request is sent to you to confirm
      or deny. You have 24 hours to confirm. There are no penalties for turning down a trip.
      Guides are not lowered in the queue for turning down a trip. We want you to be as booked
      up as you want to be!</p>
       `,
  },
  {
    question: 'What is the Cancellation Policy?',
    answer: `<p>We have a comprehensive cancellation policy that protects both the guide and the customer.</p>
      <ul>
      <li>- Anything 15 days or greater from the trip date is fully refundable to the customer.</li>
      <li>- Anything between 14 and 7 days from the trip date is 50% refundable. Half to the customer
      and half to the guide.</li>
      <li>- Anything 7 days or less to the trip is non-refundable. 100% of the trip cost goes to the guide.</li>
      <p>Of course, things come up. We have a dedicated support team here to help if there is bad
      weather or other unforeseen circumstances (like a global pandemic….) that prevent the trip from
      happening.</p>
       `,
  },

  {
    question: 'Can I choose which non-profit Outguided donates to?',
    answer: `<p>Yes! We are proud to support organizations that work to preserve the wild spaces. If you have a
      specific non-profit you would like to see on outguided that isn’t already listed, please reach out
      to {{EMAIL}} and let us know.</p>`,
  },
  {
    question: 'What’s Outguided’s Story?',
    answer: `<p>While researching guided fly-fishing trips in Montana, Brooks and Tim (Outguided’s
        Co-Founders) could not believe how hard it was to research and book the right guide.
        We were stuck playing phone tag, building a spreadsheet with possible options, and
        emailing back and forth. The discovery and booking process...sucked.</p>
        <p>Ultimately, we had a great trip and caught some fish, but this problem stuck with us. If it
        was this hard for both of us, how hard would it be for people who were less experienced
        anglers or looking to go out there for the first time? After all, we believe life is all about
        new experiences and the people you do them with.</p>
        <p>Months later, we couldn’t get rid of this idea. There are so many incredible guided
        adventures out there, but there was no easy way of finding the right guide. We realized
        how big the problem was. Then, we set out to work. Outguided was born.</p>`,
  },
  {
    question: 'Is it really free to use?',
    answer: `<p>Yes! Outguided is 100% free to use for guides. We are free to list with and commission free.
      There are no hidden fees for guides.</p>
      <p>We pass on a small fee to the consumer at check out. This fee covers credit card processing,
      our support team, and platform development.</p>`,
  },
  {
    question: 'What do I do if I have a question but don’t see it answered here?',
    answer: `<p>We’re here to help! If you have questions or issues that haven’t been answered here email the
      team at {{EMAIL}}. If you need help right away give us a call at {{PHONE}}.</p>`,
  },
]

const guides_tmpData = [
  {
    name: 'Post Your Trips',
    description: 'Post your adventures on Outguided to our growing community of adventurers.',
  },
  {
    name: 'Guide when you want.',
    description: 'Outguided sends booking request directly to your phone and email. You’re in control.',
  },
  {
    name: 'Grow Your Business',
    description: 'Easily set up direct deposit, and you’ll get paid as soon as the trip begins.',
  },
]

export const guides_cards = [
  {
    icon: icon1,
    name: '100% <br>free to use',
    description: `Creating a listing is completely free with no strings attached. 
      You keep 100% of your earnings from bookings.`,
  },
  {
    icon: icon2,
    name: 'Scheduling <br>Management',
    description: `Set your calendar availability within Outguided. Booking requests are 
      sent directly to you via text and email for your approval, 
      so don’t worry about having to keep your calendar perfectly up-to-date.`,
  },
  {
    icon: icon3,
    name: 'Cancellation <br>Protection',
    description: `
       Outguided protects your trips. If a customer no shows or cancels within 7 days of a trip, 
       you will still get paid 100% of the trip cost. You will get 50% of the trip cost if the 
       cancellation is within 15 days of the scheduled trip.`,
  },
  {
    icon: icon4,
    name: 'Support 365 days <br> a year',
    description: `We love what we do. Our passionate support team is on call for 
      you 7 days a week, whether you need help with a booking or just have 
      a quick question about your listing.`,
  },
]

export const ForGuidesContainer: React.FC<{ withForm?: boolean }> = ({ withForm = true }) => {
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <>
      {withForm && <TopBanner />}
      <HowItWorks title="How Outguided works in 3 steps:" items={guides_tmpData} />
      <WhatProvide title="What does Outguided provide?" cards={guides_cards} />
      <StayBooked onCtaClick={onClick} />
      <FAQ className={styles['for-guides__wrapper']} items={faqs_guides} />
      <Partners className={styles['for-guides__wrapper']} />
    </>
  )
}
