import React, { Children } from 'react'
import Booking from 'services-new/booking/booking.model'
import { Accordion } from 'components-new/common/Accordion/Accordion'
import { AccountBookingCard } from 'components-new/common/AccountBookingCard/AccountBookingCard'
import { useUser } from 'hooks/useUser'
import styles from './BookingList.module.scss'

interface IBookingList {
  header: string
}

export const BookingList: React.FC<React.PropsWithChildren<IBookingList>> = ({ header, children: childrenProp }) => {
  const children = Children.toArray(childrenProp)
  return (
    <div className={styles['bl__block']}>
      <h2 className={styles['bl__header']}>
        {header}
        {children?.length !== 0 && <span className={styles['bl__header-number']}>{children?.length}</span>}
      </h2>
      {children.length > 0 ? (
        <Accordion id={2}>
          <div className={styles['bl__cards-wrapper']}>{children}</div>
        </Accordion>
      ) : (
        <div>No bookings found</div>
      )}
    </div>
  )
}
