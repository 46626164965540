import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const location = useLocation<any>()

  useEffect(() => {
    const scroll = typeof location.state?.scroll === 'undefined' || location.state.scroll
    if (scroll) {
      window.scrollTo(0, 0)
    }
  }, [location.pathname])

  return children
}
