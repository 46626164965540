import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { IQuotation } from './Quotation.types'
import styles from './Quotation.module.scss'

export const Quotation: React.FC<IQuotation> = () => {
  return (
    <Wrapper containerClasses={styles.quotation}>
      <figure className={styles.quotation__figure}>
        <blockquote className={styles.quotation__blockquote}>
          We want more people, from all walks of life, to go on adventures they never thought possible.
          <br />
          See you out there!
        </blockquote>
        <figcaption className={styles.quotation__figcaption}>&mdash; Brooks and Tim | Co-Founders</figcaption>
      </figure>
    </Wrapper>
  )
}
