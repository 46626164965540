import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import {
  GetBooking,
  GetBookings,
  CancelBooking,
  RejectBooking,
  AcceptBooking,
  CalculateCancelBooking,
} from 'services-new/booking/booking-api'
import Booking, { BookingInput } from 'services-new/booking/booking.model'
import { initialState, InitialState } from 'utils/api'

export const bookingAdapter = createEntityAdapter<Booking>()

export const bookingSlice = createSlice({
  name: 'bookings',
  initialState: bookingAdapter.getInitialState<InitialState>(initialState),
  reducers: {
    reset: (state) => Object.assign(state, bookingAdapter.getInitialState<InitialState>(initialState)),
  },
  extraReducers: (builder) => {
    builder.addCase(GetBooking.pending, (state, action) => {
      state.loading = true
      state.loaded = false
      state.error = null
    })
    builder.addCase(GetBooking.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      bookingAdapter.addOne(state, new Booking(action.payload.data))
    })
    builder.addCase(GetBooking.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })
    builder.addCase(GetBookings.pending, (state) => {
      state.loading = true
      state.loaded = false
      state.error = null
    })
    builder.addCase(GetBookings.fulfilled, (state, action: PayloadAction<AxiosResponse<BookingInput[]>>) => {
      state.loading = false
      state.loaded = true
      bookingAdapter.addMany(state, Booking.fromArray(action.payload.data))
    })
    builder.addCase(GetBookings.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error
    })

    // actions
    builder.addCase(CalculateCancelBooking.pending, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(CalculateCancelBooking.fulfilled, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: null,
      }

      bookingAdapter.setOne(
        state,
        new Booking({ ...action.payload.data.booking, cancel_amount: action.payload.data.amount })
      )
    })
    builder.addCase(CalculateCancelBooking.rejected, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: action.error,
      }
    })
    builder.addCase(CancelBooking.pending, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(CancelBooking.fulfilled, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: null,
      }
      bookingAdapter.setOne(state, new Booking(action.payload.data))
    })
    builder.addCase(CancelBooking.rejected, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: action.error,
      }
    })
    builder.addCase(RejectBooking.pending, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(RejectBooking.fulfilled, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: null,
      }
      bookingAdapter.setOne(state, new Booking(action.payload.data))
    })
    builder.addCase(RejectBooking.rejected, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: action.error,
      }
    })
    builder.addCase(AcceptBooking.pending, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: true,
        error: null,
      }
    })
    builder.addCase(AcceptBooking.fulfilled, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: null,
      }
      bookingAdapter.setOne(state, new Booking(action.payload.data))
    })
    builder.addCase(AcceptBooking.rejected, (state, action) => {
      state.loaders[action.meta.arg] = {
        loading: false,
        error: action.error,
      }
    })
  },
})
export const { reset } = bookingSlice.actions
export default bookingSlice.reducer
