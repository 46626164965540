import logoPath from 'assets/icons/sprite.svg'
import FlagImage from 'assets/img/footer-flag-small.png'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import { ABOUT_PAGE, ADVENTURER_PAGE, browseLink, GUIDE_PAGE, HOME_PAGE, pageLink } from 'global/routes'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import React from 'react'
import styles from './Footer.module.scss'
import { IFooter } from './Footer.types'

export const Footer: React.FC<IFooter> = ({ img }) => {
  const { supportEmail, supportPhone, supportPhoneClean } = useSettingsContext()
  return (
    <Wrapper tagName="footer" backgroundImage={img} showOverlay>
      <div className={styles.footer__upper}>
        <div className={classNames(styles.footer__box, styles['footer__box--first'])}>
          <svg
            className={styles.footer__logo}
            viewBox="0 0 138 31"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use xlinkHref={`${logoPath}#icon_brand_logo`} />
          </svg>
          <p className={styles.footer__origin}>
            Proudly Made in <img src={FlagImage} width="24" alt="" className={styles.footer__flag} />
          </p>
        </div>
        <div className={styles.footer__box}>
          <div className={styles.footer__title}>Company</div>
          <ul className={styles.footer__list}>
            <li className={classNames(styles['footer__list-item'], styles.footer__item)}>
              <Link to={HOME_PAGE} className={styles.footer__link}>
                Home
              </Link>
            </li>
            <li className={classNames(styles['footer__list-item'], styles.footer__item)}>
              <Link to={browseLink()} className={styles.footer__link}>
                Browse
              </Link>
            </li>
            <li className={classNames(styles['footer__list-item'], styles.footer__item)}>
              <Link to={GUIDE_PAGE} className={styles.footer__link}>
                For Guides
              </Link>
            </li>
            <li className={classNames(styles['footer__list-item'], styles.footer__item)}>
              <Link to={ADVENTURER_PAGE} className={styles.footer__link}>
                For Adventurers
              </Link>
            </li>
            <li className={classNames(styles['footer__list-item'], styles.footer__item)}>
              <Link to={ABOUT_PAGE} className={styles.footer__link}>
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.footer__box}>
          <div className={styles.footer__title}>Support</div>
          <a href={`tel:${supportPhoneClean}`} className={classNames(styles.footer__link, styles.footer__item)}>
            {supportPhone}
          </a>
          <a href={`mailto:${supportEmail}`} className={classNames(styles.footer__link, styles.footer__item)}>
            {supportEmail}
          </a>
        </div>
      </div>
      <div className={styles.footer__lower}>
        <div className={classNames(styles['footer__item-lower'], styles['footer__item-lower--first'])}>
          &copy; {new Date().getFullYear()}
        </div>
        <Link to={pageLink('terms-and-conditions')} className={styles['footer__item-lower']}>
          Terms & conditions
        </Link>
        <Link to={pageLink('privacy-policy')} className={styles['footer__item-lower']}>
          Privacy policy
        </Link>
      </div>
    </Wrapper>
  )
}
