// from node_modules
import React, { FC } from 'react'
import { Footer } from 'components-new/layout/Footer/Footer'
// assets
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import Seo from 'components/Seo/Seo'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import s from './UtilityPage.module.scss'
import { UtilityPageProps } from './UtilityPage.types'

export const UtilityPage: FC<UtilityPageProps> = ({ title, children }) => {
  return (
    <div className={s.page}>
      {<Seo noIndex={true} />}
      <Navbar />
      <Wrapper paddingType="normal" containerClasses={s.container} contentClasses={s.content}>
        <h1 className={s.header}>{title}</h1>
        {children}
      </Wrapper>
      <Footer img={footerImage} />
    </div>
  )
}
