import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { conversationAdapter } from 'features/conversation/ConversationsSlice'
import {
  GetConversations,
  RemoveConversation,
  CreateConversation,
  SendMessage,
  GetConversation,
} from 'services-new/conversation/conversation-api'
import Conversation from 'services-new/conversation/conversation.model'
import { useUser } from 'hooks/useUser'
import { messageLink } from 'global/routes'
import { useHistory } from 'react-router'
import { io, Socket } from 'socket.io-client'
import { BACKEND_API } from 'global/environment'
import { MessagesContext } from './MessagesContext.types'

type SendMessageInput = {
  conversation: Conversation['id']
}

export const MessagesContextInstance = createContext<MessagesContext>({} as MessagesContext)

export const MessagesContextProvider: FC = (props) => {
  const { children } = props
  const state = useSelector((s: RootState) => s.conversations)
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoggedIn, accessToken } = useUser()
  const { selectAll } = conversationAdapter.getSelectors()
  const socketClient = useRef<Socket>()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(GetConversations())
    }
  }, [isLoggedIn])

  const conversations = useMemo(() => {
    return selectAll(state)
  }, [state])

  const sendMessage = async (conversation: Conversation, messageText: string) => {
    if (conversation.id === 'new') {
      const result: any = await dispatch(
        CreateConversation({ conversation: Conversation.toOutput(conversation), message: { body: messageText } })
      )
      const conversationId = result?.payload?.data?.conversation?.id
      if (conversationId) {
        history.push(messageLink(conversationId))
      }
    } else {
      dispatch(SendMessage({ body: messageText, id: conversation.id }))
    }
  }

  const removeConversation = (id: Conversation['id']) => {
    dispatch(RemoveConversation(id))
  }

  useEffect(() => {
    if (isLoggedIn && !socketClient.current?.connected) {
      console.log('connect')
      socketClient.current = io(`${BACKEND_API}`, {
        withCredentials: true,
        auth: {
          token: accessToken,
        },
      })
    } else if (!isLoggedIn && socketClient.current && socketClient.current.connected) {
      console.log('disconnect')
      socketClient.current?.disconnect()
    }
    return () => {
      socketClient.current?.disconnect()
    }
  }, [isLoggedIn])

  const onSendMessage = ({ conversation: conversationId }: SendMessageInput) => {
    if (state.currentConversation && state.currentConversation.id === conversationId) {
      dispatch(GetConversation(conversationId))
    } else if (conversations.some((c) => c.id === conversationId)) {
      dispatch(GetConversations())
    }
  }

  const onCreateConversation = () => {
    dispatch(GetConversations())
  }

  useEffect(() => {
    socketClient.current?.on('connect', () => {})
    socketClient.current?.on('sendMessage', onSendMessage)
    socketClient.current?.on('createConversation', onCreateConversation)

    socketClient.current?.on('disconnect', () => {
      console.log('on disconnect')
    })

    return () => {
      console.log('off')
      socketClient.current?.off('connect')
      socketClient.current?.off('sendMessage')
      socketClient.current?.off('createConversation')
    }
  }, [conversations.length, state.currentConversation])

  return (
    <MessagesContextInstance.Provider
      value={{
        conversations,
        sendMessage,
        removeConversation,
        unreadedMessagesCount: useMemo(
          () => conversations.filter((conversation) => conversation.unreaded).length,
          [conversations]
        ),
        isLoading: useCallback(
          (id) => (id && id !== 'new' ? state.loaders[id]?.loading || false : state.loading),
          [state.loading, state.loaders]
        ),
        draft: state.draftConversation,
      }}
    >
      {children}
    </MessagesContextInstance.Provider>
  )
}

export function useMessagesContext(): MessagesContext {
  const context = useContext(MessagesContextInstance)

  if (!context) {
    throw new Error('useMessagesContext used without provider')
  }

  return context
}
