import { backendUTCToLocal, localToBackendUtc } from 'utils/datesHelpers'

export interface SeasonRangeInput {
  start_date: string
  end_date: string
}

export type SeasonRangeOutput = {
  start_date: Date
  end_date: Date
}
export interface SeasonRange {
  startDate: Date
  endDate: Date
}
export interface PricingInput {
  id?: number
  duration?: number | null
  minimum_capacity?: number
  maximum_capacity?: number
  price?: number
  price_variable?: number
  type?: 'public' | 'private' | null
  price_type?: 'fixed' | 'person' | null
  season_type?: 'year' | 'range' | 'custom' | null
  start_time?: number[] | null | number
  season_start?: string | null
  season_end?: string | null
  season_custom_ranges?: Array<SeasonRangeInput> | null
  deposit?: number
}
export interface PricingOutput {
  id?: number
  duration: number
  minimum_capacity: number
  maximum_capacity: number
  price: number
  price_variable: number
  type: 'public' | 'private'
  price_type: 'fixed' | 'person'
  start_time: number[]
  season_type: 'year' | 'range' | 'custom'
  season_start: string | null
  season_end: string | null
  season_custom_ranges: Array<SeasonRangeOutput> | null
  deposit: number
}

class Pricing {
  id: number
  duration: number
  deposit: number
  startTime: Array<{ value: number }>
  minimumCapacity: number
  maximumCapacity: number
  type: 'public' | 'private'
  priceType: 'fixed' | 'person'
  price: number
  priceVariable: number
  seasonType: 'year' | 'range' | 'custom'
  seasonStart: string | null
  seasonEnd: string | null
  seasonCustomRanges: Array<SeasonRange>

  constructor(input: PricingInput) {
    this.id = input.id || 0
    this.duration = input.duration || 60

    const valid_start_time = Number.isInteger(input.start_time)
      ? [input.start_time as number]
      : (input.start_time as number[]) || []

    this.startTime = valid_start_time.map((s) => ({ value: s }))
    this.minimumCapacity = input.minimum_capacity || 1
    this.maximumCapacity = input.maximum_capacity || 1
    this.price = (input.price || 0) / 100
    this.priceVariable = (input.price_variable || 0) / 100
    this.type = input.type || 'public'
    this.priceType = input.price_type || 'fixed'
    this.seasonType = input.season_type || 'year'
    this.deposit = input.deposit || 10
    this.seasonStart = input.season_start || null
    this.seasonEnd = input.season_end || null
    this.seasonCustomRanges = input.season_custom_ranges
      ? input.season_custom_ranges
          .filter((r) => r.start_date && r.end_date)
          .map((range) => ({
            startDate: backendUTCToLocal(new Date(range.start_date)),
            endDate: backendUTCToLocal(new Date(range.end_date)),
          }))
      : []
  }

  static fromArray(input: PricingInput[] | null): Pricing[] {
    return input ? input.map((i) => new Pricing(i)) : []
  }

  static toOutput(data: Pricing): PricingOutput {
    return {
      id: data.id ? data.id : undefined,
      duration: data.duration,
      deposit: data.deposit,
      start_time: Array.from(new Set(data.startTime.map((s) => s.value))).sort((a, b) => a - b),
      minimum_capacity: data.minimumCapacity,
      maximum_capacity: data.maximumCapacity,
      type: data.type,
      price_type: data.priceType,
      price: data.price * 100,
      price_variable: data.priceVariable * 100,
      season_type: data.seasonType,
      season_start: data.seasonStart,
      season_end: data.seasonEnd,
      season_custom_ranges: data.seasonCustomRanges
        ? data.seasonCustomRanges.map((range) => ({
            start_date: localToBackendUtc(range.startDate),
            end_date: localToBackendUtc(range.endDate),
          }))
        : [],
    }
  }
}

export default Pricing
