import { Input } from 'components-new/forms/Input/Input'
import { FormGroup } from 'components-new/forms/FormGroup/FormGroup'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { PhoneInput } from 'components-new/forms/PhoneInput/PhoneInput'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { RegisterFormInputsProps } from './RegisterFormInputs.types'

export const RegisterFormInputs: FC<RegisterFormInputsProps> = ({}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <FormGroup columns>
        <Input
          {...register('firstName', {
            required: 'This field is required',
          })}
          placeholder="Your first name"
          label="First Name"
          error={errors?.firstName?.message}
        />
        <Input
          {...register('lastName', {
            required: 'This field is required',
          })}
          placeholder="Your last name"
          label="Last Name"
          error={errors?.lastName?.message}
        />
      </FormGroup>
      <Input
        {...register('email', {
          required: 'This field is required',
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Email is invalid',
          },
        })}
        placeholder="Your email"
        label="E-mail"
        error={errors?.email?.message}
      />
      <Input
        {...register('password', {
          required: 'This field is required',
          minLength: {
            value: 7,
            message: 'Minimum password length is 7 characters',
          },
          pattern: {
            message: 'Field cannot contain spaces',
            value: /^\S*$/,
          },
        })}
        type="password"
        placeholder="Password"
        label="Password"
        error={errors?.password?.message}
      />
      <PhoneInput
        {...register('phone', {
          required: 'This field is required',
          validate: (value) => !value || isValidPhoneNumber(value) || 'Invalid phone number',
        })}
        error={errors?.phone?.message}
        placeholder="Your number"
        label="Phone number"
      ></PhoneInput>
    </>
  )
}
