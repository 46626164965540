import { useEffect, useLayoutEffect, useState, Dispatch, SetStateAction } from 'react'

export const useLockedBody = (
  initialLocked = false
): { locked: boolean; setLocked: Dispatch<SetStateAction<boolean>> } => {
  const [locked, setLocked] = useState(initialLocked)

  useLayoutEffect(() => {
    if (!locked) {
      return
    }

    const originalOverflow = document.documentElement.style.overflow
    const originalPaddingRight = document.documentElement.style.paddingRight

    document.documentElement.style.overflow = 'hidden'

    const root = document.documentElement
    const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0

    if (scrollBarWidth) {
      document.documentElement.style.paddingRight = `${scrollBarWidth}px`
    }

    return () => {
      document.documentElement.style.overflow = originalOverflow

      if (scrollBarWidth) {
        document.documentElement.style.paddingRight = originalPaddingRight
      }
    }
  }, [locked])

  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked)
    }
  }, [initialLocked])

  return { locked, setLocked }
}
