import 'react-toastify/dist/ReactToastify.min.css'

import React, { Suspense, useEffect } from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { IntercomProvider } from 'react-use-intercom'
import { MessagesContextProvider } from 'containers-new/AccountMessagesContainer/MessagesContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { ScrollToTop } from 'utils/ScrollToTop'
import { SearchContextProvider } from 'containers-new/SearchContainer/SearchContext'
import { SettingsContextProvider } from 'containers-new/HomeContainer/SettingsContext'
import { SwitchRoutes } from 'containers/SwitchRoutes/SwitchRoutes'
import moment from 'moment-timezone'
import mixpanel from 'mixpanel-browser'
import Seo from './components/Seo/Seo'

const App = () => {
  useEffect(() => {
    moment.tz.setDefault('UTC')
    mixpanel.init('0e556d8a997363729e3f40ca9e91a713', { debug: true, ignore_dnt: true })
  }, [])

  return (
    <>
      <Router>
        <ToastContainer
          transition={Slide}
          pauseOnHover={false}
          pauseOnFocusLoss
          newestOnTop
          position="top-left"
          draggable={false}
          autoClose={5000}
        />
        <Seo />
        <IntercomProvider
          appId={process.env.REACT_APP_INTERCOM_APP_ID}
          shouldInitialize={process.env.REACT_APP_INTERCOM_APP_ID && navigator.userAgent !== 'ReactSnap'}
        >
          <SettingsContextProvider>
            <SearchContextProvider>
              <MessagesContextProvider>
                <ScrollToTop>
                  <Suspense fallback={<div>...</div>}>
                    <SwitchRoutes />
                  </Suspense>
                </ScrollToTop>
              </MessagesContextProvider>
            </SearchContextProvider>
          </SettingsContextProvider>
        </IntercomProvider>
      </Router>
    </>
  )
}
export default App
