import { MapBoxLocationDetails } from 'services/mapbox/mapbox-api'
import { MapGL } from 'components-new/common/MapGL/MapGL'

import { extractAddressMapBox } from 'services/shared/models/Address'
import Location from 'services/shared/models/Location'
import { MapGLPin } from 'components-new/common/MapGLPin/MapGLPin'
import React, { useState, useEffect, useCallback, useRef, forwardRef } from 'react'
import classNames from 'classnames'
import { LocationEditProps } from './LocationEdit.types'
import styles from './LocationEdit.module.scss'

const defaultMapState = {
  width: '100%',
  height: '100%',
  latitude: 43.475,
  longitude: -110.769,
  zoom: 3,
}

export const LocationEdit = forwardRef<HTMLInputElement, LocationEditProps>((props, ref) => {
  const { location, onLocationChange = () => {}, mapHeight, geocoderContainerRef, error } = props

  const containerRef = useRef(null)
  const mapRef = useRef(null)
  const [locationString, setLocationString] = useState(location?.address?.locationString)
  const [viewport, setViewport] = useState({
    ...defaultMapState,
    ...(location ? { latitude: location?.latitude, longitude: location?.longitude, zoom: 15 } : {}),
  })
  const locationDetails$ = MapBoxLocationDetails.getObservable$()

  useEffect(() => {
    const subscription = locationDetails$.subscribe(([locationDetails, timezone, params]: any[]) => {
      const locationFeatures = locationDetails.features
      const timezoneFeatures = timezone.features

      if (locationFeatures && locationFeatures.length > 0) {
        const { longitude, latitude } = params
        if (
          (location && viewport.latitude === latitude && viewport.longitude === longitude) ||
          (latitude === defaultMapState.latitude && longitude === defaultMapState.longitude)
        ) {
          return
        }
        const address = extractAddressMapBox(locationFeatures[0])
        const tz = timezoneFeatures && timezoneFeatures.length > 0 ? timezoneFeatures[0].properties.TZID : ''
        const newLocation = new Location({
          coordinates: `${latitude},${longitude}`,
          address,
          timezone: tz,
          latitude,
          longitude,
          label: null,
        })

        setLocationString(address.toString())
        console.log(newLocation)
        onLocationChange(newLocation)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const handleViewportChange = useCallback((newViewport) => {
    setViewport({ ...viewport, ...newViewport })
    MapBoxLocationDetails.requestNewLocationDetails(newViewport)
  }, [])

  return (
    <div className={classNames({ [styles['location-edit']]: true, [styles['location-edit--error']]: !!error })}>
      <h5>
        Selected location: {locationString}{' '}
        {typeof error === 'string' && <span className={styles['location-edit__error']}>{error}</span>}
      </h5>

      <div ref={containerRef} style={{ height: mapHeight || 500 }} className={styles['location-edit__map']}>
        <input type="text" ref={ref} onFocus={(e) => e.target.blur()} className={styles['location-edit__ref-input']} />
        <MapGL
          ref={mapRef}
          {...viewport}
          onViewportChange={handleViewportChange}
          geocoder={true}
          geolocate={true}
          geocoderContainerRef={geocoderContainerRef}
        >
          <MapGLPin latitude={viewport.latitude} longitude={viewport.longitude} />
        </MapGL>
      </div>
    </div>
  )
})
