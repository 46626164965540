import { postRequest$ } from 'utils/http'
import { BACKEND_API } from 'global/environment'

import { Observable } from 'rxjs'
import { BookingInput } from 'services-new/booking/booking.model'
import { CheckoutDTO } from './dto/CheckoutDTO'
import { CheckoutInput } from './models/Checkout'

const BASE_URL = `${BACKEND_API}/bookings`

export function validate$(data: CheckoutDTO) {
  return postRequest$(BASE_URL + '/validate', data) as Observable<CheckoutInput>
}

export function complete$(data: CheckoutDTO) {
  return postRequest$(BASE_URL, data) as Observable<BookingInput>
}
