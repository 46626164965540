import React from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import classNames from 'classnames'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import styles from './FAQ.module.scss'
import { FAQItem } from './FAQItem/FAQItem'
import { FAQProps } from './FAQ.types'

export const FAQ: React.FC<FAQProps> = ({ className, items }) => {
  const { supportPhone, supportPhoneClean, supportEmail } = useSettingsContext()
  const faqs = items

  const email = `<a href="mailto:${supportEmail}" target="_blank">${supportEmail}</a>`
  const phone = `<a href="tel:${supportPhoneClean}">${supportPhone}</a>`
  const half = Math.ceil(faqs.length / 2)

  return (
    <Wrapper className={classNames({ [styles['faq']]: !!styles['faq'], [className!]: !!className })}>
      <h2 className={styles['faq__header']}>FAQ</h2>
      <dl className={styles['faq__items']}>
        <div>
          {faqs.slice(0, half).map((faq, i) => (
            <FAQItem
              faq={{ ...faq, answer: faq.answer.replace('{{PHONE}}', phone).replace('{{EMAIL}}', email) }}
              key={faq.question}
              id={i}
            />
          ))}
        </div>
        <div>
          {faqs.slice(-half).map((faq, i) => (
            <FAQItem
              faq={{ ...faq, answer: faq.answer.replace('{{PHONE}}', phone).replace('{{EMAIL}}', email) }}
              key={faq.question}
              id={i}
            />
          ))}
        </div>
      </dl>
    </Wrapper>
  )
}
