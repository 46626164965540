import classNames from 'classnames'
import { Button } from 'components-new/common/Button/Button'
import s from './PaymentsExternalAccount.module.scss'

interface IPaymentsExternalAccount {
  onDelete: (id: string) => void
  onDefault: (id: string) => void
  isDefault: boolean
  id: string
  title: string
  number: string
  label: string
}
export const PaymentsExternalAccount: React.FC<IPaymentsExternalAccount> = ({
  isDefault,
  title,
  label,
  id,
  number,
  onDelete,
  onDefault,
}) => {
  return (
    <div className={classNames(s.container, { [s['container--default']]: isDefault })}>
      <p className={s.content}>
        <span className={s.label}>{label}</span>
        <span>
          {title} - <span className={s.number}>{number}</span>
        </span>
      </p>
      <div className={s.actions}>
        {isDefault && <span className={s.label}>Default Method</span>}
        {!isDefault && (
          <Button onClick={() => onDefault(id)} theme="link-small">
            Set as default
          </Button>
        )}
        {!isDefault && (
          <Button
            onClick={() => {
              if (window.confirm('Are you sure?')) {
                onDelete(id)
              }
            }}
            theme="plain-orange-secondary"
            size="extra-small"
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  )
}
