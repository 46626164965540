import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CheckoutState } from 'redux/Checkout/reducer'
import { Observable, ReplaySubject } from 'rxjs'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { BookingInput } from 'services-new/booking/booking.model'
import { validate$, complete$ } from 'services/checkout/checkout-api'
import { CheckoutDTO, prepareDataToSave } from 'services/checkout/dto/CheckoutDTO'
import Checkout, { CheckoutInput } from 'services/checkout/models/Checkout'
import { RootState } from 'store'
import { formattedPrice } from 'utils/numberhelper'

export const useCheckout = (): useCheckoutReturn => {
  const checkoutState = useSelector((state: RootState) => state.checkout)
  const [checkout, setCheckout] = useState({} as Checkout)
  const [isValidating, setIsValidating] = useState(false)
  const [isValidatedInitially, setIsValidatedInitially] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const setIsSubmitingCallback = useCallback(
    (value) => {
      setIsSubmiting(value)
    },
    [setIsSubmiting]
  )

  const subject$ = useRef(new ReplaySubject<CheckoutDTO>())

  useEffect(() => {
    const subscription = subject$.current
      .pipe(
        debounceTime(500),
        switchMap((data: CheckoutDTO) => validate$(data))
      )
      .subscribe(
        (checkoutResponse: CheckoutInput) => {
          setCheckout(new Checkout(checkoutResponse))
          setIsValidating(false)
          setIsValidatedInitially(true)
        },
        (err) => {
          setIsValidating(false)
        }
      )

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const validate = useCallback(
    (data: any = {}) => {
      setIsValidating(true)
      const checkoutDTO = prepareDataToSave(checkoutState)
      subject$.current.next({ ...checkoutDTO, ...data })
    },
    [checkoutState]
  )

  const complete = useCallback(
    (data: any = {}) => {
      setIsSubmiting(true)
      const checkoutDTO = prepareDataToSave(checkoutState)
      return complete$({ ...checkoutDTO, ...data }).pipe(
        tap((result) => {
          setIsSubmiting(false)
        })
      )
    },
    [checkoutState]
  )

  const formattedSubAmount = useMemo(() => {
    return checkout.subAmount
      ? formattedPrice(checkout.subAmount, 'USD')
      : checkoutState.marketingPage
      ? `from ${formattedPrice(checkoutState.marketingPage.pricing.price, 'USD')}`
      : ''
  }, [checkout, checkoutState.marketingPage])

  return {
    validate,
    complete,
    isValidating,
    isValidatedInitially,
    setIsSubmiting: setIsSubmitingCallback,
    isSubmiting,
    checkout,
    formattedSubAmount,
    state: checkoutState,
  }
}

export interface useCheckoutReturn {
  complete: (data: any) => Observable<BookingInput>
  validate: (data: any) => void
  isValidating: boolean
  isSubmiting: boolean
  setIsSubmiting: (value: boolean) => void
  isValidatedInitially: boolean
  checkout: Checkout
  formattedSubAmount: string
  state: CheckoutState
}
