import React, { useEffect, FC, useMemo } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'

import classNames from 'classnames'

import enUS from 'date-fns/locale/en-US'
import { getComputedMonthName } from 'utils/calendarHelper'
import styles from './DatePicker.module.scss'
import { DatePickerProps } from './DatePicker.types'

export const DatePicker: FC<DatePickerProps> = ({
  children,
  className,
  required,
  name,
  // value,
  selected,
  label,
  error,
  success,
  disabled,
  onBlur,
  onFocus,
  onChange,
  hideControl,
  onMonthChange,
  selectedDays,
  availableDays,
  selectsRange,
  minDate,
  maxDate,
  calendarClassName,
  ...nativeProps
}) => {
  useEffect(() => {
    registerLocale('en', enUS)
  }, [])

  const containerClasses = () =>
    classNames({
      [styles['date-picker']]: !!styles['date-picker'],
    })

  const highlightDates = useMemo(() => {
    const highlights = []
    if (typeof selectedDays !== 'undefined') {
      highlights.push({
        'react-datepicker__day--selected': [...selectedDays],
      } as {
        'react-datepicker__day--selected': Date[]
      })
    }
    if (typeof availableDays !== 'undefined') {
      highlights.push({
        'react-datepicker__day--highlighted': [...availableDays],
      } as {
        'react-datepicker__day--highlighted': Date[]
      })
    }
    return highlights
  }, [selectedDays, availableDays])

  return (
    <div className={containerClasses()}>
      <ReactDatePicker
        onMonthChange={onMonthChange}
        selected={selected}
        inline
        locale="en"
        highlightDates={highlightDates}
        showYearDropdown={false}
        onChange={onChange}
        selectsRange={selectsRange}
        minDate={minDate}
        maxDate={maxDate}
        calendarClassName={calendarClassName}
        renderCustomHeader={({
          date,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          increaseMonth,
          decreaseMonth,
        }) => (
          <div className={styles['date-picker__navigation']}>
            {!prevMonthButtonDisabled && (
              <div onClick={decreaseMonth} className={styles['date-picker__navigation--previous']} />
            )}

            <span className={styles['date-picker__month']}>{getComputedMonthName(date)}</span>
            {date.getFullYear().toString()}

            {!nextMonthButtonDisabled && (
              <div onClick={increaseMonth} className={styles['date-picker__navigation--next']} />
            )}
          </div>
        )}
        {...nativeProps}
      />
    </div>
  )
}

export default DatePicker
