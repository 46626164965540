import { Button } from 'components-new/common/Button/Button'
import React, { FC } from 'react'
import { Wrapper } from 'containers-new/Wrapper/Wrapper'
import styles from './StayBooked.module.scss'
import { StayBookedProps } from './StayBooked.types'

export const StayBooked: FC<StayBookedProps> = ({ onCtaClick }) => (
  <Wrapper containerClasses={styles['stay-booked']} contentClasses={styles['stay-booked__content']}>
    <h2 className={styles['stay-booked__header']}>Stay as Booked Up as You Want to Be.</h2>
    <Button theme="secondary" onClick={onCtaClick} className={styles['stay-booked__button']}>
      Get Started
    </Button>
  </Wrapper>
)
