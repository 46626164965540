import moment from 'moment'
import Activity, { ActivityInput } from 'services/shared/models/Activity'
import { formatDay } from 'utils/datesHelpers'
import Image, { ImageInput } from '../../services/shared/models/Image'

export interface IPostInput {
  id: number
  activity: ActivityInput | null
  published_at: string
  title: string
  slug: string
  excerpt: string
  content: string
  image: ImageInput | null
}

class Post {
  id: number
  activity: Activity | null
  publishedAt: string
  title: string
  slug: string
  excerpt: string
  content: string
  image: Image | null

  constructor(post: IPostInput) {
    this.id = post.id
    this.activity = post.activity ? new Activity(post.activity) : null
    this.publishedAt = moment(post.published_at).format('MMMM DD, YYYY')
    this.title = post.title
    this.slug = post.slug
    this.excerpt = post.excerpt
    this.content = post.content
    this.image = post.image ? new Image(post.image) : null
  }

  static fromArray(input: IPostInput[]): Post[] {
    return input && Array.isArray(input) ? input.map((i) => new Post(i)) : []
  }
}

export default Post
