/* colors
 main colors */
export const colorPrimary = '#a53820'
export const colorInterface = '#be7f70'
export const colorBlack = '#1b1b1b'
export const colorBlackFull = '#000'
export const colorWhite = '#ffff'
export const colorLight = '#f9f3f1'
export const colorBg = '#2f333c'
export const colorGray0 = '#f4f3f1'
export const colorGray1 = '#c5c5c5'
export const colorError = '#d25034'
export const colorDark = '#606B76'
export const colorGreen = '#086B61'

// messages color palette
export const messagesColorPalette = [
  '#c2185b',
  '#6915ab',
  '#13299c',
  '#cA5522',
  '#007c70',
  '#e2be00',
  '#67a600',
  '#007792',
]

/* media breakpoints */
export const breakpointXs = 0
export const breakpointSm = 600
export const breakpointMd = 960
export const breakpointLg = 1280
export const breakpointXl = 1920

export const headerHeight = 100
export const footerHeight = 104
export const stickyFooterHeight = 104
export const floatingComponentHeight = 104
