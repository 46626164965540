import { getRequest$, postRequest$, putRequest$ } from 'utils/http'
import { BACKEND_API } from 'global/environment'

const BASE_URL = `${BACKEND_API}/users`

export function updateUser$(userId, data) {
  const url = `${BASE_URL}/${userId}`
  return putRequest$(url, data)
}

export function updateSlug$(data) {
  const url = `${BACKEND_API}/update-slug`
  return postRequest$(url, data)
}

export function getUserDetails$() {
  const url = `${BACKEND_API}/me`
  return getRequest$(url)
}

export function getFeaturedUsers$(params) {
  const url = `${BACKEND_API}/user/featured`
  return getRequest$(url, { params })
}

export function getOtherUserDetails$(userId) {
  const url = `${BASE_URL}/${userId}`
  return getRequest$(url)
}

export function getOtherUserDetailsBySlug$(slug) {
  const url = `${BASE_URL}/slug/${slug}`
  return getRequest$(url)
}

export function updateRole$(params) {
  const { id } = params
  const { role } = params
  const url = `${BASE_URL}/${id}`
  return putRequest$(url, { role })
}

export function becomeHost$(params) {
  // eslint-disable-next-line camelcase
  const { display_name, description, languages } = params
  const url = `${BACKEND_API}/become-host`
  return postRequest$(url, {
    display_name,
    description,
    languages,
  })
}
