import Seo from 'components/Seo/Seo'
import { Navbar } from 'components-new/layout/Navbar/Navbar'
import { BlogContainer } from 'containers-new/BlogContainer/BlogContainer'
import { Footer } from 'components-new/layout/Footer/Footer'
import footerImage from 'assets/img/outguided/footer/footer.jpg'
import { GetPosts } from 'services-new/post/post-api'
import { RootState } from 'store'
import { postsAdapter } from 'features/post/PostsSlice'
import { useDispatch, useSelector } from 'react-redux'
import React, { useLayoutEffect, useMemo } from 'react'
import { Loader } from 'components-new/common/Loader/Loader'
import { useSettingsContext } from 'containers-new/HomeContainer/SettingsContext'
import { NotFoundPage } from '../NotFoundPage/NotFoundPage'

export const POSTS_PER_PAGE = 3

export const BlogPage: React.FC<{}> = () => {
  const state = useSelector((s: RootState) => s.posts)
  const { featuredPost } = useSettingsContext()
  const dispatch = useDispatch()
  const { selectAll } = postsAdapter.getSelectors()

  useLayoutEffect(() => {
    dispatch(
      GetPosts({
        id_nin: featuredPost ? [featuredPost.id] : [],
        _sort: 'published_at:DESC',
        _limit: 100,
        _start: 0,
      })
    )
  }, [])

  const posts = useMemo(() => {
    return selectAll(state)
  }, [state])

  if (state.loading) {
    return <Loader fullScreen />
  }

  if (!posts.length) {
    return <NotFoundPage />
  }
  return (
    <div>
      <Seo title="Blog" />
      <Navbar withFixedPosition />
      <BlogContainer posts={posts} />
      <Footer img={footerImage} />
    </div>
  )
}
