import React, { FC } from 'react'
import { OurStory } from 'containers-new/AboutUsContainer/components/OurStory/OurStory'
import { OurValues } from 'containers-new/AboutUsContainer/components/OurValues/OurValues'
import { Quotation } from 'containers-new/AboutUsContainer/components/Quotation/Quotation'
import { LogoStory } from 'containers-new/AboutUsContainer/components/LogoStory/LogoStory'

export const AboutUsContainer: FC<{}> = () => {
  return (
    <>
      <OurStory />
      <OurValues />
      <LogoStory />
      <Quotation />
    </>
  )
}
